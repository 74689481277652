//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { MasterEntityModel } from '@app/@dynamic-widget/models/master-entity.model';

//#endregion functional/model imports

export class ListItemModel extends MasterEntityModel {

  //#region model properties
  //#endregion model properties

  //#region constructor

  constructor() {
    super();
  }

  //#endregion constructor

}
