//#region angular imports

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
//#endregion angular imports

//#region modules

import { AbpSuiteModule, AbpCalendarConstant, AbpNavMenuConstant, AbpDatepickerConstant, AbpTextboxConstant, AbpLabelConstant, AbpFormControlConfigModel, AbpValidationDisplayConstant, AbpConfigModel } from '@colibrium/abp-suite';

//#endregion modules

//#region components

import { FieldRowRendererComponent, DynamicFieldWidgetComponent, ArraySecRenderComponent } from '@app/@dynamic-widget/components';

//#endregion components

//#region directives
//#endregion directives

//#region pipes/filter
//#endregion pipes/filters

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AbpSuiteModule,
    CarouselModule,
  ],
  declarations: [

    //#region components
    
    DynamicFieldWidgetComponent,
    FieldRowRendererComponent,
    ArraySecRenderComponent
    
    //#endregion components

  ],
  exports: [

    //#region modules
    
    //#endregion modules

    //#region components
    
    DynamicFieldWidgetComponent,

    //#endregion components

    //#region directives
    //#endregion directives

    //#region pipes/filters
    //#endregion pipes/filters

  ]
})
export class DynamicWidgetModule {
  static forRoot(config: AbpConfigModel): ModuleWithProviders<DynamicWidgetModule> {
    return {
      ngModule: DynamicWidgetModule,
      providers: [
        { provide: AbpCalendarConstant.calendarConfigInjectKey, useValue: config.calendarConfig },
        { provide: AbpNavMenuConstant.navConfigInjectKey, useValue: config.navConfig },
        { provide: AbpDatepickerConstant.dateConfigInjectKey, useValue: config.formControlConfig.dateConfig },
        { provide: AbpTextboxConstant.textboxConfigInjectKey, useValue: config.formControlConfig.textConfig },
        { provide: AbpValidationDisplayConstant.validationDisplayConfigInjectKey, useValue: config.formControlConfig.validationConfig },
        { provide: AbpLabelConstant.labelConfigInjectKey, useValue: config.formControlConfig.labelConfig }
      ]
    };
  }

}
