import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { AppConfig } from '@shared/models/app-config.model';
import { Constant } from '@shared/utilities/constant';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js/dist/zone';

fetch('/assets/config.json')
  .then(response => response.json())
  .then((config: AppConfig) => {
    if (environment.production) {
      enableProdMode()
    }
    platformBrowserDynamic(
      [
        { provide: Constant.appConfig, useValue: config },
        { provide: Constant.configurations, useValue: config.authConfig },
        { provide: MSAL_CONFIG, useValue: config.authConfig.aadConfig.config },
        { provide: MSAL_CONFIG_ANGULAR, useValue: config.authConfig.aadConfig.angularConfig },
      ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err))
  })
