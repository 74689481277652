import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor() { }

  public saveProgramId(value: any) {
    sessionStorage.setItem('programId', value);
  }

  public getProgramId() {
    return sessionStorage.getItem('programId');
  }
  public removeProgramId() {
    sessionStorage.removeItem('programId');
  }
  public saveresourceCategoryId(value: any) {
    sessionStorage.setItem('resourcecategoryId', value);
  }

  public getresourceCategoryId() {
    return sessionStorage.getItem('resourcecategoryId');
  }
  public removeresourceCategoryId() {
    sessionStorage.removeItem('resourcecategoryId');
  }
  public savecategoryId(value: any) {
    sessionStorage.setItem('categoryId', value);
  }

  public getcategoryId() {
    return sessionStorage.getItem('categoryId');
  }
  public removecategoryId() {
    sessionStorage.removeItem('categoryId');
  }
}