import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoggerModel } from '@core/models/logger.model';
import { LogLevel } from '@core/utilities/log-level.enum';
import { CoreUrl } from '@core/utilities/core.url';
import { HttpUtility } from '@core/utilities/http.utility';

@Injectable()

export class LoggingService {

  constructor(private httpHelper: HttpUtility) {
  }

  /**
   * logs info message
   * @param message
   */
  public logInfo(message: string): Observable<LoggerModel> {
    return this.logData(LogLevel.Info, message);
  }

  /**
   * logs info message
   * @param message
   */
  public logError(message: string): Observable<LoggerModel> {
    return this.logData(LogLevel.Error, message);
  }

  /**
   * processes log data
   * @param level
   * @param message
   */
  private logData(level: LogLevel, message: string): Observable<any> {
    let logModel: LoggerModel = {
      logLevel: level,
      logMessage: message
    };
    return this.httpHelper.post(CoreUrl.api.logDataUrl, logModel);
  }

}
