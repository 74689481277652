//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { InputFieldModel, AbpButtonFieldModel, AbpLabelFieldModel, AbpDatepickerFieldModel, AbpIconButtonFieldModel } from '@colibrium/abp-suite';

import { ControlFieldType } from '@app/@dynamic-widget/enums';
import { MasterEntityModel } from '@app/@dynamic-widget/models/master-entity.model';
import { ListItemModel } from '@app/@dynamic-widget/models/list-item.model';
import { FieldValidationMapModel } from '@app/@dynamic-widget/models/field/field-validation-map.model';

//#endregion functional/model imports

export class FormFieldModel extends MasterEntityModel {

  //#region model properties

  public fieldType: ControlFieldType
  public placeHolder: string;
  public tooltipText: string;
  public validationMaps: FieldValidationMapModel[];
  public options: ListItemModel[];
  public fieldValue: any;
  public isDisabled: boolean;
  public triggerParentFieldValue: ListItemModel;
  public fieldCss: string;
  public iconClass: string;
  public internalfield : string;
  public externalfield : string;
  public isInputField: boolean; // Is custom Component input field
  public checked: boolean;
  public isLableOnLeft: boolean = false;

  public abpField: InputFieldModel | AbpLabelFieldModel | AbpButtonFieldModel | AbpIconButtonFieldModel; 

  //#endregion model properties

  //#region constructor

  constructor() {
    super();
    this.validationMaps = [];
  }

  //#endregion constructor

}
