import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../layout.service';
import { MenuLink } from '@shared/models/menu-link.model';
import { MenuLinkService } from '@shared/services/menu-link.service';
import { MenuType } from '@shared/enums/menu-type.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  public menu: MenuLink[];
  lefNaveExpanded: boolean = false;
  public step = 'step0';
  private tID = 0;

  constructor(private LayoutService: LayoutService, private menuService: MenuLinkService, private router: Router) {
    this.LayoutService.changeSlideState(false);
    // this.menuService.getMenuLink(MenuType.MenuLink).subscribe((res: any) => {
    //   this.menu = res;
    // });
  }

  ngOnInit(): void {}

  slideMe(){
    this.lefNaveExpanded = !this.lefNaveExpanded;
    if (this.lefNaveExpanded){
      this.LayoutService.changeSlideState(true);
    }else{
      this.LayoutService.changeSlideState(false);
    }
  }

  upstep(state){
    if (state === this.tID){
      this.step = 'step0';
      this.tID = 0;
    }else{
      this.tID = state;
      this.step = 'step' + state;
    }
  }
}
