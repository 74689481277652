import { Pipe } from "@angular/core";

@Pipe({
  name: 'novalue'
})
export class NoValuePipe {
  transform(rawVal) {
    if (rawVal) {
      let newStr = '';
      newStr = (rawVal != "")?rawVal:'-' ;
      return newStr;
    }
    else{
      return '-';
    }
    
  }
}