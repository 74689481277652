import { Component, OnInit, Input, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { CommonConfigInit } from '@app/dynamic-widgets/commonConfigInit';
import { Guid } from '@shared/utilities/guid';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '@shared/utilities/constant';
import { StorageConfigurations } from '@app/dynamic-widgets/models/storage-configurations.model';
import { StorageWidget } from '@app/dynamic-widgets/models/storage-widget.model';
import { TableColumns } from '@shared/controls/models/table-columns.model';
import { PaginationModel } from '@shared/models/pagination.model';
import { ListWidgetComponent } from '../list-widget/list-widget.component';
import { DashBoardService } from '@app/dashboard/dashboard.service';
import { DashboardResponse } from '@app/dashboard/models/dashboard.response.model';
import { Widget } from '@app/dashboard/models/widget.model';
import { WidgetConfig } from '@app/dashboard/models/widget-config.model';
import { StorageWidgetService } from './storage-widget.service';
import {FileDataModel } from '@app/dynamic-widgets/models/file-data.model';
import { WidgetEventModel } from '@app/dynamic-widgets/models/widget-event.model';
import { saveAs } from 'file-saver';
import mapIcons from './storageIcons.js';
import { FileRequestModel } from '@app/dynamic-widgets/models/file-request-model';

interface UploadDataDropDown {
  name: string;
  class: string;
  image: string;
}

@Component({
  selector: 'app-storage-widget',
  templateUrl: './storage-widget.component.html',
  styleUrls: ['./storage-widget.component.scss']
})
export class StorageWidgetComponent  implements OnInit, CommonConfigInit {
  @Input() isExpanded = false;
  @Input() input: string;
  @Input() titleConfig: WidgetTitleConfig;
  @Input() isFromDashboard: boolean;
  @Input() params: any;

public invoice: boolean;
  public cols: TableColumns[];
  public paginationModel: PaginationModel = Constant.defaultPagination;
  public count: number;
  public data: any;
  public isExportable: boolean;
  public isReset: boolean = false;
  public checkBoxSelection: boolean = false;
  public radioSelection: boolean = false;
  public searchFilter: boolean = false;
  public messages: string;
  fileToUpload: File;
  private dataSourceUrl: string;
  public spinnerName: string = Guid.randomId;
  private modalRef: NgbModalRef;
  public fileDataModel: FileDataModel;
  public storageListConfiguration: Widget;
  public files: File[] = [];
  public rejectedFiles: File[] = [];
  public errorMessages: string = '';
  public storageIcon: boolean = true;
  public uploadDataDropDown: UploadDataDropDown[];
  public selectedUploadDataDropDown: UploadDataDropDown;
  public fileRequestModel: FileRequestModel;

  @ViewChild('widgetModel', { read: TemplateRef, static: true }) widgetModel: TemplateRef<any>;
  @ViewChild('innerListwidget') innerListwidget: ListWidgetComponent;
  @Input() storageListwidget: WidgetTitleConfig;

  constructor(
      private modalService: NgbModal,
      private dashboardService: DashBoardService,
      private storageWidgetService: StorageWidgetService
  ) {
    this.uploadDataDropDown = [
      {name: 'Select', class: '', image: ''},
      {name: 'Liability Insurance (2)', class: '2', image: 'assets/tick-mark-icon-small.png'},
      {name: 'Accuracy Form', class: '3', image: 'assets/bell-icon.png'},
      {name: 'CMS Certificates (1)', class: '4', image: 'assets/tick-mark-icon-small.png'},
      {name: 'Other Documents', class: '5', image: ''},
    ];
     // future item to integrate data
   }

  ngOnInit(){
    if (this.input) {
      if (this.params) {
        this.input = this.input.replace(Constant.paramReqgex, (x) => {
          const value = this.params[x.slice(2, -2)];
          return value ? value : x;
        });
      }
      const res: StorageWidget = this.storageWidgetMapper(this.input);
      if (this.validateConfiguration(res))
      {
        let imagepath = 'assets/bell-icon.png';
        this.paginationModel.pageSize = res.pageSize ;
        this.dataSourceUrl = res.dataSourceUrl;
        this.checkBoxSelection = res.checkBoxSelection;
        this.searchFilter = res.searchFilter;
        this.radioSelection = res.radioSelection;
        this.messages = res.messages;
        this.titleConfig.image = imagepath;

        this.fileRequestModel = {
          storageTypeKey : res.storageTypeKey,
          subFolderPath : res.subFolderPath,
          overrideFile : res.overrideFile
        };
      }
      this.dashboardService.getDashboardConfiguration('StorageList').subscribe((res: DashboardResponse) => {
        this.storageListConfiguration = this.mapWidget(res.widgets[0]);
    });

    }

  }

  onSelect(event) {
    this.files.push(...event.addedFiles);

    this.files.forEach(f => {
      this.fileDataModel = {
        fileName : this.bIndFileImage(f.name),
        fileType : f.type.toUpperCase(),
        newFile : '<i class=\'newlabel\'>NEW</i>'
      };
      this.innerListwidget.data.unshift(this.fileDataModel);
    });

    this.rejectedFiles.push(...event.rejectedFiles);

    this.rejectedFiles.forEach(
      f => {if (f.type != 'image/png')
      {
        this.errorMessages += f.name + ' is a wrong format \n';
      }
    }
    );
    this.rejectedFiles.forEach(f => {
      if (f.size > 15000)
      {
        this.errorMessages += f.name + ' exceeding the file size \n';
      }
    });
    if (this.errorMessages !== '')
    {
     alert(this.errorMessages);
    }
    this.rejectedFiles = [];
    this.files = [];
    this.errorMessages = '';
  }

  public uploadmyfile(event)
  {
    this.files.forEach(f => {
      this.fileDataModel = {
        fileName : f.name,
        fileType : f.type,
        newFile: ''
      };
      this.uploadFileToActivity(f);
    });
    alert('File uploaded successfully.');
    this.innerListwidget.onRefresh();
  }

  public onRowAction(event: WidgetEventModel)
  {
    if (event.eventName == 'View')
    {
      this.fileRequestModel.fileName = event.data.fileName;
      const downloadedFile = this.DownloadFileFromPath(this.fileRequestModel);
    }
    if (event.eventName == 'Delete')
    {

    }
  }

  uploadFileToActivity(f: any) {
    this.storageWidgetService.postFile(f, this.fileRequestModel).subscribe(data => {
      this.storageWidgetService.postdataFile(this.fileDataModel).subscribe(data => {
      });
    });
  }

  DownloadFileFromPath(fileRequestModel: FileRequestModel): any{
    let thefile = {};
    this.storageWidgetService.downloadFileFromPath(fileRequestModel).subscribe(data => {
      saveAs(data, fileRequestModel.fileName);
      alert('File downloaded successfully.');
    }, error => {
      console.log(error);
    });
  }

  private bIndFileImage(fileName)
  {
      let myclass = '';
      let extension;
      let firstName;

      firstName = fileName.split('.').shift();
      extension = fileName.split('.').pop();

      Object.keys(mapIcons).forEach((ind) => {
        if (ind == extension.toLowerCase( )){
          myclass = mapIcons[ind];
        }
      });
      return fileName = myclass + firstName;
  }

  private validateConfiguration(config: StorageWidget): boolean {
    let isValid: boolean = true;
    if (!config) {
      isValid = false;
      console.error(`${this.titleConfig.title} - widget configuration missing`);
    }
    return isValid;
  }

  private storageWidgetMapper(value: string) {
    const inputRes: StorageConfigurations = JSON.parse(value);
    let res: StorageWidget = {
      dataSourceUrl: inputRes.dataSourceUrl,
      listSortableType: inputRes.listSortableType,
      messages: inputRes.messages,
      columnFields: inputRes.columnFields ? JSON.parse(inputRes.columnFields) : null,
      isExportable: inputRes.isExportable ? JSON.parse(inputRes.isExportable) : false,
      checkBoxSelection: inputRes.checkBoxSelection ? JSON.parse(inputRes.checkBoxSelection) : null,
      radioSelection: inputRes.radioSelection ? JSON.parse(inputRes.radioSelection) : null,
      searchFilter: inputRes.searchFilter ? JSON.parse(inputRes.searchFilter) : null,
      rowActions: inputRes.rowActions ? JSON.parse(inputRes.rowActions) : null,
      tableActions: inputRes.tableActions ? JSON.parse(inputRes.tableActions) : null,
      pageSize : inputRes.pageSize == null ? this.paginationModel.pageSize : inputRes.pageSize,
      storageTypeKey : inputRes.storageTypeKey ? inputRes.storageTypeKey : null,
      subFolderPath : inputRes.subFolderPath ? inputRes.subFolderPath : null,
      overrideFile: inputRes.overrideFile ? inputRes.overrideFile : null
    };
    return res;
  }

  /**
   * Expands list widget component
   * @param isExpand
   */
  public expand(isExpand: boolean) {

    this.isExpanded = isExpand;
    if (isExpand) {
      this.modalRef = this.modalService.open(this.widgetModel, { size: 'xl', keyboard: true });
      this.modalRef.result.then((result) => {
        this.isExpanded = false;
      },
        () => {
          this.isExpanded = false;
        });
    }
    else {
      this.modalRef.close();
    }
  }

  public onRefresh(): void {
    this.isReset = true;
    this.innerListwidget.onRefresh();
    this.innerListwidget.data = [];
  }


  private mapWidget(widget: WidgetConfig): Widget {
    return {
      name: widget.name,
      configuration: widget.configuration,
      titleConfiguration: {
        title: widget.commonConfiguration.title,
        isExpandable: widget.commonConfiguration.isExpandable ? JSON.parse(widget.commonConfiguration.isExpandable) : false,
        explicitRefreshEnabled: widget.commonConfiguration.explicitRefreshEnabled ? JSON.parse(widget.commonConfiguration.explicitRefreshEnabled) : false,
        dateFilterEnabled: widget.commonConfiguration.dateFilterEnabled ? JSON.parse(widget.commonConfiguration.dateFilterEnabled) : false,
        dragEnabled: widget.commonConfiguration.dragEnabled ? JSON.parse(widget.commonConfiguration.dragEnabled) : null,
        autoRefresh: widget.commonConfiguration.autoRefresh ? JSON.parse(widget.commonConfiguration.autoRefresh) : null,
      },
      commonConfiguration: {
        cols: parseInt(widget.commonConfiguration.cols),
        rows: parseInt(widget.commonConfiguration.rows),
        x: parseInt(widget.commonConfiguration.x),
        y: parseInt(widget.commonConfiguration.y),
        maxItemRows: widget.commonConfiguration.maxItemRows ? parseInt(widget.commonConfiguration.maxItemRows) : null,
        maxItemCols: widget.commonConfiguration.maxItemCols ? parseInt(widget.commonConfiguration.maxItemCols) : null,
        minItemRows: widget.commonConfiguration.minItemRows ? parseInt(widget.commonConfiguration.minItemRows) : null,
        minItemCols: widget.commonConfiguration.minItemCols ? parseInt(widget.commonConfiguration.minItemCols) : null,
        dragEnabled: widget.commonConfiguration.dragEnabled ? JSON.parse(widget.commonConfiguration.dragEnabled) : null,
        resizeEnabled: widget.commonConfiguration.resizeEnabled ? JSON.parse(widget.commonConfiguration.resizeEnabled) : null
      },
      actions: widget.actions
    };
  }
}
