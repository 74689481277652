//#region angular imports

import { Input, Optional, Injector, ViewChild, ElementRef, SimpleChanges, ChangeDetectorRef, Inject, Injectable } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { AbpValidationDisplayConstant, AbpValidationDisplayConfigModel } from '@colibrium/abp-validation-display';
import { AbpBaseConstant } from '@colibrium/abp-base';
import { AbpDatepickerFieldModel, AbpLabelFieldModel, AbpTextFieldModel, InputFieldModel, ValueAccessorBase } from '@colibrium/abp-form-control-base';

//#endregion functional/model imports

@Injectable()
export abstract  class ControlBase<T extends AbpTextFieldModel> extends ValueAccessorBase {

  //#region ViewChild

  @ViewChild('inputControlVariable', { static: false }) private inputControl: ElementRef;

  //#endregion ViewChild

  //#region input properties

  @Input() field: T;
  @Input() isFormSubmitted: boolean;
  @Input() id: string;

  //#endregion input properties

  //#region model properties

  public control: BehaviorSubject<FormControl> = new BehaviorSubject<FormControl>(null);
  public isDynamic: boolean = false;

  public inputControlCss: string = '';

  public displayLabelField: AbpLabelFieldModel;

  //#endregion model properties

  //#region constructor

  constructor(@Optional() protected injector: Injector,
    @Inject(AbpValidationDisplayConstant.validationDisplayConfigInjectKey) protected validationConfig: AbpValidationDisplayConfigModel,
    protected changeDetector: ChangeDetectorRef) {
    super();
    this.validationConfig = this.getValidationConfigDefaults(this.validationConfig);
  }

  //#endregion constructor

  //#region public functions

  //#region life cycle event methods

  /**
   * on initialization
   * @param controlClass
   */
  protected onInitialization(controlClass: string = AbpBaseConstant.cssClass.abp_input_control) {
    this.setDisplayLabelField();
    this.setInputControlClass(controlClass);
    this.setValueBase(this.getInitialValue());
  }

  /**
   * on ng on changes
   * @param simpleChanges
   * @param controlClass
   */
  protected onNgInputChanges(simpleChanges: SimpleChanges, controlClass: string = AbpBaseConstant.cssClass.abp_input_control) {
    if (simpleChanges.field && simpleChanges.field.previousValue != simpleChanges.field.currentValue) {
      this.onInitialization(controlClass);
    }
    if (simpleChanges.isFormSubmitted && simpleChanges.isFormSubmitted.previousValue != simpleChanges.isFormSubmitted.currentValue) {
      if (!this.isFormSubmitted) {
        this.clearValidationClasses();
      }
    }
  }

  /**
   * on view initialization
   */
  protected onViewInitialization() {
    this.setControl();
  }

  //#endregion life cycle event methods

  /**
   * runs change detection on demand
   */
  public runChangeDetection() {
    this.onInitialization(this.inputControlCss);
    //reset css classes before running change detection accordingly in order to refresh UI
    if (!this.isFormSubmitted) {
      this.clearValidationClasses();
    }
    this.changeDetector.detectChanges();
  }

  /**
   * on input change
   */
  public onInputChange(val: any): void {
    this.setValueBase(val);
    if (this.field instanceof InputFieldModel && this.field.changeEventHandler) {
      this.field.changeEventHandler(val);
    }
  }

  /**
   * on input blur
   * @param val
   */
  public onInputBlur(val: any): void {
    this.setValueBase(val);
    if (this.field instanceof AbpTextFieldModel) {
      if (this.field.blurEventHandler) {
        this.field.blurEventHandler(val);
      }
    }
  }

  /**
   * sets base value for all events
   * @param val
   */
  public setValueBase(val: any): void {
    if (val == undefined) {
      val = null;
    }
    this.value = val;
    this.field.fieldValue = this.value;
    if (this.isDynamic && this.control) {
      this.control.value.setValue(this.value);
    }

  }

  /**
   * sets base control csss class
   * @param controlClass
   */
  public setInputControlClass(controlClass: string) {
    this.inputControlCss = this.field.cssClass ? this.field.cssClass : controlClass;
  }

  /**
   * sets class for control based on validity
   * @param isValid
   */
  public setClassForControlValidity(isValid: boolean) {
    if (this.inputControl) {
      this.clearValidationClasses();
        if (isValid == true) {
          if (this.validationConfig.successControlClass) {
            this.inputControl.nativeElement.classList.add(this.validationConfig.successControlClass);
          }
        }
        else if (isValid == false) {
          if (this.validationConfig.errorControlClass) {
            this.inputControl.nativeElement.classList.add(this.validationConfig.errorControlClass);
          }
        }
    }
  }

  /**
   * sets display label field
   */
  public setDisplayLabelField(): void {
    this.displayLabelField = new AbpLabelFieldModel();
    if (this.field) {
      this.displayLabelField.fieldCode = this.field.fieldCode;
      this.displayLabelField.displayLabelText = this.field.displayLabelText;
      this.displayLabelField.isVisible = this.field.isVisible;
      this.displayLabelField.isRequired = this.field.isRequired;
      this.displayLabelField.displayLabelCss = this.field.displayLabelCss;
    }
  }

  //#endregion public functions

  //#region private functions

  /**
   * sets control value
   */
  private setControl() {
    if (!this.isDynamic) {
      this.control.next(this.injector.get(NgControl).control as FormControl);
      //this.control.unsubscribe();
    }
  }

  /**
   * clears classes for validations
   */
  private clearValidationClasses() {
    if (this.inputControl && this.inputControl.nativeElement && this.inputControl.nativeElement.classList) {
      if (this.inputControl.nativeElement.classList.contains(this.validationConfig.successControlClass)) {
        this.inputControl.nativeElement.classList.remove(this.validationConfig.successControlClass);
      }
      if (this.inputControl.nativeElement.classList.contains(this.validationConfig.errorControlClass)) {
        this.inputControl.nativeElement.classList.remove(this.validationConfig.errorControlClass);
      }
    }
  }

  /**
   * gets default values for configs
   * @param injectionModel
   */
  private getValidationConfigDefaults(injectionModel: AbpValidationDisplayConfigModel) {
    if (!injectionModel) {
      injectionModel = {
        errorControlClass: AbpBaseConstant.cssClass.abp_input_control_error,
        errorTextClass: AbpBaseConstant.cssClass.abp_error_text,
        showErrorOnSubmitOnly: false,
        successControlClass: AbpBaseConstant.cssClass.abp_input_control_valid
      }
    }
    return injectionModel;
  }

  /**
   * gets initial control value from FormControl if defined. Else gets field value
   */
  private getInitialValue() {
    if (this.control && this.control.value && this.control.value.value) {
      return this.control.value.value;
    }
    else {
      return this.field.fieldValue;
    }
  }

  //#endregion private functions

}
