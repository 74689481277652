<p-table [columns]="columns" [value]="data" [lazy]="isLazyLoad" [resizableColumns]="true" stickyHeader="true"
  (onLazyLoad)="loadLazyData($event)" [paginator]="canShowPaginator" [pageLinks]="pageLinks" [rows]="pageSize"
  [totalRecords]="totalCount" sortMode="multiple" [(selection)]="selectedItems" [lazyLoadOnInit]="true"
  [globalFilterFields]="['col.header']" [autoLayout]="true"
  [ngClass]="[hideHeader?'headerDisplay':'', resourceWidgetStyle?'resourceWidgetStyle':'']" class="table-scroll"
  #tableRef>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngIf="checkBoxSelection">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngIf="radioSelection"></th>
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="col.feature">
          <th *appAuthCheck="col.feature" [ngClass]="{'status-header-alignment': col.css}" [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="!col.isSortable">
            {{ col.header }}
          </th>
        </ng-container>
        <ng-container *ngIf="!col.feature">
          <th [ngClass]="{'status-header-alignment': col.css}" [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="!col.isSortable">
            {{ col.header }}
          </th>
        </ng-container>
      </ng-container>
      <th *ngIf="rowActions">
        {{rowActions.length > 1 ? 'Actions' : 'Action' }}
      </th>
    </tr>
    <tr *ngIf="searchFilter">
      <th *ngIf="checkBoxSelection"></th>
      <th *ngFor="let col of columns" [ngSwitch]="col.searchType">
        <p-dropdown *ngSwitchCase="'dropdown'" [options]="getDropdownOptions(col.field)"
          (onClick)="getDropdownOptionsEvent(col.field)" (onChange)="tableRef.filter($event.value, col.field, 'equals')"
          styleClass="p-column-filter-dropdown" placeholder="Select a value" [showClear]="true">
          <ng-template let-option pTemplate="item">
            <span>{{option.label}}</span>
          </ng-template>
        </p-dropdown>

        <input *ngSwitchCase="'text'" pInputText type="text" placeholder="Search..." class="p-column-filter"
          (input)="tableRef.filter($event.target.value, col.field, 'contains')" />
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" (click)="onNavigate(null,true,null, rowData)">
      <td *ngIf="checkBoxSelection">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngIf="radioSelection">
        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
      </td>
      <td *ngIf="rowActions">
        <ng-template ngFor let-index='index' let-action [ngForOf]="rowActions">
          <span *ngIf="action.IsStartActionIcon">
            <a href="javascript:;" class="ml-1 mr-1" [ngClass]="{'common-blue-btn rounded-btn': !action.actionIcon}"
              (click)="emitRowEvent(action, rowData)" data-toggle="tooltip" [title]="action.action">
              <i *ngIf="action.actionIcon" [ngClass]="action.actionIcon"></i>
              <span *ngIf="!action.actionIcon">{{action.action}}</span>
            </a>
          </span>
        </ng-template>
      </td>
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="col.feature">
          <td *appAuthCheck="col.feature"  [ngClass]="{'status-alignment': col.css}" class="{{col.alignment}}">
            <span *ngIf="col.isInitial" class="name-initial"
              [ngStyle]="{'background-color': GetRandomColor(rowData[col.field])}">{{GetInitial(rowData[col.field])}}</span>
            <span *ngIf="col.css" class="status {{GetCssColor( rowData[col.field], col.css)}}"></span>
            <span *ngIf="col.image" style="margin-right: 20px;">
              <img title="icon" src="{{col.image}}">
            </span>
            <span [ngSwitch]="col.actionLink">
              <span *ngSwitchCase="actionsLinkList.StaticAction">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,null,rowData)"> {{
                      rowData[col.field] | phone }} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,null,rowData)"> {{
                      rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.StaticActionWithId">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,rowData[col.field], rowData)">
                      {{ rowData[col.field] | phone }} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,rowData[col.field], rowData)">
                      {{ rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteAction">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,null,rowData)">
                      {{ rowData[col.field] | phone}} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,null,rowData)">
                      {{ rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteActionWithId">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,rowData[col.field],rowData)">
                      {{ rowData[col.field] | phone}} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,rowData[col.field],rowData)">
                      {{ rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteDynamic">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(rowData[col.redirectionUrl],false,null,rowData)">
                      {{ rowData[col.field | phone]}} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(rowData[col.redirectionUrl],false,null,rowData)">
                      {{ rowData[col.field]}} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteImage">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(rowData[col.fieldUrl],false,null,rowData)">
                      <img title="icon" *ngIf='rowData[col.field]' [ngClass]="[imageStyle?'imageDisplay' :'']" src="{{rowData[col.field]| phone}}}">
                    </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(rowData[col.fieldUrl],false,null,rowData)">
                      <img  title="icon" *ngIf='rowData[col.field]' [ngClass]="[imageStyle?'imageDisplay' :'']" src="{{rowData[col.field]?rowData[col.field]:''}}">
                    </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchDefault>
                <span *ngIf="col.isMultiField; else normal" class="heading">
                  <span *ngFor="let coll of col.multiField">
                    <span [ngSwitch]="col.type">
                      <div *ngSwitchCase="controlList.mobile"> {{rowData[coll]?(rowData[coll].trim()|phone):''}}
                      </div>
                      <div *ngSwitchDefault> {{rowData[coll]?(rowData[coll].trim()):''}}
                      </div>
                    </span>
                  </span>
                </span>
                <ng-template #normal>
                  <span [ngSwitch]="col.type">
                    <span *ngSwitchCase="controlList.mobile">
                      {{rowData[col.field] | phone}}
                    </span>
                    <span *ngSwitchDefault [innerHTML]=rowData[col.field]>
                    </span>
                  </span>
                </ng-template>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container *ngIf="!col.feature">
          <td  *ngIf='rowData[col.field]' [ngClass]="{'status-alignment': col.css}" class="{{col.alignment}}">
            <span *ngIf="col.isInitial" class="name-initial"
              [ngStyle]="{'background-color': GetRandomColor(rowData[col.field])}">{{GetInitial(rowData[col.field])}}</span>
            <span *ngIf="col.css" class="status {{GetCssColor( rowData[col.field], col.css)}}"></span>
            <span *ngIf="col.image" style="margin-right: 20px;">
              <img title="icon" src="{{col.image}}">
            </span>
            <span [ngSwitch]="col.actionLink">
              <span *ngSwitchCase="actionsLinkList.StaticAction">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,null,rowData)"> {{
                      rowData[col.field] | phone }} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,null,rowData)"> {{
                      rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.StaticActionWithId">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,rowData[col.field], rowData)">
                      {{ rowData[col.field] | phone }} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,true,rowData[col.field], rowData)">
                      {{ rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteAction">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,null,rowData)">
                      {{ rowData[col.field] | phone}} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,null,rowData)">
                      {{ rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteActionWithId">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,rowData[col.field],rowData)">
                      {{ rowData[col.field] | phone}} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(col.redirectionUrl,false,rowData[col.field],rowData)">
                      {{ rowData[col.field] }} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteDynamic">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(rowData[col.redirectionUrl],false,null,rowData)">
                      {{ rowData[col.field | phone]}} </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(rowData[col.redirectionUrl],false,null,rowData)">
                      {{ rowData[col.field]}} </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchCase="actionsLinkList.RouteImage">
                <span [ngSwitch]="col.type">
                  <span *ngSwitchCase="controlList.mobile">
                    <a href="javascript:;" (click)="onNavigate(rowData[col.fieldUrl],false,null,rowData)">
                      <img title="icon" *ngIf='rowData[col.field]' [ngClass]="[imageStyle?'imageDisplay' :'']" src="{{rowData[col.field]| phone}}}">
                    </a>
                  </span>
                  <span *ngSwitchDefault>
                    <a href="javascript:;" (click)="onNavigate(rowData[col.fieldUrl],false,null,rowData)">
                      <img title="icon" *ngIf='rowData[col.field]' [ngClass]="[imageStyle?'imageDisplay' :'']" src="{{rowData[col.field]?rowData[col.field]:''}}">
                    </a>
                  </span>
                </span>
              </span>
              <span *ngSwitchDefault>
                <span *ngIf="col.isMultiField; else normal" class="heading">
                  <span *ngFor="let coll of col.multiField">
                    <span [ngSwitch]="col.type">
                      <div *ngSwitchCase="controlList.mobile">{{rowData[coll]?(rowData[coll].trim()|phone):''}}
                      </div>
                      <div *ngSwitchDefault>{{rowData[coll]?(rowData[coll].trim()):''}}
                      </div>
                    </span>
                  </span>
                </span>
                <ng-template #normal>
                  <span [ngSwitch]="col.type">
                    <span *ngSwitchCase="controlList.mobile">
                      {{rowData[col.field] | phone}}
                    </span>
                    <span *ngSwitchDefault [innerHTML]=rowData[col.field]>
                    </span>
                  </span>
                </ng-template>
              </span>
            </span>
          </td>
        </ng-container>
      </ng-container>
      <td *ngIf="rowActions">
        <ng-template ngFor let-index='index' let-action [ngForOf]="rowActions">
          <span *ngIf="!action.IsStartActionIcon">
            <a href="javascript:;" class="ml-1 mr-1" [ngClass]="{'common-blue-btn rounded-btn': !action.actionIcon}"
              (click)="emitRowEvent(action, rowData)" data-toggle="tooltip" [title]="action.action">
              <i *ngIf="action.actionIcon" [ngClass]="action.actionIcon"></i>
              <span *ngIf="!action.actionIcon">{{action.action}}</span>
            </a>
          </span>
        </ng-template>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <span *ngIf="!noRecordsMessage">
      <tr class="no-records">
        <td [attr.colspan]="totalColspan" style="text-align: center;display: block !important;">
          No result​s found for the given criteria
        </td>
      </tr>
    </span>
    <span *ngIf="noRecordsMessage">
      <tr style="border-bottom: none !important" class="no-records">
        <td style="text-align: center;display: block !important;">
          {{noRecordsMessage}}
        </td>
      </tr>
    </span>
  </ng-template>
</p-table>
<!-- <div style="height: 5px; width: 100%; background-color: violet;"></div> -->
<div *ngIf="tableActions" class="footer">
  <div class="float-right-imp">
    <div class="justify-content-center">
      <ng-template ngFor let-index='index' let-action [ngForOf]="tableActions">
        <a href="javascript:;" class=" common-blue-btn rounded-btn ml-1 mr-1"
          [ngClass]="{'common-blue-btn': (action.type == actionList.event && !isRowsSelected)}"
          (click)="emitTableEvent(action)">
          <i *ngIf="action.actionIcon" [ngClass]="action.actionIcon"></i>
          {{action.action}}
        </a>
      </ng-template>
    </div>
  </div>
</div>
