export interface Resource {
    categoryName:  string;
    categoryId:    string;
    resourcebycat: Resourcebycat[];
    resourcecount: number;
}

export interface Resourcebycat {
    memberID:      null;
    populationID:  null;
    resourceID:    string;
    resourceName:  string;
    resourceOrder: null;
    type:          Type;
    contentURL:    string;
    description:   string;
    imageURL:      null | string;
    categoryID:    string;
    isNew:         IsNew;
    priority:      Priority;
    startDate:     Date;
    endDate:       Date;
    categoryId:    string;
    categoryName:  null;
    id:            string;
    deletedDate:   null;
    isDeleted:     boolean;
    createdBy:     null;
    createdDate:   Date;
    updatedBy:     null;
    updatedDate:   null;
}

export enum IsNew {
    N = "N",
    Y = "Y",
    no = "no",
    No = "No",
    yes = "yes",
    Yes = "Yes",
}

export enum Priority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
    VeryHigh = "Very High",
    Urgent = "Urgent",
}

export enum Type {
    WebsiteLink = "Website-Link",
    PDF = "pdf"

}