//#region angular imports

import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

//#endregion angular imports

//#region core imports

//#endregion core imports

//#region functional/model imports

import { ControlHelper } from '@app/@dynamic-widget/form-helpers/control.helper';
import { ReactiveFormHelper } from '@app/@dynamic-widget/form-helpers/reactive-form.helper';
import { ColumnModel, FormFieldModel, RowModel, SectionInfoModel } from '@app/@dynamic-widget/models';
import { StringHelper } from '@colibrium/abp-base';
import { AppConfigModel } from '../models/app-config.model';
import { ControlFieldType } from '@app/@dynamic-widget/enums';
import { CalendarConfig } from '@shared/controls/models/calendar-config.model';
import { Type } from '@angular/core';
import { DashboardConstant } from '@app/dashboard/dashboard.constant';
import { controlConfig } from '@shared/utilities/control.config';
import * as _ from 'lodash';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { Constant } from '@shared/utilities/constant';
import { DatePickerComponent } from '@app/dynamic-widgets/date-picker/date-picker.component';

//#endregion functional/model imports

export class ScreenBase<T> {

  //#region model properties

  public model: T
  public group: FormGroup;
  public show: boolean = false;
  public formSubmitted: boolean = false;
  public defaultCalendarconfig: CalendarConfig = {
    dateFormat: DashboardConstant.dateFilter.dateFormat,
    hourFormat: DashboardConstant.dateFilter.hourFormat,
    readonlyInput: false,
    showSeconds: false,
    showTime: false,
    showNavigator: false,
    appendTo: 'body'
  };
  //#endregion model properties

  //#region constructor

  constructor(protected formBuilder: FormBuilder) {
    this.group = this.formBuilder.group({});
  }

  //#endregion constructor

  //#region protected functions/events assoaciated with UI elements

  /**
   * maps field form controls for rows
   * @param sections
   * @param callBack
   */
  protected mapFieldFormControls(sections: SectionInfoModel[], sectionCode: string = "", formGroup : FormGroup,
    callBack: (_sectionInfo: SectionInfoModel, _rowModel: RowModel<FormFieldModel>, _column: ColumnModel<FormFieldModel>) => {} = null) {
    if (sections) {
      if (sectionCode != "") {
        var section = sections.find(map => map.section.code == sectionCode);
        section.section.code = StringHelper.toSafeTrim(section.section.code);
        formGroup.setControl(section.section.code, this.getFormGroup(section, callBack));
      } else {
        sections.forEach((map) => {
          map.section.code = StringHelper.toSafeTrim(map.section.code);
          formGroup.setControl(map.section.code, this.getFormGroup(map, callBack));
        });
      }
    }
  }

  /**
   * adds form controls to group
   * @param sectionFieldMap
   * @param callBack
   */
  protected getFormGroup(sectionFieldMap: SectionInfoModel,
    callBack: (_sectionInfo: SectionInfoModel, _rowModel: RowModel<FormFieldModel>, _column: ColumnModel<FormFieldModel>) => {} = null): FormGroup {
    let sectionGroup: FormGroup = this.formBuilder.group({});
    if (sectionFieldMap && sectionFieldMap.rowFields && sectionFieldMap.rowFields.length > 0) {
      sectionFieldMap.rowFields.forEach((row) => {
        if (row.columns && row.columns.length > 0) {
          row.columns.forEach((column) => {
            this.addFormControls(sectionGroup, sectionFieldMap, row, column, callBack);
          });
        } else if (row.subSection && row.subSection.length > 0) {
          this.getFormArray(sectionGroup, row.subSection, sectionFieldMap.section.code, callBack);
        }
      });
    }
    return sectionGroup;
  }

  /**
   * adds form controls to array
   * @param sectionFieldMap
   * @param callBack
   */
  protected getFormArray(fgroup: FormGroup, subSections: SectionInfoModel[], code: string,
    callBack: (_sectionInfo: SectionInfoModel, _rowModel: RowModel<FormFieldModel>, _column: ColumnModel<FormFieldModel>) => {} = null) {
    let sectionArray: FormArray = this.formBuilder.array([]);

    if (subSections) {
      subSections.forEach((map) => {
        map.section.code = StringHelper.toSafeTrim(map.section.code);
        sectionArray.push(this.getFormGroup(map, callBack));
      });
      fgroup.setControl(code, sectionArray);
    }
  }

  /**
   * adds form controls for each row
   * @param fgroup
   * @param sectionInfo
   * @param rowModel
   * @param col
   * @param callBack
   */
  protected addFormControls(
    fgroup: FormGroup,
    sectionInfo: SectionInfoModel,
    rowModel: RowModel<FormFieldModel>,
    col: ColumnModel<FormFieldModel>,
    callBack: (_sectionInfo: SectionInfoModel, _rowModel: RowModel<FormFieldModel>, _column: ColumnModel<FormFieldModel>) => {} = null) {

    col.fieldInfo.abpField = ControlHelper.getAbpField(col.fieldInfo, col.cssClass, this.configDataMapper());
    
    this.customFieldInputs(fgroup, col);
    if (callBack) {
      callBack(sectionInfo, rowModel, col);
    }

    if (ControlHelper.isInputControl(col.fieldInfo)) {
      col.fieldInfo.code = StringHelper.toSafeTrim(col.fieldInfo.code);
      ReactiveFormHelper.addFormControl(fgroup, col.fieldInfo);
    }
    if (col.childList && col.childList.length > 0) {
      col.childList.forEach((childRow) => {
        childRow.columns.forEach((childColumn) => {
          this.addFormControls(fgroup, sectionInfo, childRow, childColumn, callBack);
        });
      });
    }
  }

  protected customFieldInputs(fgroup: FormGroup, col: ColumnModel<FormFieldModel>) {
    //TODO - Need to come up with an approach of adding this custom inputs.
    if (col.fieldInfo.fieldType == ControlFieldType.Custom) {
      if (col.customComponent.componentName == "DatePickerComponent") {
        col.customComponent.componentClass = DatePickerComponent;
        var dateConfig = _.cloneDeep(this.defaultCalendarconfig);
        if(col.fieldInfo.validationMaps){
          col.fieldInfo.validationMaps.forEach(map => {
            if (map.validatorCode == Constant.validatorCode.minDate) {
              if (map.configValue == DynamicWidgetsConstant.dateComponentConstants.currentDate) {
                dateConfig.minDate = new Date();
              } else {
                dateConfig.minDate = new Date(map.configValue);
              }
            } else if (map.validatorCode == Constant.validatorCode.maxDate) {
              if (map.configValue == DynamicWidgetsConstant.dateComponentConstants.currentDate) {
                dateConfig.maxDate = new Date();
              } else {
                dateConfig.maxDate = new Date(map.configValue);
              }
            }
          })
        }
        
        col.customComponent.componentInputModel = new Map<string, any>();
        col.customComponent.componentInputModel.set("config", dateConfig);
        col.customComponent.componentInputModel.set("formControlName", col.fieldInfo.code);
        col.customComponent.componentInputModel.set("showIcon", true);
        col.customComponent.componentInputModel.set("placeholder", col.fieldInfo.placeHolder);
        col.customComponent.componentInputModel.set("field", col.fieldInfo.abpField);
        col.customComponent.componentInputModel.set('dateValue', col.fieldInfo.fieldValue);

        col.fieldInfo.code = StringHelper.toSafeTrim(col.fieldInfo.code);
        ReactiveFormHelper.addFormControl(fgroup, col.fieldInfo);
      }
    }
  }

  /**
   * gets secMap for given code
   * @param secMaps
   * @param secCode
   */
  protected getSectionMapsForCode(secMaps: SectionInfoModel[], secCode: string): RowModel<FormFieldModel>[] {
    if (secMaps && secMaps.length > 0) {
      let sMap = secMaps.find(x => x.section.code == secCode);
      if (sMap) {
        return sMap.rowFields;
      }
    }
  }

  /**
   * gets abp field data
   * @param rowFields
   * @param fieldCode
   */
  protected getAbpField(rowFields: RowModel<FormFieldModel>[], fieldCode: string): FormFieldModel {
    let field: FormFieldModel = null;
    if (rowFields && rowFields.length > 0) {

      let index = -1;
      for (let row of rowFields) {
        index = row.columns.findIndex(x => x.fieldInfo.code == fieldCode);
        if (index > -1) {
          field = row.columns[index].fieldInfo;
          break;
        }
        else {
          //Check child lisst
          for (let column of row.columns) {
            field = this.getAbpField(column.childList, fieldCode);
            if (field) {
              break;
            }
          }
        }
      }

    }
    return field;
  }

  /**
   * returns form control value if defined. else returns null
   * @param formGroup
   * @param controlName
   */
  protected getFormControlValue(formGroup: FormGroup, controlName: string) {
    if (formGroup && controlName) {
      let control = formGroup.get(controlName);
      if (control) {
        return control.value;
      }
    }
    return null;
  }

  /**
   * sets form control value if defined
   * @param formGroup
   * @param controlName
   */
  protected setFormControlValue(formGroup: FormGroup, controlName: string, value: any) {
    if (formGroup && controlName) {
      let control = formGroup.get(controlName);
      if (control) {
        control.setValue(value);
      }
    }
  }

  private configDataMapper() {
    let appConfigData: AppConfigModel = new AppConfigModel();

    appConfigData.defaultSelectText = ' Select ';
    appConfigData.noneOptionText = 'None of the above';
    appConfigData.selectAllOptionText = 'Select All';
    return appConfigData;
  }

  //#endregion protected functions/events assoaciated with UI elements

  //#region private functions

  //#endregion private functions

}

