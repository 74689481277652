import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';

import { ChartType, ChartOptions , PositionType, ChartData} from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts/public_api';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';

import { ChartWidgetConfiguration } from '@app/dynamic-widgets/models/chart-widget-configuration.model';
import { ChartLegendPosition } from '@app/dynamic-widgets/enums/chart-legend-position.enum';
import { ChartLabelStyle } from '@app/dynamic-widgets/enums/chart-label-style.enum';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { CustomChartType } from '@app/dynamic-widgets/enums/custom-chart-type.enum';
import { DateFilter } from '@shared/models/date-filter.model';
import { ChartFilterData } from '@app/dynamic-widgets/models/chart-filter-data.model';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements OnInit {

  @Input() input: string;
  @Input() titleConfig: WidgetTitleConfig;
  @Input() set isExpanded(value: boolean)
  {
    this._isExpanded = value;
    //this.reDrawChart();
    this.setChartOptions(); 
  }

  @Input() isFromDashboard: boolean;
  get isExpanded()
  {
    return this._isExpanded;
  }

  private _isExpanded: boolean = false;
  public chartLabels: Label[] = [];
  public chartType: ChartType = CustomChartType.Bar;

  /** This is the plugin to bind the value to the chart legend */
  public LegendDataPlugins = {
    afterLayout: function (chart) {
      chart.legend.legendItems.forEach(
        (label, index) => {
          if(chart.config.type == CustomChartType.Pie && chart.data.datasets.length == 1)
          {
            let sum = 0;
            let dataArr = chart.data.datasets[0].data;
            dataArr.forEach(data => {
              sum += data;
            });
            let percentage = (dataArr[index]*100 / sum).toFixed(2)+"%";         
            label.text = percentage + ' ' + label.text;
          }
          else if(chart.config.type == CustomChartType.Bar && index <= chart.data.datasets.length && chart.data.datasets[index].data.length == 1)
          {
            let value = chart.data.datasets[index].data[0];
            label.text = value + ' ' + label.text;
          }      
          return label;
        }
      )
    }
  };
  

  public chartPlugins = [pluginDataLabels, this.LegendDataPlugins];

  public colors: Color[] = [];

  public chartData: ChartData = {
    labels: [],
    datasets: []
  };

  public chartOptions: ChartOptions = {      
    responsive: true,
    title: {},
    elements: {
      line: {fill:false}
    },
    animation:
    {
      duration: 1000
    },
    legend: {

    }
  };

  public ChartConfiguration: ChartWidgetConfiguration;

  public showLoader: boolean;

  private modalRef: NgbModalRef;

  @ViewChild('widgetModel', { read: TemplateRef, static: true }) widgetModel: TemplateRef<any>;

  @ViewChild('baseChart') chartRef: BaseChartDirective;

  constructor(private widgetService: DynamicWidgetsService,
    private modalService: NgbModal) { }


  ngOnInit() {    

    this.ChartConfiguration = JSON.parse(this.input);
    this.chartType = this.getChartType(this.ChartConfiguration.chartType);   
    this.setChartOptions();
    this.setData(this.ChartConfiguration.dataSourceUrl, {});
  }
  
  /**
   * Applies date filter and gets the data
   * @param datefilter 
   */
  public applyFilter(datefilter: DateFilter)
  {
    this.setData(this.ChartConfiguration.dataSourceUrl, { dateFilter: datefilter });
  }

  public expand(isExpand: boolean) {
    this.isExpanded = isExpand;
    if (isExpand) {
      this.modalRef = this.modalService.open(this.widgetModel, { size: 'xl', keyboard: true });
      this.modalRef.result.then((result) => {
        this.isExpanded = false;
      },
        () => {
          this.isExpanded = false;
        });
    }
    else {
      this.modalRef.close();
    }
  }

  /**
   * Sets chart data by making http call to the provided url
   * @param url 
   */
  private setData(url: string, chartFilter: ChartFilterData)
  {
    if(url)
    {
      this.showLoader = true;
      this.widgetService.getChartData(url, chartFilter).subscribe((data: ChartData) => {  
        this.chartData = data;    
        this.showLoader = false;
      });
    }
  }

  /**
   * Sets chart data labels
   * @param dataLabelStyle 
   */
  private setDataLabel(dataLabelStyle: string)
  {
    this.chartOptions.plugins = {
     datalabels : this.getDataLabelOptions(dataLabelStyle)
    }
  }

  

  /**
   * Returns chart data label options based on given style
   * @param dataLabelStyle 
   */
  private getDataLabelOptions(dataLabelStyle: string) : LabelOptions
  {
    switch(dataLabelStyle.toLowerCase())
    {
      case ChartLabelStyle.None: return  {  display: false  };
      case ChartLabelStyle.Inner: return { display: true, align: 'start', anchor: 'end', clamp:true, clip:true };
      case ChartLabelStyle.Outer: return { display: true, align: 'end', anchor: 'end', clamp:true, clip:true }
      default: return  {  display: false  };
    }
  }

  /**
   * Sets chart title
   */
  private setTitle()
  {
    this.chartOptions.title.display = this.isExpanded && (this.ChartConfiguration.chartTitle || []).length > 0;
    this.chartOptions.title.text = this.ChartConfiguration.chartTitle || '';
    this.chartOptions.title.position = 'bottom';
  }

  /**
   * Sets chart legend
   */
  private setLegend()
  {
    this.chartOptions.legend.display = this.isExpanded && this.ChartConfiguration.legendPosition.toLowerCase() != ChartLegendPosition.None;
    this.chartOptions.legend.position = this.getLegendPosition(this.ChartConfiguration.legendPosition);
    this.chartOptions.legend.fullWidth = true;
  }
 
   /**
   * Sets chart scales
   */
  private setScales()
  {
    if(this.chartType == 'line' || this.chartType == 'bar' || this.chartType == "horizontalBar")
    {
      this.chartOptions.scales = {
        yAxes: [
          {
            display: this.isTrue(this.ChartConfiguration.showYAxis),
            ticks:{
              beginAtZero: true,
              fontSize: 12,
              fontFamily: 'Poppins-Regular'
            },
            scaleLabel: {
              display: (this.ChartConfiguration.yAxisTitle || []).length > 0 ,
              labelString: this.ChartConfiguration.yAxisTitle,
              fontStyle: 'bold',
              fontSize:14,
              fontFamily:'Poppins-Regular'
            },
            gridLines: { color: "rgba(0, 0, 0, 0)",}
          }
        ],
        xAxes: [
          {
            display: this.isTrue(this.ChartConfiguration.showXAxis),
            scaleLabel: {
              display: (this.ChartConfiguration.xAxisTitle || []).length > 0,
              labelString: this.ChartConfiguration.xAxisTitle,
              fontStyle: 'bold',
              fontSize: 14,
              fontFamily: 'Poppins-Regular'
            },
            ticks:
            {
             // display: this.isExpanded,
              fontSize: 12,
              fontFamily: 'Poppins-Regular'
            },
            gridLines: {color: "rgba(0, 0, 0, 0)", }
          }
        ]
      }
    }
  }

  /**
   * Returns chart type by given string
   * @param type 
  */
  private getChartType(type: string) : ChartType
  {   
    switch(type)
    {
      case CustomChartType.Line: return CustomChartType.Line;
      case CustomChartType.Bar: return CustomChartType.Bar;
      case CustomChartType.Pie: return CustomChartType.Pie;
      case CustomChartType.HorizontalBar: return CustomChartType.HorizontalBar;
      case CustomChartType.Radar: return CustomChartType.Radar;
      case CustomChartType.Doughnut: return CustomChartType.Doughnut;
      case CustomChartType.PolarArea: return CustomChartType.PolarArea;
      case CustomChartType.Bubble: return CustomChartType.Bubble;
      case CustomChartType.Scatter: return CustomChartType.Scatter;
      case CustomChartType.Area: this.chartOptions.elements.line.fill = true; return CustomChartType.Line;
      default: return CustomChartType.Bar;
    }
  }

  /**
   * Returns postition style by given string
   * @param position 
   */
  private getLegendPosition(position: string) : PositionType
  {
    switch(position.toLowerCase())
    {
      case ChartLegendPosition.Bottom: return 'bottom';
      case ChartLegendPosition.Left: return 'left';
      case ChartLegendPosition.Right: return 'right';
      case ChartLegendPosition.Top: return 'top';
      default: return 'top';
    }
  }

  /**
   * Sets chart options
   */
  private setChartOptions()
  {
    this.setScales();
    this.setLegend();
    this.setTitle();
    this.setDataLabel(this.ChartConfiguration.dataLabelStyle);
  }

  /**
   * Re-draws the chart
   */
  private reDrawChart()
  {
    if(this.chartRef && this.chartRef.chart)
    {
      this.setChartOptions();   
      this.chartRef.ngOnDestroy();
      this.chartRef.chart = this.chartRef.getChartBuilder(this.chartRef.ctx);
    }
  }

  /**
   * Tests the given string yields to true
   * @param value 
   */
  private isTrue(value: string) : boolean
  {
    value = value || 'false';
    return value == 'true' || value == "1"; 
  }
}
