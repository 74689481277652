import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { MenuLink } from '../models/menu-link.model';
import { MenuType } from '../enums/menu-type.enum';
import { Constants } from './constants';
import { Constant } from '@shared/utilities/constant';
import { AppConfig } from '@shared/models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class MenuLinkService {

  private baseUrl: string;

  constructor(
    private httpUtility: HttpUtility,
    @Inject(Constant.appConfig) private appConfig: AppConfig) {

    if (environment.production) {
      this.baseUrl = `${appConfig.apiBaseUrl}${Constants.urls.menuManagerUrl}`;
    }
    else {
      this.baseUrl = `${appConfig.apiBaseUrl}`;
    }
  }

  /**
   * save menulink 
   * @param menuLinkForm -  MenuLink
   */
  public saveMenuLink(menuLinkForm: MenuLink[]) {
    console.log("menu-link.service","saveMenuLink", `${this.baseUrl}${Constants.urls.menuLinkUrl}`, menuLinkForm)
    return this.httpUtility.post(`${this.baseUrl}${Constants.urls.menuLinkUrl}`, menuLinkForm);
  }

  /**
   * get menulink 
   * @param type -  MenuType
   */
  public getMenuLink(type: MenuType) {
    console.log("menu-link.service","getMenuLink", `${this.baseUrl}${Constants.urls.menuLinkUrl}/${type}`)
    return this.httpUtility.get(`${this.baseUrl}${Constants.urls.menuLinkUrl}/${type}`);
  }

}
