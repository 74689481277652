import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, ChangeDetectorRef, Inject, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { ConfirmationService } from "primeng/api";
import { CommonConfigInit } from '@app/dynamic-widgets/commonConfigInit';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { Guid } from '@shared/utilities/guid';
import { Constant } from '@shared/utilities/constant';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Action } from '@shared/controls/models/action.model';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ScreenBase } from '@app/@dynamic-widget/form-helpers/screen.base';
import { FormWidget } from '@app/dynamic-widgets/models/form-widget.model';
import { ColumnModel, FormFieldModel, ListItemModel, RowModel, SectionInfoModel } from '@app/@dynamic-widget/models';
import { DateHelper, StringHelper } from '@colibrium/abp-base';
import { AbpButtonFieldModel, AbpMessagePopupModel } from '@colibrium/abp-popup';
import { ControlFieldType } from '@app/@dynamic-widget/enums';
import { forkJoin } from 'rxjs';
import { InputFieldModel } from '@colibrium/abp-suite';
import { ControlHelper } from '@app/@dynamic-widget/form-helpers/control.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { WidgetDescriptionConfig } from '@app/dashboard/models/widget-description-config.model';
import { UserService } from '@colibrium/auth-lib';
import { FormWidgetService } from './form-widget.service';
import { AppConfig } from '@shared/models/app-config.model';
import { Constants } from '@shared/services/constants';
import { ActivatedRoute } from '@angular/router';
import { HttpUtility } from '@app/core/utilities/http.utility';

@Component({
    selector: 'app-form-widget',
    templateUrl: './form-widget.component.html',
    styleUrls: ['./form-widget.component.scss']
})
export class FormWidgetComponent extends ScreenBase<FormWidget> implements OnInit, CommonConfigInit {

    @Input() isExpanded = false;
    @Input() input: string;
    @Input() titleConfig: WidgetTitleConfig;
    @Input() isFromDashboard: boolean;
    @Input() params: any;
    @Input() description: WidgetDescriptionConfig = new WidgetDescriptionConfig();

    @Output() onEvent = new EventEmitter();

    public spinnerName: string = Guid.randomId;
    public isExportable: boolean;
    private modalRef: NgbModalRef;
    public actions: Action[];
    public errorMessage: string;
    public copyModel: FormWidget;
    public popupModel: AbpMessagePopupModel;
    public showPopup: boolean = false;
    public saveModel: FormGroup;
    private dropdownObservables = [];
    private sectionObservables = [];
    private saveObservables = [];
    public showSection = false;
    public cancelChangesModel: SectionInfoModel;
    public showContentPopup: boolean = false;
    public popupSection: SectionInfoModel;
    public popupFormGroup: FormGroup;
    public popupFormSubmitted: boolean;
    public showWarning: boolean = false;
    public formTouched: boolean = false;
    public phoneNumberTypes = [];
    public hideAddNew = false;
    public loginUserName: string;
    public popUpSectionCode: string;
    public popupHeader: string;
    public isbtndisbaled: boolean = false;
    public isdeleteclicked: boolean = false;
    public selectedAddressData = [];
    public selectedPhoneData = [];
    public selectedDataForId = [];
    public memberId: string;
    private httpUtility: HttpUtility

    @ViewChild('widgetModel', { read: TemplateRef, static: true }) widgetModel: TemplateRef<any>;

    constructor(
        private widgetService: DynamicWidgetsService,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        fb: FormBuilder,
        private userService: UserService,
        private confirmationService: ConfirmationService,
        private formwidgetservice: FormWidgetService,
        private route: ActivatedRoute,
        @Inject(Constant.appConfig) private appConfig: AppConfig
    ) {
        super(fb);
        this.model = new FormWidget();
        this.copyModel = new FormWidget();
        this.saveModel = this.formBuilder.group({});
        this.popupModel = new AbpMessagePopupModel();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.memberId = params.get('id');
        });
        if (this.input) {
            this.detailsFormWidget();
            this.userService.userInformationObs.subscribe(userInfo => {
                if (userInfo) {
                    this.loginUserName = userInfo.displayName;
                }
            });
        } else {
            this.errorMessage = DynamicWidgetsConstant.formErrorMessages.configurationNotFound;
        }
    }

    /**
     * Gets details of form widget
     */
    public detailsFormWidget() {
        this.model = this.formWidgetMapper(JSON.parse(this.input).formGroups);

        if (this.validateConfiguration(this.model)) {
            this.getData();
        }
    }

    /**
     * form widget mapper
     * @param value -
     */

    private formWidgetMapper(value: string) {
        const inputRes: FormWidget = JSON.parse(value);
        return inputRes;
    }

    /**
     * Validates configuration
     * @param config
     */
    private validateConfiguration(config: FormWidget): boolean {
        let isValid: boolean = true;
        if (config == null) {
            isValid = false;
            console.error(`${this.titleConfig.title} - formGroups configuration missing`);
        } else {
            if (config.sections == null) {
                isValid = false;
                console.error(`${this.titleConfig.title} - sections configuration missing`);
            }
        }
        return isValid;
    }

    /**
     * sets screen controls
     */
    public setScreenControls(sectionCode: string = ''): void {
        this.mapFieldFormControls(this.model.sections, sectionCode, this.group, this.fieldHandlersCallback.bind(this));
    }

    /**
     * on Refresh
     */
    public onRefresh(): void {
        this.initializeFormWidget();
        this.detailsFormWidget();
    }

    /**
     * Resets all variables
     * @param value -
     */
    private initializeFormWidget() {
        this.dropdownObservables = [];
        this.sectionObservables = [];
        this.saveObservables = [];
        this.model = new FormWidget();
        this.saveModel = this.formBuilder.group({});
        this.popupModel = new AbpMessagePopupModel();
        this.group = this.formBuilder.group({});
        this.showSection = false;
        this.popupFormGroup = this.formBuilder.group({});
        this.popupFormSubmitted = false;
        this.showContentPopup = false;
        this.isdeleteclicked = false;
        this.selectedAddressData = [];
        this.selectedPhoneData = [];
        this.selectedDataForId = null;
    }

    ngOnDestroy(): void {
    }

    /**
     * Expands form widget component
     * @param isExpand
     */
    public expand(isExpand: boolean) {

        this.isExpanded = isExpand;
        if (isExpand) {
            this.modalRef = this.modalService.open(this.widgetModel, { size: 'xl', keyboard: true });
            this.modalRef.result.then(() => {
                this.isExpanded = false;
            },
                () => {
                    this.isExpanded = false;
                });
        }
        else {
            this.modalRef.close();
        }
    }

    /**
     * Saves form data
     */
    public onSubmit(MemberId: any) {
        this.model.sections.forEach(sec => {
            sec.isFormSubmitted = true;
            let formGroupArray: FormArray = this.saveModel.get(sec.section.code) as FormArray;
            if (formGroupArray && formGroupArray.valid) {

            }
        });

        forkJoin(this.saveObservables.map(x => x.obs))
            .pipe(
                finalize(() => {
                    this.spinner.hide(this.spinnerName);
                })
            )
            .subscribe((res: []) => {
                alert("Submitted successfully");
                this.onRefresh();
            },
                (error: any) => {
                    this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToSave;
                });
    }

    /**
     * Prepopulating data
     */
    public getData() {
        if (this.model.sections) {
            this.spinner.show(this.spinnerName);
            // get all dropdown API and section data api as observable in array
            this.model.sections.forEach(section => {
                if (section?.rowFields) {
                    this.processSectionForDropDown(section);
                }
            });


            // get all section API as observable in array
            this.model.sections.forEach(async mSection => {
                if (mSection.section.fetchUrl) {
                    await this.fetchData(mSection);
                }
            });

            if (this.sectionObservables && this.sectionObservables.length > 0) {
                // call all section fetch api
                forkJoin(this.sectionObservables.map(y => y.obs))
                    .subscribe((sectionsData: Array<string>) => {
                        if (sectionsData) {
                            //addressData
                            if (sectionsData[0]) {
                                this.selectedAddressData = Array.from(sectionsData[0]);
                            }

                            //phoneData
                            if (sectionsData[1]) {
                                this.selectedPhoneData = Array.from(sectionsData[1]);
                            }
                            // map section data to model
                            this.mapSectionData(sectionsData);

                            if (this.dropdownObservables && this.dropdownObservables.length > 0) {
                                this.spinner.show(this.spinnerName);
                                forkJoin(this.dropdownObservables.map(x => x.obs))
                                    .pipe(
                                        finalize(() => {
                                            this.spinner.hide(this.spinnerName);
                                        })
                                    )
                                    .subscribe((res: []) => {
                                        // map dropdown field and value
                                        const data = this.mapDropdownFieldValue(res);
                                        // map dropdown data
                                        this.mapDropDownValues(this.model, data);
                                        // make all sections non editable
                                        this.viewOfScreenOnPageLoad();
                                        this.showSection = true;
                                    },
                                        (error: any) => {
                                            this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToFetchData;
                                        });
                            } else {
                                // make all sections non editable
                                this.viewOfScreenOnPageLoad();
                                this.showSection = true;
                                this.spinner.hide(this.spinnerName);
                            }
                        }
                    },
                        (error: any) => {
                            this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToFetchData;
                            this.spinner.hide(this.spinnerName);
                        });
            } else {
                this.viewOfScreenOnPageLoad();
                this.showSection = true;
                this.spinner.hide(this.spinnerName);
            }
        }
    }

    /** 
     * Decides on how to load the screen on page load. 
    */
    public viewOfScreenOnPageLoad() {
        switch (this.model.screen.code) {
            case Constant.MemberPortal.ContactInfo:
                this.makeFormNonEditable(this.model.sections);

                break;
            case Constant.MemberPortal.ContactPreferences:
                this.setScreenControls();
                break;
        }
    }

    /**
     * Maps section fields with data
     * @param sectionsData
     */
    mapSectionData(sectionsData: string[]) {
        if (sectionsData.length > 0) {
            let index = 0;
            this.model.sections.forEach(section => {
                if (sectionsData[index]) {
                    section.section.count = sectionsData[index].length;
                    for (let i = 0; i < sectionsData[index].length; i++) {
                        if (i == 0) {
                            this.mapDataToForm(sectionsData[index][i], section.section.code);
                        } else {
                            this.addNewSection(section.section.code, sectionsData[index][i]);
                        }
                    }
                    index++;
                } else {
                    section.section.count = 0;
                }
            });
        }
    }

    /**
     * Maps dropdown field values
     * @param data 
     * @returns 
     */
    mapDropdownFieldValue(data: any[]) {
        let dropdown = [];
        data.forEach((res, i) => {
            const listItemArray: ListItemModel[] = [];
            if (res) {
                res.forEach(item => {
                    if (this.dropdownObservables[i].fieldCode == Constant.FormWidgetManager.phoneNumberType) {
                        if (this.phoneNumberTypes && !this.phoneNumberTypes.includes(item.value)) {
                            const listItem = new ListItemModel();
                            listItem.code = item.value;
                            listItem.displayText = item.value;
                            listItemArray.push(listItem);
                        }
                    } else {
                        const listItem = new ListItemModel();
                        listItem.code = item.value;
                        listItem.displayText = item.text ? item.text : item.value;
                        listItemArray.push(listItem);
                    }
                });
            }
            if (listItemArray.length == 0) {
                this.hideAddNew = true;
            }
            dropdown.push({ 'fieldCode': this.dropdownObservables[i].fieldCode, 'value': _.cloneDeep(listItemArray) });
        });

        return dropdown;
    }

    /**
     * Maps dropdown values
     * @param model 
     * @param data 
     */
    mapDropDownValues(model: FormWidget, data: any) {
        model.sections.forEach(section => {
            this.mapSectionDropdownValues(section, data);
        });
    }

    /**
     * Maps each section dropdown values
     * @param section 
     * @param data 
     */
    mapSectionDropdownValues(section: SectionInfoModel, data: any) {
        section.rowFields.forEach(row => {
            if (row.columns && row.columns.length > 0) {
                this.mapColumnDropdownValue(row, data);
            }

            if (row.subSection && row.subSection.length > 0) {
                row.subSection.forEach(subSection => {
                    this.mapSectionDropdownValues(subSection, data);
                });
            }
        });
    }

    /**
     * Maps each column field dropdown values with api data
     * @param row 
     * @param data 
     */
    mapColumnDropdownValue(row: RowModel<FormFieldModel>, data: any) {
        row.columns.forEach(col => {
            if (col.fieldInfo.fetchUrl) {
                col.fieldInfo.options = data.filter(x => x.fieldCode == col.fieldInfo.code)[0].value;
            }

            if (col.childList && col.childList.length > 0) {
                col.childList.forEach(childRow => {
                    this.mapColumnDropdownValue(childRow, data);
                });
            }
        });
    }

    public async fetchData(section: SectionInfoModel) {
        this.sectionObservables.push({ 'sectionName': section.section.code, 'obs': this.widgetService.get(section.section.fetchUrl) });
    }

    private async processSectionForDropDown(section: SectionInfoModel) {
        section.rowFields.forEach(row => {
            if (row.columns && row.columns.length > 0) {
                this.processColumnForDropDown(row, section.section.code);
            }

            if (row.subSection && row.subSection.length > 0) {
                row.subSection.forEach(subSection => {
                    this.processSectionForDropDown(subSection);
                });
            }
        });
    }

    private processColumnForDropDown(row: RowModel<FormFieldModel>, sectionCode: string) {
        row.columns.forEach(col => {
            if (col.fieldInfo.fetchUrl) {
                this.fetchDropdownData(col, sectionCode);
            }

            if (col.childList && col.childList.length > 0) {
                col.childList.forEach(childRow => {
                    this.processColumnForDropDown(childRow, sectionCode);
                });
            }
        });
    }

    private fetchDropdownData(column: ColumnModel<FormFieldModel>, sectionCode: string) {
        this.dropdownObservables.push({
            'sectionCode': sectionCode,
            'fieldCode': column.fieldInfo.code,
            'obs': this.widgetService.get(column.fieldInfo.fetchUrl),
            'gotData': false
        });
    }

    /**
     * maps data to fields
     * @param sectionData
     * @param secCode
     */
    private mapDataToForm(sectionData: string, secCode: string) {
        const section = this.model.sections.find(sec => sec.section.code == secCode);
        if (section) {
            section.rowFields.forEach(row => {
                if (row.subSection && row.subSection.length > 0) {
                    row.subSection.forEach(sec => {
                        sec.rowFields.forEach(rowField => {
                            this.mapColumnData(sec, rowField, sectionData);
                        });
                    });
                } else if (row.columns && row.columns.length > 0) {
                    row.columns.forEach(sec => {
                        this.mapColumnsData(sec, sectionData);
                    });
                }
            });
        }
    }

    /**
     * maps column fields data
     * @param row
     * @param sectionData
     */
    private mapColumnData(sec: SectionInfoModel, row: RowModel<FormFieldModel>, sectionData: string) {
        row.columns.forEach(col => {
            if (sectionData == '') {
                col.fieldInfo.fieldValue = sectionData;
                if (col.fieldInfo.validationMaps != undefined) {
                    if (col.fieldInfo.validationMaps.length > 0) {
                        col.fieldInfo.validationMaps.forEach((map) => {
                            if (map.validatorCode == Constant.validatorCode.required) {
                                col.fieldInfo.displayText = col.fieldInfo.displayText + "*";
                            }
                        });
                    }
                }
                col.fieldInfo.abpField = null;
            } else {
                var fieldData = sectionData[col.fieldInfo.code];
                if (sec.section.id == col.fieldInfo.code) {
                    sec.section.displayText = fieldData;
                    this.phoneNumberTypes.push(fieldData);
                }
                // to add column data
                this.mapColumnsData(col, sectionData);
            }

            // to add child column data
            if (col.childList && col.childList.length > 0) {
                col.childList.forEach(childRow => {
                    this.mapColumnData(sec, childRow, sectionData);
                });
            }
        });
    }

    private mapColumnsData(col: ColumnModel<FormFieldModel>, sectionData: string) {
        var fieldData = sectionData[col.fieldInfo.code];
        if (fieldData) {
            col.fieldInfo.fieldValue = (fieldData != null && fieldData != "" && fieldData != "None") ? fieldData : '';
        }
        else if (col.fieldInfo.code == Constant.FormWidgetManager.deleteBtn || col.fieldInfo.code == Constant.FormWidgetManager.editBtn) {
            col.fieldInfo.fieldValue = sectionData[col.fieldInfo.externalfield];
            if (col.fieldInfo.fieldValue == null) {
                col.fieldInfo.fieldValue = sectionData[col.fieldInfo.internalfield];
            }

        }
        else if (col.fieldInfo.fieldType == ControlFieldType.Checkbox) {
            if (col.fieldInfo.fieldValue == undefined || col.fieldInfo.fieldValue == null || col.fieldInfo.fieldValue == '') {
                col.fieldInfo.fieldValue = false;
                col.fieldInfo.checked = false;
            }
        }
        else {
            let data: string[] = col.fieldInfo.code.split("_");
            if (data.length > 0) {
                data.forEach(element => {
                    if (sectionData[element]) {
                        if (col.fieldInfo.fieldValue) {
                            col.fieldInfo.fieldValue = col.fieldInfo.fieldValue.concat(', ', sectionData[element]);
                        } else {
                            col.fieldInfo.fieldValue = sectionData[element];
                        }
                    } else if (col.fieldInfo.fieldValue == null || !sectionData[element]) {
                        col.fieldInfo.fieldValue = '-';
                    }
                });
            } else {
                col.fieldInfo.fieldValue = '-';
            }
        }
    }

    /**
     * Adds subsection
     */
    public addNewSection(secCode: string, sectionData: string = '') {
        var section = this.model.sections.find(sec => sec.section.code == secCode);
        if (section) {
            var arrayRow = section.rowFields.find(row => row.code == secCode);
            if (arrayRow) {
                var arraySec = arrayRow.subSection[0];
                var newSec = _.cloneDeep(arraySec);
                newSec.rowFields.forEach(rowField => {
                    rowField.columns.forEach(colElement => {
                        this.mapColumnData(newSec, rowField, sectionData);
                    });
                });
                arrayRow.subSection.push(newSec);
            }
            if (sectionData == '') {
                this.mapContolValueToModel(arrayRow);
                this.setScreenControls(secCode);
            }
            section.isFormSubmitted = false;
        }
    }

    /**
       * Add new section changes
       * @param sectionCode
       */
    public addNewPopup(sectionCode: string) {
        this.selectedDataForId = null;
        this.showContentPopup = true;
        this.showWarning = false;
        this.formTouched = false;
        this.isbtndisbaled = false;
        var mainSection = _.cloneDeep(this.copyModel.sections.
            find(x => x.section.code == sectionCode));
        var subSection = _.cloneDeep(this.copyModel.sections.
            find(x => x.section.code == sectionCode).rowFields.find(x => x.code == sectionCode).subSection);

        this.popupSection = subSection[0];
        this.popupSection.rowFields.forEach(row => {
            this.mapColumnData(this.popupSection, row, '');
            if (row.rowCssClass.includes('action-btn')) {
                row.rowCssClass = row.rowCssClass.replace('action-btn', 'd-none');
            }
            else {
                row.rowCssClass = row.rowCssClass.replace('d-none', '');
            }
        })
        var formGroup = this.formBuilder.group({});
        this.mapFieldFormControls(subSection, sectionCode, formGroup, this.fieldHandlersCallback.bind(this));

        this.popupFormGroup = formGroup.controls[sectionCode] as FormGroup;
        this.popupHeader = "Add " + mainSection.section.displayText;
        this.popupFormSubmitted = false;
        this.popUpSectionCode = sectionCode;
    }

    /**
           * Edit new section changes
           * @param sectionCode
           */
    public EditPopup(sectionCode: string, editid: string) {
        this.showContentPopup = true;
        this.showWarning = false;
        this.formTouched = false;
        this.isbtndisbaled = false;
        var mainSection = _.cloneDeep(this.copyModel.sections.
            find(x => x.section.code == sectionCode));
        var subSection = _.cloneDeep(this.copyModel.sections.
            find(x => x.section.code == sectionCode).rowFields.find(x => x.code == sectionCode).subSection);
        this.popupSection = subSection[0];
        this.popupSection.rowFields.forEach(row => {
            this.mapColumnData(this.popupSection, row, '');
            if (row.rowCssClass.includes('action-btn')) {
                row.rowCssClass = row.rowCssClass.replace('action-btn', 'd-none');
            }
            else {
                row.rowCssClass = row.rowCssClass.replace('d-none', '');
            }
        })
        var formGroup = this.formBuilder.group({});
        this.mapFieldFormControls(subSection, sectionCode, formGroup, this.fieldHandlersCallback.bind(this));
        this.popupFormGroup = formGroup.controls[sectionCode] as FormGroup;
        this.popupHeader = "Edit " + mainSection.section.displayText;
        this.popupFormSubmitted = false;
        this.popUpSectionCode = sectionCode;
        if (this.popUpSectionCode.toLowerCase() == "address") {
            this.selectedDataForId = this.selectedAddressData.find(x => x.addressId == editid);
        }
        else if (this.popUpSectionCode.toLowerCase() == "phoneno") {
            this.selectedDataForId = this.selectedPhoneData.find(x => x.phoneId == editid);
        }
        this.popupFormGroup.patchValue(
            this.selectedDataForId
        );

    }

    public Delete(deleteUrl: string, deleteid: string) {
        if (!this.isdeleteclicked) {
            this.isdeleteclicked = true;
            this.spinner.show(this.spinnerName);
            let count = 0;
            if (deleteUrl.includes("address") || deleteUrl.includes("phone")) {
                var url = deleteUrl.includes("address") ? `${this.appConfig.apiBaseUrl}${Constants.id4.getMemberAddressDetails}` : `${this.appConfig.apiBaseUrl}${Constants.id4.getMemberPhoneDetails}`;
                url = url.replace(/##memberId##/g, this.memberId).replace(/##source##/g, this.appConfig.Source);
                this.formwidgetservice.get(url).subscribe((result: any) => {
                    this.spinner.hide(this.spinnerName);
                    count = result.length;
                    if (count <= this.appConfig.MinimumRequiredMemberAddress) {
                        let addressmsg = "Cannot delete. At least " + this.appConfig.MinimumRequiredMemberAddress + " address is required.";
                        let phonemsg = "Cannot delete. At least " + this.appConfig.MinimumRequiredMemberPhoneNumber + " phone is required.";
                        let popupmsg = deleteUrl.includes("address") ? addressmsg : phonemsg;
                        this.OpenModal(popupmsg);
                        this.isdeleteclicked = false;
                    }
                    else {

                        let deletemsg = DynamicWidgetsConstant.alertMessages.memberDeleteConfirm;
                        if (confirm(deletemsg)) {
                            this.isdeleteclicked = false;
                            this.spinner.show(this.spinnerName);
                            let url = "";
                            url = deleteUrl.replace(/##deleteid##/g, deleteid);
                            this.formwidgetservice.delete(url).subscribe((res: any) => {
                                this.spinner.hide(this.spinnerName);
                                this.onRefresh();
                                this.isdeleteclicked = false;
                            }),
                                (error: any) => {

                                    this.spinner.hide(this.spinnerName);
                                    this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToSave;
                                };
                        }
                        this.isdeleteclicked = false;

                    }

                }),
                    (error: any) => {

                        this.spinner.hide(this.spinnerName);
                        this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToSave;
                    };


            }
        }
        //this.isdeleteclicked = true;
    }

    /**
     * Opens modal popup on error
     * @param error
     */
    public OpenModal(errorMsg: string) {
        this.confirmationService.confirm({
            message: errorMsg,
            header: '',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

            },
            key: "widgetminValueDialog"
        });
    }
    /**
      * Convert form to non editable mode
      * @param sections
      */
    public makeFormNonEditable(sections: SectionInfoModel[]) {
        this.copyModel = _.cloneDeep(this.model);
        sections.forEach(section => {
            this.makeSecNonEditable(section);
        });
        this.setScreenControls();
    }

    /**
     * convert section to non editable mode
     * @param section
     */
    public makeSecNonEditable(section: SectionInfoModel) {
        if (section.rowFields && section.rowFields.length > 0) {
            section.rowFields.forEach(row => {
                this.processColumn(row);

                if (row.subSection && row.subSection.length > 0) {
                    row.subSection.forEach(sec => {
                        this.makeSecNonEditable(sec);
                        if (!section.section.count || section.section.count == 0) {
                            sec.cssClass = 'd-none';
                        }
                    });
                }
            });
        }
    }

    /**
     * Process columns to convert input controls to labels and disable add and clear buttons.
     * @param row
     */
    private processColumn(row: RowModel<FormFieldModel>) {
        if (row.columns && row.columns.length > 0) {
            row.columns.forEach(col => {
                if (ControlHelper.isInputControl(col.fieldInfo)) {
                    col.fieldInfo.fieldType = ControlFieldType.Label;
                    col.fieldInfo.abpField = null;
                }
                if (col.fieldInfo.code == Constant.FormWidgetManager.addnewBtn && this.hideAddNew) {
                    row.rowCssClass = "d-none";
                }

                // child list
                if (col.childList && col.childList.length > 0) {
                    col.childList.forEach(childRow => {
                        this.processColumn(childRow);
                    });
                }
            });
        }
    }

    /**
     * on close footer button click in popup
     */
    public onCloseBtnClick() {
        if (this.formTouched) {
            this.showWarning = true;
            this.formTouched = false;
        } else {
            this.showPopup = false;
            this.showContentPopup = false;
            this.showWarning = false;
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.key === "Escape") {
            // Do things
            this.onCloseBtnClick();
        }
    }

    /**
     * on ok click event in cancel popup
     * Reverts the changes and make form non editable.
     */
    public onOkClick(sectionCode: string) {
        var index = this.model.sections.findIndex(sec => sec.section.code == sectionCode);
        this.model.sections[index] = _.cloneDeep(this.copyModel.sections[index]);
        this.copyModel.sections[index] = _.cloneDeep(this.cancelChangesModel);
        this.setScreenControls();
        this.showPopup = false;
        this.cancelChangesModel = new SectionInfoModel();
    }

    /**
     * Save ContactInfo section data 
     * @param sectionCode
     */
    public saveData() {
        var index = this.model.sections.findIndex(sec => sec.section.code == this.popUpSectionCode);
        this.popupFormSubmitted = true;
        var section = this.model.sections[index];
        if (this.popupFormGroup && this.popupFormGroup.valid) {
            if(this.validateDuplicateExist()) {
                return false;
            }
            this.isbtndisbaled = true;
            if (section.section.postUrl) {
                this.popupFormGroup.value.source = 'Portal'; // Will be removed as required from API
                this.popupFormGroup.value.createdBy = this.loginUserName
                this.popupFormGroup.value.createdDate = new Date();
                this.popupFormGroup.value.isActive = 'true';
                if (this.selectedDataForId != null) {
                    if (this.popUpSectionCode.toLowerCase() == "address") {
                        this.popupFormGroup.value.addressId = this.selectedDataForId['addressId'];
                        this.popupFormGroup.value.addressExternalId = this.selectedDataForId['addressExternalId'];

                    }
                    else if (this.popUpSectionCode.toLowerCase() == "phoneno") {
                        this.popupFormGroup.value.phoneId = this.selectedDataForId['phoneId'];
                        this.popupFormGroup.value.phoneExternalId = this.selectedDataForId['phoneExternalId'];

                    }
                    this.popupFormGroup.value.rowSource = this.selectedDataForId['rowSource'];
                }
                else {
                    this.popupFormGroup.value.rowSource = 'Member Portal';
                }

                if (this.popupFormGroup.value.isPrimary == "" && this.popupFormGroup.value.isPrimary !== true) {
                    this.popupFormGroup.value.isPrimary = false;
                }
                this.popupFormGroup.value.startDate = new Date();
  
                this.widgetService.post(section.section.postUrl, this.popupFormGroup.value)
                    .subscribe((res: boolean) => {
                        if (res) {
                            this.onRefresh();
                        }
                    }),
                    (error: any) => {
                        this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToSave;
                    };
            }
        }
    }

    /**
     * Validate duplicate phone or address exists 
    */
    private validateDuplicateExist(): boolean {
        let isDuplicate = false;

        if (this.popUpSectionCode.toLowerCase() == "phoneno") {

            if(!this.selectedDataForId) {
              isDuplicate = this.selectedPhoneData.some((el:any) => el.number === this.popupFormGroup.value.number);
            } else {
                if (this.popupFormGroup.value.number != this.selectedDataForId['number']) {
                  isDuplicate = this.selectedPhoneData.some((el:any) => el.number === this.popupFormGroup.value.number);
                }
            }

        } else if (this.popUpSectionCode.toLowerCase() == "address") {

            if (!this.selectedDataForId) {
                isDuplicate = this.selectedAddressData.some((el: any) => el.street1.trim().toLowerCase() === this.popupFormGroup.value.street1.trim().toLowerCase());
            } else {
                if (this.popupFormGroup.value.street1.trim().toLowerCase() !== this.selectedDataForId['street1'].trim().toLowerCase()) {
                    isDuplicate = this.selectedAddressData.some((el: any) => el.street1.trim().toLowerCase() === this.popupFormGroup.value.street1.trim().toLowerCase());
                }
            }
        }

        if(isDuplicate) {
          let popupmsg = (this.popUpSectionCode.toLowerCase() == "phoneno") ? 
          DynamicWidgetsConstant.alertMessages.validateMsgForDuplicatePhone : DynamicWidgetsConstant.alertMessages.validateMsgForDuplicateAddress;
          this.OpenModal(popupmsg);
        }

        return isDuplicate;
    }

    /**
     * Maps control value to model
     * @param row
     */
    private mapContolValueToModel(row: RowModel<FormFieldModel>) {
        row.subSection.forEach(subsec => {
            subsec.rowFields.forEach(row => {
                this.mapFieldContolValueToModel(row.columns);
            });
        });
    }

    /**
     * Map field control value to model
     * @param columns
     */
    private mapFieldContolValueToModel(columns: ColumnModel<FormFieldModel>[]) {
        if (columns && columns.length > 0) {
            columns.forEach(col => {
                if (ControlHelper.isInputControl(col.fieldInfo)) {
                    if (col.fieldInfo.abpField && col.fieldInfo.abpField instanceof InputFieldModel) {
                        col.fieldInfo.fieldValue = col.fieldInfo.abpField.fieldValue;
                    }
                }
                if (col.childList && col.childList.length > 0) {
                    col.childList.forEach(child => {
                        this.mapFieldContolValueToModel(child.columns);
                    });
                }
            });
        }
    }

    /**
     * Saves ContactPreferences data
     */
    public saveChanges(sectionCode: string) {
        let formGroup: FormGroup = this.group.get(sectionCode) as FormGroup;
        var index = this.model.sections.findIndex(sec => sec.section.code == sectionCode);
        var section = this.model.sections[index];
        if (formGroup && formGroup.valid) {
            if (section.section.postUrl) {
                formGroup.value.source = 'Portal'; // Will be removed as required from API
                formGroup.value.language = "English";
                formGroup.value.startDate = new Date();
                if (formGroup.value.memberHubContactPreferenceID) {
                    formGroup.value.id = formGroup.value.memberHubContactPreferenceID;
                    formGroup.value.UpdatedBy = this.loginUserName;
                    formGroup.value.UpdatedDate = new Date();
                } else {
                    formGroup.value.createdBy = this.loginUserName
                    formGroup.value.createdDate = new Date();
                }

                if (formGroup.value.doNotDisturb == true) {
                    formGroup.value.isDoNotDisturb = DynamicWidgetsConstant.formErrorMessages.defaultYesValue;
                }
                else if (formGroup.value.doNotDisturb == false) {
                    formGroup.value.isDoNotDisturb = DynamicWidgetsConstant.formErrorMessages.defaultValue;
                }
                else {
                    formGroup.value.isDoNotDisturb = DynamicWidgetsConstant.formErrorMessages.defaultUnKnowValue;
                }

                formGroup.value.isActive = true;

                if (!formGroup.value.CanMail) {
                    formGroup.value.CanMail = DynamicWidgetsConstant.formErrorMessages.defaultUnKnowValue;
                }
                if (!formGroup.value.CanText) {
                    formGroup.value.CanText = DynamicWidgetsConstant.formErrorMessages.defaultUnKnowValue;
                }
                if (!formGroup.value.CanMail) {
                    formGroup.value.CanMail = DynamicWidgetsConstant.formErrorMessages.defaultUnKnowValue;
                }
                if (!formGroup.value.CanEmail) {
                    formGroup.value.CanEmail = DynamicWidgetsConstant.formErrorMessages.defaultUnKnowValue;
                }
                if (!formGroup.value.CanPhone) {
                    formGroup.value.CanPhone = DynamicWidgetsConstant.formErrorMessages.defaultUnKnowValue;
                }
                if (!formGroup.value.CanMarketingMail) {
                    formGroup.value.CanMarketingMail = DynamicWidgetsConstant.formErrorMessages.defaultValue;
                }
                if (!formGroup.value.CanMarketingText) {
                    formGroup.value.CanMarketingText = DynamicWidgetsConstant.formErrorMessages.defaultValue;
                }
                if (!formGroup.value.CanMarketingEmail) {
                    formGroup.value.CanMarketingEmail = DynamicWidgetsConstant.formErrorMessages.defaultValue;
                }
                if (!formGroup.value.CanMarketingPhone) {
                    formGroup.value.CanMarketingPhone = DynamicWidgetsConstant.formErrorMessages.defaultValue;
                }

                if (!formGroup.value.preferredContactMethod) {
                    formGroup.value.preferredContactMethod = DynamicWidgetsConstant.formErrorMessages.defaultNoneValue;
                }

                if (!formGroup.value.backupContactMethod) {
                    formGroup.value.backupContactMethod = DynamicWidgetsConstant.formErrorMessages.defaultNoneValue;
                }

                if (((formGroup.value.preferredContactMethod.toLowerCase() !== 'any') &&
                    (formGroup.value.backupContactMethod.toLowerCase() !== 'any')) &&
                    ((formGroup.value.preferredContactMethod !== DynamicWidgetsConstant.formErrorMessages.defaultNoneValue) &&
                        (formGroup.value.backupContactMethod !== DynamicWidgetsConstant.formErrorMessages.defaultNoneValue))) {

                    if (formGroup.value.preferredContactMethod == formGroup.value.backupContactMethod) {
                        alert('Preferred Contact Method and Back Up Contact Method cannot be same');
                        return;
                    }
                }



                this.widgetService.post(section.section.postUrl, formGroup.value)
                    .subscribe((res: boolean) => {
                        if (res) {
                            this.onRefresh();
                        }
                    }),
                    (error: any) => {
                        this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToSave;
                    };
            }
        }
        section.isFormSubmitted = true;
    }

    public resetChanges(sectionCode: string) {
        this.group.reset();
        this.model.sections.find(sec => sec.section.code == sectionCode).isFormSubmitted = true;
    }

    /**
     * sets required field attributes as per the screen requirements
     * @param sectionInfo
     * @param rowModel
     * @param column
     */
    private fieldHandlersCallback(sectionInfo: SectionInfoModel, rowModel: RowModel<FormFieldModel>, column: ColumnModel<FormFieldModel>) {
        switch (StringHelper.toSafeTrim(column.fieldInfo.code)) {
            case Constant.FormWidgetManager.addnewBtn:
                if (column.fieldInfo.abpField instanceof AbpButtonFieldModel) {
                    column.fieldInfo.abpField.clickEventHandler = this.addNewPopup.bind(this, sectionInfo.section.code);
                    this.popUpSectionCode = sectionInfo.section.code;
                }
                break;
            case Constant.FormWidgetManager.editBtn:
                if (column.fieldInfo.abpField instanceof AbpButtonFieldModel) {
                    column.fieldInfo.abpField.clickEventHandler = this.EditPopup.bind(this, sectionInfo.section.code, column.fieldInfo.fieldValue);
                    this.popUpSectionCode = sectionInfo.section.code;

                }
                break;
            case Constant.FormWidgetManager.deleteBtn:
                if (column.fieldInfo.abpField instanceof AbpButtonFieldModel) {

                    column.fieldInfo.abpField.clickEventHandler = this.Delete.bind(this, column.fieldInfo.deleteUrl, column.fieldInfo.fieldValue);

                    break;
                }
            case Constant.FormWidgetManager.saveBtn:

                if (column.fieldInfo.abpField instanceof AbpButtonFieldModel) {
                    column.fieldInfo.abpField.clickEventHandler = this.saveChanges.bind(this, sectionInfo.section.code);
                }
                break;
            case Constant.FormWidgetManager.resetBtn:

                if (column.fieldInfo.abpField instanceof AbpButtonFieldModel) {
                    column.fieldInfo.abpField.clickEventHandler = this.resetChanges.bind(this, sectionInfo.section.code);
                }
                break;
        }
    }
}
