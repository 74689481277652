

<ng-template [ngIf]="!isExpanded">
    <ng-container *ngTemplateOutlet="widgetModel"></ng-container>
</ng-template>

<ng-template #widgetModel>
    <div
        [ngClass]="{'bg-white shadow-sm p-4 border-radius-5': !isFromDashboard || isExpanded, 'my-5': !isFromDashboard && !isExpanded}">
        <app-widget-title [titleConfig]="titleConfig" [isExpanded]="isExpanded" (onExpand)="expand($event)" (onFilter)="applyFilter($event)">
        </app-widget-title>
        <div class="container p-2">
                <div *ngIf="chartData.datasets.length > 0 && chartData.datasets[0].data.length > 0; else emptyBlock">
                    <canvas baseChart #baseChart="base-chart"
                            [datasets]="chartData.datasets"
                            [labels]="chartData.labels"
                            [options]="chartOptions"
                            [plugins]="chartPlugins"
                            [chartType]="chartType"
                            [colors]="colors">
                    </canvas>
                </div>
                <ng-template #emptyBlock>
                    <div class="card-body text-center pad-90">
                        <div *ngIf="showLoader; else messageBlock" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <ng-template #messageBlock>
                            <span>
                                No data to render.
                            </span>
                        </ng-template>
                    </div>
                </ng-template>
        </div>
    </div>
</ng-template>

