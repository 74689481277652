import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaregiverInfoService {
  private user = new BehaviorSubject<boolean>(false);
  togglingUser = this.user.asObservable();

  constructor(private httpUtility: HttpUtility) { }

  onSelectionChange(flag){ 
    this.user.next(flag);
  }

  delete(url) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpUtility.deleteByOptions(url, { headers, responseType: 'text' });
  }
  put(url,data):Observable<any>{
return this.httpUtility.putByOptions(url,data,{observe:'response'});
  }
}
