import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CalendarConfig } from '@shared/controls/models/calendar-config.model';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DateCompareValidator } from '@shared/validators/date-compare.validator';
import { DashboardConstant } from '@app/dashboard/dashboard.constant';

@Component({
  selector: 'app-custom-date-range',
  templateUrl: './custom-date-range.component.html',
  styleUrls: ['./custom-date-range.component.scss']
})
export class CustomDateRangeComponent implements OnInit {
  public config: CalendarConfig = {
    dateFormat: DashboardConstant.dateFilter.dateFormat,
    hourFormat: DashboardConstant.dateFilter.hourFormat,
    readonlyInput: true,
    showSeconds: false,
    showTime: false,
    showNavigator: false
  };
  public dateRangeForm: FormGroup;

  @Output() onApply = new EventEmitter();

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.dateRangeForm = this.formBuilder.group({

    });
    this.dateRangeForm.addControl(DashboardConstant.dateFilter.startDate, new FormControl(null));
    this.dateRangeForm.addControl(DashboardConstant.dateFilter.endDate, new FormControl(null));
    this.dateRangeForm.validator = DateCompareValidator(DashboardConstant.dateFilter.startDate,
                                                        DashboardConstant.dateFilter.endDate);
  }

  /**
   * Emit apply event
   */
  public apply()
  {
      this.onApply.emit(this.dateRangeForm.value);
  }
}
