import { Component, OnInit } from '@angular/core';

import { MenuType } from '@shared/enums/menu-type.enum';
import { MenuLinkService } from '@shared/services/menu-link.service';
import { MenuLink } from '@shared/models/menu-link.model';

@Component({
  selector: 'app-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.scss']
})
export class QuickLinkComponent implements OnInit {

  public menuType = MenuType.QuickLink;
  public menu: MenuLink[];
  public isDisabled:boolean = true;

  constructor(private menuService: MenuLinkService) { }

  ngOnInit() {
      this.menuService.getMenuLink(this.menuType).subscribe((res: any) => {
         this.menu = res;
         this.isDisabled = this.menu.length == 0;
      });
  }

}
