import { Component, OnInit, Input } from '@angular/core';

import Parser from 'morph-expressions';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

import { DemographicActionConfiguartion } from '@app/dynamic-widgets/models/demographic-action-configuration.model';
import { DemographicActionWidget } from '@app/dynamic-widgets/models/demographic-action-widget.model';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { WidgetAction } from '@shared/models/widget-action.model';
import { Guid } from '@shared/utilities/guid';

@Component({
  selector: 'app-demograhic-action-widget',
  templateUrl: './demograhic-action-widget.component.html',
  styleUrls: ['./demograhic-action-widget.component.scss']
})
export class DemograhicActionWidgetComponent implements OnInit {

  @Input() input: string;
  @Input() actions: WidgetAction[];

  public disabled = false;
  public configurations: DemographicActionWidget;
  public header: string;
  public description: string;
  public spinnerName: string = Guid.randomId;
  public errorMessage: string;
  public data: any;

  private parser = new Parser();

  constructor(
    private service: DynamicWidgetsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {   
    if (this.input) {
      this.configurations = this.demographicWidgetMapper(this.input); 
      this.getData();       
    }
  }

  /**
   * Event trigger for actions
   * @param action 
   */
  public onAction(action: WidgetAction)
  {
    if(action && action.url)
    {
      let data = this.data;
      if(action.data)
      {
        data = JSON.parse(action.data);
      }
      this.service.post(action.url, data).subscribe((result: boolean) => {
        if(result)
        {
          this.getData();
        }
        else
        {
          this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToUpdate;
        }
      },
      (error) => {
        this.errorMessage = `${DynamicWidgetsConstant.demographicErrorMessages.failedToUpdate}: ${error}`;
      })
    }
  }

  /**
   * Tests the given string yields to true
   * @param value 
   */
  private isTrue(value: any) : boolean
  {
    value = value || 'false';
    return value == 'true' || value == "1"; 
  }

   /**
   * demographic widget mapper
   * @param value -
   */

  private demographicWidgetMapper(value: string) {
    const inputRes: DemographicActionConfiguartion = JSON.parse(value);
    const res: DemographicActionWidget = {
      dataSourceUrl: inputRes.dataSourceUrl,
      fields:  JSON.parse(inputRes.fields),
      description: inputRes.description,
      headerFieldName: inputRes.headerFieldName
    };
    return res;
  }

  /**
   * Get widget data making api call
   */
  private getData()
  {
    this.spinner.show(this.spinnerName);
    this.service.getDemographicActionData(this.configurations.dataSourceUrl)
    .pipe(finalize(() => {
      this.spinner.hide(this.spinnerName);
    }))
    .subscribe((demographicData) => {
      this.bindData(demographicData);
    },
      (error) => {
        this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToFetchData;
      }
    );
  }

  /**
   * Binds widget data
   * @param data 
   */
  private bindData(data: any)
  {
    if (!data) {
      this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToFetchData;
    } else {
      this.data = data;
      this.configurations.fields.forEach(field => {
        field.value = _.get(data, field.fieldName, null);
      });
      this.header = _.get(data, this.configurations.headerFieldName, null);
      this.description = this.configurations.description;
      this.setActionEnableStatus();
    }
    
  }

  /**
   * To set actions enable status
   */
  private setActionEnableStatus()
  {
    if(this.actions.length > 0 && this.data)
    {
     this.actions.forEach(action => {
       if(action.condition && action.condition.length > 0)
       {
         action.enable = this.isTrue(this.parser.parseAndEval(action.condition, this.data));
       }
       else
       {
         action.enable = true;
       }          
     })
    }
  }
}
