<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <div class="page-not-found-container">
                <h2>Oops!</h2>
                <h4>404</h4>
                <p>The page you are looking for might have been removed<br/> had its name changes or is temporarily unavailable.</p>
                <div class="col-12 text-center mb-4">
                    <button class="btn btn-blue text-white border-radius100 mt-2 paddingLR34 paddingTB10" [routerLink]="['/home']">Go To Homepage</button>
                </div>
            </div>
        </div>
    </div>
</div>