<div class="container">
    <ng-container *ngIf="errorMessage; then error; else noError">
    </ng-container>
    <ng-template #error>
        <div class="api-error">
            <h2>Oops!</h2>
            {{errorMessage}}
        </div>
    </ng-template>
    <ng-template #noError>
        <ngx-spinner [name]="spinnerName" [fullScreen]="false" type="square-jelly-box" size="small"></ngx-spinner>
        <div class="card-form">
            <div class="card-body">
                <div class="text-center font-weight-bold header">
                    {{header}}
                </div>
                
                <div class="description">
                    <div class="actions pad-0" *ngIf="actions && actions.length > 0">                       
                        <nav class="navbar navbar-expand pad-0">
                            <ul class="navbar-nav mr-auto">                               
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle pad-0" href="#" id="actionsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Actions
                                    </a>
                                    <div class="dropdown-menu pad-0" aria-labelledby="actionsDropdown">
                                        <ng-container *ngFor="let action of actions | orderBy: 'order'; let i = index">
                                            <button type="button" class="btn" [ngClass]="action.css" [ngStyle]="action.styles" [disabled]="!action.enable" (click)="onAction(action)">{{action.actionName}}</button>
                                        </ng-container>
                                      </div>
                                </li>
                            </ul>
                        </nav>                       
                    </div>
                    <p>{{description}}</p>
                </div>
                <hr>
                <div *ngIf="configurations.fields.length > 0">
                    <div *ngFor="let filed of configurations.fields | orderBy: 'order';">
                        <span>
                            <label class="text-left col-8 pad-0">{{filed.displayName}}:</label>
                        </span>
                        <span>
                            <label class="text-right font-weight-bold col-4 pad-0">{{filed.value}}</label>
                        </span>
                    </div>                    
                </div>
            </div>
        </div>
    </ng-template>
</div>
