
export class CoreUrl {

  //#region properties

  public static readonly api = class {
    public static readonly logDataUrl: string = '';//CoreUrl.getApiUrl('');
  };

  public static readonly page = class {
    public static readonly loginUrl: string = 'login';
    public static readonly errorUrl: string = 'error';
    public static readonly landingUrl: string = 'healthplan/dashboard';
  };

  //gets Api service url
  // private static getApiUrl(address: string): string {
  //   return `${environment.apiBaseUrl}${address}`;
  // }

  /**
   * gets site url
   * @param address
   */
  // private static getSiteUrl(address: string): string {
  //   return `${environment.siteBaseUrl}${address}`;
  // }
  //#endregion private methods

}
