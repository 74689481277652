<div [ngClass]="isExpand ? 'menu-expand': 'menu'">
    <ng-container *ngTemplateOutlet="menuItemsTemplate; context:{ $implicit: menu , level: 'L0' }"></ng-container>
</div>
<ng-template #menuItemsTemplate let-list let-level="level">
    <ng-container *ngFor="let item of list;let i = index">
        <ng-template #child>
            <span class="list-group-item list-group-item-action cursor-pointer"
                (click)="navigate(item)">
                <img *ngIf="isMenuType(item.type)" src="{{imageSrc(item.icon)}}" alt="{{item.label}}">
                <span class="menu-label">{{item.label}}</span>
        </span>
        </ng-template>
        <ng-template [ngIf]="item.subMenu?.length > 0" [ngIfElse]="child" #parent>
            <a href="#{{level+i}}" class="list-group-item list-group-item-action bg-light cursor-pointer" data-toggle="collapse">
                <img class="menu-icon" *ngIf="isMenuType(item.type)" src="{{imageSrc(item.icon)}}" alt="{{item.label}}">
                <span class="menu-label">{{item.label}}</span>
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </a>
            <div class="collapse list-unstyled" id="{{level+i}}">
                <ng-container
                    *ngTemplateOutlet="menuItemsTemplate; context:{ $implicit: item.subMenu, level: level+i }">
                </ng-container>
            </div>
        </ng-template>
    </ng-container>
</ng-template>