import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CreateOrUpdateSecureMessageDto, MessageRequestDto,MemberMessageResponseDto, FilterDto, SecureMessageListingDto, MessageConversionListingDto, UpdateMemberReadDateDto } from '@shared/models/message.model';
import { Observable } from 'rxjs';
import { AppConfig } from '@shared/models/app-config.model';
import { Constants } from '@shared/services/constants';
import { Constant } from '@shared/utilities/constant';

@Injectable()
export class CustomMessageService {
    constructor(private http: HttpClient, 
        @Inject(Constant.appConfig) private appConfig: AppConfig,
        private _http: HttpClient) { }
  
    private headers: HttpHeaders;

  
    public getSecureMessages(memberId: string): Observable<SecureMessageListingDto[]> {
        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.getmessageMemberUrl.replace(/##memberId##/g,memberId)}`;

        return this._http.get<SecureMessageListingDto[]>(url, {headers: this.headers});
    }

    public createOrUpdateSectionData(formToSave: CreateOrUpdateSecureMessageDto): Observable<MemberMessageResponseDto> {
        
        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.memberMessageCreateOrUpdateMessageUrl}`;

        return this._http.post<MemberMessageResponseDto>(url, formToSave, {headers: this.headers});
    }

    public updateMemberReadDate(formToSave: UpdateMemberReadDateDto): Observable<string> {
      

        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.updateMemberReadUrl}`;

        return this._http.post<string>(url, formToSave, {headers: this.headers});
    }

    public uploadAttachmentFiles(body: FormData, messageId:string, memberId:string) : Observable<any> {

        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.uploadAttachmentUrl}${messageId}${'/'}${memberId}`+ '?RowSource=' + Constant.AIProwSource;
        return this._http.post<any>(url, body, {headers: this.headers});
    }

    public downLoadAttachmentFile(messageId:string, memberId:string, fileName:string) : Observable<any> {

        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.downloadAttachmentUrl}${'/'}${messageId}${'/'}${memberId}`+ '?fileName=' + fileName;
        return this._http.get<any>(url, {headers: this.headers, observe: 'body', responseType: 'blob' as 'json'});
    }

    public downloadFulfillmentDocument (memberId:string, documentId:string, conversationId:string) : Observable<any> {
 
        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.DownloadFulfillmentDocumentUrl}${'/'}${memberId}${'/'}${documentId}${'/'}${conversationId}`;
        return this._http.get<any>(url, {headers: this.headers, observe: 'body', responseType: 'blob' as 'json'});
    }

    public getDocument(documentName: string, documentId: string): Observable<any> {
        const encodedDocumentName = encodeURIComponent(documentName);
  
        const url = `${this.appConfig.apiBaseUrl}${Constants.messages.DownloadDocumentUrl}/${documentId}/${encodedDocumentName}`;
        return this._http.get<any>(url, { headers: this.headers, observe: 'body', responseType: 'blob' as 'json' });
    }
    
    public getViewConversationMessages(conversationId: string, isMessageViewed:boolean): Observable<MessageConversionListingDto[]> {
        let url = `${this.appConfig.apiBaseUrl}${Constants.messages.viewMessageUrl.replace(/##conversationId##/g, conversationId)}?isMessageViewed=${isMessageViewed}`;
        
        return this._http.get<MessageConversionListingDto[]>(url, {headers: this.headers});
    }

    private getMemberMessageRequest(memberId: string): MessageRequestDto {

        let body = new MessageRequestDto();
        
        let filters: FilterDto[] = [];
        let filter = new FilterDto();

        filter.operator = 'equals';
        filter.property = 'MemberId';
        filter.value = memberId;

        filters.push(filter);

        body.pageNumber = 1;
        body.filters = filters;
        body.pageSize = 1000;

        return body;

    }
}
