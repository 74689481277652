import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingIndicatorService } from './loading-indicator.service';
import { UserMemberDetailsModel } from '@shared/models/usermemberdetails';
import { UserDetails } from '@shared/models/userdetails.model';
import { Constant } from '@shared/utilities/constant';
import { AppConfig } from '@shared/models/app-config.model';
import { Constants } from '@layout/layout.constants';
import { BaseApiClient } from './base-api-client.service';
import { UserMemberDetailsService } from './usermemberdetails.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



export interface IMemberDetailsService {
  url: string;
  getMemberdetails(userId: string): Observable<UserMemberDetailsModel>;
  getCurrentUser(member: UserMemberDetailsModel);
}

@Injectable()
export class MemberDetailsService extends BaseApiClient implements IMemberDetailsService {
  public baseUrl: string; // base url
  public url: string; // URL to web api


  constructor(
    private readonly http: HttpClient,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private usermemberdetailsService: UserMemberDetailsService,
    @Inject(Constant.appConfig) private appConfig: AppConfig
  ) {
    super();
    this.baseUrl = appConfig.apiBaseUrl;
    this.url = this.baseUrl + Constants.url.getUserDetailsURL;
  }



  getMemberdetails(userId: string): Observable<UserMemberDetailsModel> {
    const url = `${this.url}/${userId}`;
    this.loadingIndicatorService.startLoading();
    return this.http.get<UserMemberDetailsModel>(url).pipe(
      tap((): void => {
        this.loadingIndicatorService.stopLoading();
      }),
      catchError(this.createErrorResponseHandler(_ => this.loadingIndicatorService.stopLoading()))
    );


  }
  getCurrentUser(userobj: UserMemberDetailsModel) {

    this.usermemberdetailsService.usermemberdetails = userobj;
    let currentuserdetails: UserDetails = null;
    if (userobj.memberId != null)//Logged in user is a member
    {
      const currentItem: UserDetails = {
        memberId: userobj.memberId,
        firstName: userobj.firstName,
        lastName: userobj.lastName
      };
      currentuserdetails = currentItem;
      let activeuser: UserMemberDetailsModel = (JSON.parse(JSON.stringify(userobj))) as UserMemberDetailsModel;
      this.usermemberdetailsService.usermemberdetails.activeUser = activeuser;
    }
    else if (userobj.users && userobj.users.length != 0) {//Logged in user is not a member, but has access to other user accounts
      let firstUser = userobj.users[0];
      const currentItem: UserDetails = {
        memberId: firstUser.memberId,
        firstName: firstUser.firstName,
        lastName: firstUser.lastName
      };
      currentuserdetails = currentItem;
      this.usermemberdetailsService.usermemberdetails.activeUser = firstUser;
    }
    this.usermemberdetailsService.saveMemberDetails(this.usermemberdetailsService.usermemberdetails);
    return currentuserdetails;
  }

}