import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartsModule } from 'ng2-charts';
import { OrderModule } from 'ngx-order-pipe'
import { NgxSpinnerModule } from 'ngx-spinner';

import { DynamicWidgetsComponent } from '@app/dynamic-widgets/dynamic-widgets.component';
import { DemographicWidgetComponent } from '@app/dynamic-widgets/widgets/demographic-widget/demographic-widget.component';
import { ListWidgetComponent } from '@app/dynamic-widgets/widgets/list-widget/list-widget.component';
import { ChartWidgetComponent } from '@app/dynamic-widgets/widgets/chart-widget/chart-widget.component';
import { DemograhicActionWidgetComponent } from '@app/dynamic-widgets/widgets/demograhic-action-widget/demograhic-action-widget.component';

import { ControlsModule } from '@shared/controls/controls.module';
import { WorkflowWidgetComponent } from './widgets/workflow-widget/workflow-widget.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateRangeComponent } from './custom-date-range/custom-date-range.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetTitleComponent } from './widget-title/widget-title.component';
import { AuthDirectivesModule } from '@colibrium/auth-lib';
import { PhoneModule } from '@shared/pipes/customPipes.module';
import { FormWidgetComponent } from './widgets/form-widget/form-widget.component';
import { controlConfig } from '@shared/utilities/control.config';
import { AbpSuiteModule } from '@colibrium/abp-suite';
import { DynamicWidgetModule } from '@app/@dynamic-widget/dynamic-widget.module';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { StorageWidgetComponent } from './widgets/storage-widget/storage-widget.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { QuickLinkWidgetComponent } from './widgets/quick-link-widget/quick-link-widget.component';
import {DateModule} from '@shared/pipes/datePipes.module';

@NgModule({
  declarations: [
    DynamicWidgetsComponent,
    DemographicWidgetComponent,
    ListWidgetComponent,
    ChartWidgetComponent,
    DemograhicActionWidgetComponent,
    WorkflowWidgetComponent,
    CustomDateRangeComponent,
    WidgetTitleComponent,
    FormWidgetComponent,
    StorageWidgetComponent,
    QuickLinkWidgetComponent
    
  ],
  imports: [
    CommonModule,
    PhoneModule,
    ControlsModule,
    ChartsModule,
    NgxSpinnerModule,
    OrderModule,
    NgbModule,
    ReactiveFormsModule,
    AuthDirectivesModule,
    AbpSuiteModule.forRoot(controlConfig),
    DynamicWidgetModule.forRoot(controlConfig),
    AccordionModule,
    ProgressBarModule,
    NgxDropzoneModule,
    DropdownModule,
    FormsModule,
    DateModule
   ],

  exports: [DynamicWidgetsComponent, DemographicWidgetComponent, ListWidgetComponent, FormWidgetComponent,StorageWidgetComponent,QuickLinkWidgetComponent]
})
export class DynamicWidgetsModule { }
