//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { SectionModel } from '@app/@dynamic-widget/models/section-map/section.model';
import { RowModel } from '@app/@dynamic-widget/models/row.model';
import { FormFieldModel } from '@app/@dynamic-widget/models/field/form-field.model';

//#endregion functional/model imports

export class SectionInfoModel {

  //#region model properties

  public section: SectionModel;
  public rowFields: RowModel<FormFieldModel>[];
  public cssClass: string;
  public isFormSubmitted: boolean;

  //#endregion model properties

  //#region constructor

  constructor() {
    this.rowFields = [];
  }

  //#endregion constructor

}
