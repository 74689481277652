<div class="title top-title d-flex align-items-center justify-content-between TitleFont">
    <div class="d-flex {{ titleConfig.parentContainerCss }}">
        <div class="{{titleConfig.iconContainerCss}}">
            <img title="icon" class="{{titleConfig.iconCss}}" src={{titleConfig.image}}/>
        </div>
        <div class="{{titleConfig.titleContainerCss}}">
            <span class="{{titleConfig.titleCss}} TitleFont">{{titleConfig.title}}</span>
        </div>
    </div>
    <div class="right-icons">
        <ul>
            <li *ngIf="titleConfig.dateFilterEnabled" ngbDropdown placement="bottom-right">
                <span class="filter TextFont" id="navbardrop" data-toggle-second="tooltip" title="Filter">
                    <i class="fa fa-filter" ngbDropdownToggle aria-hidden="true"></i>
                </span>
                <div ngbDropdownMenu aria-labelledby="navbardrop">
            <li ngbDropdownItem *ngFor="let option of filterList" (click)="applyFilter(option.value)">
                {{option.value}}
            </li>
    </div>
    </li>
    <li>
        <span *ngIf="titleConfig.explicitRefreshEnabled" class="refresh" data-toggle="tooltip" title="Refresh">
            <i class="fa fa-refresh" (click)="refresh()" aria-hidden="true"></i>
        </span>
    </li>
    <li>
        <span *ngIf="titleConfig.isExportable" class="refresh" data-toggle="tooltip" title="Export to Excel">
            <i class="fa fa-file-excel-o" (click)="export(0)" aria-hidden="true"></i>
        </span>
    </li>

    <li>
        <span *ngIf="titleConfig.isExportable" class="refresh" data-toggle="tooltip" title="Export to PDF">
            <i class="fa fa-file-pdf-o" (click)="export(1)" aria-hidden="true"></i>
        </span>
    </li>
    <li>
        <span *ngIf="titleConfig.isExpandable && isExpanded" class="expand" data-toggle="tooltip" title="compress">
            <i class="fa fa-compress" (click)="toggleExpand()" aria-hidden="true"></i>
        </span>
        <span *ngIf="titleConfig.isExpandable && !isExpanded" class="expand" data-toggle="tooltip" title="Expand">
            <i class="fa fa-expand" (click)="toggleExpand()" aria-hidden="true"></i>
        </span>
    </li>
    <li>
        <span *ngIf="titleConfig.dragEnabled && !isExpanded" class="drag handler"
            data-toggle="tooltip" title="Drag">
            <i class="fa fa-arrows" aria-hidden="true"></i>
        </span>
    </li>
    </ul>
</div>
</div>
<ng-template #dateFilter let-modal>
    <div class="modal-header">
        <div>
            <span class="navbar-brand py-md-0" span>Custom date range</span>
        </div>
        <div class="float-right">
            <i class="fa fa-times" (click)="modal.dismiss('Cross click')" aria-hidden="true"></i>
        </div>
    </div>
    <div class="modal-body">
        <app-custom-date-range (onApply)="apply($event)">
        </app-custom-date-range>
    </div>
</ng-template>