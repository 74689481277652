import { Inject, Injectable } from '@angular/core';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { Constant } from '@shared/utilities/constant';
import { AppConfig } from '@shared/models/app-config.model';
import { Observable } from 'rxjs';
import { FileRequestModel } from '@app/dynamic-widgets/models/file-request-model';


@Injectable({
  providedIn: 'root'
})
export class StorageWidgetService {

  private baseUrl: string;

  constructor(private httpUtility: HttpUtility,
              private httpClient: HttpClient,
              @Inject(Constant.appConfig) private appConfig: AppConfig) {

    if (environment.production) {
      this.baseUrl = `${appConfig.apiBaseUrl}${DynamicWidgetsConstant.urls.unifiedPortalHubUrl}`;
    }
    else {
      this.baseUrl = `${appConfig.apiBaseUrl}`;
    }
  }

  public postFile(file: any, fileRequestModel: FileRequestModel): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(Constant.file, file, file.name);
    formData.append(Constant.storageTypeKey, fileRequestModel.storageTypeKey);
    formData.append(Constant.subFolderPath, fileRequestModel.subFolderPath);
    formData.append(Constant.overrideFile, fileRequestModel.overrideFile);
    return this.httpUtility.post(`${this.baseUrl}${DynamicWidgetsConstant.urls.saveInFolderUrl}`, formData);
  }

  public downloadFileFromPath(fileRequestModel: FileRequestModel): Observable<Blob> {
    return this.httpClient.post(`${this.baseUrl}${DynamicWidgetsConstant.urls.downloadFromPathUrl}`, fileRequestModel, {responseType: 'blob'});
  }

  public postdataFile(fileDataModel: any): Observable<any> {
    return this.httpUtility.post(`${this.baseUrl}${DynamicWidgetsConstant.urls.saveFilesUrl}`, fileDataModel);
  }
}
