import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AppComponent } from '@app/app.component';
import { ErrorComponent } from '@shared/components/error/error.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { PagenotfoundComponent } from '@app/pagenotfound/pagenotfound.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedService } from '@shared/services/shared.service';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from '@core/serializers/custom-url-serilaizer';
import {​​​​​ TabsModule }​​​​​ from 'ngx-bootstrap/tabs';
import { DialogModule } from 'primeng/dialog';
import { AuthLibModule } from '@colibrium/auth-lib';
import { DynamicWidgetsModule } from './dynamic-widgets/dynamic-widgets.module';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { PhonePipe } from '@shared/pipes/phone.pipe';
import { NgbModule, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { NoValuePipe } from '@shared/pipes/novalue.pipe';
import {AutoLogoutService} from '@shared/services/auto-service';
import { ReplacePipe } from '@shared/pipes/replace.pipe';
import { CoreModule } from './core/core.module';
import { LayoutModule } from '@layout/layout.module';
import { MemberDetailsService } from '@shared/services/memberdetails.service';
import { ProgramService } from '@shared/services/program.service';
import { CustomMessageService } from '@shared/services/message.service';
import { DialogService } from 'primeng/dynamicdialog';
import { PdfjsviewerComponent } from './shared/components/pdfjsviewer/pdfjsviewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NewWindowContainerComponent } from './shared/new-window-container/new-window-container.component';
import { NewWindowService } from '@shared/services/new-window.service';
import { DeviceLayoutService } from '@shared/services/devicelayout.service';



@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    LoaderComponent,
    PagenotfoundComponent,
    PdfjsviewerComponent,
    NewWindowContainerComponent
  ],

  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    AuthLibModule,
    LayoutModule,
    ToastModule,
    ConfirmDialogModule,
    TabsModule,
    DialogModule,
    AngularSvgIconModule,
    DynamicWidgetsModule,
    NgbModule,
    PdfJsViewerModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    SharedService,
    AutoLogoutService,
    MemberDetailsService,
    DatePipe,
    PhonePipe,
    NoValuePipe,
    ReplacePipe,
    ProgramService,
    CustomMessageService,
    DialogService,
    NewWindowService,
    DeviceLayoutService    
  ],
  exports: [AngularSvgIconModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
