<ng-template [ngIf]="isLoaded">
    <ng-container *ngIf="imageDetails.redirectUrl; then withRedirection; else withoutRedirection"></ng-container>
</ng-template>
<ng-template #withRedirection>
    <a class="on-hover" (click)="onImageClick()">
        <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
    </a></ng-template>
<ng-template #withoutRedirection>
    <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
</ng-template>
<ng-template #imageTemplate><img [class]="class" [src]="imageObjectUrl" /></ng-template>