<section  [class]="leftExpand? 'wrapper left-expanded' : leftPanel? 'wrapper no-leftPanel' : 'wrapper'">
    <div [class]="leftPanel? 'container' : 'container-fluid'">
        <div class="row">
            <div class="col-12">
                <breadcrumb #parent>  
                    <ol class="breadcrumb">
                    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                        <li *ngIf="!route.terminal" class="breadcrumb-item">
                        <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
                        </li>
                        <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">{{ route.displayName }}</li>
                    </ng-template>
                    </ol>
                </breadcrumb> 
            </div>
        </div>
    </div>
</section>