<div class="container sign-up-container">
    <div class="row  align-items-end">
        <div class="col-lg-6 logo">
            
            <img src="../../../assets/Smart_step_Logo_Final_2_JPEG.png" class="branding_sagility">
        </div>
        <div class="col-lg-6 sign-in-top">
            <h6 class="sign-up-text">
                <a routerLink="/login">Login</a>
            </h6>
        </div>        
    </div>
    <div class="row mt-2 mb-3">
        <div class="col-12">
            <div class="bg-white shadow-sm border-radius-5">
                <div class="sign-up-title p-3">
                    <div>
                    <h3>Caregiver Sign Up</h3>
                    </div>
                    <div>
                    <p class="text-white TextFont"><i class="fa fa-question-circle-o" icon="pi pi-check" pTooltip="A caregiver is anyone who helps care for someone. This 
                        could be someone within your family or close friend." tooltipPosition="bottom" tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;"></i>
                        What is a Caregiver?
                    </p>
                    </div>
                </div>
                <div class="steps p-3">
                    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
                </div>
                <div class="form-container row justify-content-center p-3">
                    <div class="col-sm-9 mb-4" *ngIf="form1">
                        <form [formGroup]="registrationGroup">
                            <div>
                                <p class="signup-info">If you are having trouble and need assistance, call 1-888-413-2099 Monday-Friday 8am-8pm EST. Registration is available only between 8AM-3AM EST.<br>Please sign up using the 7 character code provided in the welcome email you have received on the email address enrolled by the Policyholder.
                                    The code is a series of letters and numbers and must be entered correctly to complete your sign up process.
                                </p>
                            </div>
                            <div class="top-title">
                                <h1 class="text-gray">validation</h1>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Registration Code
                                    <sup>*</sup></label>
                                <div class="p-col col-11 col-sm-7">
                                    <div class="lock-icon">
                                        <input type="text" maxlength="10" class="form-control"
                                            formControlName="registrationCode" 
                                            (change)="RcodeValidate($event)" pInputText placeholder="eg. XY00012" />
                                    </div>
                                   <div *ngIf="rcSubmitted && rc.registrationCode.errors" class="error">
                                        <div *ngIf="rc.registrationCode.errors.required">Invalid Registration
                                            Code</div>
                                        <div *ngIf="rc.registrationCode.errors.pattern">Invalid Registration
                                            Code</div>
                                    </div>
                                    <div *ngIf="RcodeError" class="error">
                                        <div>Invalid Registration Code</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group calender-input row p-field p-grid d-flex align-items-center mb-5">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Policy Holder Date of Birth
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8 col-lg-6">
                                    <div class="lock-icon"> 

                                        <div class="input-group">
                                            <input
                                            (dateSelect)="onDateSelect($event)"
                                            (keyup)="OnkeyUp($event)"
                                            [minDate]="{year: minYear, month: 1, day: 1}"
                                            [maxDate]="{year: maxYear, month: maxMonth, day: Date}"
                                              class="form-control"
                                              placeholder=" MM/DD/YYYY"
                                              name="dp"
                                              [(ngModel)]="dateVal"
                                              ngbDatepicker
                                              #d="ngbDatepicker"
                                              (blur)="onDateBlur($event)"
                                              
                                              formControlName ="dob" monthNavigator="true"
                                            />
                                            <div>
                                           <button class="btn btn-outline-secondary cal-margin" (click)="d.toggle()" type="button"><img src="../../../../assets/calendar1.png" alt="Calender"></button> 
                                            </div>
                                            <div class="p-col col-1 col-sm-1 pl-0 margin-left">
                                                <i class="fa fa-question-circle-o question-mark-black" icon="pi pi-check" 
                                                pTooltip="The date of birth is for the person who has designated you as a caregiver."
                                                tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;" tooltipPosition="bottom"></i>
                                            </div>
                                          </div>                                          
                                    </div>
                              
                                    <div *ngIf="((rcSubmitted && rc.dob.errors)  || (!validYear))" class="error">
                                        <div *ngIf="(rc.dob.errors.required)  || (!validYear)">Please enter valid Date Of Birth
                                        </div>
                                    </div>
                                
                                </div>
                                <!-- <div class="p-col col-1 col-sm-1 pl-0">
                                    <i class="fa fa-calendar fa-lg " aria-hidden="true"></i>
                                </div> -->
                                
                            </div>
                            <div class="col-lg-7 offset-lg-4 d-flex align-items-center">
                                <div class="submit-btn">
                                    <p-button label="Next" (onClick)="nextPage1()" icon="pi pi-arrow-right"
                                        iconPos="right">
                                    </p-button>
                                </div>
                                <div class="cancel-btn">
                                    <p-button label="Cancel" class="ml-2" routerLink="/login"></p-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8 mb-4" *ngIf="form2">
                        <form [formGroup]="group" noValidate>
                            <div>
                                <p class="signup-info"><i>If you have any questions or face any issues completing the form below, please call 1-888-413-2099.</i></p>
                            </div>
                            <div class="top-title">
                                <h1>portal credentials</h1>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Email Address 
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8">
                                    <div class="lock-icon" *ngIf="memberContactresult; else emailedit">
                                        <input type="email" class="form-control" placeholder="eg. abc@xyz.com"
                                            formControlName="email" disabled="true" />
                                    </div>
                                    <ng-template #emailedit>
                                    <div class="lock-icon" >
                                        <input type="email" class="form-control" placeholder="eg. abc@xyz.com"
                                            formControlName="email" />
                                    </div>
                                   </ng-template>
                                    <div *ngIf="formSubmitted && f.email.errors" class="error">
                                        <div *ngIf="f.email.errors.required">Invalid Email Address</div>
                                        <div *ngIf="f.email.errors.email || f.email.errors.pattern " >Invalid email format</div>
                                    </div>
                                </div>
                            </div>

                           
                           <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Mobile Phone <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8">
                                    <div class="lock-icon d-flex" *ngIf="memberContactresult; else mobileedit">
                                        <input type="tel" class="form-control"
                                            [value]="group.get('mobileNumber').value | phone"
                                            placeholder="eg. 0000000000" maxlength="12" minlength="10" 
                                            formControlName="mobileNumber" disabled="true"/>
                                    </div>
                                    <ng-template #mobileedit>
                                        <div class="lock-icon d-flex">
                                            <input type="tel" class="form-control"
                                                [value]="group.get('mobileNumber').value | phone"
                                                placeholder="eg. 0000000000" maxlength="12" minlength="10" 
                                                formControlName="mobileNumber" />
                                        </div>
                                    </ng-template>
                                    <div *ngIf="formSubmitted && f.mobileNumber.errors" class="error">
                                        <div *ngIf="f.mobileNumber.errors.required">Invalid Mobile Phone</div>
                                        <div *ngIf="f.mobileNumber.errors.pattern">Enter only numbers</div>
                                        <div *ngIf="f.mobileNumber.errors.minlength">Invalid Mobile Phone</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Set Password
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8">
                                    <div class="lock-icon d-flex">
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                            pInputText placeholder="******" formControlName="password" />
                                        <a href="javascript:;"><i class="fa fa-lock" [ngClass]="{
                                                        'fa-eye-slash': !fieldTextType,
                                                        'fa-eye': fieldTextType
                                                      }" (click)="toggleFieldTextType()"></i></a>
                                                      <div class="sign-up-title" class="mar-top-35">
                                                          <p class="password-tooltip">
                                                          <i class="fa fa-question-circle-o" icon="pi pi-check" 
                                                          tooltipPosition="{{tooltipPosition}}" pTooltip="Minimum : 8 characters
                                                          Maximum : 24 characters
                                                          Must contain : 1 uppercase, lowercase, special character(e.g. , ! @ #) and number.
                                                          Must not contain : First or Last name of the Policy Holder" 
                                                          tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;" ></i>
                                                        </p>
                                                      </div>

                                    </div>
                                    <div *ngIf="formSubmitted && f.password.errors" class="error">
                                        <div *ngIf="f.password.errors.required">Password is very weak</div>
                                        <div *ngIf="f.password.errors.pattern">The entered password does not
                                            meet the
                                            required standard</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Confirm Password
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8">
                                    <div class="lock-icon">
                                        <input [type]="fieldTextTypeCF ? 'text' : 'password'" class="form-control"
                                            pInputText placeholder="******" formControlName="confirmPassword" />
                                        <a href="javascript:;"><i class="fa fa-lock" [ngClass]="{
                                                        'fa-eye-slash': !fieldTextTypeCF,
                                                        'fa-eye': fieldTextTypeCF
                                                      }" (click)="toggleFieldTextTypeCF()"></i></a>
                                    </div>
                                    <div *ngIf="formSubmitted && f.confirmPassword.errors" class="error">
                                        <div *ngIf="f.confirmPassword.errors.required">Password is very weak
                                        </div>
                                        <div *ngIf="f.confirmPassword.errors.pattern">The entered password does
                                            not meet the required standard</div>
                                        <div *ngIf="f.confirmPassword.errors.confirmedValidator">Password and
                                            Confirm Password must be match.</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showServerError" class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <div class="p-col col-lg-7 offset-lg-4 col-sm-9">
                                    <div class="d-flex">
                                        <span class="error pb-2">{{serverError}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <div class="p-col col-lg-7 offset-lg-4 col-sm-9">
                                    <div class="d-flex">
                                        <div class="submit-btn">
                                            <p-button label="Save and Go to My Portal" (onClick)="submitForm()"
                                                icon="pi pi-arrow-right" iconPos="right"></p-button>
                                        </div>
                                        <div class="cancel-btn">
                                            <p-button label="Cancel" class="ml-2" (onClick)="prevPage()"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="showSpinner">
           
                <ngx-spinner [name]="spinnerName" [fullScreen] = "false"  size="medium"  type="square-jelly-box">
                    <p class="text-white"> Loading, please wait... </p>
                </ngx-spinner>
            
            </div>
        </div>
    </div>
</div>
<abp-content-popup *ngIf="showPopup" [(showPopup)]="showPopup" class="popupHeader">
    <div header>
        <h5 class="headerColour sign-up-headfont">
            <i class="fa fa-exclamation-circle iconClass"></i>
            System Info
        </h5>
    </div>
    <div body>
        <div class="col-lg-12 sign-in-top">
            <h6 class="login-text sign-up-text">
                {{errorMessageForMemberReg}}
                <!-- The member with the given registration code is already signed up.-->
                <br> Please click here to <a href="/login">Login</a>
            </h6>
        </div>
    </div>
</abp-content-popup>

