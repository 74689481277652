import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Input, ComponentRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ComponentMap } from '@app/dynamic-widgets/component.map';
import { Widget } from '@app/dashboard/models/widget.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dynamic-widgets',
    templateUrl: './dynamic-widgets.component.html',
    styleUrls: ['./dynamic-widgets.component.scss']
})
export class DynamicWidgetsComponent implements OnInit, AfterViewInit  {
    private component: ComponentRef<any>;
    @Input() isExpanded:boolean = false;
    @Input() params: any;
    @Input() isFromDashboard : boolean;

    @ViewChild('widget', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

    public modalRef: NgbModalRef;
    public widgetConfiguration: Widget;

    @Output() toggleExpand = new EventEmitter();

    @Input() set widget(dynamicComponent: Widget) {
        if (dynamicComponent) {
            this.widgetConfiguration = dynamicComponent;
        }
    }

    constructor(private factoryResolver: ComponentFactoryResolver, private modalService: NgbModal) { }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        if (this.widgetConfiguration) {
            let factory = ComponentMap.getComponents(this.factoryResolver, this.widgetConfiguration.name);
            if (factory) {

                this.container.clear();
                this.component = factory.create(this.container.parentInjector);
                this.component.instance.input = this.widgetConfiguration.configuration;
                this.component.instance.titleConfig = this.widgetConfiguration.titleConfiguration;
                this.component.instance.actions = this.widgetConfiguration.actions;
                this.component.instance.isFromDashboard = this.isFromDashboard;
                this.component.instance.params =  this.params;
                this.container.insert(this.component.hostView);
            }
        }
    }     
}
