<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6" style="max-width: 70% !important; flex: 0 0 70% !important;">
        <div class="copyright">
          Copyright © {{currentYear}} Sagility Smart Step<sup>TM</sup> falls prevention program. All rights reserved | <span>Call Us at </span> <a title="phoneNumber" href="tel:1-888-413-2099">1-888-413-2099,</a>  Monday – Friday 8am-8pm EST. 
        </div>
      </div>
      <div class="col-lg-6 text-right" style="max-width: 30% !important; flex: 0 0 30% !important;">
        <div class="footer-links">
          <!-- <a style="cursor: pointer;">CONTACT US</a> -->
          <!-- <a style="cursor: pointer;" class = "on-hover" (click)="onClick(privacyPolicy)">PRIVACY POLICY</a> 
          <a class = "on-hover" (click)="onClick(termsandConditions)">TERMS OF SERVICE</a> -->
          <a style="cursor: pointer;" class = "on-hover" target="_blank" href="assets/files/Global_Privacy_Policy.pdf">PRIVACY POLICY</a>
          <a class = "on-hover" title="TERMS OF USE"  target="_blank" href="assets/files/PRU Portal Terms of Use.pdf">| &nbsp; TERMS OF USE</a>
        </div>
      </div>
    </div>
  </div>
</footer>
