<div class="d-flex">
    <abp-field-display-label [field]="displayLabelField"></abp-field-display-label>

    <app-calendar (onDateChange)="onDateChange($event)" [config]="config"
        showIcon="true" [dateValue] = "dateValue">

    </app-calendar>

    <abp-validation-display [control]="control|async" [isSubmitted]="isFormSubmitted"
        [validationMap]="field.validationMap" (isControlValid)="setClassForControlValidity($event)">
    </abp-validation-display>
</div>