import { Inject, Injectable } from '@angular/core';
import { HttpUtility } from '@app/core/utilities/http.utility';

import { PaginationModel } from '@shared/models/pagination.model';
import { environment } from 'src/environments/environment';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { ChartFilterData } from './models/chart-filter-data.model';
import { Constant } from '@shared/utilities/constant';
import { AppConfig } from '@shared/models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class DynamicWidgetsService {

  private baseUrl: string;

  constructor(private httpUtility: HttpUtility,
    @Inject(Constant.appConfig) private appConfig: AppConfig) {

    if (environment.production) {
      this.baseUrl = `${appConfig.apiBaseUrl}${DynamicWidgetsConstant.urls.unifiedPortalHubUrl}`;
    }
    else {
      this.baseUrl = `${appConfig.apiBaseUrl}`;
    }
  }

  /**
   * Gets data for List widget with or without pagination.
   * @param url
   * @param paginationModel
   */
   public getListWidgetData(url: string, paginationModel: PaginationModel,showPaginator: boolean, showGlobalProgressBar: boolean = false) 
   {
      let result:any;
      if (showPaginator) {
        result =this.httpUtility.post(this.getEndPointBaseUrl(url), paginationModel, null, showGlobalProgressBar);
      } else {
        result =  this.httpUtility.get(this.getEndPointBaseUrl(url), null, showGlobalProgressBar);
      }
      return result;
    }


  /**
   * Gets dashboard configuration
   * @param url
   */
  public getDemographicData(url: string, showGlobalProgressBar: boolean = false) {
    return this.httpUtility.get(this.getEndPointBaseUrl(url), null, showGlobalProgressBar);
  }

  /**
   * Gets data for chart widget
   * @param url
   */
  public getChartData(url: string, data: ChartFilterData) {
    return this.httpUtility.post(this.getEndPointUrl(url), data, null);
  }

  /**
   * Gets data for demographic action widget
   * @param url
   */
  public getDemographicActionData(url: string) {
    return this.httpUtility.get(this.getEndPointUrl(url), null);
  }

  /**
   * To make http post call
   */
  public post(url: string, data: any) {
    return this.httpUtility.post(this.getEndPointUrl(url), data, null);
  }

  /**
   * To make http post call
   */
  public postByOptions(url: string, data: any, options?: any) {
    return this.httpUtility.postByOptions(this.getEndPointUrl(url), data, options);
  }

  /**
   * To make http put call
   */
  public put(url: string, data: any) {
    return this.httpUtility.put(this.getEndPointUrl(url), data, null);
  }

  /**
   * To make http get call
   */
  public get(url: string) {
    return this.httpUtility.get(this.getEndPointUrl(url), null);
  }

  /**
   * To make http delete call
   */
  public delete(url: string, data: any) {
    return this.httpUtility.delete(this.getEndPointUrl(url), data);
  }

  private getEndPointUrl(url: string) {
    let endpointUrl = '';
    if (url.indexOf('http') !== -1) {
      endpointUrl = url;
    } else {
      endpointUrl = `${this.baseUrl}${url}`;
    }
    return endpointUrl;
  }

  private getEndPointBaseUrl(url: string) {
    let endpointUrl = '';
    if (url.indexOf('http') !== -1) {
      endpointUrl = url;
    } else {
      endpointUrl = `${this.baseUrl}${DynamicWidgetsConstant.urls.apiUrl}${url}`;
    }
    return endpointUrl;
  }
}
