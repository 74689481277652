import { ScreenModel, SectionInfoModel } from '@app/@dynamic-widget/models';

export class FormWidget
{
    screen: ScreenModel;
    sections: SectionInfoModel[];

    constructor() {
        this.sections = [];
      }
}