import { UrlSerializer, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { CoreConstant } from '@core/utilities/core.constant';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer;
    constructor() {
        this._defaultUrlSerializer = new DefaultUrlSerializer();
    }

    public parse(url: string): UrlTree {
        // Encode "+" to "%2B"
        url = url.replace(/\+/gi, CoreConstant.token.plusEncodedValue);
        // Use the default serializer.
        return this._defaultUrlSerializer.parse(url);
    }

    public serialize(tree: UrlTree): string {
        return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, CoreConstant.token.plusEncodedValue);
    }
}
