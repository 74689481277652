import { PaginationModel } from "@shared/models/pagination.model";

export class Constant {
    public static readonly AIProwSource: string = "Member Portal";
    public static readonly title: string = "Atom Prototype";
    public static readonly configurations: string = "Configurations";
    public static readonly appConfig: string = "AppConfig";
    public static readonly field: string = "number";
    public static readonly phoneNumber: string = "phonenumber";
    public static readonly noLeadingTrailingWhiteSpaceRegex: string = "";
    public static readonly forwardCurlBrace: string = "{";
    public static readonly backwardCurlBrace: string = "}";
    public static readonly urlParamRegex = "/{(?!{)S/";
    public static readonly paramReqgex = /{{\b\S+?\b}}/;
    public static readonly file: string = "file";
    public static readonly storageTypeKey: string = "StorageTypeKey";
    public static readonly subFolderPath: string = "SubFolderPath";
    public static readonly overrideFile: string = "OverrideFile";
    public static readonly isNew: string = "IsNew=yes";
    public static readonly priority: string = "Priority=Urgent";
    public static readonly successMessage = "Member sign up successful";
    public static readonly caregiverSignupSuccessMessage = "Caregiver sign up successful";
    public static readonly isAlerted: string = "&IsAlerted=yes";
    public static readonly memberSource: string = "&Source=Client";
    public static readonly allowedFileTypesMessage: string = "You may attach word documents (.doc or .docx), PDFs (.pdf), or images (.jpeg or .png)";
    public static readonly allowedFileTypes: string = ".doc,.docx,.pdf,.jpeg,.jpg,.png";
    

    public static readonly StorageSession: string = "sessionTrk";

    public static readonly loader = class {
        public static readonly progressElement = "progress";
        public static readonly fullPercentile = "100%";
    };

    public static readonly severity = class {
        public static readonly success = "success";
    };
    
    public static readonly futureDateFarmat = "YYYY-MM-DD HH:mm";
    public static readonly dateCompareValidator = "DateCompareValidator";
    public static readonly contactMethodCompareValidator = "ContactMethodCompareValidator";
    public static readonly menuLinkUrlValidator = "InvalidMenuLinkUrl";
    public static readonly urlValidtor = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    
    public static readonly autoSearch = class {
        public static readonly minLenght: number = 2;
        public static readonly debounceTime: number = 300;
    };
    
    public static readonly customDateFormat: string = "MM/DD/YYYY";
    public static readonly phoneNumberFormat: string = "$1-$2-$3";

    public static get defaultPagination(): PaginationModel {
        return { pageNumber: 1, pageSize: 5 };
    }

    public static readonly validationDetails = (text: string) =>
        `Enter valid ${text}`;

    public static readonly verticalAlign = "middle";
    public static readonly centerAlign = "center";

    public static readonly pdf = class {
        public static readonly pdfTheme = "grid";
        public static readonly pdfExtention = ".pdf";
        public static readonly headerBackGroundColor = "white";
        public static readonly textColor = "black";
    };

    public static readonly excel = class {
        public static readonly excelSaveType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        public static readonly excelExtension = ".xlsx";
    };

    public static readonly validations = class {
        public static readonly invalidUrl = "Invalid Url";
    };

    //#region validations
    public static readonly validatorCode = class {
        public static readonly required: string = "required";
        public static readonly minlength: string = "minlength";
        public static readonly maxlength: string = "maxlength";
        public static readonly pattern: string = "pattern";
        public static readonly minDate: string = "minDate";
        public static readonly maxDate: string = "maxDate";
    };
    //#endregion validations

    //#region FormWidgetManager
    public static readonly FormWidgetManager = class {
        public static readonly Licenses: string = "Licenses";
        public static readonly editBtn: string = "EditBtn";
        public static readonly deleteBtn: string = "DeleteBtn";
        public static readonly addnewBtn: string = "AddNewBtn";
        public static readonly saveBtn: string = "SaveBtn";
        public static readonly resetBtn: string = "ResetBtn";
        public static readonly cancelBtn: string = "Cancel";
        public static readonly cancelChangesBtn: string = "CancelChangesBtn";
        public static readonly phoneNumberType: string = "phoneNumberType";
        public static readonly saveChangesBtn: string = "SaveChangesBtn";
        public static readonly clearAllBtn: string = "ClearAllBtn";
    };
    //#endregion FormWidgetManager

    //#region MemberPortal
    public static readonly MemberPortal = class {
        public static readonly Form: string = "Form";
        public static readonly Demographics: string = "Demographics";
        public static readonly Storage: string = "Storage";
        public static readonly List: string = "List";
        public static readonly ToDoList: string = "ToDoList";
        public static readonly CheckItOut: string = "CheckItOut";
        public static readonly ContactInfo: string = "ContactInfo";
        public static readonly ContactPreferences: string = "ContactPreferences";
        public static readonly QuickLink: string = "QuickLink";
        public static readonly ScrollItem: string = "scrollItem";
        public static readonly BehaviorSmooth: any = "smooth";
        public static readonly BlockCenter: any = "center";
        public static readonly SurveyComplete: any = "COMPLETED";
        public static readonly Hometab: any = "home";
        public static readonly Surveytab: any = "surveys";
        public static readonly Caregivertab: any = "caregiverInfo";
    };

    public static readonly MemberactionType = class {
        public static readonly OpenTask: string = "OpenTask";
        public static readonly OpenResource: string = "OpenResource";
        public static readonly OpenSurvey: string = "OpenSurvey";
        public static readonly OpenMyInfo: string = "OpenMyInfo";
        public static readonly OpenCaregiverInfo: string = "OpenCaregiverInfo";
    };

    public static readonly MemberTab = class {
        public static readonly MyPrograms: string = "myPrograms";
        public static readonly MyResources: string = "myResources";
        public static readonly Surveys: string = "surveys";
        public static readonly MyInfo: string = "myInfo";
        public static readonly ContactPreferenceFormWidgetId: string = "contactPreferenceFormWidgetId";
        public static readonly CaregiversListWidgetId: string = "caregiversListWidgetId";
        public static readonly CaregiverInfo: string = "caregiverInfo";
    };

    //#region MyPrograms
    public static readonly getMemberProgramDetailsUrl = "MemberManager/api/MemberPopulation/MemberProgramDetails?MemberID=";
    public static readonly getMemberTasksUrl = "MemberManager/api/MemberTask/GetMemberTasks?MemberID=";
    public static readonly putMemberTaskUrl = "MemberManager/api/MemberTask";
    public static readonly programResourceCountUrl = "MemberManager/api/Resource/ResourceCountByProgram?ProgramId=";
    //#endregion MyPrograms

    //#region Myresource
    public static readonly getProgramsByMemberUrl = "MemberManager/api/Resource/ProgramsByMemberId?MemberId=";
    public static readonly getMemberResourcesByProgramUrl = "MemberManager/api/Resource/MemberResourcesByProgram?ProgramId=";
    public static readonly getMemberResourcesByMemberIdUrl = "MemberManager/api/Resource/MemberResourcesByMemberId?MemberId=";
    public static readonly getMemberResourceByProgramUrl = "MemberManager/api/Resource/MemberResourcesByProgram";
    public static readonly postMemberResourceData = "MemberManager/api/Resource/MemberResource";
    public static readonly getImageorContentByUrl = "/ResourceDefinition/DownloadResourceFile/";
    //#endregion Myresource

    //#region Survey
    public static readonly assessmentType = "FA";
    public static readonly assessmentCompletedLabel = "Thank you for completing the assessment.<br><br>Please call 1-888-413-2099 with any questions.";
    public static readonly assessmentAction = "START";

    public static readonly getMemberBasicDetailsUrl = "MemberManager/api/Member/MemberBasicDetails?source=##source##&MemberId=";
    public static readonly postMemberHealthAssessmentUrl = "MemberManager/api/MemberHealthAssessment";
    public static readonly healthAssessmentID = "D6941FDC-0532-4A1E-EEB1-08D9242BAA05";
    public static readonly healthAssessmentStatus = "Completed";
    public static readonly assignmentDate = "2022-01-01";
    public static readonly getMemberHealthAssessmentDetailsUrl = "MemberManager/api/MemberHealthAssessment/MemberHealthAssessmentDetails?source=##source##&MemberId=";
    public static readonly postAPHMemberContactUrl = "MemberManager/api/member/##memberId##/Contact?MemberSource=##source##&ContactSource=##source##";
    public static readonly getMemberHealthAssessmentResponseUrl = "MemberManager/api/MemberHealthAssessment/GetMemberHealthAssessmentResponses?id=##id##&isMember=true";
    //#endregion Survey

    //#regoin myInfo
    public static readonly memberType = "Caregiver";
    public static readonly memberPortalConsentType = "MemberPortal";
    public static readonly talkToCaregiverConsentType = "TalkToCaregiver";
    public static readonly phoneType = "Mobile";
    public static readonly emailAddressType = "Personal";
    public static readonly memberPortalSenderType = "Member";
    public static readonly memberContactDeleteUrl = (
        source: string
    ): string => {
        return `?MemberSource=${source}&ContactSource=${source}`;
    };
    //#endregion myInfo

    public static readonly Roles = class {
        public static readonly member: string = "Member";
        public static readonly contact: string = "Contact";
    };

    public static readonly AddressTypes = class {
        public static readonly physical = "Physical";
        public static readonly mailing = "Mailing";
        public static readonly unknown = "Unknown";
    };
}
