import { Component, OnInit } from '@angular/core';
import { NewWindowService } from '@shared/services/new-window.service';

@Component({
  selector: 'app-pdfjsviewer',
  templateUrl: './pdfjsviewer.component.html',
  styleUrls: ['./pdfjsviewer.component.scss']
})
export class PdfjsviewerComponent implements OnInit {

  title:string;
  url:string;

  constructor(private newWindowService: NewWindowService ) { }

  ngOnInit(): void {
    this.title=this.newWindowService.title;
    this.url=this.newWindowService.url;

  }

}
