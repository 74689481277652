import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class NewWindowService {

    title:string;
    url:string;

    constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private appRef: ApplicationRef,
      private injector: Injector
    ) {}
  
    openNewWindow(component: any): Window | null {
      const newWindow = window.open('', '_blank');
      if (!newWindow) {
        return null;
      }
      newWindow.document.title = this.title;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = componentFactory.create(this.injector);
  
      this.appRef.attachView(componentRef.hostView);
  
      const newWindowContainerElement = newWindow.document.createElement('app-new-window-container');
      newWindow.document.body.appendChild(newWindowContainerElement);
  
      const componentRootNode = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      newWindowContainerElement.appendChild(componentRootNode);
  
      return newWindow;
    }

    redirectURL(title:string,url:string){

      this.title=title;
      this.url=url;
    }
  }