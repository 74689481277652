export class WidgetDescriptionConfig{
    text: string;
    isDescriptionVisible: boolean;
    descriptionCss: string;
    descriptionContainerCss: string;

    constructor() {
        this.text = '';
        this.isDescriptionVisible = false;
        this.descriptionCss = '';
        this.descriptionContainerCss = '';
    }
}