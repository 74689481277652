import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class DeviceLayoutService {


    public getDeviceLayout(){

    /* For Desktop View */
    const desktopMediaQuery =  window.matchMedia("screen and (min-width: 1024px)");
    const  isDesktop = desktopMediaQuery.matches;   

    /* For Tablet View */
    const tabletMediaQuery =  window.matchMedia("screen and (min-device-width: 768px) and (max-device-width: 1024px");
    const  isTablet = tabletMediaQuery.matches;    

    /* For Mobile Portrait View */
    const mobilePortraitMediaQuery =  window.matchMedia("screen and (max-device-width: 480px) ");
    const  isMobilePorttrait = mobilePortraitMediaQuery.matches;   

    /* For Mobile Landscape View */
    const mobileLandscapeMediaQuery =  window.matchMedia("screen and (max-device-width: 640px) ");
    const  isMobileLandscape = mobileLandscapeMediaQuery.matches;   

    /* For Mobile Phones Portrait or Landscape View */
    const mobilePortraitorLandscapeMediaQuery =  window.matchMedia("screen and (max-device-width: 640px)");
    const  isMobilePortraitorLandscape = mobilePortraitorLandscapeMediaQuery.matches;    

    /* For iPhone 4 Portrait or Landscape View */
    const iPhone4MediaQuery =  window.matchMedia("screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2)");
    const  isiPhone4 = iPhone4MediaQuery.matches;    

    /* For iPhone 5 Portrait or Landscape View */
    const iPhone5MediaQuery =  window.matchMedia("(device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2)");
    const  isiPhone5 = iPhone5MediaQuery.matches;    

    /* For iPhone 6 and 6 plus Portrait or Landscape View */
    const iPhone6MediaQuery =  window.matchMedia("(min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3)");
    const  isiPhone6 = iPhone6MediaQuery.matches;   

    const isMobileDeviceLayout = isTablet || isMobilePorttrait || isMobileLandscape || isMobilePortraitorLandscape || isiPhone4 || isiPhone5 || isiPhone6;
    const isDesktopDeviceLayout = isDesktop;

     return [isMobileDeviceLayout,isDesktopDeviceLayout];
    }
}