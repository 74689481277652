import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import * as moment from 'moment';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { CalendarConfig } from '@shared/controls/models/calendar-config.model';
import { ControlConstant } from '@shared/controls/control.constant';
import { Constant } from '@shared/utilities/constant';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true
    }
  ]
})
export class CalendarComponent implements ControlValueAccessor {

  public disabled: boolean;
  public onChange: Function;
  public onTouched: Function;
  private date: string;
  @Input() placeholder: string = ControlConstant.placeholderText;

  @Input() config: CalendarConfig = {
    dateFormat: ControlConstant.format,
    hourFormat: 24,
    readonlyInput: false,
    showSeconds: true,
    showTime: false,
    showNavigator: false,
    disabled: false
  };

  @Input() dateValue: Date;
  @Output() onDateChange = new EventEmitter();

  constructor() {
    this.onChange = () => { };
    this.onTouched = () => { };
    this.disabled = false;
  }

  //#region get/set properties

  get value() { return this.date; }
  set value(value: any) {
    //When value changes, notify the custom form control by calling onChange and/or onTouched to update the custom control model value accordingly
    if (this.date !== value) {
      this.date = value;
      this.onChange(value);
      this.onTouched(value);
    }
  }

  //#endregion get/set properties

  ngOnInit() {
    if (!this.config.yearRange && this.config.showNavigator) {
      let currentYear = new Date().getFullYear();
      this.config.yearRange = `${currentYear - 10}:${currentYear + 10}`;
    }
    if(this.dateValue){
      this.value = this.dateValue;
    }
  }

  /**
   * This method is called when date change happens in calender
   */
  public dateChange() {
    if (this.value && !this.config.showTime) {
      this.value = moment(this.value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
      const time = this.value.getTime();
      // Convert timezoneOffset to hours, to add to Date value in milliseconds
      const timezoneOffset = this.value.getTimezoneOffset() * 60000;
      const totalMilliseconds = timezoneOffset <= 0 ? time + Math.abs(timezoneOffset) : time - Math.abs(timezoneOffset);
      this.value = new Date(totalMilliseconds);
    } else if (this.value && this.config.showTime) {
      this.value = moment(this.value).seconds(0).milliseconds(0).toDate();
    }
    this.onDateChange.emit(this.value);
  }

  /**
 * This method is called by the forms API to write to the view when programmatic changes from model to view are requested.
 * @param val
 */

  public writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  /**
 * registers a callback function that is called when the control's value changes in the UI.
 * @param fn
 */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
 * registers a callback function is called by the forms API on initialization to update the form model on blur.
 * @param fn
 */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
 * function that is called by the forms API when the control status changes to or from 'DISABLED'
 * @param isDisabled
 */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
