import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';

import { TableComponent } from '@shared/controls/components/table/table.component';
import { CalendarComponent } from '@shared/controls/components/calendar/calendar.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ListCardsComponent } from './components/list-cards/list-cards.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PaginatorComponent } from './components/list-cards/paginator/paginator.component';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { DropdownModule } from 'primeng/dropdown';
import { PhoneModule } from '@shared/pipes/customPipes.module';
import { AuthDirectivesModule } from '@colibrium/auth-lib';
import { DatePickerComponent } from '@app/dynamic-widgets/date-picker/date-picker.component';
import { AbpValidationDisplayConfigModel, AbpValidationDisplayConstant, AbpValidationDisplayModule } from '@colibrium/abp-validation-display';
import { AbpLabelConfigModel, AbpLabelConstant, AbpLabelModule } from '@colibrium/abp-label';

@NgModule({
  declarations: [
    TableComponent,
    CalendarComponent,
    ListCardsComponent,
    PaginatorComponent,
    ImageLoaderComponent,
    DatePickerComponent
  ],
  imports: [
    PhoneModule,
    CommonModule,
    FormsModule,
    TableModule,
    CalendarModule,
    AngularSvgIconModule,
    NgbModule,
    NgScrollbarModule,
    DropdownModule,
    AuthDirectivesModule,
    AbpValidationDisplayModule,
    AbpLabelModule
  ],
  exports: [
    TableComponent,
    CalendarComponent,
    ListCardsComponent,
    ImageLoaderComponent,
    DatePickerComponent
  ]
})
export class ControlsModule {
  static forRoot(validationConfig: AbpValidationDisplayConfigModel, labelConfig: AbpLabelConfigModel): ModuleWithProviders<ControlsModule> {
    return {
      ngModule: ControlsModule,
      providers: [
        { provide: AbpValidationDisplayConstant.validationDisplayConfigInjectKey, useValue: validationConfig },
        { provide: AbpLabelConstant.labelConfigInjectKey, useValue: labelConfig }
      ]
    };
  }
}
