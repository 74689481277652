import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, Inject } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

import { TableColumns } from '@shared/controls/models/table-columns.model';
import { PaginationModel } from '@shared/models/pagination.model';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { ListWidget } from '@app/dynamic-widgets/models/list-widget.model';
import { SortType } from '@app/dynamic-widgets/enums/sort-type.enum';
import { ControlType } from '@app/dynamic-widgets/enums/control-type.enum';
import { ExcelService } from '@shared/services/excel.service';
import { PdfService } from '@shared/services/pdf.service';
import { ExportType } from '@app/dynamic-widgets/enums/export-type.enum';
import { ListConfigurations } from '@app/dynamic-widgets/models/list-configurations.model';
import { CommonConfigInit } from '@app/dynamic-widgets/commonConfigInit';
import { Constant } from '@shared/utilities/constant';
import { finalize, takeWhile } from 'rxjs/operators';
import { Guid } from '@shared/utilities/guid';
import { UserService } from '@colibrium/auth-lib';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { LazyLoadOptions } from '@shared/controls/models/lazy-load-options.model';
import { Action } from '@shared/controls/models/action.model';
import { ActionType } from '@shared/enums/action-type.enum';
import {KeyValuePair } from '@app/dynamic-widgets/models/key-value.model';
import mapIcons from '.././storage-widget/storageIcons.js';
import { WidgetDescriptionConfig } from '../../../dashboard/models/widget-description-config.model.js';
import { AppConfig } from '@shared/models/app-config.model';
import { Type } from '@shared/models/resource.model';

@Component({
  selector: 'app-list-widget',
  templateUrl: './list-widget.component.html',
  styleUrls: ['./list-widget.component.scss']
})
export class ListWidgetComponent implements OnInit, CommonConfigInit {

  @Input() isExpanded = false;
  @Input() input: string;
  @Input() titleConfig: WidgetTitleConfig;
  @Input() description: WidgetDescriptionConfig = new WidgetDescriptionConfig();
  @Input() isFromDashboard: boolean;
  @Input() params: any;
  @Input() storageIcon : boolean;
  @Output() onRowAction = new EventEmitter();
  @Output() onTableAction = new EventEmitter();
  @Output() onNavigateAction = new EventEmitter();

  public cols: TableColumns[];
  public paginationModel: PaginationModel = Constant.defaultPagination;
  public count: number;
  public data: any = [];
  public isExportable: boolean;
  public isReset: boolean = false;
  public rowActions: Action[];
  public tableActions: Action[];
  public checkBoxSelection: boolean = false;
  public radioSelection: boolean = false;
  public searchFilter : boolean = false;
  public messages: string;
  public showPaginator: boolean = true;
  public hideHeader: boolean = false;
  public imageStyle: boolean = false;
  public resourceWidgetStyle: boolean = false;
  public noRecordsMessage: string;
  public isCustomNavigation: boolean = false;
  private dataSourceUrl: string;
  private userName: string;
  public spinnerName: string = Guid.randomId;
  private modalRef: NgbModalRef;
  private alive: boolean = true;
  public isDataFetchComplete = false;

  @ViewChild('widgetModel', { read: TemplateRef, static: true }) widgetModel: TemplateRef<any>;

  constructor(
      private listWidgetService: DynamicWidgetsService,
      private exportService: ExcelService,
      private pdfService: PdfService,
      private userService: UserService,
      private spinner: NgxSpinnerService,
      private modalService: NgbModal,
      @Inject(Constant.appConfig) private appConfig: AppConfig
    ) { }

  ngOnInit() {
    if (this.input) {
      if (this.params) {
        this.input = this.input.replace(Constant.paramReqgex, (x) => {
          const value = this.params[x.slice(2, -2)];
          return value ? value : x;
        });
      }
      const res: ListWidget = this.listWidgetMapper(this.input);
      if (this.validateConfiguration(res))
      {
        this.paginationModel.pageSize = res.pageSize ;
        this.isExportable = res.isExportable;
        this.dataSourceUrl = res.dataSourceUrl;
        this.tableActions = res.tableActions;
        this.rowActions = res.rowActions;
        this.checkBoxSelection = res.checkBoxSelection;
        this.searchFilter = res.searchFilter;
        this.radioSelection = res.radioSelection;
        this.cols = this.getColumns(res.columnFields, res.listSortableType);
        this.titleConfig.isExportable = res.isExportable;
        this.messages = res.messages;
        this.hideHeader = res.hideHeader;
        this.imageStyle = res.imageStyle;
        this.resourceWidgetStyle = res.resourceWidgetStyle;
        this.showPaginator = res.showPaginator;
        this.noRecordsMessage = res.noRecordsMessage;
        this.isCustomNavigation = res.isCustomNavigation;
        //this.getListWidgetData(this.dataSourceUrl, this.paginationModel, this.showPaginator);
        this.initializeAutoRefresh(this.titleConfig.autoRefresh);
      }
    }
  }

  /**
   * Validates configuration
   * @param config
   */
  private validateConfiguration(config: ListWidget): boolean {
    let isValid: boolean = true;
    if (!config) {
      isValid = false;
      console.error(`${this.titleConfig.title} - widget configuration missing`);
    }
    else {
      if (!config.dataSourceUrl) {
        isValid = false;
        console.error(`${this.titleConfig.title} - data source url configuration missing`);
      }
      if (!config.columnFields)
      {
        isValid = false;
        console.error(`${this.titleConfig.title} - columnFields configuration missing`);
      }
      else
      {
        config.columnFields.forEach((element, index) => {
          if (element.actionLink != null && element.redirectionUrl == null) {
            isValid = false;
            console.error(`${this.titleConfig.title} - ${element.field} redirection url is missing`);
          }
        });
      }
      if (config.rowActions != null)
      {
        config.rowActions.forEach((element, index) => {
          if (element.type == ActionType.link && element.redirectionUrl == null) {
            isValid = false;
            console.error(`${this.titleConfig.title} - ${element.action} action redirection url is missing`);
          }
        });
      }
      if (config.tableActions != null)
      {
        config.tableActions.forEach((element, index) => {
          if (element.type == ActionType.link && element.redirectionUrl == null) {
            isValid = false;
            console.error(`${this.titleConfig.title} - ${element.action} action redirection url is missing`);
          }
        });
      }
    }
    return isValid;
  }

  /**
   * Export implementation
   *  @param option - ExportType
   */

  public export(option: ExportType) {
    const totalPagination: PaginationModel = this.getTotalPagination();
    this.userService.userInformationObs.subscribe(userInfo => {
      if (userInfo) {
        this.userName = userInfo.displayName;
      }
    });
    this.listWidgetService.getListWidgetData(this.dataSourceUrl, totalPagination, this.showPaginator).subscribe((res) => {
      const fileName = `${this.titleConfig.title}_${this.userName}_${moment().format(DynamicWidgetsConstant.exportDateFormat)}`;
      switch (option) {
        case ExportType.Excel:
          const columns = this.getExcelColumns();
          const excelData = this.formatListData(res.paginationData.data);
          this.exportService.exportExcel(excelData, columns, this.titleConfig.title, fileName);
          break;
        case ExportType.Pdf:
          const cols = this.getPdfColumns();
          const data = this.formatListData(res.paginationData.data);
          this.pdfService.exportPdf(data, cols, this.titleConfig.title, fileName);
          break;
      }
    });
  }

  /**
   * Loazy loads paginition grid data
   */
  public onLazyLoad(data: LazyLoadOptions) {
    this.isReset = false;
    this.paginationModel = { pageSize: data.pageSize, pageNumber: data.pageNumber};
    this.paginationModel.sortFields = [];
    data.sortColumns.forEach(a =>
      this.paginationModel.sortFields.push({ property: a.property, sortType: a.sortType })
    );
    this.getListWidgetData(this.dataSourceUrl, this.paginationModel, this.showPaginator);
  }

  /**
   * Expands list widget component
   * @param isExpand
   */
  public expand(isExpand: boolean) {

    this.isExpanded = isExpand;
    if (isExpand) {
      this.modalRef = this.modalService.open(this.widgetModel, { size: 'xl', keyboard: true });
      this.modalRef.result.then((result) => {
        this.isExpanded = false;
      },
        () => {
          this.isExpanded = false;
        });
    }
    else {
      this.modalRef.close();
    }
  }

/**
   * get total pagination
   */

  private getTotalPagination() {
    return {
      pageNumber: 1,
      pageSize: this.count
    };
  }

  /**
   * form excel columns
   */

  private getExcelColumns() {
    return this.cols.map((col: TableColumns) => {
      return {
        key: col.field,
        header: col.header,
        style: { alignment: { horizontal: this.excelDataTypeFormat(col.type) } }
      }
    });
  }

  /**
   * form pdf columns
   */

  private getPdfColumns() {
    return this.cols.map((col: TableColumns) => {
      return {
        dataKey: col.field,
        header: col.header
      }
    });
  }


  /**
   * excel datatype formats
   * @param type - string
   */

  private excelDataTypeFormat(type: string) {
    switch (type) {
      case ControlType.date:
        return DynamicWidgetsConstant.horizantalAlignment;
      case ControlType.amount:
        return DynamicWidgetsConstant.horizantalAlignment;
    }
  }


  /**
   * on Refresh
   */
  public onRefresh(): void {
    this.isReset = true;
    this.getListWidgetData(this.dataSourceUrl, this.paginationModel, this.showPaginator);
  }

  public doNoting(): void{


  }

  /**
   * list widget mapper
   * @param value - string
   */

  private listWidgetMapper(value: string) {
    const inputRes: ListConfigurations = JSON.parse(value);
    let res: ListWidget = {
      dataSourceUrl: inputRes.dataSourceUrl,
      listSortableType: inputRes.listSortableType,
      messages: inputRes.messages,
      columnFields: inputRes.columnFields ? JSON.parse(inputRes.columnFields) : null,
      isExportable: inputRes.isExportable ? JSON.parse(inputRes.isExportable) : true,
      checkBoxSelection: inputRes.checkBoxSelection ? JSON.parse(inputRes.checkBoxSelection) : null,
      radioSelection: inputRes.radioSelection ? JSON.parse(inputRes.radioSelection) : null,
      searchFilter: inputRes.searchFilter ? JSON.parse(inputRes.searchFilter) : null,
      rowActions: inputRes.rowActions ? JSON.parse(inputRes.rowActions) : null,
      tableActions: inputRes.tableActions ? JSON.parse(inputRes.tableActions) : null,
      pageSize : inputRes.pageSize == null ? this.paginationModel.pageSize : inputRes.pageSize,
      showPaginator: inputRes.showPaginator ? JSON.parse(inputRes.showPaginator) : true,
      hideHeader: inputRes.hideHeader ? JSON.parse(inputRes.hideHeader) : false,
      imageStyle: inputRes.imageStyle ? inputRes.imageStyle : false,
      resourceWidgetStyle: inputRes.resourceWidgetStyle ? inputRes.resourceWidgetStyle : false,
      isCustomNavigation: inputRes.isCustomNavigation ? inputRes.isCustomNavigation : false,
      noRecordsMessage:inputRes.noRecordsMessage
    };
    return res;
  }

  /**
   * get columns for list
   * @param columns - TableColumns[]
   * @param listSortableType - SortType
   */

  private getColumns(columns: TableColumns[], listSortableType: SortType) {
    return columns.map((col: TableColumns) => {
      return {
        field: col.field,
        isMultiField: col.isMultiField,
        multiField: col.multiField ?  col.multiField.split(",") : col.multiField,
        fieldUrl: col.fieldUrl,
        header: col.header,
        actionLink: col.actionLink,
        redirectionUrl: col.redirectionUrl,
        type: col.type,
        isSortable: this.identifySortType(listSortableType, col.isSortable),
        isInitial: col.isInitial,
        css: col.css,
        alignment: col.alignment,
        feature: col.feature,
        searchType: col.searchType,
        image: col.image
      };
    });
  }

  /**
   * Identify Sort Type
   * @param sortType - SortType
   * @param colSort - boolean
   */

  private identifySortType(sortType: SortType, colSort: boolean) {
    switch (sortType) {
      case SortType.Yes:
        return true;
      case SortType.No:
        return false;
      case SortType.PartiallyOn:
        return colSort;
    }
  }

  /**
   * Get List Widget Data
   * @param endpointUrl - string
   * @param paginationModel - PaginationModel
   */

  private getListWidgetData(endpointUrl: string, paginationModel: PaginationModel, showPaginator: boolean) {
    this.spinner.show(this.spinnerName);
    this.listWidgetService.getListWidgetData(endpointUrl, paginationModel, showPaginator)
      .pipe(
        finalize(() => {
          this.spinner.hide(this.spinnerName);
          this.isDataFetchComplete = true;
        })
      )
      .subscribe((res: any) => {
        // remove this line when multiple call issue is fixed
        this.isDataFetchComplete = false;
        if (res) {
          let responseData = res.paginationData ? res.paginationData.data : res;
          this.data = this.formatListData(responseData);

          if (this.storageIcon)
          {
            this.bindImage(this.data);
          }
          this.count = res.paginationData ? res.paginationData.count : res.count;
          if (res.messageHolders){
            this.fillMessages(res.messageHolders);
          }

        }
      });
  }

  private bindImage(data)
  {
    let myclass;
    let extension;
    let firstName;
    data.forEach(element => {
       if (element.fileName)
       {
         firstName = element.fileName.split('.').shift();
         extension = element.fileName.split('.').pop();

         Object.keys(mapIcons).forEach((ind) => {
              if (ind == extension.toLowerCase( )){
                myclass = mapIcons[ind];
              }
         });
         element.fileName = myclass + firstName;
         myclass = '';
         extension = '';
         firstName = '';
       }
     });
  }

  /**
   * Format List Widget Data
   * @param data - any
   */
  private formatListData(widgetDataList: any[]) {
    
    let data: any[] = [];

    if (widgetDataList && widgetDataList.length > 0)
    {
    
      let documentdefinitionUrl = this.appConfig.surveyConfig.contentServiceApiBaseUrl + Constant.getImageorContentByUrl;

      for(let widgetData of widgetDataList){
          if (widgetData.type === Type.PDF) {
            widgetData.contentURL = decodeURIComponent(documentdefinitionUrl+widgetData.contentURL);
          }
          if(widgetData.imageURL !== null && widgetData.imageURL !== ""){
            widgetData.imageURL = decodeURIComponent(documentdefinitionUrl+widgetData.imageURL);
          }
        if(widgetData.allowInUIMode !== undefined && widgetData.allowInUIMode !== null ){
          let allowInUIMode:string = widgetData.allowInUIMode.toString();
          if(allowInUIMode === "Agent"){
            continue;
          }else{
            data.push(widgetData);  
          }          
        } else{
          data.push(widgetData);
        }

      }

      const firstRow = data[0];
      this.cols.forEach(col => {
        const value = _.get(firstRow, col.field);
        if (value == undefined) {
          console.error(`${this.titleConfig.title}- ${col.field} field not found`);
        }
      });
    }

    return _.map(data, o => {
      this.cols.forEach(col => {
        if (o.hasOwnProperty(col.field)) {
          switch (col.type) {
            case ControlType.date:
              o[col.field] = moment(o[col.field]).format(Constant.customDateFormat);
              break;
            case ControlType.amount:
              o[col.field] = `$${o[col.field]}`;
              break;
          }
        }
      });
      return o;
    });
  }
  /**
     * Initialize auto refresh
     * @param autoRefresh -
     */
  private initializeAutoRefresh(autoRefresh: number) {
    if (autoRefresh) {
      interval(autoRefresh * 1000).pipe(takeWhile(() => this.alive))
        .subscribe(() => this.onRefresh());
    }
  }
  /**
  * Fill Messages
  * @param messageHolders
  */
 private fillMessages(messageHolders: KeyValuePair[]){
  if (this.messages != undefined && this.messages != null && messageHolders.length > 0){
    messageHolders.forEach(message => {
      let modifiedKey = Constant.forwardCurlBrace + message.key + Constant.backwardCurlBrace;
      this.messages = this.messages.replace(modifiedKey, message.value);
   });
  } else {
    this.messages = '';
  }
}

}
