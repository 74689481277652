import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { FilterOption } from '@app/dashboard/enums/filter-option.enum';
import * as moment from 'moment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DynamicWidgetsConstant } from '../dynamic-widgets.constant';
import { DateFilter } from '@shared/models/date-filter.model';
import { ControlType } from '@app/dynamic-widgets/enums/control-type.enum';
import { Constant } from '@shared/utilities/constant';
import * as _ from 'lodash';

@Component({
  selector: 'app-widget-title',
  templateUrl: './widget-title.component.html',
  styleUrls: ['./widget-title.component.scss']
})
export class WidgetTitleComponent implements OnInit {

  public filterList: any;
  public modalRef: NgbModalRef;

  @Input() titleConfig: WidgetTitleConfig;
  @Input() isExpanded: boolean;
  @Output() onExpand = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Output() onFilter = new EventEmitter();
  @Output() onExport = new EventEmitter();

  @ViewChild('dateFilter', { read: TemplateRef, static: true }) dateFilter: TemplateRef<any>;
  
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.filterList = Object.keys(FilterOption).map(n => ({ name: n, value: FilterOption[n] }));
  }

  public toggleExpand()
  {
    this.isExpanded = !this.isExpanded;
    this.onExpand.emit(this.isExpanded);
  }

  public refresh()
  {
    this.onRefresh.emit();
  }

  /**
     * apply date filter
     * @param filterOption 
     * @param index 
     */
  public applyFilter(filterOption: FilterOption) {
    let startDate = moment.utc();
    switch (filterOption) {
      case FilterOption.Custom:
        this.modalRef = this.modalService.open(this.dateFilter, { size: 'lg' })
        break;
      case FilterOption.LastYear:
        startDate.subtract(1, DynamicWidgetsConstant.dateFilter.unit.years);
        break;
      case FilterOption.LastSixMonths:
        startDate.subtract(6, DynamicWidgetsConstant.dateFilter.unit.months);
        break;
      case FilterOption.LastThreeMonths:
        startDate.subtract(3, DynamicWidgetsConstant.dateFilter.unit.months);
        break;
      case FilterOption.LastMonth:
        startDate.subtract(1, DynamicWidgetsConstant.dateFilter.unit.months);;
        break;
      case FilterOption.LastWeek:
        startDate.subtract(1, DynamicWidgetsConstant.dateFilter.unit.week);
        break;
      case FilterOption.Yesterday:
        startDate.subtract(1, DynamicWidgetsConstant.dateFilter.unit.day);
        break;
    }
    if (FilterOption.Custom != filterOption) {
      this.onFilter.emit({ startDate: startDate.toDate(), endDate: moment.utc().toDate() });
    }
  }
  public apply(filter: DateFilter) {
    this.onFilter.emit(filter);
    this.modalRef.close();
  }
  
  public export(exportType: any){
   this.onExport.emit(exportType);
  }
 
}
