<ng-template [ngIf]="isUserLoggedIn && isUserInfoAvailable && visibility" [ngIfElse]="login">
  <app-header></app-header>
  <loader></loader>
  <div>
  <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</ng-template>
<ng-template #login>
  <router-outlet></router-outlet>
</ng-template>
<!-- this line of code is for global toast and Confirm dialog -->
<p-toast class="margin-top-toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <h4><i class="pi pi-check"></i>&nbsp;&nbsp;{{message.summary}}</h4>
    </div>
  </ng-template>
</p-toast>
<p-confirmDialog></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" key = "sessionDialog" [rejectVisible] = false acceptLabel = "Ok" [closable] = false></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" key = "widgetminValueDialog" [rejectVisible] = false acceptLabel = "Ok" [closable] = false></p-confirmDialog>