<div class="container sign-up-container">
    <div class="row mt-4 align-items-end">
        <div class="col-lg-6 logo">
            <img src="assets/logo-sign-up.jpg">
        </div>
        <div class="col-lg-6 sign-in-top">
            <h6 class="sign-up-text">First time user? Click here to sign up as:
                <a href="/register">Policy Holder</a> |
                <a href="#">Caregiver</a></h6>
        </div>
    </div>
    <div class="row mt-2 mb-3">
        <div class="col-12">
            <div class="bg-white shadow-sm border-radius-5">
                <div class="sign-up-title p-3">
                    <h3>Log in</h3>
                </div>
                <div class="form-container row justify-content-center p-3">
                    <div class="col-lg-8 my-4">
                        <form [formGroup]="group" (ngSubmit)="submitForm()">
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-md-3 col-form-label">Username / Email
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-md-5">
                                    <input type="email" class="form-control" formControlName="email" pInputEmail
                                        placeholder="eg. abc@xyz.com" />
                                    <div *ngIf="submitted && f.email.errors" class="error">
                                        <div *ngIf="f.email.errors.required">Invalid Username / Email</div>
                                    </div>
                                </div>
                                <div class="p-col col-12 col-md-4 mt-4 mt-md-0 sign-up-text">
                                    <a href="#">Forgot Username / Email</a>
                                </div>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-5">
                                <label class="p-col-fixed col-12 col-md-3 col-form-label">Password <sup>*</sup></label>
                                <div class="p-col col-12 col-md-5">
                                    <input type="password" class="form-control" formControlName="password" pInputText
                                        placeholder="******" />
                                    <div *ngIf="submitted && f.password.errors" class="error">
                                        <div *ngIf="f.password.errors.required">Please enter valid Password</div>
                                    </div>
                                </div>
                                <div class="p-col col-12 col-md-4 mt-4 mt-md-0 sign-up-text">
                                    <a href="#">Forgot Password</a>
                                </div>
                            </div>
                            <div *ngIf="submitted && showError" class="error">
                                <div>Please enter valid UserName / Password</div>
                            </div>
                            <div class="col-lg-7 offset-lg-3 text-center submit-btn">                                
                                <!-- <button class="btn btn-primary mr-1">Submit</button> -->
                                <p-button label="Submit" icon="pi pi-arrow-right"
                                    iconPos="right">
                                </p-button> 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
