export class DashboardConstant {
    public static readonly urls = class {
        public static readonly MemberPortalUrl: string = 'MemberManager/';
        public static readonly dashboradUrl: string = 'api/Dashboard';
    };
    public static readonly routeParam: string = 'name';
    public static readonly dragHandleClass =  'handler';
    public static readonly dateFilter = class
    {
        public static readonly startDate = 'startDate';
        public static readonly endDate = 'endDate';
        public static readonly dateFormat = 'mm/dd/yy';
        public static readonly hourFormat = 24;
        public static readonly unit = class {
            public static readonly years = 'years';
            public static readonly months = 'months';
            public static readonly week = 'week';
            public static readonly day = 'day';
        };
    };
}