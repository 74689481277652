import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';

import { GlobalErrorService } from '@core/services/global-error.service';
import { RouteService } from '@core/services/route.service';
import { AuthenticationService, UserService } from '@colibrium/auth-lib';
import { LoaderService } from '@app/core/services/loader.service';
import { CoreUrl } from '@core/utilities/core.url';

import { Constant } from '@shared/utilities/constant';
import { LayoutService } from '@layout/layout.service';
import { filter, map, mergeMap } from 'rxjs/operators';

import { MemberSiteVisitService } from '@shared/services/memberSiteVisit.service';
import { Guid as TsGuid } from 'guid-typescript';
import { SessionModel } from '@shared/models/session.model';
import { AppConfig } from '@shared/models/app-config.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public title = Constant.title;
  public isError = false;
  public isUserLoggedIn = false;
  public canShowSideNav = false;
  public leftExpand: boolean = false;
  public canShowNav = false;
  public visibility = false;

  private currentPage = '';

  private errorSubscription: Subscription;
  private loginSubscription: Subscription;

  public get isUserInfoAvailable(): boolean {
    return this.userService.userInformation != null;
  }

  constructor(
    private errorService: GlobalErrorService,
    private routeService: RouteService,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private router: Router,
    private userService: UserService,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private memberSiteVisitService: MemberSiteVisitService,
    @Inject(Constant.appConfig) private appConfig: AppConfig
  ) {

    // user tracking
    this.router.events.subscribe((event: any) => {
      if (event.url !== undefined && this.currentPage !== event.url) {
        this.memberSiteVisitService.saveMemberSiteVisit(event.url,null);

        this.currentPage = event.url;
      }
    });

    // try to get session data from browser
    let sessionData = localStorage.getItem(Constant.StorageSession);
    try {
      let session: SessionModel = JSON.parse(sessionData) as SessionModel;

      // JSON.parse breaks TS typing and turns our Date into a string, we're setting it back to a Date at runtime
      session.Timestamp = new Date(session.Timestamp);

      // use session data if still recent (last activity under timeout)
      if ((new Date().getTime() - session.Timestamp.getTime()) < (appConfig.trackingSessionTimeout * 1000)){
        session.Timestamp = new Date();
        sessionData = JSON.stringify(session);
      }
      // otherwise, we'll consider a new session
      else{
        sessionData = null;
      }
    // if we can't parse the session for some reason we'll create a new session
    } catch (ex) {
      // console.log(ex);
      sessionData = null;
    }

    // if we don't have session data for any reason, create a new session
    if (sessionData == null){
      const newSession = new SessionModel();
      newSession.Id = TsGuid.create().toString();
      newSession.Timestamp = new Date();

      sessionData = JSON.stringify(newSession);
    }
    localStorage.setItem(Constant.StorageSession, sessionData);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.onNavigationStart();
      } else if (event instanceof NavigationEnd) {
        this.onNavigationEnd();
      }
      if (event instanceof NavigationCancel) {
        this.onNavigationCancel();
      }
      if (event instanceof NavigationError) {
        this.onNavigationError();
      }
    });

    this.errorSubscription = this.errorService.isGlobalError.subscribe((isError: boolean) => {
      // TODO: Implement logic to navigate to Error page or to show a Error message section as per requirement
      if (isError) {
        this.routeService.redirect(CoreUrl.page.errorUrl);
      }
    });
    this.layoutService.currentSlideState.subscribe(data => {
      this.leftExpand = data;
    });
  }

  ngOnInit() {
    this.loginSubscription = this.authService.isUserLoggedInObs.subscribe((isloggedIn) => {
      /**
       * TODO: setTimeout is used here to avoid expression change error,
       * needs to be updated it if found any other solution
       *  */
      setTimeout(() => {
        this.isUserLoggedIn = isloggedIn;
      });
    });
     
    this.router.events.pipe(
      filter(events=>events instanceof NavigationEnd),
      map(evt =>this.activatedRoute),
      map(route => {
      while(route.firstChild) {
      route = route.firstChild;
      }
      return route;
      }))
      .pipe(
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
      ).subscribe(x=>x.header===true ?this.visibility=true:this.visibility=false)
    
  }

  ngOnDestroy() {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  //#region router event functions

  /**
   * on router navigation start event
   */
  public onNavigationStart() {
    this.loaderService.start();
  }

  /**
   * on router navigation end event
   */
  public onNavigationEnd() {
    this.loaderService.complete();
  }

  /**
   * on router navigation cancel event
   */
  public onNavigationCancel() {
    this.loaderService.stop();
  }

  /**
   * on router navigation start event
   */
  public onNavigationError() {
    this.loaderService.stop();

  }

  /**
   * toggle side nav bar
   */
  public toggleSideNav(val: boolean) {
    this.canShowSideNav = val;
  }

  //#endregion route event functions

}
