//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports
//#endregion functional/model imports

/** This model is for holding config properties required for the application **/
export class AppConfigModel {

  //#region model properties

  public noneOptionText: string;
  public selectAllOptionText: string;
  public defaultSelectText: string;

  //#endregion model properties

  //#region constructor

  constructor() {
  }

  //#endregion constructor

}
