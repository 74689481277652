import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public slideState = new BehaviorSubject<boolean>(null);
  public quoteId: string;
  public groupId: string;
  public memberId: string;
  public selectedProducts: string[];
  public memberProductId: string;
  public popupQuoteName: string;
  public saveForLater = false;
  public effectiveDate: String;
  private breadCrumb = new BehaviorSubject<boolean>(null);
  private leftPanel = new BehaviorSubject<boolean>(null);
  private alertState = new BehaviorSubject<boolean>(null);

  /**
   * currentAlertState
   */
  get currentAlertState() {
    return this.alertState.asObservable();
  }

  /**
   * changeAlertState
   */
  public changeAlertState(alert) {
    this.alertState.next(alert);
  }

  /**
   * currentSlideState
   */
  get currentSlideState() {
    return this.slideState.asObservable();
  }

  /**
   * changeSlideState
   */
  public changeSlideState(slide) {
    this.slideState.next(slide);
  }

  /**
   * leftPanelState
   */
  get leftPanelState() {
    return this.leftPanel.asObservable();
  }

  /**
   * changeleftPanelState
   */

  public changeleftPanelState(slide) {
    this.leftPanel.next(slide);
  }

  /**
   * breadCrumbState
   */
  get breadCrumbState() {
    return this.breadCrumb.asObservable();
  }

  /**
   * changebreadCrumbState
   */

  public changebreadCrumbState(slide) {
    this.breadCrumb.next(slide);
  }

  /**
   * global filter implementation
   *  @param value - PageModel
   *  @param tblList - PageModel
   */

  public filterData(value: string, tblList: any[]) {
    const filterList = [];
    if (value.length && tblList.length) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < tblList.length; i++) {
        const fields = Object.keys(tblList[i]);
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < fields.length; j++) {
          const fieldName = fields[j];
          const val = ((tblList[i][fieldName]).toLowerCase()).includes(value.toLowerCase());
          if (val) {
            filterList.push(tblList[i]);
            break;
          }
        }
      }
    }
    return filterList;
  }

  /**
   * save Quote Id
   * @param str -
   */
  saveQuoteId(str: string) {
    this.quoteId = str;
  }

  /**
   * get Quote Id
   */
  getQuoteId(): string {
    return this.quoteId;
  }

  /**
   * save groupId
   * @param groupId -
   */
  saveGroupId(groupId: string) {
    this.groupId = groupId;
  }

  /**
   * get groupId
   */
  getGroupId(): string {
    return this.groupId;
  }

  /**
   * get member Id
   */
  getMemberId(): string {
    return this.memberId;
  }

  /**
   * save selected LOB
   * @param products -
   */
  saveSelectedLOBs(products: string[]) {
    this.selectedProducts = products;
  }

  /**
   * get LOB
   */
  getSelectedLOBs() {
    return this.selectedProducts;
  }

  /**
   * get member productId
   */
  getmemberProductId(): string {
    return this.memberProductId;
  }

  /**
   * Save Quote name
   * @param quoteName -
   */
  saveQuoteName(quoteName: string) {
    this.popupQuoteName = quoteName;
  }

  /**
   * get Quote name
   */
  getQuoteName() {
    return this.popupQuoteName;
  }

  /**
   * Save effective date
   * @param effDate -
   */
  saveEffectiveDate(effDate: String) {
    this.effectiveDate = effDate;
  }

  /**
   * get effective date
   */
  getEffectiveDate() {
    return this.effectiveDate;
  }

}
