//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports
//#endregion functional/model imports

export class MasterEntityModel {

  //#region model properties
  public id: string;
  public code: string;
  public displayText: string;
  public displayLabelCss: string;
  public isDeleted: boolean;
  public fetchUrl:string;
  public postUrl:string;
  public deleteUrl:string;

  //#endregion model properties

  //#region constructor

  constructor() {
  }

  //#endregion constructor

}
