//#region angular imports

import { Component, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

//#endregion angular imports

//#region core imports

//#endregion core imports

//#region functional/model imports

import { RowModel, FormFieldModel } from '@app/@dynamic-widget/models';
import { DynamicFormBase } from '@app/@dynamic-widget/dynamic-form.base';
import { OwlOptions } from 'ngx-owl-carousel-o';

//#endregion functional/model imports

@Component({
  selector: 'abp-array-sec',
  templateUrl: './array-sec-render.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./array-sec-render.scss']
})
export class ArraySecRenderComponent extends DynamicFormBase {

  //#region input properties

  @Input() arraySection: RowModel<FormFieldModel>[] = [];

  //#endregion input properties

  //#region model properties
  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    autoWidth: true,
    margin: 40,
    autoHeight: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 2
      }
    },
    nav: false,
    dotsData: true
  }
  //#endregion model properties

  //#region constructor

  constructor() {
    super();
  }

  //#endregion constructor

  //#region component events

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  //#endregion component events

  //#region service calls
  //#endregion service calls

  //#region public functions/events assoaciated with UI elements

  //#endregion public functions/events assoaciated with UI elements

  //#region private functions

  //#endregion private functions

}
