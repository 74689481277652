export enum CustomChartType
{
    Line = "line",
    Bar = "bar",
    HorizontalBar = "horizontalBar",
    Pie = "pie",
    Radar = "radar",
    Doughnut = "doughnut",
    PolarArea = "polarArea",
    Bubble = "bubble",
    Scatter = "scatter",
    Area = "area"
}