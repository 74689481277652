import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { AppConfig } from '@shared/models/app-config.model';
import { Constants } from '@shared/services/constants';
import { Constant } from '@shared/utilities/constant';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public group: FormGroup;
  public submitted = false;
  public showError = false;

  constructor(private route: ActivatedRoute,
              private httpUtility: HttpUtility,
              protected formBuilder: FormBuilder,
              @Inject(Constant.appConfig) private appConfig: AppConfig
  ) { }

  get f() { return this.group.controls; }

  ngOnInit(): void {
    this.group = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  submitForm() {
    this.submitted = true;
    if (this.group && this.group.valid) {
      this.route.queryParams.subscribe(params => {
        if (params[Constants.id4.clientIdParam]) {
          let url = Constants.id4.authorizeCallbackUrl;
          url = url.concat(
            Constants.id4.clientIdParam + '=' + params[Constants.id4.clientIdParam],
            '&' + Constants.id4.redirectUriParam + '=' + params[Constants.id4.redirectUriParam],
            '&' + Constants.id4.responseTypeParam + '=' + params[Constants.id4.responseTypeParam],
            '&' + Constants.id4.scopeParam + '=' + params[Constants.id4.scopeParam],
            '&' + Constants.id4.stateParam + '=' + params[Constants.id4.stateParam],
            '&' + Constants.id4.codeChallengeParam + '=' + params[Constants.id4.codeChallengeParam],
            '&' + Constants.id4.codeChallengeMethodParam + '=' + params[Constants.id4.codeChallengeMethodParam],
            '&' + Constants.id4.responseModeParam + '=' + params[Constants.id4.responseModeParam]);

          if (url != '' || url != undefined) {
            this.group.value.RememberLogin = false;
            this.group.value.ReturnUrl = url;
            this.httpUtility.post(`${this.appConfig.authConfig.id4Config.authority}${Constants.id4.loginApi}`, this.group.value)
              .subscribe(response => {
                if (response) {
                  const newUrl = this.appConfig.authConfig.id4Config.authority + response.returnUrl;
                  window.location.href = newUrl;
                  this.appConfig.authConfig.landingUrl = this.appConfig.authConfig.landingUrl + '/';
                }
              },
              (error) => {
                this.showError = true;
              }
            );
          }
        }
      });
    }
  }
}
