import { Component, OnInit, Output, EventEmitter, Inject, HostListener } from '@angular/core';

import { AuthenticationService, RoleDetailModel, UserService } from '@colibrium/auth-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '@layout/layout.service';
import { ApplicationSettingType } from '@layout/enums/application-setting-type.enum';
import { CommonAttributes } from '@layout/models/common-attributes.model';
import { ApplicationSettings } from '@layout/models/application-settings.model';
import { Constants } from '@layout/layout.constants';
import { environment } from 'src/environments/environment';
import { Constant } from '@shared/utilities/constant';
import { AppConfig } from '@shared/models/app-config.model';
import { SharedService } from '@shared/services/shared.service';
import { AutoLogoutService } from '@shared/services/auto-service';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { UserMemberDetailsService } from '@shared/services/usermemberdetails.service';
import { CoreUrl } from '@app/core/utilities/core.url';
import { UserMemberDetailsModel } from '@shared/models/usermemberdetails';
import { UserDetails } from '@shared/models/userdetails.model';
import { CaregiverInfoService } from '@app/user/caregiver-info/caregiver-info.service';
import { MemberDetailsService } from '@shared/services/memberdetails.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter();

  public userId: string;
  public userName: string;
  public activeRole: RoleDetailModel;
  public roles: RoleDetailModel[];
  public applicationTitle: string;
  public showAdmin = false;
  public showFev = false;
  public headerUrl: string;
  public today: number = Date.now();
  public memberId: string;
  public firstName: string;
  public lastName: string;
  public usersDetails: UserMemberDetailsModel;
  public currentMember: string;
  private barToggle = false;
  constructor(
    private caregiverInfoService: CaregiverInfoService,
    private userService: UserService,
    private authService: AuthenticationService,
    private layoutService: LayoutService,
    private sharedService: SharedService,
    private autoLogoutService: AutoLogoutService,
    private httpUtility: HttpUtility,
    private router: Router,
    private route: ActivatedRoute,
    private usermemberdetailsService: UserMemberDetailsService,
    private memberdetailsservice: MemberDetailsService,
    @Inject(Constant.appConfig) private appConfig: AppConfig
  ) { }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.showAdminToggle(2, event);
    event.stopPropagation()
  }

  ngOnInit() {
    this.getUrl();
    this.userService.userInformationObs.subscribe(userInfo => {
      if (userInfo) {
        this.userId = userInfo.userId;
        this.userName = userInfo.displayName;
        this.activeRole = userInfo.activeRole;
        this.roles = userInfo.roles;

        if (this.usermemberdetailsService.usermemberdetails && this.usermemberdetailsService.usermemberdetails.userId == userInfo.userId) {
          this.memberId = this.usermemberdetailsService.usermemberdetails.memberId;
          this.firstName = this.usermemberdetailsService.usermemberdetails.firstName;
          this.lastName = this.usermemberdetailsService.usermemberdetails.lastName;
          this.usersDetails = this.usermemberdetailsService.usermemberdetails;
        }
        else {
          this.memberdetailsservice.getMemberdetails(this.userId).subscribe((response) => {
            if (response) {
              this.usersDetails = response;
              let currentuser: UserDetails = this.memberdetailsservice.getCurrentUser(response);
              if (currentuser) {
                this.firstName = currentuser.firstName;
                this.lastName = currentuser.lastName;
                this.memberId = currentuser.memberId;
              }
              else {
                this.router.navigate([CoreUrl.page.errorUrl]);
              }
            }
            else {
              this.router.navigate([CoreUrl.page.errorUrl]);
            }
          });
        }
      }
    });
    
    this.currentMember = localStorage.getItem('selectedMember');
    
    this.layoutService.getConfigurations(ApplicationSettingType.header).subscribe((response: ApplicationSettings[]) => {
      let config: CommonAttributes = JSON.parse(response.find(x => x.key == Constants.applicationTitle).value);
      this.applicationTitle = config.content;
    })

  }

  /**
 * gets Logo Url
 */
  public getUrl() {
    if (environment.production) {
      this.headerUrl = `${this.appConfig.apiBaseUrl}${Constants.url.MemberPortal}${Constants.imageUpload}logo.png`;
    }
    else {
      this.headerUrl = `${this.appConfig.MemberPortalUrl}${Constants.imageUpload}logo.png`;
    }
  }

  showAdminToggle(id, event) {
    if (id == 1) {
      this.showAdmin = !this.showAdmin;
      event.stopPropagation()
    } else {
      this.showAdmin = false;
    }
  }

  showFevToggle() {
    this.showFev = !this.showFev;
  }

  showAlertBar() {
    this.sharedService.changeAlertState("");
  }

  /**
   * Logs out the user
   */
  public logout() {
    // when we log out we want to remove session info so any new activity is regarded as a new session
    localStorage.removeItem(Constant.StorageSession);
    localStorage.clear();
    this.authService.logout();
  }

  public changeStatus(event) {
    if (event.target.value == 'Logout') {
      localStorage.removeItem(Constant.StorageSession);
      localStorage.clear();
      this.authService.logout();
    }
    else {
      localStorage.setItem('selectedMember',event.target.value);
      this.currentMember = event.target.value;
      let memberid: string;
      if (event.target.value != "null") {
        memberid = event.target.value;
      } else {
        memberid = this.usermemberdetailsService.usermemberdetails.users[0].memberId;
      }

      let activeUser = this.usermemberdetailsService.usermemberdetails.users.find(x => x.memberId == memberid);

      if (activeUser === undefined) {
        activeUser = (JSON.parse(JSON.stringify(this.usermemberdetailsService.usermemberdetails))) as UserMemberDetailsModel;
      }

      this.usermemberdetailsService.usermemberdetails.activeUser = activeUser;
      this.usermemberdetailsService.saveMemberDetails(this.usermemberdetailsService.usermemberdetails);
      this.caregiverInfoService.onSelectionChange(true);
      let landingUrlReplace = this.appConfig.authConfig.landingUrl;
      landingUrlReplace = landingUrlReplace.replace("{{MemberIDPlaceHolder}}", memberid);
      
      this.router.navigateByUrl(landingUrlReplace)
      .then(() => {
        window.location.reload();
      });
     // this.router.navigateByUrl(landingUrlReplace);
    }
  }

  /**
   * set active role based on selected role id
   */
  public setActiveRole(role: RoleDetailModel) {
    this.userService.setActiveRole(role);
  }

  /**
   * toggle side nav bar
   */
  public toggle() {
    this.barToggle = !this.barToggle;
    this.toggleSideNav.emit(this.barToggle);
  }
}
