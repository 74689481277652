export class MemberSignUpModel{

    constructor(memberExternalId:string, memberId:string, memberSource:string, externalUserId:string, firstName:string, 
        lastName:string,dateOfBirth:string,gender:string){
            this.memberExternalId = memberExternalId;
            this.memberId = memberId;
            this.memberSource = memberSource;
            this.externalUserId = externalUserId;
            this.firstName = firstName;
            this.lastName = lastName;
            this.dateOfBirth = dateOfBirth;
            this.gender = gender;
    }

    memberExternalId:string;
    memberId:string;
    memberSource:string;
    externalUserId:string;
    firstName:string;
    lastName:string;
    dateOfBirth:string;
    gender:string;
    
    address:MemberAddress;
    phone:MemberPhone;
    emailAddress:MemberEmail;
    contact:MemberContact;
 }

 export class MemberAddress{
    constructor(addressType:string, street1:string, city:string, state:string, zip:string, isPrimary:boolean, isActive:boolean, rowSource:String){
        this.addressType = addressType;
        this.street1 = street1;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.isPrimary = isPrimary;
        this.isActive = isActive;
        this.rowSource=rowSource;

    }
    addressType:string;
    street1:string;
    city:string;
    state:string;
    zip:string;
    isPrimary:boolean;
    isActive:boolean; 
    rowSource:String;   
 }

 export class MemberPhone{
    constructor(phoneType:string, number:string, isPrimary:boolean, isActive:boolean, rowSource:string){
        this.phoneType = phoneType;
        this.number = number;
        this.isPrimary = isPrimary;
        this.isActive = isActive;
        this.rowSource=rowSource;
    }

    phoneType:string;
    number:string;
    isPrimary:boolean;
    isActive:boolean;   
    rowSource:string; 
 }

 export class MemberEmail{

    constructor(emailAddressType:string, emailAddress:string, isPrimary:boolean, isActive:boolean, rowSource:string){
        this.emailAddressType = emailAddressType;
        this.emailAddress = emailAddress;
        this.isPrimary = isPrimary;
        this.isActive = isActive;
        this.rowSource=rowSource;
    }

    emailAddress:string;
    emailAddressType:string;
    isPrimary:boolean;
    isActive:boolean; 
    rowSource:string;   
 }

 export class MemberContact{
    constructor(type:string,consentTypes:string[],preferredContactMethod:string,firstName:string,lastName:string,isPrimary:boolean,isActive:boolean,
        emailAddresses:MemberEmail[], phoneNumbers:MemberPhone[], rowSource:string){
        this.type = type;
        this.consentTypes = consentTypes;
        this.preferredContactMethod = preferredContactMethod;
        this.firstName = firstName;
        this.lastName = lastName;
        this.isPrimary = isPrimary;
        this.isActive = isActive;
        this.emailAddresses = emailAddresses;
        this.phoneNumbers = phoneNumbers;
        this.rowSource=rowSource;

    }
    type:string;
    consentTypes:string[];
    preferredContactMethod:string;
    firstName:string;
    lastName:string;
    isPrimary:boolean;
    isActive:boolean;   
    emailAddresses:MemberEmail[];
    phoneNumbers:MemberPhone[];
    rowSource:string;
 }