import { Component, OnInit, Input, OnDestroy, ViewChild, TemplateRef, Output, EventEmitter, Inject } from '@angular/core';
import { finalize, takeWhile } from 'rxjs/operators';

import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

import { DemographicWidget } from '@app/dynamic-widgets/models/demographic-widget.model';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { DemographicGroup } from '@app/dynamic-widgets/models/demographic-group.model';
import { ControlType } from '@app/dynamic-widgets/enums/control-type.enum';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { DemographicConfigurations } from '@app/dynamic-widgets/models/demographic-configurations.model';
import { CommonConfigInit } from '@app/dynamic-widgets/commonConfigInit';
import { Guid } from '@shared/utilities/guid';
import { ActionLink } from '@shared/enums/action-link.enum';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { ActionType } from '@shared/enums/action-type.enum';
import { Action } from '@shared/controls/models/action.model';
import { WidgetEventModel } from '@app/dynamic-widgets/models/widget-event.model';
import { Constant } from '@shared/utilities/constant';
import { WidgetDescriptionConfig } from '../../../dashboard/models/widget-description-config.model';
import { FormGroup,FormControl, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { AppConfig } from '@shared/models/app-config.model';
import { UserMemberDetailsService } from '@shared/services/usermemberdetails.service';
import { Constants } from '@shared/services/constants';
import { DateValidator } from '@shared/validators/date.validators';
import { ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-demographic-widget',
  templateUrl: './demographic-widget.component.html',
  styleUrls: ['./demographic-widget.component.scss']
})
export class DemographicWidgetComponent implements OnInit, OnDestroy, CommonConfigInit {
  @Input() input: string;
  @Input() titleConfig: WidgetTitleConfig;
  @Input() description: WidgetDescriptionConfig = new WidgetDescriptionConfig();
  @Input() isExpanded = false;
  @Input() params: any;
  @Input() isFromDashboard: boolean;
  @Output() onEvent = new EventEmitter();
  @Input() showCustomFooterTemplate: boolean = false;
  @ViewChild('demographicEditModal', { read: TemplateRef, static: true }) demographicEditModal: TemplateRef<any>;

  private alive: boolean = true;
  public demographicFormSaved: boolean = false;

  public controlList = ControlType;
  public actionList = ActionType;
  public demographicConfigurations: DemographicWidget;
  public errorMessage: string;
  public fieldGroups: DemographicGroup[];
  public actions: Action[];
  public spinnerName: string = Guid.randomId;
  public data: any;
  public membercode : string;
  private modalRef: NgbModalRef;
  public isbtnDisable: boolean = false;
  demographicForm:FormGroup;
  public demographicFormData: WidgetEventModel;
  public demographicUpdateData:any;
  public memberId : string;

  @ViewChild('widgetModel', { read: TemplateRef, static: true }) widgetModel: TemplateRef<any>;


  constructor(
    private widgetService: DynamicWidgetsService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private route:ActivatedRoute,
    private usermemberdetailsService:UserMemberDetailsService,
    @Inject(Constant.appConfig) private appConfig: AppConfig) { }
 


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.memberId = params.get('id');
    });
    if (this.input) {
      if (this.params)
      {
        this.input = this.input.replace(Constant.paramReqgex, (x) => {
          const value = this.params[x.slice(2, -2)];
          return value ? value : x;
        });
      }
      this.demographicConfigurations = this.demographicWidgetMapper(this.input);
      if (this.validateConfiguration(this.demographicConfigurations))
      {
        this.fieldGroups = this.demographicConfigurations.fieldGroups;
        this.actions = this.demographicConfigurations.actions;
        this.initializeAutoRefresh(this.titleConfig.autoRefresh);
        if (this.demographicConfigurations) {
          this.bindDemographicData();
          } else {
          this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.invalidConfiguration;
        }
      }
    } else {
      this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.configurationNotFound;
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   * Validates configuration
   * @param config
   */
  private validateConfiguration(config: DemographicWidget): boolean
  {
    let isValid: boolean = true;
    if (config == null)
    {
      isValid = false;
      console.error(`${this.titleConfig.title} - widget configuration missing`);
    }
    else{
      if (config.dataSourceUrl == null)
      {
        isValid = false;
        console.error(`${this.titleConfig.title} - data source url configuration missing`);
      }
      if (config.fieldGroups == null)
      {
        isValid = false;
        console.error(`${this.titleConfig.title} - fieldGroups configuration missing`);
      }
      else{
        config.fieldGroups.forEach((element, index) => {
          if (element.fields == null || element.fields.length < 1) {
            isValid = false;
            console.error(`${this.titleConfig.title} - fieldGroup[${index}] fields missing`);
          }
          else {
            element.fields.forEach((field, fieldIndex) => {
              if (field.type == ControlType.link && field.redirectionUrl == null) {
                isValid = false;
                console.error(`${this.titleConfig.title} - fieldGroup[${index}].${field.fieldName} redirection url is missing`);
              }
            });
          }

        });
      }
      if (config.actions != null)
      {
        config.actions.forEach((element, index) => {
          if (element.type == ActionType.link && element.redirectionUrl == null)
          {
            isValid = false;
            console.error(`${this.titleConfig.title} - fieldGroup[${index}].${element.action} action redirection url is missing`);
          }
        });
      }
    }
    return isValid;
  }

  /**
   * demographic widget mapper
   * @param value -
   */

  private demographicWidgetMapper(value: string) {
    const inputRes: DemographicConfigurations = JSON.parse(value);
    const res: DemographicWidget = {
      dataSourceUrl: inputRes.dataSourceUrl,
      fieldGroups: inputRes.fieldGroups ? JSON.parse(inputRes.fieldGroups) : null,
      actions: inputRes.actions ? JSON.parse(inputRes.actions) : null
    };
    return res;
  }

  /**
   * on Refresh
   */
  public onRefresh(): void {
    this.bindDemographicData();
  }

  /**
   * bind demographics data
   */
  private bindDemographicData() {
    this.spinner.show(this.spinnerName);
    this.widgetService.getDemographicData(this.demographicConfigurations.dataSourceUrl)
      .pipe(finalize(() => {
        this.spinner.hide(this.spinnerName);
      }))
      .subscribe((demographicData) => {
        if (!demographicData) {
          this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToFetchData;
        } else {
          this.data = demographicData;
          this.fieldGroups.forEach(group => {
            group.fields.forEach(field => {
              const value = _.get(demographicData, field.fieldName);
              if (value == undefined || value == null || value == "")
              {
                field.value = '-';
              }
              else
              {
                field.value = value;
              }
            });
          });
        }
      },
        (error) => {
          this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToFetchData;
        }
      );
  }


  /**
   * Emits event
   * @param action
   */
  public emitEvent(action: Action)
  {
    if (action.type == this.actionList.event)
    {
      const data: any = {};
      this.fieldGroups.forEach(x => {x.fields.forEach(y => {data[y.fieldName] = y.value; }); });
      const eventModel: WidgetEventModel = {eventName: action.action, data: data};
      this.onEvent.emit(eventModel);
    }
    else if (action.type == this.actionList.link)
    {
      this.onNavigate(action.actionLink, action.redirectionUrl);
    }
  }

  /**
   * Navigate Url
   * @param actionLink - number
   * @param redirectionUrl - string
   * @param id - string
   */
  public onNavigate(actionLink: number, redirectionUrl: string, id?: string) {
    let blankUrl: string;
    let url: string;

    redirectionUrl = this.PopulateUrlParams(redirectionUrl);

    switch (actionLink) {
      case ActionLink.StaticAction:
        blankUrl = DynamicWidgetsConstant.target;
        url = redirectionUrl;
        break;
      case ActionLink.StaticActionWithId:
        blankUrl = DynamicWidgetsConstant.target;
        url = `${redirectionUrl}/${id}`;
        break;
      case ActionLink.RouteAction:
        url = redirectionUrl;
        break;
      case ActionLink.RouteActionWithId:
        url = `${redirectionUrl}/${id}`;
        break;
    }
    return window.open(url, blankUrl);
  }

  /**
   * Populates url params
   * @param redirectionUrl
   * @returns url params
   */
  public PopulateUrlParams(redirectionUrl: string): string
  {
    const params: RegExpMatchArray = redirectionUrl.match(Constant.urlParamRegex);
    if (params)
    {
      params.forEach(param => {
        if (param){
          const p = param.slice(1, (param.length - 1));
          const value = _.get(this.data, p, null);
          redirectionUrl = redirectionUrl.replace(param, value);
        }
      });
    }
    return redirectionUrl;
  }

  /**
   * Expands demographic widget component
   * @param isExpand
   */
  public expand(isExpand: boolean)
  {

    this.isExpanded = isExpand;
    if (isExpand)
    {
      this.modalRef = this.modalService.open(this.widgetModel, { size: 'xl', keyboard: true });
      this.modalRef.result.then((result) => {
        this.isExpanded = false;
      },
        () => {
          this.isExpanded = false;
        });
    }
    else{
      this.modalRef.close();
    }
  }

  /**
   * Initialize auto refresh
   * @param autoRefresh -
   */
  private initializeAutoRefresh(autoRefresh: number) {
    if (autoRefresh) {
      interval(autoRefresh * 1000).pipe(takeWhile(() => this.alive))
        .subscribe(() => this.onRefresh());
    }
  }

  public  editPopup(){
    this.isbtnDisable = false;
    this.demographicFormSaved = false;
    this.demographicForm = this.fb.group({
      firstName: [this.data.firstName, [Validators.required,Validators.pattern("^[a-zA-Z'\\s_-]*$"),Validators.maxLength(100)]],
      lastName: [this.data.lastName,  [Validators.required,Validators.pattern("^[a-zA-Z'\\s_-]*$"),Validators.maxLength(100)]],
      preferredName: [this.data.preferredName,[Validators.pattern("^[a-zA-Z\ \]*$"),Validators.maxLength(100)]],
      dateOfBirth: [this.formatDate(this.data.dateOfBirth), Validators.compose([Validators.required, DateValidator.dateVaidator])],
      fullName: [this.data.fullName, [Validators.required]]

  });
    this.modalRef = this.modalService.open(this.demographicEditModal, { size: 'lg' });
  }
  cancelForm() {
     if (this.demographicForm.dirty) {
          if (confirm(DynamicWidgetsConstant.alertMessages.memberConfirmMessage)) {
            this.modalRef.close();
          }
        }
        else {
          this.modalRef.close();
        }
      }
        formatDate(date:any){
          if (date) {
            return moment(date).format(Constant.customDateFormat);
        }
        }
        saveForm(){
          this.demographicFormSaved = true;
    if (this.demographicForm.valid) {
      this.isbtnDisable = true;
      this.updateDemographicData();
    }
  
        }
        private updateDemographicData() {
          //const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
          this.demographicUpdateData = this.demographicData();
          var url = `${this.appConfig.apiBaseUrl}${Constants.postMemberBasicDetails}`;  
         var memberdemographicUrl = url.replace(/##memberId##/g,this.memberId).replace(/##source##/g,this.appConfig.Source);
          this.widgetService.postByOptions(memberdemographicUrl, this.demographicUpdateData,{ responseType: 'text'})
            .subscribe((res: any) => {
              if (res) {
                this.modalRef.close();
                this.bindDemographicData();
              }
            }),
            (error: any) => {
              this.isbtnDisable = false;
              this.errorMessage = DynamicWidgetsConstant.formErrorMessages.failedToSave;
            };
        }
      
        private demographicData() {
          return {
            firstName: this.demographicForm.value.firstName,
            lastName: this.demographicForm.value.lastName,
            fullName: this.demographicForm.value.firstName + ' ' + this.demographicForm.value.lastName,
            preferredName:this.demographicForm.value.preferredName,
            dateOfBirth:this.demographicForm.value.dateOfBirth
          }
        }
}
