import { Inject, Injectable } from '@angular/core';

import { HttpUtility } from '@app/core/utilities/http.utility';
import { DashboardConstant } from '@app/dashboard/dashboard.constant';
import { AppConfig } from '@shared/models/app-config.model';
import { Constant } from '@shared/utilities/constant';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashBoardService {
    private url: string;

    constructor(private httpUtility: HttpUtility,
        @Inject(Constant.appConfig) private appConfig: AppConfig) {
        if (environment.production) {
            this.url = `${appConfig.apiBaseUrl}${DashboardConstant.urls.MemberPortalUrl}`;
        } else {
            this.url = `${appConfig.apiBaseUrl}`;
        }
    }

    /**
     * Gets dashboard configuration
     * @param name - dashboard name
     * @returns -
     */
    public getDashboardConfiguration(name: string) {
        console.log("getDashboardConfiguration", `${this.url}${DashboardConstant.urls.dashboradUrl}/${name}`);
        return this.httpUtility.get(`${this.url}${DashboardConstant.urls.dashboradUrl}/${name}`);
    }
}
