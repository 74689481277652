import { Injectable, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Constant } from '@shared/utilities/constant';
import { AuthenticationService } from '@colibrium/auth-lib';
import { ConfirmationService } from "primeng/api";


const MINUTES_UNITL_AUTO_LOGOUT = 15 // in 15 mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';
const SESSION_WINDOW = 'sessionDialog';
@Injectable()
export class AutoLogoutService implements OnInit{
stopInterval:boolean=false;
    Id:any;
    public getLastAction() {
        return parseInt(localStorage.getItem(STORE_KEY));
    }

    public setLastAction(lastAction: number) {
        localStorage.setItem(STORE_KEY, lastAction.toString());
    }

    constructor(private router: Router,
        private authService: AuthenticationService, private confirmationService: ConfirmationService) {
        this.initListener();
        if(!this.stopInterval){
        this.initInterval();
        }
    }
ngOnInit(){
    this.stopInterval = false;
    localStorage.setItem(STORE_KEY, Date.now().toString());
}
    initListener() {
        document.body.addEventListener('click', () => this.reset());
        document.body.addEventListener('mouseover', () => this.reset());
        document.body.addEventListener('mouseout', () => this.reset());
        document.body.addEventListener('keydown', () => this.reset());
        document.body.addEventListener('keyup', () => this.reset());
        document.body.addEventListener('keypress', () => this.reset());
    }

    reset() {
        this.setLastAction(Date.now());
    }

    initInterval() {
      this.Id =  setInterval(() => {
            this.check();
        }, CHECK_INTERVAL);
    }

    check() {
        const now = Date.now();
        const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;
        let sessionWindow = localStorage.getItem(SESSION_WINDOW);
        if (isTimeout && sessionWindow == null) {
            this.stopInterval=true;
          
            clearInterval(this.Id);
        
          localStorage.setItem(SESSION_WINDOW, "true");
            this.confirmationService.confirm({
                message: 'The session has expired due to inactivity. Please login again to start a new session.',
                header: 'SESSION EXPIRED',
                icon: 'pi pi-info-circle',
                accept: () => {
                    localStorage.removeItem(Constant.StorageSession);
                    localStorage.removeItem(STORE_KEY);
                    localStorage.clear();
                    localStorage.removeItem(SESSION_WINDOW);
                    this.authService.logout();
                },
                key: "sessionDialog"
            });
        }


    }
    

}


