//#region angular imports

import { Injectable } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

//#endregion angular imports

//#region functional/model imports

import { ExportColumn } from '@shared/models/export-column.model';
import { Constant } from '@shared/utilities/constant';

//#endregion functional/model imports

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    /**
     * export excel
     * @param jsonData - any[]
     * @param columns - ExportColumn[]
     * @param sheetName - string
     * @param fileName - string
     */

    public exportExcel(jsonData: any[], columns: ExportColumn[], sheetName: string, fileName: string) {
        const wb = new Workbook();
        const ws = wb.addWorksheet(sheetName);

        //Add Header Row
        const headers = columns.map(a => a.header);
        const headerRow = ws.addRow(headers);
        // Header Cell Style : font alignment
        headerRow.eachCell((cell) => {
            cell.font = {
                bold: true
            },
                cell.alignment = {
                    vertical: Constant.verticalAlign,
                    horizontal: Constant.centerAlign
                }
        });

        
        // Assign columns and width 
        ws.columns = columns;
        ws.columns.forEach(column => {
            column.width = (column.header.length + 4);
        });

        // Assign data 
        for (let i in jsonData) {
            ws.addRow(jsonData[i]);
        }

        wb.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: Constant.excel.excelSaveType });
            fs.saveAs(blob, `${fileName}${Constant.excel.excelExtension}`);
        });

    }
}
