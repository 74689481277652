//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { RowModel } from '@app/@dynamic-widget/models/row.model';
import { FormFieldModel } from '@app/@dynamic-widget/models/field/form-field.model';
import { AbpCustomFieldModel } from '@colibrium/abp-dynamic-form/lib/attr-directive/abp-custom-field.model';

//#endregion functional/model imports

export class ColumnModel<T extends FormFieldModel> {

  //#region model properties

  public fieldInfo: T;
  public childList: RowModel<T>[];
  public order: number;
  /** column level css*/
  public cssClass: string;
  public columnSpan: number;

  public childDivCss: string;
  public isRendered: boolean;

  public customComponent: AbpCustomFieldModel;
  //#endregion model properties

  //#region constructor

  constructor() {
  }

  //#endregion constructor

}
