//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { ColumnModel } from '@app/@dynamic-widget/models/column.model';
import { FormFieldModel } from '@app/@dynamic-widget/models/field/form-field.model';
import { SectionInfoModel } from './section-map/section-info.model';

//#endregion functional/model imports

export class RowModel<T extends FormFieldModel> {

  //#region model properties

  public rowNumber: number;
  public columns: ColumnModel<T>[];
  public rowCssClass: string;
  public code: string;
  public subSection: SectionInfoModel[];

  //#endregion model properties

  //#region constructor

  constructor() {
    this.columns = [];
  }

  //#endregion constructor

}
