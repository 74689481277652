<div class="container sign-up-container">
    <div class="row  align-items-end">
        <div class="col-lg-6 logo">
            
            <img src="../../../assets/Smart_step_Logo_Final_2_JPEG.png" class="branding_sagility">
        </div>
        <div class="col-lg-6 sign-in-top">
            <h6 class="sign-up-text">
                <a routerLink="/login">Login</a>
            </h6>
        </div>        
    </div>
    <div class="row mt-2 mb-3">
        <div class="col-12">
            <div class="bg-white shadow-sm border-radius-5">
                <div class="sign-up-title p-3">
                    <div>
                    <h3>Policy Holder Sign Up</h3>
                    </div>
                    <div>
                    <p class="text-white"><i class="fa fa-question-circle-o" icon="pi pi-check" pTooltip="A policyholder is a Prudential covered individual who has been identified as eligible for the Sagility Smart StepTM program." 
                        tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;" tooltipPosition="bottom"></i>
                        What is a Policy Holder?
                    </p>
                    </div>
                </div>
                <div class="steps p-3">
                    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
                </div>
                <div class="form-container row justify-content-center p-3">
                    <div class="col-sm-9 mb-4" *ngIf="form1">
                        <form [formGroup]="registrationGroup">
                            <div>
                                <p><i>Registration is available only between 8AM-3AM EST.<br>Please enter the 9 character code provided in your invitation letter to the falls prevention program. The code is a series of letters and numbers and must be entered correctly to complete your registration.</i>
                                </p>
                            </div>
                            <div class="top-title">
                                <h1 class="text-gray">validation</h1>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Registration Code
                                    <sup>*</sup></label>
                                <div class="p-col col-11 col-sm-7">
                                    <div class="lock-icon">
                                        <input type="text" maxlength="10" class="form-control"
                                            formControlName="registrationCode" [(ngModel)]="Rcode"
                                            (change)="RcodeValidate($event)" pInputText placeholder="eg. ABCDEFA1" />
                                    </div>
                                    <div *ngIf="rcSubmitted && rc.registrationCode.errors" class="error">
                                        <div *ngIf="rc.registrationCode.errors.required">Invalid Registration
                                            Code</div>
                                        <div *ngIf="rc.registrationCode.errors.pattern">Invalid Registration
                                            Code</div>
                                    </div>
                                    <div *ngIf="RcodeError" class="error">
                                        <div>Invalid Registration Code</div>
                                    </div>
                                </div>
                                <!-- <div class="p-col col-1 col-sm-1 pl-0">
                                    <i class="fa fa-question-circle-o question-mark-black" icon="pi pi-check" pTooltip="Please enter the 8 character code provided in your invitation letter to the Falls Prevention 
                                    program. The code is a series of letters and numbers and must be entered correctly to complete your 
                                    registration." tooltipPosition="bottom"></i>
                                </div> -->
                            </div>
                            <div class="form-group calender-input row p-field p-grid d-flex align-items-center mb-5">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Policy Holder Date of Birth
                                    <sup>*</sup></label>
                                <div class="p-col col-11 col-sm-7">
                                    <div class="lock-icon"> 

                                        <div class="input-group">
                                            <input
                                            (dateSelect)="onDateSelect($event)"
                                            (keyup)="OnkeyUp($event)"
                                            [minDate]="{year: minYear, month: 1, day: 1}"
                                            [maxDate]="{year: maxYear, month: maxMonth, day: Date}"
                                              class="form-control"
                                              placeholder="MM/DD/YYYY"
                                              name="dp"
                                              [(ngModel)]="dateVal"
                                              ngbDatepicker
                                              #d="ngbDatepicker"
                                              (blur)="onDateBlur($event)"
                                              
                                              formControlName ="dob" monthNavigator="true"
                                            />
                                            <button class="btn btn-outline-secondary cal-margin" (click)="d.toggle()" type="button"><img src="../../../../assets/calendar1.png" alt="Calender"></button>
                                          </div>                            
                                       
                                    </div>
                                    
                                
                                    <div *ngIf="((rcSubmitted && rc.dob.errors)  || (!validYear))" class="error">
                                        <div *ngIf="(rc.dob.errors.required)  || (!validYear)">Please enter valid Date Of Birth
                                        </div>
                                    </div>
                                
                                </div>
                                <!-- <div class="p-col col-1 col-sm-1 pl-0">
                                    <i class="fa fa-calendar fa-lg " aria-hidden="true"></i>
                                </div> -->
                                
                            </div>
                            <div class="col-lg-7 offset-lg-4 d-flex align-items-center">
                                <div class="submit-btn">
                                    <p-button label="Next" (onClick)="nextPage1()" icon="pi pi-arrow-right"
                                        iconPos="right">
                                    </p-button>
                                </div>
                                <div class="cancel-btn">
                                    <p-button label="Cancel" class="ml-2" routerLink="/login"></p-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8 mb-4" *ngIf="form2">
                        <form [formGroup]="group" noValidate>
                            <div>
                                <p><i>If you have any questions or face any issues completing the form below, <br> please call 1-888-413-2099.</i></p>
                            </div>
                            <div class="top-title">
                                <h1>portal credentials</h1>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Email Address
                                    <sup>*</sup></label>
                                <div class="p-col col-11 col-sm-8">
                                    <div class="lock-icon">
                                        <input type="email" class="form-control" placeholder="eg. abc@xyz.com"
                                            formControlName="email" />
                                    </div>
                                    <div *ngIf="formSubmitted && f.email.errors" class="error">
                                        <div *ngIf="f.email.errors.required">Invalid Email Address</div>
                                        <div *ngIf="f.email.errors.email || f.email.errors.pattern " >Invalid email format</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Set Password
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8">
                                    <div class="lock-icon d-flex">
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                            pInputText placeholder="******" formControlName="password" />
                                        <a href="javascript:;"><i class="fa fa-lock" [ngClass]="{
                                                        'fa-eye-slash': !fieldTextType,
                                                        'fa-eye': fieldTextType
                                                      }" (click)="toggleFieldTextType()"></i></a>
                                                      <div class="sign-up-title" class="mar-top-35">
                                                          <p class="password-tooltip">
                                                          <i class="fa fa-question-circle-o" icon="pi pi-check" 
                                                          tooltipPosition="top" pTooltip="Minimum : 8 characters
                                                          Maximum : 24 characters
                                                          Must contain : 1 uppercase, lowercase, special character(e.g. , ! @ #) and number.
                                                          Must not contain : First or Last name of the Policy Holder" 
                                                          tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;" ></i>
                                                        </p>
                                                      </div>

                                    </div>
                                    <div *ngIf="formSubmitted && f.password.errors" class="error">
                                        <div *ngIf="f.password.errors.required">Password is very weak</div>
                                        <div *ngIf="f.password.errors.pattern">The entered password does not
                                            meet the
                                            required standard</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <label class="p-col-fixed col-12 col-sm-4 col-form-label">Confirm Password
                                    <sup>*</sup></label>
                                <div class="p-col col-12 col-sm-8">
                                    <div class="lock-icon">
                                        <input [type]="fieldTextTypeCF ? 'text' : 'password'" class="form-control"
                                            pInputText placeholder="******" formControlName="confirmPassword" />
                                        <a href="javascript:;"><i class="fa fa-lock" [ngClass]="{
                                                        'fa-eye-slash': !fieldTextTypeCF,
                                                        'fa-eye': fieldTextTypeCF
                                                      }" (click)="toggleFieldTextTypeCF()"></i></a>
                                    </div>
                                    <div *ngIf="formSubmitted && f.confirmPassword.errors" class="error">
                                        <div *ngIf="f.confirmPassword.errors.required">Password is very weak
                                        </div>
                                        <div *ngIf="f.confirmPassword.errors.pattern">The entered password does
                                            not meet the required standard</div>
                                        <div *ngIf="f.confirmPassword.errors.confirmedValidator">Password and
                                            Confirm Password must be match.</div>
                                    </div>
                                </div>
                            </div>
                            <div class='mb-5'>
                                <div class="top-title">
                                    <h1>Personal Information</h1>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">First Name
                                        <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <input type="text" class="form-control" formControlName="firstName" />
                                        </div>
                                        <div *ngIf="formSubmitted && f.firstName.errors" class="error">
                                            <div *ngIf="f.firstName.errors.required">Invalid First Name</div>
                                            <div *ngIf="f.firstName.errors.pattern">Invalid First Name</div>
                                             <div *ngIf="f.firstName.errors.maxlength">You can enter only 100 characters</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">Last Name
                                        <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <input type="text" class="form-control" formControlName="lastName" />
                                        </div>
                                        <div *ngIf="formSubmitted && f.lastName.errors" class="error">
                                            <div *ngIf="f.lastName.errors.required">Invalid Last Name</div>
                                            <div *ngIf="f.lastName.errors.pattern">Invalid Last Name</div>
                                            <div *ngIf="f.lastName.errors.maxlength">You can enter only 100 characters</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">Gender
                                        <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <select class="form-control" formControlName="gender">
                                                <option value=''>Select Gender</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </select>
                                        </div>
                                        <div *ngIf="formSubmitted && f.gender.errors" class="error">
                                            <div *ngIf="f.gender.errors.required">Please Select Gender</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="top-title">
                                    <h1>contact</h1>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">Phone <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon d-flex">
                                            <input type="tel" class="form-control"
                                                [value]="group.get('mobileNumber').value | phone"
                                                placeholder="eg. 0000000000" maxlength="10" minlength="10"
                                                formControlName="mobileNumber"  (change)="PhoneValidate($event)" (keyup)="PhoneValidator()"/>
                                        </div>
                                        <div *ngIf="formSubmitted && f.mobileNumber.errors"  class="error"> 
                                            <div *ngIf= "f.mobileNumber.errors.pattern">Enter only numbers</div>
                                            <div *ngIf="f.mobileNumber.errors.required">Invalid Phone</div>                                             
                                        </div>
                                        <div *ngIf="perror && f.mobileNumber.errors.minlength && !f.mobileNumber.errors.pattern"   class="error">
                                            <div>Invalid Phone</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">Phone Type
                                        <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <select class="form-control" formControlName="phoneType">
                                                <option value=''>Select Phone Type</option>
                                                <option value="1 ">Home</option>
                                                <option value="2 ">Mobile</option>
                                                <option value="6">Text</option>
                                                <option value="4 ">Work/Office</option>
                                            </select>
                                            <p class="phoneType-tooltip">
                                                <i class="fa fa-question-circle-o" icon="pi pi-check" 
                                                tooltipPosition="top" 
                                                pTooltip="By selecting Mobile or Text, you are agreeing to let us contact you via text message."
                                                tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;"></i>
                                              </p>
                                        </div>
                                        <div *ngIf="formSubmitted && f.phoneType.errors" class="error">
                                            <div *ngIf="f.phoneType.errors.required">Please Select Phone Type</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">Address
                                        <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <input type="text" class="form-control" formControlName="address" />
                                        </div>
                                        <div *ngIf="formSubmitted && f.address.errors" class="error">
                                            <div *ngIf="f.address.errors.required">Invalid Address</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">City <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <input type="text" class="form-control" formControlName="city" />
                                        </div>
                                        <div *ngIf="formSubmitted && f.city.errors" class="error">
                                            <div *ngIf="f.city.errors.required">Invalid City</div>
                                            <div *ngIf="f.city.errors.pattern">Invalid City</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">State <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <select class="form-control" formControlName="state">
                                                <option value='' selected>Select State</option>
                                                <option *ngFor="let state of states" value={{state.stateCode}}>
                                                    {{state.stateName}} </option>
                                            </select>
                                        </div>
                                        <div *ngIf="formSubmitted && f.state.errors" class="error">
                                            <div *ngIf="f.state.errors.required">Please Select State</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                    <label class="p-col-fixed col-12 col-sm-4 col-form-label">Zip <sup>*</sup></label>
                                    <div class="p-col col-12 col-sm-8">
                                        <div class="lock-icon">
                                            <input type="text" class="form-control" formControlName="zipCode"
                                                minlength="5" maxlength="9" [(ngModel)]="zcode"
                                                (change)="ZipValidate($event)" />
                                        </div>
                                        <div *ngIf="formSubmitted && f.zipCode.errors" class="error">
                                            <div
                                                *ngIf="f.zipCode.errors.required || f.zipCode.errors.minlength">
                                                Invalid Zip</div>
                                        </div>
                                        <div *ngIf="zerror" class="error">
                                            <div>Invalid Zip</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row p-field p-grid d-flex align-items-center pb-2">
                                    <div class="col-12 col-sm-4"></div>
                                    <div class="p-col col-sm-8">
                                        <div class="lock-icon">
                                            <p>Would you like to add your Caregiver's information now and allow him or
                                                her access to your information? </p>
                                            <p>By adding a caregiver to your account, you allow your health information to be disclosed to the person (caregiver) you identify. Your caregiver is anyone who helps you. This may be someone within your family or a close friend. The program will share your information with your caregiver and provide additional support for those who help you. The health information pertains to the services that are provided through the Sagility Smart Step<sup>TM</sup> a falls prevention program.</p>
                                            <div class="d-flex align-items-center">
                                                <div *ngFor="let category of categories" class="p-field-checkbox">
                                                    <p-radioButton [inputId]="category.key" name="addCaregiver"
                                                        [value]="category" class="mr-2" formControlName="addCaregiver">
                                                    </p-radioButton>
                                                    <label [for]="category.key"
                                                        class="mb-0 mr-3">{{category.name}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="this.group.value.addCaregiver.name == 'Yes'">
                                    <div class="top-title">
                                        <h1>caregiver information</h1>
                                    </div>
                                    <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                        <label class="p-col-fixed col-12 col-sm-4 col-form-label">Caregiver First Name
                                            <sup>*</sup></label>
                                        <div class="p-col col-12 col-sm-8">
                                            <div class="lock-icon">
                                                <input type="text" class="form-control"
                                                    formControlName="caregiverFirstName" />
                                            </div>
                                            <div *ngIf="formSubmitted && f.caregiverFirstName.errors" class="error">
                                                <div *ngIf="f.caregiverFirstName.errors.required">Invalid First Name</div>
                                                <div *ngIf="f.caregiverFirstName.errors.pattern">Invalid First Name</div>
                                                <div *ngIf="f.caregiverFirstName.errors.maxlength">You can enter only 100 characters</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                        <label class="p-col-fixed col-12 col-sm-4 col-form-label">Caregiver Last Name
                                            <sup>*</sup></label>
                                        <div class="p-col col-12 col-sm-8">
                                            <div class="lock-icon">
                                                <input type="text" class="form-control"
                                                    formControlName="caregiverLastName" />
                                            </div>
                                            <div *ngIf="formSubmitted && f.caregiverLastName.errors" class="error">
                                                <div *ngIf="f.caregiverLastName.errors.required">Invalid Last Name</div>
                                                <div *ngIf="f.caregiverLastName.errors.pattern">Invalid Last Name</div>
                                                <div *ngIf="f.caregiverLastName.errors.maxlength">You can enter only 100 characters</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                        <label class="p-col-fixed col-12 col-sm-4 col-form-label">Email Address
                                            <sup>*</sup></label>
                                        <div class="p-col col-12 col-sm-8">
                                            <div class="lock-icon">
                                                <input type="email" class="form-control" pInputEmail
                                                    placeholder="eg. abc@xyz.com" formControlName="caregiverEmail" />
                                                    <p class="Email-tooltip">
                                                        <br>
                                                        <i class="fa fa-question-circle-o " icon="pi pi-check" 
                                                        tooltipPosition="top" 
                                                        pTooltip="Unique email address is required, cannot use Policy Holder email address"
                                                        tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;"></i>
                                                      </p> 
                                            </div>
                                            <div *ngIf="formSubmitted && f.caregiverEmail.errors" class="error">
                                                <div *ngIf="f.caregiverEmail.errors.required">Invalid Email</div>
                                                <div *ngIf="f.caregiverEmail.errors.email || f.caregiverEmail.errors.pattern">Invalid email format</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                        <label class="p-col-fixed col-12 col-sm-4 col-form-label">Mobile Phone
                                            <sup>*</sup></label>
                                        <div class="p-col col-12 col-sm-8">
                                            <div class="lock-icon">
                                                <input type="tel" class="form-control"
                                                    [value]="group.get('caregiverMobileNo').value | phone"
                                                    placeholder="eg. 0000000000" maxlength="10" minlength="10"
                                                    formControlName="caregiverMobileNo"  (change)="MobilePhoneValidate($event)" (keyup)="MobilePhoneValidator()"/>
                                            </div>
                                            <div *ngIf="formSubmitted && f.caregiverMobileNo.errors"  class="error"> 
                                                <div *ngIf= "f.caregiverMobileNo.errors.pattern">Enter only numbers</div>
                                                <div *ngIf="f.caregiverMobileNo.errors.required">Invalid Mobile Phone</div>                                             
                                            </div>
                                            <div *ngIf="perror && f.caregiverMobileNo.errors.minlength && !f.caregiverMobileNo.errors.pattern"   class="error">
                                                <div>Invalid Mobile Phone</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div *ngIf="!marked"></div> -->
                            </div>
                            <div class="top-title">
                                <h1></h1>
                                <p>
                                    <i>
                                        By clicking “Save and Go” I hereby give Sagility Smart Step<sup>TM</sup> permission to collect and use my personal identifiable information (PII) and personal health information (PHI) to enroll me in the Sagility Smart Step<sup>TM</sup> falls prevention program and authorize to contact me via text, telephone or email messaging, as a means of communication for the falls prevention program. I understand that at any time, I can decline receiving all text messages by replying “STOP” after receiving a message.
                                    </i>
                                </p>
                            </div>
                            <div class="form-group row p-field p-grid d-flex align-items-center mb-4 pb-2">
                                <div class="p-col col-lg-7 offset-lg-4 col-sm-9">
                                    <div class="d-flex">
                                        <div class="submit-btn">
                                            <p-button label="Save and Go to My Portal" (onClick)="submitForm()"
                                                icon="pi pi-arrow-right" iconPos="right"></p-button>
                                        </div>
                                        <div class="cancel-btn">
                                            <p-button label="Cancel" class="ml-2" (onClick)="prevPage()"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="showSpinner">
           
                <ngx-spinner [name]="spinnerName" [fullScreen] = "false"  size="medium"  type="square-jelly-box">
                    <p class="text-white"> Loading, please wait... </p>
                </ngx-spinner>
            
            </div>
        </div>
    </div>
    
</div>
<abp-content-popup *ngIf="showPopup" [(showPopup)]="showPopup" class="popupHeader">
    <div header>
        <h5 class="headerColour">
            <i class="fa fa-exclamation-circle iconClass"></i>
            System Info
        </h5>
    </div>
    <div body>
        <div class="col-lg-12 sign-in-top">
            <h6 class="login-text">
                {{errorMessageForMemberReg}}                
            </h6>
            <div *ngIf="ShowloginHref">
                <!-- The member with the given registration code is already signed up.-->
                <span ><br> Please click here to <a href="/login">Login</a> </span>
            </div>
        </div>
    </div>
</abp-content-popup>



    
