//#region angular imports

import { Injectable } from '@angular/core';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { PdfColumn } from '@shared/models/pdf-column.model';
import { Constant } from '@shared/utilities/constant';

//#endregion angular imports

//#region functional/model imports


//#endregion functional/model imports

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    /**
     * export excel
     * @param jsonData - any[]
     * @param columns - PdfColumn[]
     * @param sheetName - string
     * @param fileName - string
     */

    public exportPdf(jsonData: any[], columns: PdfColumn[], sheetName: string, fileName: string) {
        var doc = new jsPDF('p', 'pt', 'a4');

        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.text(sheetName, pageWidth / 2, 20, Constant.centerAlign);

        doc.autoTable({
            theme: Constant.pdf.pdfTheme,
            headerStyles: {
                halign: Constant.centerAlign,
                valign: Constant.verticalAlign,
                fillColor: Constant.pdf.headerBackGroundColor,
                textColor: Constant.pdf.textColor,
                lineWidth: 0.25,
                lineColor: [189, 195, 199]
            },
            body: jsonData,
            columns: columns,
        })
        doc.save(`${fileName}.${Constant.pdf.pdfExtention}`);
    }
}
