import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'appendEnv'
})
export class AppendEnvPipe implements PipeTransform {

  transform(value: any, envVariable: string, appendAfter: boolean = true): any {
    if (appendAfter) {
      return `${environment[envVariable]}${value}`;
    }
    return `${value}${environment[envVariable]}`;
  }

}
