import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableColumns } from '@shared/controls/models/table-columns.model';
import { Constant } from '@shared/utilities/constant';
import { QuickLinkWidget } from '@app/dynamic-widgets/models/quick-link-widget.model';
import { QuickLinkConfigurations } from '@app/dynamic-widgets/models/quick-link-configurations.model';
import { WidgetTitleConfig } from '@app/dashboard/models/widget-title-config.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMemberDetailsBaseModel } from '@shared/models/usermemberdetails';
import { UserMemberDetailsService } from '@shared/services/usermemberdetails.service';

@Component({
  selector: 'app-quick-link-widget',
  templateUrl: './quick-link-widget.component.html',
  styleUrls: ['./quick-link-widget.component.scss']
})
export class QuickLinkWidgetComponent implements OnInit {
  @Input() input: string;
  @Input() params: any;
  @Input() titleConfig: WidgetTitleConfig;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();


  public cols: TableColumns[];
  public count: number;
  public data: any;
  public isExportable: boolean;
  public isReset: boolean = false;
  public quickLinkWidgets: any[];
  public widgetSpace: boolean = false;
  public memberId: string = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usermemberdetailsService: UserMemberDetailsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.memberId = params.get('id');
    });
    if (this.input) {
      if (this.params) {
        this.input = this.input.replace(Constant.paramReqgex, (x) => {
          const value = this.params[x.slice(2, -2)];
          return value ? value : x;
        });
      }
      const res: QuickLinkWidget = this.quickLinkWidgetMapper(this.input);
      if (this.validateConfiguration(res)) {
        this.quickLinkWidgets = res.QuickLinkWidgets;
        let memberdetails: UserMemberDetailsBaseModel = (JSON.parse(this.usermemberdetailsService.getMemberDetails())) as UserMemberDetailsBaseModel;

        if (memberdetails && memberdetails.memberId != this.memberId) {
          let cgIndex = this.quickLinkWidgets.findIndex((x => x.widgetId = "contactPreferenceFormWidgetId"));
          this.quickLinkWidgets[cgIndex].tab = Constant.MemberactionType.OpenCaregiverInfo;
          this.quickLinkWidgets = this.quickLinkWidgets.filter(x => x.widgetId != "caregiversListWidgetId");
       }
      }
      if (this.quickLinkWidgets.length > 2) {
        this.widgetSpace = true;
      }
    }
  }

  navigateUrl(widgetData: any) {
    this.clicked.emit(widgetData);
  }

  private quickLinkWidgetMapper(value: string) {
    const inputRes: QuickLinkConfigurations = JSON.parse(value);
    let res: QuickLinkWidget = {
      QuickLinkWidgets: inputRes.QuickLinkWidgets ? JSON.parse(inputRes.QuickLinkWidgets) : null
    };
    return res;
  }

  /**
   * Validates configuration
   * @param config
   */
  private validateConfiguration(config: QuickLinkWidget): boolean {
    let isValid: boolean = true;
    if (!config) {
      isValid = false;
      console.error(`${this.titleConfig.title} - widget configuration missing`);
    }
    return isValid;
  }

}
