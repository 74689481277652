import { NgModule, Optional, SkipSelf, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CoreRoutingModule } from '@core/core-routing.module';
import { throwIfAlreadyLoaded } from '@core/module-import-guard';
import { LoggingService } from '@core/services/logging.service';
import { GlobalErrorService } from '@core/services/global-error.service';
import { RouteService } from '@core/services/route.service';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { AppErrorHandler } from '@core/providers/app-error-handler';
import { HttpUtility } from '@core/utilities/http.utility';
import { HttpAuthInterceptor } from '@core/interceptors/http-auth.interceptor';
import { LoaderService } from '@app/core/services/loader.service';
import { AuthenticationService, AuthGuard } from '@colibrium/auth-lib';
import { MsalService } from '@azure/msal-angular';

const coreServices = [
  HttpClientModule,
  LoggingService,
  GlobalErrorService,
  RouteService,
  HttpUtility,
  AuthenticationService,
  AuthGuard,
  LoaderService
];

const coreInterceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
];

const coreProviders = [
  //{ provide: ErrorHandler, useClass: AppErrorHandler }
];

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule
  ],
  exports: [
  ],
  declarations: [],
  providers: [MsalService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        coreServices,
        coreInterceptors,
        coreProviders
      ],
    };
  }
}
