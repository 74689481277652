<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let sec of arraySection; let i = index;">
    <ng-template carouselSlide slideBy='page' [dotContent]="sec.section.displayText">
      <div class="row application-form-container">
        <div class="{{sec.cssClass}} col-12">
          <abp-dynamic-field-widget [rowList]="sec.rowFields" [formSubmitted]="formSubmitted"
            [pageForm]="pageForm.controls[i]">
          </abp-dynamic-field-widget>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template carouselSlide></ng-template>
</owl-carousel-o>