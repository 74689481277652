import { Pipe } from "@angular/core";
import { Constant } from '@shared/utilities/constant';

@Pipe({
  name: 'phone'
})
export class PhonePipe {
  transform(rawNum) {
    if (rawNum) {
      let newStr = '';
      newStr = rawNum.toString().replace(/(\d{3})(\d{3})(\d{4})/,Constant.phoneNumberFormat);
      return newStr;
    }
    else{
      return '';
    }
  }
}
