import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class LoadingIndicatorService {

  private requestCount: number = 0;
  onLoadingChanged: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  // Notifies observers for live requests
  startLoading(customLog?: string) {
    if (customLog) { 
      console.log(`LoadingIndicator.startLoading: ${customLog}`);
    }
    this.requestCount++;
    this.onLoadingChanged.next(true);
  }

  // Notifies observers when all requests have finished
  stopLoading(customLog?: string) {
    if (customLog) { 
      console.log(`LoadingIndicator.stopLoading: ${customLog}`);
    }
    this.requestCount = this.requestCount > 0 ? this.requestCount - 1 : this.requestCount;
    if (this.requestCount === 0) {
      this.onLoadingChanged.next(false);
    }
  }

  constructor() { }
}
