<ng-container *ngIf="show">
  <div [ngClass]="[parentColumn.cssClass? parentColumn.cssClass:'']">
    <ng-container dynamicField
                  [id]="id"
                  [field]="parentColumn.fieldInfo.abpField"
                  [isFormSubmitted]="formSubmitted"
                  [formControlElement]="pageForm.controls[parentColumn.fieldInfo.code]"
                  [customComponent] = "parentColumn.customComponent">
    </ng-container>
  </div>
  <ng-container *ngIf="hasChild">
    <div #childDiv>
      <ng-container *ngFor="let childRow of parentColumn.childList">
        <div class="row">
          <ng-container *ngFor="let columnField of childRow.columns">

            <div *ngIf="showChild(columnField)"
                 class="col-sm-12 col-md-{{columnField.columnSpan}}">

              <abp-field-row [id]="id"
                             [parentColumn]="columnField"
                             [formSubmitted]="formSubmitted"
                             [pageForm]="pageForm">
              </abp-field-row>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
