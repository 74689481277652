import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { ApplicationSettingType } from '@layout/enums/application-setting-type.enum';
import { Constants } from '@layout/layout.constants';
import { BehaviorSubject } from 'rxjs';
import { Constant } from '@shared/utilities/constant';
import { AppConfig } from '@shared/models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private url: string;

  public slideState = new BehaviorSubject<boolean>(null);
  public currentSlideState = this.slideState.asObservable();

  leftPanel = new BehaviorSubject<boolean>(null);
  leftPanelState = this.leftPanel.asObservable();

  expandModal = new BehaviorSubject<boolean>(null);
  expandModalState = this.expandModal.asObservable();

  modalName = new BehaviorSubject<string>(null);
  modalNameState = this.modalName.asObservable();

  constructor(private httpUtility: HttpUtility, @Inject(Constant.appConfig) private appConfig: AppConfig) {
    if (environment.production) {
      this.url = `${appConfig.apiBaseUrl}${Constants.url.MemberPortal}`;
    } else {
      this.url = `${appConfig.apiBaseUrl}`;
    }
  }

  /**
   * Gets all Configurations by type
   * @param configurationType
   */
  public getConfigurations(configurationType: ApplicationSettingType) {
    console.log("layout.service",  "getConfigurations", `${this.url}${Constants.appSettings}${Constants.type}${configurationType}`);
    return this.httpUtility.get(`${this.url}${Constants.appSettings}${Constants.type}${configurationType}`);
  }

  changeSlideState(slide) {
    this.slideState.next(slide);
  }

  changeleftPanelState(slide) {
    this.leftPanel.next(slide);
  }

  changeexpandModalState(stat) {
    this.expandModal.next(stat);
  }

  changemodalNameState(name) {
    this.modalName.next(name);
  }
}
