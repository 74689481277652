<ul class="tree" [ngClass]="{ 'expand': expanded || level != 0,'root': !expanded && level == 0 }">
    <ng-container *ngFor="let menuItem of menu; let i=index">
        <ng-container *ngIf="menuItem.subMenu?.length > 0; else elseTemplate">
            <li *appAuthCheck="menuItem.label" (mouseover)="setHover(i)" (mouseleave)="setHover(-1)" [ngClass]="{'hover': selectedIndex == i || hoverIndex == i, 'submenu': selectedIndex == i}" [ngStyle]="{'background': getUrl(selectedIndex,i,menuItem.icon)}">
                <a href="javascript:;" (click)="navigate(menuItem, i)">{{menuItem.label}}</a>
                <app-menu *ngIf="selectedIndex==i" [menu]="menuItem.subMenu" [level] = "level+1" [expanded]="expanded"></app-menu>
            </li>
        </ng-container>
        <ng-template #elseTemplate>
            <li *appAuthCheck="menuItem.label" (mouseover)="setHover(i)" (mouseleave)="setHover(-1)" [ngClass]="{'active': selectedIndex == i, 'hover': hoverIndex == i}" [ngStyle]="{'background': getUrl(selectedIndex,i,menuItem.icon)}">
                <a href="javascript:;" (click)="navigate(menuItem, i)">
                    <span>
                        {{menuItem.label}}
                    </span>
                </a>
            </li>
        </ng-template>
    </ng-container>
</ul>
