import { Component, OnInit } from '@angular/core';
import { SharedService } from '@shared/services/shared.service'

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  public breadCrumb = true;

  constructor(
    private sharedService: SharedService
  ) {
    this.sharedService.breadCrumbState.subscribe(data => {
      this.breadCrumb = data;
    });

  }

  ngOnInit() {
  }

}
