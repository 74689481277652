<ng-container *ngIf="rowList">
  <ng-container *ngFor="let rowField of rowList">
    <div class="row {{rowField.rowCssClass}}">
      <ng-container *ngFor="let columnField of rowField.columns">
        <div class="{{columnField.columnSpan}}">
          <abp-field-row [id]="id" [parentColumn]="columnField" [formSubmitted]="formSubmitted" [pageForm]="pageForm">
          </abp-field-row>
        </div>

      </ng-container>      
    </div>
    <ng-container *ngIf="rowField.subSection">
      <abp-array-sec [arraySection]="rowField.subSection" [formSubmitted]="formSubmitted"
        [pageForm]="pageForm.controls[rowField.code]">
      </abp-array-sec>
    </ng-container>
  </ng-container>
</ng-container>
