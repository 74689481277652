
import { ComponentFactoryResolver } from '@angular/core';
import { DemographicWidgetComponent } from '@app/dynamic-widgets/widgets/demographic-widget/demographic-widget.component';
import { ListWidgetComponent } from '@app/dynamic-widgets/widgets/list-widget/list-widget.component';
import { ChartWidgetComponent } from '@app/dynamic-widgets/widgets/chart-widget/chart-widget.component';
import { DemograhicActionWidgetComponent } from './widgets/demograhic-action-widget/demograhic-action-widget.component';
import { WorkflowWidgetComponent } from './widgets/workflow-widget/workflow-widget.component';
import { FormWidgetComponent } from './widgets/form-widget/form-widget.component';
import { QuickLinkWidgetComponent } from './widgets/quick-link-widget/quick-link-widget.component';

export class ComponentMap {
    public static readonly getComponents = (factoryResolver: ComponentFactoryResolver, name: string) => {
        switch (name) {
            case "Demographics":
                return factoryResolver.resolveComponentFactory(DemographicWidgetComponent);
            case "List":
                return factoryResolver.resolveComponentFactory(ListWidgetComponent);
            case "Chart":
                return factoryResolver.resolveComponentFactory(ChartWidgetComponent);
            case "DemoActions":
                return factoryResolver.resolveComponentFactory(DemograhicActionWidgetComponent);
            case "Workflow":
                return factoryResolver.resolveComponentFactory(WorkflowWidgetComponent);
            case 'Form':
                return factoryResolver.resolveComponentFactory(FormWidgetComponent);
            case "QuickLink":
                return factoryResolver.resolveComponentFactory(QuickLinkWidgetComponent);
             default:
                return null;
        }
    }
}