export class DynamicWidgetsConstant {
    public static readonly target = '_blank';
    public static readonly horizantalAlignment = 'right';
    public static readonly exportDateFormat = 'YYYYMMDDHHmmss';
    public static readonly urls = class {
        public static readonly unifiedPortalHubUrl: string = 'MemberManager/';
        public static readonly apiUrl = 'api/';
        public static readonly saveInFolderUrl: string = '/api​/Storage​/SaveInFolder';
        public static readonly downloadFromPathUrl: string = '/api​/Storage​/DownloadFromPath';
        public static readonly saveFilesUrl: string = '/api/File/SaveFiles';
    }
    public static readonly demographicErrorMessages = class {
        public static readonly configurationNotFound = 'Demographic Configuration Not Found';
        public static readonly invalidConfiguration = 'Invalid Demographic configuration';
        public static readonly failedToFetchData = 'Failed to fetch demographic data';
        public static readonly failedToUpdate = 'Failed to update the data';
    };
    public static readonly dateFilter = class {
        public static readonly startDate = 'startDate';
        public static readonly endDate = 'endDate';
        public static readonly dateFormat = 'mm/dd/yy';
        public static readonly hourFormat = 24;
        public static readonly unit = class {
            public static readonly years = 'years';
            public static readonly months = 'months';
            public static readonly week = 'week';
            public static readonly day = 'day';
        }
    }
    public static readonly dateComponentConstants = class {
        public static readonly currentDate = 'currentDate';
    }
    public static readonly formErrorMessages = class {
        public static readonly configurationNotFound = 'Form Configuration Not Found';
        public static readonly invalidConfiguration = 'Invalid Form configuration';
        public static readonly failedToFetchData = 'Failed to fetch Form data';
        public static readonly failedToSave = 'Failed to Save the data';
        public static readonly failedToDelete = 'Failed to Delete the data';
        public static readonly defaultValue = "No";
        public static readonly defaultUnKnowValue = "Unknown";
        public static readonly defaultYesValue = "Yes";
        public static readonly defaultNoneValue = "None";
    };
    public static readonly alertMessages = class {
        public static readonly memberConfirmMessage = 'System will reset the changes back to its original state and lose all your changes. Do you wish to undo all the changes?';
        public static readonly memberDeleteConfirmFirst ='Are you sure you would like to delete caregiver ';
        public static readonly memberDeleteConfirm = 'Are you sure you would like to delete?';
        public static readonly memberDeleteConfirmLast ='?Deleting a caregiver means he or she will no longer have access to your portal account or data. Proceed?';
        public static readonly validateMsgForDuplicatePhone = 'The Entered Phone Number already exists . Please enter unique Phone Number';
        public static readonly validateMsgForDuplicateAddress = 'The Entered Address already exists . Please enter unique Address';
    };
}
