import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbpTextFieldModel, DateHelper } from '@colibrium/abp-form-control-base';
import { AbpValidationDisplayConfigModel, AbpValidationDisplayConstant } from '@colibrium/abp-validation-display';
import { ControlBase } from '@shared/controls/components/control.base';
import { CalendarConfig } from '@shared/controls/models/calendar-config.model';
import { DynamicWidgetsConstant } from '../dynamic-widgets.constant';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent extends ControlBase<AbpTextFieldModel> {
  @Input() config: CalendarConfig;
  @Input() dateValue: Date;
  @Output() outputValue = new EventEmitter();

  constructor(@Inject(AbpValidationDisplayConstant.validationDisplayConfigInjectKey) config: AbpValidationDisplayConfigModel,
    inj: Injector,
    detector: ChangeDetectorRef) {
    super(inj, config, detector);
  }

  //#region component events

  ngOnInit() {
    this.control.getValue().setValue(this.field.fieldValue);
    this.onInitialization();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.onNgInputChanges(simpleChanges);
  }

  ngAfterViewInit() {
    this.onViewInitialization();
  }

  ngAfterViewChecked() {
  }

  //#endregion component events

  /**
   * Emit apply event
   */
   public onDateChange(date)
   {
      date = DateHelper.getDateAsString(date, DynamicWidgetsConstant.dateFilter.dateFormat);
      this.setValueBase(date);
      this.outputValue.emit(date);
   }
}
