import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MenuLink } from '@shared/models/menu-link.model';
import { LayoutConstant } from '@layout/layout.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public selectedIndex: number = -1;
  public hoverIndex: number = -1;

  get expanded(): boolean
  {
    return this._expanded;
  }
  private _expanded: boolean;
  @Input() menu: MenuLink[];
  @Input() level: number = 0;
  @Input() set expanded(val: boolean)
  {
    if (!val)
    {
      this.selectedIndex = -1;
    }
    this._expanded = val;
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  /**
   * navigates to menu action
   * @param menuItem
   */
  public navigate(menuItem: MenuLink, index: number) {
      if (menuItem.subMenu && menuItem.subMenu.length > 0)
      {
        this.selectedIndex = this.selectedIndex == index ? -1 : index;
        return;
      }
      else{
        this.selectedIndex = index;
        if (menuItem.isInternalUrl) {
          if (menuItem.isNewTab) {
            window.open(menuItem.url);
          }
          else {
            this.router.navigateByUrl(menuItem.url);
          }

        }
        else {
          if (menuItem.isNewTab) {
            window.open(menuItem.url, LayoutConstant.blank);
          }
          else {
            window.open(menuItem.url, LayoutConstant.self);
          }
        }
      }
  }

  /**
   * Gets url
   * @param selectedIndex
   * @param i
   * @param icon
   * @returns
   */
  public getUrl(selectedIndex: number, i: number, icon: string)
  {
    if (selectedIndex == i || this.hoverIndex == i)
    {
      icon = `${icon}-active.png`;
    }
    else{
      icon = `${icon}.png`;
    }

    return `url(../../../../assets/menu-icons/${icon}) no-repeat`;
  }

  /**
   * Sets hover
   * @param index
   */
  public setHover(index: number)
  {
    this.hoverIndex = index;
  }
}
