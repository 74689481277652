import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CommonAttributes } from '@layout/models/common-attributes.model';
import { LayoutService } from '@layout/layout.service';
import { ApplicationSettingType } from '@layout/enums/application-setting-type.enum';
import { RedirectionType } from '@layout/enums/redirection-type.enum';
import { ApplicationSettings } from '@layout/models/application-settings.model';
import { Constants } from '@layout/layout.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public termsandConditions: CommonAttributes;
  public copyRights: string;
  public privacyPolicy: CommonAttributes;
  public twitter: CommonAttributes;
  public googlePlus: CommonAttributes;
  public facebook: CommonAttributes;
  public contactUs: string;
  public currentYear: number = new Date().getFullYear();
  constructor(private layoutService: LayoutService, private router: Router) { }

  ngOnInit() {
    // this.layoutService.getConfigurations(ApplicationSettingType.footer)
    // .subscribe((response: ApplicationSettings[]) => {
    //   this.termsandConditions = this.mapToConfiguration(response, Constants.termsAndConditions);
    //   this.copyRights = this.mapToConfiguration(response, Constants.copyRights).content;
    //   this.privacyPolicy = this.mapToConfiguration(response, Constants.privacyPolicy);
    //   this.twitter = this.mapToConfiguration(response, Constants.twitter);
    //   this.facebook = this.mapToConfiguration(response, Constants.facebook);
    //   this.googlePlus = this.mapToConfiguration(response, Constants.googlePlus);
    //   this.contactUs = this.mapToConfiguration(response, Constants.contactUs).content;
    // });
  }

  /**
   * redirects URL based on configuration
   * @param commonAttributes
   */
  public onClick(commonAttributes: CommonAttributes) {
    if (commonAttributes.redirectionType === RedirectionType.internal) {
      this.router.navigateByUrl(commonAttributes.redirectUrl);
    } else {
      window.open(commonAttributes.redirectUrl, Constants.blank);
    }
  }

  /**
   * custom mapper
   * @param request
   * @param key
   */
  private mapToConfiguration(request: ApplicationSettings[], key: string): CommonAttributes
  {
    return JSON.parse(request.find(x => x.key == key).value);
  }

}
