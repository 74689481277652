<ng-template [ngIf]="!isExpanded">
    <ng-container *ngTemplateOutlet="widgetModel"></ng-container>
</ng-template>

<ng-template #widgetModel>
    <div class="widget" [ngClass]="{'bg-white shadow-sm p-4 border-radius-5': !isFromDashboard || isExpanded, 'my-5': !isFromDashboard && !isExpanded}">
        <app-widget-title [titleConfig]="titleConfig" [isExpanded] = "isExpanded" (onExpand)="expand($event)" (onRefresh)="onRefresh()">
        </app-widget-title>
        <div class="container content">
            <ng-container *ngIf="errorMessage; then error; else noError">
            </ng-container>
    
            <ng-template #error>
                <div class="api-error">
                    <h2>Oops!</h2>
                    {{errorMessage}}
                </div>
            </ng-template>
    
            <ng-template #noError>
                <ngx-spinner [name]="spinnerName" [fullScreen]="false" type="square-jelly-box" size="small"></ngx-spinner>
                <div *ngIf="demographicConfigurations && fieldGroups">
                    <div class="{{description.descriptionContainerCss}}">
                        <span class="{{description.descriptionCss}}">{{description.text}}</span>
                    </div>
                    <ng-template ngFor let-index='index' let-fieldGroup [ngForOf]="fieldGroups">
                      <!-- <ng-template [ngIf]="!fieldGroup.visibleOnExpand || isExpanded"> -->
                      <ng-template [ngIf]="fieldGroup.isHeaderVisible">
                        <h5 class="text-primary">{{fieldGroup.headerText}}</h5>
                      </ng-template>
                      <div class="detailed-container">
                        <ng-template ngFor let-field [ngForOf]="fieldGroup.fields">
                          <!-- <div *ngIf="!field.visibleOnExpand || isExpanded"> -->
                          <div>
                            <ul class="list-unstyled list-leftnright">
                              <ng-template [ngIf]="field.isSecure" [ngIfElse]="nonSecure">
                                <li class="row" *appAuthCheck="field.feature">
                                  <ng-container *ngTemplateOutlet="fieldRender; context:{field:field}"></ng-container>
                                </li>
                              </ng-template>
                              <ng-template #nonSecure>
                                <li class="row">
                                  <ng-container *ngTemplateOutlet="fieldRender; context:{field:field}"></ng-container>
                                </li>
                              </ng-template>
                            </ul>
                          </div>
                        </ng-template>
                      </div>
                      <hr *ngIf="index != fieldGroups.length - 1">
                      <!-- </ng-template> -->
                    </ng-template>
                </div>
                
            </ng-template>
        </div>
        <div *ngIf="actions" class="custom-footer footer">
            <div class="float-right-imp">
                <ng-template 
                    [ngIf]="showCustomFooterTemplate" 
                    [ngIfThen]="messageTemplate" 
                    [ngIfElse]="actionButtonTemplate"
                >
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #actionButtonTemplate>
    <div class="col d-md-flex justify-content-center mt-3 mb-4">
        <div class="text-center mb-3 mb-md-0" [ngClass]="action.containerCss" *ngFor="let action of actions">
            <a href="javascript:;" class="" [ngClass]="{'common-blue-btn rounded-btn': !action.actionIcon}" (click)="emitEvent(action)">
                <i *ngIf="action.actionIcon" [ngClass]="action.actionIcon"></i>
                <span *ngIf="!action.actionIcon">{{action.action}}</span>
            </a>
        </div>
    </div>
</ng-template>

<ng-template #customTemplate>
    <ng-content></ng-content>
</ng-template>

<ng-template let-field = "field" #fieldRender>
    <div *ngIf="field.isDisplayNameVisible" class="col-6 text-semibold form-field">
        {{field.displayName}}
        <span class="field-separator">:</span>
    </div>
    <div class="col-6">
        <!-- <span *ngIf="field.isDisplayNameVisible">: </span> -->
        <span [ngSwitch]="field.type">
            <ng-template [ngSwitchCase]="controlList.label">
               <span [innerHTML] = field.value></span> 
            </ng-template>
            <ng-template [ngSwitchCase]="controlList.link">
                <a href="javascript:;" (click)="onNavigate(field.actionLink,field.redirectionUrl,field.value)">
                    {{field.value}} </a>
            </ng-template>
            <ng-template [ngSwitchCase]="controlList.date">
                {{field.value | customDate}}
            </ng-template>
            <ng-template [ngSwitchCase]="controlList.mobile">
                {{field.value | phone}}
            </ng-template>
            <ng-template ngSwitchDefault>
                {{field.value}}
            </ng-template>
        </span>
    </div>
    
</ng-template>
<ng-template #messageTemplate>
    <div class="submit-btn pr-3 pl-3 w-100 text-center pt-5 d-flex flex-wrap justify-content-center">
        <p class="w-100 message-font">If you would like to update your name, preferred name or date of birth, please click the "Update" button below.</p>
        <div class="col d-md-flex justify-content-center mt-3 mb-4">
            <div class="text-center mb-3 mb-md-0">
                <a href="javascript:;" class="common-blue-btn rounded-btn" (click) = "editPopup()">
                    <i class="pi pi-pencil"></i>
                    <span class="p-button-label">Update</span>
                </a>
            </div>
        </div>
    </div>
</ng-template> 
  
 
 <ng-template #demographicEditModal let-modal >
    <div class="modal-header">
        <div>
            <span class="navbar-brand py-md-0" span>Edit My Info</span>
        </div>
        <div class="float-right">
            <i class="fa fa-times" (click)="modal.dismiss('Cross click')" aria-hidden="true"></i>
        </div>
    </div>
    <div class="modal-body">
        <form  [formGroup]="demographicForm">
            <div class="card card-form mt-1 mb-1" >
                <div class="card-body" >
                  
                  
                    <div class="form-row align-items-center form-default">
                        <div class="form-group col-6 TextFont">
                            <label>First Name</label>
                            <span class="text-danger">*</span>
                        </div>
                         <div class="form-group col-6">
                            <input type="text" class="form-control" formControlName="firstName"
                           />
                           <span class="error-text" *ngIf="demographicForm.controls.firstName.invalid && demographicFormSaved"> 
                            <span  *ngIf="demographicFormSaved && demographicForm.controls.firstName.errors.required">
                                Please enter First Name
                            </span>
                            <span  *ngIf="demographicFormSaved && demographicForm.controls.firstName.errors.pattern">
                                Invalid First Name
                            </span>
                            <span  *ngIf="demographicFormSaved && demographicForm.controls.firstName.errors.maxlength">
                                you can enter only 100 characters
                            </span>
                         </span> 
                        </div>
                        
                    </div>
                    
                    <div class="form-row align-items-center form-default">
                        <div class="form-group col-6 TextFont">
                            <label>Last Name</label>
                            <span class="text-danger">*</span>
                        </div>
                        <div class="form-group col-6">
                            <input type="text" class="form-control" formControlName="lastName" />
                            <span class="error-text" *ngIf="demographicForm.controls.lastName.invalid && demographicFormSaved"> 
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.lastName.errors.required">
                                    Please enter Last Name
                                </span>
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.lastName.errors.pattern">
                                    Invalid Last Name
                                </span>
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.lastName.errors.maxlength">
                                    you can enter only 100 characters
                                </span>
                             </span> 
                        </div>
                       
                    </div>
                    <div class="form-row align-items-center form-default">
                        <div class="form-group col-6 TextFont">
                            <label>Preferred Name</label>
                        </div>
                        <div class="form-group col-6" >
                            <input type="text" class="form-control" formControlName="preferredName"/>
                            <span class="error-text" *ngIf="demographicForm.controls.preferredName.invalid && demographicFormSaved"> 
                               
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.preferredName.errors.pattern">
                                    Invalid Preferred Name
                                </span>
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.preferredName.errors.maxlength">
                                    you can enter only 100 characters
                                </span>
                             </span> 
                        </div>
                        
                    </div>
                    <div class="form-row align-items-center form-default">
                        <div class="form-group col-6 TextFont">
                            <label>Date of Birth</label>
                            <span class="text-danger">*</span>
                        </div>
                         <div class="form-group col-6">
                            <input type="text" class="form-control"  formControlName="dateOfBirth" placeholder="MM/DD/YYYY" />
                            <span class="error-text" *ngIf="demographicForm.controls.dateOfBirth.invalid && demographicFormSaved"> 
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.dateOfBirth.errors.required">
                                    Please enter Date of Birth
                                </span>
                                <span  *ngIf="demographicFormSaved && demographicForm.controls.dateOfBirth.errors.dateVaidator">
                                    Please enter valid date
                                </span>
                             </span> 
                        </div>
                       
                    </div>
                   
                </div>
            </div>
            <div class="blue-btn float-right-imp popUpMargin">  
                <div class="justify-content-center">
                    <a href="javascript:;"   [ngClass]="isbtnDisable ? 'disable-rounded-btn ml-1 mr-1':'rounded-btn ml-1 mr-1'" (click)="saveForm()"  >
                        Update
                    </a>
                    <a href="javascript:;"class="rounded-btn ml-1 mr-1" (click)="cancelForm()">
                        Cancel
                    </a>
                </div>
            </div>
        </form> 
    </div> 
</ng-template>  



