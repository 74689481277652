import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Injectable, Inject, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import * as _ from 'lodash';

import { AuthenticationService , UserService} from '@colibrium/auth-lib';
import { Constant } from '@shared/utilities/constant';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

    constructor(@Inject(Injector) private injector: Injector) {
    }

    private get authService(): AuthenticationService {
        return this.injector.get(AuthenticationService);
    }

    private get userService(): UserService {
        return this.injector.get(UserService);
    }

    /**
     * overridden base implementation
     * @param request -
     * @param next -
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isUserLoggedIn) {
            const user = this.userService.userInformation;
            const loginType = this.userService.loginType;
            return this.authService.getToken()
                .pipe(
                    mergeMap(
                        (token) => {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${token}`,
                                    LoginType: loginType,
                                    UserName: _.get(user, 'userIdentifier', ''),
                                    RoleId: _.get(user, 'activeRole.id', ''),
                                    CacheControl:  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0', 
                                    Pragma: 'no-cache',
                                    Expires: '0',
                                    SourceApp: Constant.AIProwSource
                                }
                            });
                            return next.handle(request);
                        }
                    )
                );
        } else {
            
            if(request.url.indexOf('/api/MemberRegistration/') !== -1 && !request.headers.has('UserName')){
                request = request.clone({
                    withCredentials: true,
                    setHeaders: {
                        UserName: 'AnonUser',
                        SourceApp: Constant.AIProwSource
                    }
                  });
            }
            else{
            request = request.clone({
                withCredentials: true
              });
            }
            return next.handle(request);
        }
    }

}
