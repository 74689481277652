<ng-template [ngIf]="!isExpanded">
    <ng-container *ngTemplateOutlet="widgetModel"></ng-container>
</ng-template>

<ng-template #widgetModel>
    <div class="widget" [ngClass]="{'bg-white shadow-sm p-1 border-radius-2': !isFromDashboard || isExpanded, 'my-5': !isFromDashboard && !isExpanded}">
        <app-widget-title [titleConfig]="titleConfig" [isExpanded] = "isExpanded" (onExpand)="expand($event)" (onRefresh)="onRefresh()">
        </app-widget-title>
        <ngx-spinner [name]="spinnerName" [fullScreen]="false" type="square-jelly-box" size="small"></ngx-spinner>
        <div class="content">
        <div class="clearfix">
            <span class="float-left ng-star-inserted">
                <p [innerHTML]="messages"></p>
            </span>
        </div>
        <div class="form-group row upload-data-file-container application-form-container">
            <div class="col-sm-6">
                <p-dropdown [options]="uploadDataDropDown" [(ngModel)]="selectedUploadDataDropDown" optionLabel="name" >
                   <ng-template let-UploadDataDropDown pTemplate="item">
                    <div >
                        <span class="imgwrp" >
                        <img src="{{UploadDataDropDown.value.image}}"  /></span>
                        <div>{{UploadDataDropDown.value.name}}</div>
                    </div>
                </ng-template>
                </p-dropdown>
        </div>
        </div>
        <ngx-dropzone (change)="onSelect($event)"  class="mb-3 text-center"
         class="upload-doc-container" >
                <ngx-dropzone-label  >
                    <p>Drag & Drop  the files here<br/>or</p>
                    <!-- <input type="button"  value="Browse" > -->
                    <button>Browse...</button>
                </ngx-dropzone-label>
        </ngx-dropzone>
        <hr  class="border-dotted-t">
        <div *ngIf="storageListConfiguration" class="col-36">
            <app-list-widget #innerListwidget [titleConfig]="storageListConfiguration.titleConfiguration" [isFromDashboard]="false"
                [input]="storageListConfiguration.configuration" [storageIcon] = "storageIcon" (onRowAction)="onRowAction($event)">
            </app-list-widget>
        </div>
       </div>
    </div>
 </ng-template>