import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from '@app/core/services/loader.service';
import { Constant } from '@shared/utilities/constant';
import { LoaderStatus } from '@app/core/utilities/loader-status.enum';

@Component({
  selector: 'loader',
  templateUrl: './loader.html',
  styleUrls: ['./loader.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(Constant.loader.progressElement, { static: true }) progressorElement: any;

  public loaderStatus: LoaderStatus;
  public loaderElement: any;
  public show: boolean;

  private progress: any;
  private width = 1;
  private loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService) {
    this.show = false;
    this.loaderSubscription = this.loaderService.loaderStatus.subscribe((val: LoaderStatus) => {
      this.loaderStatus = val;
      this.onLoaderStatusChangeHandler();
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.loaderElement = this.progressorElement;
  }

  ngOnDestroy() {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  /**
   * loader status change handler
   */
  private onLoaderStatusChangeHandler(): void {
    if (this.loaderElement) {
      if (!this.progress && this.loaderStatus === LoaderStatus.Start) {
        this.width = 10;
        const handler: Function = this.frame.bind(this);
        this.progress = setInterval(handler, 10);
      }

    }
  }

  /**
   * call back for set interval
   */
  private frame() {
    if (this.loaderElement) {

      if (this.loaderStatus === LoaderStatus.Start) {
        this.show = true;
        this.width = this.width + 10;
        if (this.width > 90) {
          this.width = 90;
        }
        this.loaderElement.nativeElement.style.width = `${this.width.toString()}%`;
      } else if (this.loaderStatus === LoaderStatus.Stop || this.loaderStatus === LoaderStatus.Complete) {
        this.loaderElement.nativeElement.style.width = Constant.loader.fullPercentile;
        clearInterval(this.progress);
        this.progress = null;
        this.loaderService.resetLoaderModel();
        setTimeout(() => {
          this.show = false;
        }, 100);
      }

    }
  }

}
