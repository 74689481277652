import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public leftExpand = true;
  public leftPanel = false;
  constructor(
    private sharedService: SharedService
  ) {
    this.sharedService.currentSlideState.subscribe(data => {
      this.leftExpand = data;
    });
    this.sharedService.leftPanelState.subscribe(data => {
      this.leftExpand = data;
      this.leftPanel = !data;
    });
  }

  ngOnInit() {
  }


}
