import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalErrorService } from '@core/services/global-error.service';
import { LoggerModel } from '@core/models/logger.model';
import { ErrorModel } from '@core/models/error.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.html'
})
export class ErrorComponent {

  public referenceNumber: string;
  public errorModel: ErrorModel;

  private logSubscription: Subscription;

  constructor(private service: GlobalErrorService) {
    this.errorModel = new ErrorModel();
  }

  ngOnInit() {
    this.errorModel = this.service.getErrorInfo();
    if (this.errorModel.errorLogResponse)
    {
      this.errorModel.errorLogResponse.subscribe((res: LoggerModel) => {
        this.referenceNumber = res.referenceNumber;
      });
    }
  }

  ngOnDestroy() {
    this.service.clearErrorState();
    if (this.logSubscription) {
      this.logSubscription.unsubscribe();
    }
  }

}
