import { Component, OnInit, Input } from '@angular/core';

import Parser from 'morph-expressions';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';

import { WorkflowWidget } from '@app/dynamic-widgets/models/workflow-widget.model';
import { Guid } from '@shared/utilities/guid';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { DynamicWidgetsConstant } from '@app/dynamic-widgets/dynamic-widgets.constant';
import { WorkflowWidgetConfiguration } from '@app/dynamic-widgets/models/workflow-widget-configuration.model';
import { WorkflowProcess } from '@app/dynamic-widgets/models/workflow-process.model';
import { WidgetAction } from '@shared/models/widget-action.model';

@Component({
  selector: 'app-workflow-widget',
  templateUrl: './workflow-widget.component.html',
  styleUrls: ['./workflow-widget.component.scss']
})
export class WorkflowWidgetComponent implements OnInit {

  @Input() input: string;
  
  public configurations: WorkflowWidget;
  public spinnerName: string = Guid.randomId;
  public errorMessage: string;
  public data: any;

  public processes: WorkflowProcess[];
  public currentPage: number = 1;
  public pages: number[] = [];

  private parser = new Parser();
  
  constructor(private service: DynamicWidgetsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    if (this.input) {
      this.WidgetConfigMapper(this.input); 
      this.getData();
    }
  }

  /**
   * on Refresh
   * */
  public onRefresh(): void {
    this.getData();
  }

  /**
   * page navigation
   * @param page 
   */
  public gotoPage(page: number)
  {
    this.currentPage = page;
    this.setProcesses();
  }

  /**
   * Event trigger for actions
   * @param action 
   */
  public onAction(action: WidgetAction)
  { 
    let data = this.data;
    if(action.data)
    {
        let actionData = JSON.stringify(action.data);
        let keys = actionData.match(/#(.)*?#/gm); // Keys are prefixed and suffixed with # to replace it with values in the object
        if(keys)
        {
          keys.forEach(key => {
            const value = _.get(this.data, key.replace(/#/g, ''), null);
            actionData = actionData.replace(key, value);
          })
        }
        data = JSON.parse(actionData);
    }
    this.service.post(action.url, data)
    .subscribe(
      (result: boolean) => {
      if(result)
      {
        this.getData();
      }
      else
      {
        this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToUpdate;
      }
    },
      (error) => {
        this.errorMessage = `${DynamicWidgetsConstant.demographicErrorMessages.failedToUpdate}: ${error}`;
      })
  }


  /**
   * Sets pages object
   */
  private setPages()
  {
    if(this.configurations && this.configurations.processes.length > this.configurations.displayCount)
    {
      const lastPage = Math.ceil(this.configurations.processes.length / this.configurations.displayCount);
      this.pages = [ ...Array(lastPage).keys() ].map( i => i+1);
    }
  }

  /**
   * Sets processes object
   */
  private setProcesses()
  {
    const start = this.currentPage == 1 ? 0: ((this.currentPage - 1)* this.configurations.displayCount);
    let end = start + this.configurations.displayCount;
    end = end > this.configurations.processes.length ? this.configurations.processes.length : end;
    this.processes = this.configurations.processes.slice(start, end);
  }

  /**
   * demographic widget mapper
   * @param value -
   */

  private WidgetConfigMapper(value: string) {
    const inputRes: WorkflowWidgetConfiguration = JSON.parse(value);
    if(inputRes)
    {
      this.configurations = {
        dataSourceUrl: inputRes.dataSourceUrl,
        processes: inputRes.processes ? JSON.parse(inputRes.processes) : [],
        displayCount: parseInt(inputRes.displayCount),
        containerCss: inputRes.containerCss,
        containerStyles: inputRes.containerStyles ? JSON.parse(inputRes.containerStyles) : null,
        processCss: inputRes.processCss,
        processStyles: inputRes.processStyles ? JSON.parse(inputRes.processStyles) : null
      };      
    }
  }

  /**
   * Get widget data making api call
   */
  private getData()
  {
    this.spinner.show(this.spinnerName);
    this.service.getDemographicActionData(this.configurations.dataSourceUrl)
    .pipe(finalize(() => {
      this.spinner.hide(this.spinnerName);
    }))
    .subscribe((demographicData) => {
      this.bindData(demographicData);
    },
      (error) => {
        this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToFetchData;
      }
    );
  }

  /**
   * Binds widget data
   * @param data 
   */
  private bindData(data: any)
  {
    if (!data) {
      this.errorMessage = DynamicWidgetsConstant.demographicErrorMessages.failedToFetchData;
    } else {
      this.data = data;
      this.configurations.processes.forEach(process => {
        process.fields.forEach(field => {
          field.value = _.get(data, field.fieldName, null);
        });
        process.headerValue = _.get(data, process.headerField, null);
      });    
      this.setPages();
      this.setProcesses();   
      this.setActionEnableStatus();
    }    
  }

  /**
   * To set actions enable status
   */
  private setActionEnableStatus()
  {
    if(this.configurations.processes.length > 0 && this.data)
    {
     this.configurations.processes.forEach(process =>
      {
        if(process.action && process.action.actions)
        {
          process.action.enable = !process.action.condition || this.isTrue(this.parser.parseAndEval(process.action.condition, this.data));          
          
          process.action.actions.forEach(
            action => {
              action.enable = !action.condition || this.isTrue(this.parser.parseAndEval(action.condition, this.data));                 
          });
        }        
      });
    }
  }

  /**
   * Tests the given string yields to true
   * @param value 
   */
  private isTrue(value: any) : boolean
  {
    value = value || 'false';
    return value == 'true' || value == "1"; 
  }  

}
