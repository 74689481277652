import { PaginatorModel } from './models/paginator.model';
import { LazyLoadOptions } from './models/lazy-load-options.model';

export class ControlConstant {
    public static readonly asc = 'Asc';
    public static readonly desc = 'Desc';
    public static readonly format = 'mm/dd/yy';
    public static readonly placeholderText = 'Enter Date';

    public static readonly ListCardConstants = class {
        public static readonly asc = 'asc';
        public static readonly desc = 'desc';
        public static get defaultPaginator(): PaginatorModel { return { pageNumber: 1, pageSize: 5 } };
        public static get defaultLazyLoadOptions(): LazyLoadOptions { return { pageNumber: 1, pageSize: 5, sortColumns: [] } };
        public static get defaultPaginationSizes(): number[] { return [5, 10, 20, 50] }
    };
}
