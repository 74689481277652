import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute,NavigationEnd,Router } from '@angular/router';
import { Constant } from '@shared/utilities/constant';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public mySubscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {

  }
  loginbtnclick() {
    
    this.router.navigateByUrl('/login');
  }
  
  registerbtnclick() {
    
    this.router.navigateByUrl('/register');
  }

  ngOnInit(): void {
    window.sessionStorage.clear();
    sessionStorage.clear();

    // we want to keep session data so we can track user activity
    const sessionInfo = localStorage.getItem(Constant.StorageSession);
    localStorage.clear();
    localStorage.setItem(Constant.StorageSession, sessionInfo);

    this.deleteAllCookies();
  }
  

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
 
}
