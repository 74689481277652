
//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { AbpConfigModel, AbpCalendarConfigModel } from '@colibrium/abp-suite';

//#endregion functional/model imports

const calConfig: AbpCalendarConfigModel = {
  weekDisplayNames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  monthDisplayNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
};

export const controlConfig: AbpConfigModel = {

  calendarConfig: calConfig,
  navConfig: {
    collapseStateIcon: 'fa fa-caret-right',
    expandStateIcon: 'fa fa-caret-down'
  },
  formControlConfig: {
    dateConfig: {
      calendarConfig: calConfig,
      datepickerIcon: 'pi pi-calendar'
    },
    textConfig: {
      hasShowIcon: true,
      onMaskIcon: 'fa fa-eye',
      onShowIcon: 'fa fa-eye-slash'
    },
    validationConfig: {
      errorControlClass: 'control-error',
      errorTextClass: 'red-text',
      successControlClass: 'control-valid',
      showErrorOnSubmitOnly: true
    },
    labelConfig: {
      markRequired: false,
      requiredtextClass: 'text-danger',
      requiredText: '*'
    }
  }
}
