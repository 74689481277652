import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaginatorModel } from '@shared/controls/models/paginator.model';
import { ControlConstant } from '@shared/controls/control.constant';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() totalRecords: number;
  @Input() displayRecordsCount: number;
  @Output() paginationChange = new EventEmitter<PaginatorModel>();
  @Input() paginationInfo: PaginatorModel;
  public itemsPerPage: number[] = ControlConstant.ListCardConstants.defaultPaginationSizes;

  public get totalRecordsCount() {
    return this.displayRecordsCount || this.totalRecords;
  }

  public get paginationReport() {
    const start = ((this.paginationInfo.pageNumber - 1) * this.paginationInfo.pageSize) || 1;
    let end = (this.paginationInfo.pageNumber) * this.paginationInfo.pageSize;

    if (end > this.totalRecords) {
      end = this.totalRecords;
    }
    return `${start}-${end} of ${this.totalRecordsCount}`;
  }

  constructor() {
    this.paginationInfo = ControlConstant.ListCardConstants.defaultPaginator;
  }

  ngOnInit() {
  }

  /**
   * Page size change event
   * @param pageSize -
   */
  public onPageSizeChange(pageSize: number) {
    this.paginationInfo.pageSize = + pageSize;
    this.paginationChange.emit(this.paginationInfo);
  }

  /**
   * on page number change
   * @param val -
   */
  public onPageNumberChange(pageNumber: number) {
    this.paginationInfo.pageNumber = + pageNumber;
    this.paginationChange.emit(this.paginationInfo);
  }

}
