import { Component, Input, Output, EventEmitter, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';

import { TableColumns } from '@shared/controls/models/table-columns.model';
import { LazyLoadOptions } from '@shared/controls/models/lazy-load-options.model';
import { ControlConstant } from '@shared/controls/control.constant';;
import { Table } from 'primeng/table';
import { ActionLink } from '@shared/enums/action-link.enum';
import { Action } from '@shared/controls/models/action.model';
import { ActionType } from '@shared/enums/action-type.enum';
import { Constant } from '@shared/utilities/constant';
import * as _ from 'lodash';
import { ControlType } from '@app/dynamic-widgets/enums/control-type.enum';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit {
  @Input() isLazyLoad: boolean = true;
  @Input() data: any;
  @Input() pageSize = 5;
  @Input() pageNumber: number = 1;
  @Input() columns: TableColumns[] = [];
  @Input() pageLinks: number = 3;
  @Input() showPaginator: boolean = true;
  @Input() totalCount: number = 0;
  @Input() checkBoxSelection: boolean = false;
  @Input() searchFilter: boolean = true;
  @Input() radioSelection: boolean = false;
  @Input() rowsPerPageOptions: number[] = [5, 10, 20, 50];
  @Input() rowActions: Action[];
  @Input() tableActions: Action[];
  @Input() set tableReset(val: boolean) {
    if (val) {
      this.tableRef._rows = this.pageSize;
      this.tableRef.reset();
    }
  }
  @Input() noRecordsMessage: string;
  @Input() hideHeader: boolean = false;
  @Input() imageStyle: boolean;
  @Input() resourceWidgetStyle: boolean;
  @Input() isCustomNavigation: boolean;
  @Output() onRowAction = new EventEmitter();
  @Output() onNavigateAction = new EventEmitter();
  @Output() onTableAction = new EventEmitter();
  @Output() loadLazy = new EventEmitter();
  @ViewChild('tabset') tabset;
   
  public get isRowsSelected(): boolean {
    return this.selectedItems.length > 0;
  }

  public actionList = ActionType;
  public controlList = ControlType;

  public selectedItems: any[] = [];

  public dropdownOptions: { [key: string]: any[] } = { '': [] };

  /**
   * This is added to reset the table manually as there is no communication between component to primeng component
   * use tableReset input property to reset the table
   */
  @ViewChild(Table, { static: true })
  private tableRef: Table;

  public actionTypeList = ActionType;
  public actionsLinkList = ActionLink;
  public get totalColspan(): number {
    return (this.rowActions && this.rowActions.length > 0) ? this.columns.length + 1 : this.columns.length;
  }

  public get canShowPaginator(): boolean {
    return this.showPaginator && this.data && this.data.length > 0;
  }

  constructor() {

  }

  ngOnInit() {
  }


  /**
   * creates event data and emits lazy load event
   * @param event - event data
   */
  public loadLazyData(event: any) {
    const pageNumber: number = (event.first / event.rows) + 1;
    const lazyLoadOptions: LazyLoadOptions = {
      pageNumber: pageNumber,
      pageSize: event.rows,
      sortColumns: []
    };
    if (event.multiSortMeta) {
      lazyLoadOptions.sortColumns = event.multiSortMeta
        .map((data) => {
          return { property: data.field, sortType: data.order > 0 ? ControlConstant.asc : ControlConstant.desc };
        });
    }
    this.loadLazy.emit(lazyLoadOptions);
  }

  public getDropdownOptions(field: string) {
    if (this.dropdownOptions[field]) {
      return this.dropdownOptions[field]
    }
  }

  public getDropdownOptionsEvent(field: string) {
    if (this.data) {
      this.columns.forEach(eachColumn => {
        this.dropdownOptions[eachColumn.field] = [... new Set(this.data.map(e => e[eachColumn.field]))].map(e => { return { "label": e, "value": e } })
      })
    }
  }



  /**
   * emits callback to inform parent to perform action.
   * @param action - type of action
   * @param rowData - data
   */
  public emitRowEvent(action: Action, rowData: any) {
    if (action.type == this.actionList.event) {
      this.onRowAction.emit(
        {
          eventName: action.action,
          data: rowData
        });
    }
    else if (action.type == this.actionList.link) {
      this.onActionNavigate(action.actionLink, action.redirectionUrl, rowData);
    }
  }

  /**
   * emits callback to inform parent to perform action.
   * @param action - type of action
   * @param rowData - data
   */
  public emitTableEvent(action: Action) {
    switch (action.type){
      case this.actionList.event : 
        if (this.selectedItems.length > 0) {
          this.onTableAction.emit(
            {
              eventName: action.action,
              data: this.selectedItems
            });
        }
        else {
          this.onTableAction.emit(
            {
              eventName: action.action
            });
        }
        break;
      case this.actionList.link :
        this.onActionNavigate(action.actionLink, action.redirectionUrl, null);
        break;
      case this.actionList.customEvent :
        this.onTableAction.emit(
          {
            eventName: action.action
          });
        break;
    }
  }

/**
 * Navigate Url
 * @param redirectionUrl - string
 * @param isStatic - boolean
 */

  public onNavigate(redirectionUrl: string, isStatic: boolean, id?: string, rowData?: any) {
    window.event.stopPropagation();
    if(this.isCustomNavigation)
    {
      this.onNavigateAction.emit(
        {
          data: rowData
        });
    }
    else{
    if(redirectionUrl != null){
    redirectionUrl = this.PopulateUrlParams(redirectionUrl, rowData)
    if (isStatic) {
      const url = id ? `${redirectionUrl}/${id}` : redirectionUrl;
      return window.open(url, "_blank");
    }
    else {
      const url = id ? `${redirectionUrl}/${id}` : redirectionUrl;
      return window.open(url);
    }
    }

    }
  }

  /**
 * Navigate Url
 * @param actionLink - number
 * @param redirectionUrl - string
 * @param id - string
 */

  public onActionNavigate(actionLink: number, redirectionUrl: string, id?: string, rowData?: any) {
    let blankUrl: string;
    let url: string;
    redirectionUrl = this.PopulateUrlParams(redirectionUrl, rowData)
    switch (actionLink) {
      case ActionLink.StaticAction:
        blankUrl = "_blank";
        url = redirectionUrl;
        break;
      case ActionLink.StaticActionWithId:
        blankUrl = "_blank";
        url = `${redirectionUrl}/${id}`;
        break;
      case ActionLink.RouteAction:
        url = redirectionUrl;
        break;
      case ActionLink.RouteActionWithId:
        url = `${redirectionUrl}/${id}`;
        break;
      case ActionLink.RouteImage:
        blankUrl = "_blank";
        url = `${id}`;
        break;
    }
    return window.open(url, blankUrl);
  }

  /**
   * Determines initial letter of a string
   * @param stringContent the string for which initial letter to be displayed
   */
  public GetInitial(stringContent: string): string {
    if (!stringContent || stringContent === "")
      return "NA";
    else
      return stringContent[0];
  }

  /**
   * Generate a hashcode for given string
   * @param s string input to hash
   */
  private hashCode(s: string) {
    var h = 0, l = s.length, i = 0;
    if (l > 0)
      while (i < l)
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
  };

  /**
   * Generates random color
   */
  public GetRandomColor(data: string): string {
    const index = this.hashCode(data);
    const red = ((((index + 100) * 13))%255 + 255) % 255;
    const green = ((((index + 100) * 17))%255 + 255) % 255;
    const blue = ((((index + 100) * 29))%255 + 255) % 255;
    const color = `rgb( ${red}, ${green}, ${blue})`;
    return color;
  }

  /**
   * Gets css mapped values
   * @param mapKey
   * @param mapString
   */
  public GetCssColor(mapKey: string, mapString: string) {
    var hash = JSON.parse(mapString);
    return hash[mapKey];
  }

  /**
   * Populates url params
   * @param redirectionUrl 
   * @returns url params 
   */
  public PopulateUrlParams(redirectionUrl: string, rowData?: any): string {
    const params: RegExpMatchArray = redirectionUrl.match(Constant.urlParamRegex);
    if (params) {
      params.forEach(param => {
        if (param) {
          const p = param.slice(1, (param.length - 1));
          const value = _.get(rowData, p, null);
          redirectionUrl = redirectionUrl.replace(param, value);
        }
      });
    }
    return redirectionUrl;
  }

}
