<ng-container *ngIf="errorModel">
    <div [ngSwitch]="errorModel.statusCode" class="middle-box text-center animated fadeInDown">
        <div *ngSwitchCase="400">
            <h1>400</h1>
            <h3 class="font-bold">Bad Request</h3>
            <div class="error-desc">
                This page isn't working at the moment. Please contact the administrator.
            </div>
        </div>
        <div *ngSwitchCase="401">
            <h1>401</h1>
            <h3 class="font-bold">UnAuthorized</h3>
            <div class="error-desc">
                Access is denied due to invalid credentials. Please contact the administrator.
            </div>
        </div>
        <div *ngSwitchCase="403">
            <h1>403</h1>
            <h3 class="font-bold">Forbidden</h3>
            <div class="error-desc">
                You don't have permission to access this page. Please contact the administrator.
            </div>
        </div>
        <div *ngSwitchCase="404">
            <h1>404</h1>
            <h3 class="font-bold">API not Found</h3>
            <div class="error-desc">
                Requested URL was not found. Please contact the administrator.
            </div>
        </div>
        <div *ngSwitchCase="500">
            <h1>500</h1>
            <h3 class="font-bold">Internal Server Error</h3>
            <div class="error-desc">
                This page isn't working as the server encountered an internal error or misconfiguration. Please contact the administrator.
            </div>
        </div>
        <div *ngSwitchDefault>
            <h1>Error</h1>
            <h3 class="font-bold">Something went wrong</h3>
            <div class="error-desc">
                Please contact the administrator.
            </div>
        </div>
    </div>
</ng-container>