<div class="row TitleFont">
    <span *ngFor="let quickLinkWidget of quickLinkWidgets" class="col-lg-6">
        <div class="mb-4">
            <div class="bottom-three-box-container">
                <a href="javascript:;" (click)="navigateUrl(quickLinkWidget)" class="home-quick-link-btn shadow-sm" >
                    <div class="left-box"><img src={{quickLinkWidget.image}} /> </div>
                    <div class="right-box TitleFont">{{quickLinkWidget.title}} </div>
                </a>
            </div>
        </div>
    </span>
</div>