<div [ngClass]="configurations.containerCss" [ngStyle]="configurations.containerStyles">
    <ng-container *ngIf="errorMessage; then error; else noError">
    </ng-container>
    <ng-template #error>
        <div class="api-error">
            <h2>Oops!</h2>
            {{errorMessage}}
        </div>
    </ng-template>
    <ng-template #noError>
        <ngx-spinner [name]="spinnerName" [fullScreen]="false" type="square-jelly-box" size="small"></ngx-spinner>
    <div class="row pad-20">
        <div class="col carousel" [ngClass]="configurations.processCss" [ngStyle]="configurations.processStyles"  *ngFor="let process of processes">
            <div class="card-form">                
                <div class="card-body">
                    <div class="title">
                        <h1>{{process.order}}</h1>
                        <span>{{process.title}}</span>
                    </div>
                    <hr>
                    <div class="text-center font-weight-bold header">
                        {{process.headerValue}}
                    </div>                    
                    <div class="description">
                        <div class="actions pad-0" *ngIf="process.action && process.action.actions.length > 0">                       
                            <nav class="navbar navbar-expand pad-0">
                                <ul class="navbar-nav mr-auto">                               
                                    <li class="nav-item dropdown" [class.disabled]="!process.action.enable">
                                        <a class="nav-link dropdown-toggle pad-0" [class.disabled]="!process.action.enable"  href="#" id="actionsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Actions
                                        </a>
                                        <div class="dropdown-menu pad-0" aria-labelledby="actionsDropdown">
                                            <ng-container *ngFor="let action of process.action.actions | orderBy: 'order'; let i = index">
                                                <button type="button" class="btn" [ngClass]="action.css" [ngStyle]="action.styles" (click)="onAction(action)" [disabled]="!action.enable">{{action.actionName}}</button>
                                            </ng-container>
                                          </div>
                                    </li>
                                </ul>
                            </nav>                       
                        </div>
                        <p>{{process.description}}</p>
                    </div>
                    <hr>
                    <div *ngIf="process.fields.length > 0">
                        <div *ngFor="let filed of process.fields | orderBy: 'order';">
                            <span>
                                <label class="text-left col-8 pad-0">{{filed.displayName}}:</label>
                            </span>
                            <span>
                                <label class="text-right font-weight-bold col-4 pad-0">{{filed.value}}</label>
                            </span>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="pages.length > 0">
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
                <li class="page-item">
                    <a class="page-link" aria-label="Previous" [class.disabled]="currentPage == 1" (click)="currentPage != 1 && gotoPage(currentPage - 1)">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                <li class="page-item" *ngFor="let page of pages">
                    <a class="page-link" [class.active]="currentPage == page" (click)="gotoPage(page)">{{page}}</a>
                </li>              
                <li class="page-item">
                    <a class="page-link" aria-label="Next" [class.disabled]="currentPage == pages.length" (click)="currentPage < pages.length && gotoPage(currentPage + 1)">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
          </nav>
    </div>
    </ng-template>
</div>