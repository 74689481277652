
<ng-template [ngIf]="!isExpanded">
    <ng-container *ngTemplateOutlet="widgetModel"></ng-container>
</ng-template>

<ng-template #widgetModel>
    <div class="widget" [ngClass]="{'bg-white shadow-sm p-4 border-radius-5': !isFromDashboard || isExpanded }">
        <app-widget-title [titleConfig]="titleConfig" [isExpanded] = "isExpanded" (onExpand)="expand($event)" (onExport)="export($event)" (onRefresh)="onRefresh()">
        </app-widget-title>
        <ngx-spinner [name]="spinnerName" [fullScreen]="false" type="square-jelly-box" size="small"></ngx-spinner>
        <div class="content">
            <div *ngIf="description.isDescriptionVisible" class="{{description.descriptionContainerCss}}">
                <span class="{{description.descriptionCss}}">{{description.text}}</span>
            </div>
            <div class="clearfix">
                <span class="float-left ng-star-inserted">
                    <p [innerHTML]="messages"></p>
                </span>
            </div>
            <ng-template [ngIf]="cols" >
            <button hidden (click) = "doNoting()"></button>
                <app-table 
                [columns]="cols" 
                [data]="data" 
                [tableReset]="isReset" 
                [pageSize]="paginationModel.pageSize" 
                [totalCount]="count" 
                [showPaginator]="showPaginator"
                [hideHeader]="hideHeader"
                [imageStyle]="imageStyle"
                [resourceWidgetStyle]="resourceWidgetStyle"
                [noRecordsMessage]="noRecordsMessage"
                (loadLazy)="onLazyLoad($event)"
                [rowActions]="rowActions"
                [tableActions]="tableActions"
                [checkBoxSelection] = "checkBoxSelection"
                [radioSelection] = "radioSelection"
                [searchFilter] = "searchFilter"
                (onRowAction)="onRowAction.emit($event)"
                (onTableAction)="onTableAction.emit($event)"
                (onNavigateAction)="onNavigateAction.emit($event)"
                [isCustomNavigation]="isCustomNavigation">
                </app-table>
            </ng-template>
        </div>
    </div>
</ng-template>