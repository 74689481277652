import { HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { AppConfig } from '@shared/models/app-config.model';
import { Constants } from '@shared/services/constants';
import { Constant } from '@shared/utilities/constant';
import { MenuItem, MessageService } from 'primeng/api';
import { PhonePipe } from '@shared/pipes/phone.pipe';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { constants } from 'buffer';
import { ConfirmedValidator } from './form-validators';
import {NgbCalendar, NgbDateAdapter,  NgbDateParserFormatter,  NgbDateStruct,} from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from 'ng2-charts';
import { values } from 'lodash';
import { getFullYear } from 'ngx-bootstrap/chronos';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from '@shared/utilities/guid';
import { MemberAddress, MemberContact, MemberEmail, MemberPhone, MemberSignUpModel } from '@shared/models/member-sign-up.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { config } from 'process';





@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {

    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month.toString().padStart(2,'0') + this.DELIMITER + date.day.toString().padStart(2,'0') + this.DELIMITER + date.year
      : '';
  }  
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})

export class SignUpComponent implements OnInit {
  group: FormGroup;
  items: MenuItem[];
  activeIndex = 0;
  form1 = true;
  form2 = false;
  registrationGroup: FormGroup;
  public rcSubmitted = false;
  public formSubmitted = false;
  public showPopup = false;
  public maxDate = new Date();
  public yearRange: string;
  public error: boolean;
  public states: any[];
  comparePswdError = false;
  public spinnerName: string = Guid.randomId;
  showSpinner: boolean = false;
  public ShowloginHref :boolean = false;
  toolTipEventType: string = 'hover';




  today = new Date() ;
  minYear: number = this.today.getFullYear() - 120;
  maxYear: number = this.today.getFullYear();
  maxMonth: number = this.today.getMonth() +1;
  Date: number=this.today.getDate();
  values: any = this.today.getFullYear();
  valueDate:number=this.today.getFullYear();
  validYear = true; 


  date = this.today.getDate() > 9 ? this.today.getDate() : 
   `0${this.today.getDate()}`;
  month = this.today.getMonth() > 9 ? this.today.getMonth() + 1 : 
   `${this.today.getMonth() + 1}`;
   year = this.today.getFullYear();

  currentDate=(this.year+"-"+this.month+"-"+this.date)
  
isDate(inputDate){
  var date = moment(inputDate,"MM/DD/YYYY",true);
  if(!date.isValid()){
    this.validYear = false;
    return;
  }
  else{
    this.validYear = true;
  }
  
}
  dobValidation(date: any) {
    
    let datevali=new Date(this.currentDate)    
    this.values = new Date(date);
  
    
    if (datevali < this.values || date.substr(-4) < this.minYear || date.length!= 10){
      this.validYear = false;
    }
    else
    {
      this.validYear = true;  
    }
  
}
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private widgetService: DynamicWidgetsService,
    private httpUtility: HttpUtility,
    private router: Router,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    public phonepipe: PhonePipe,
    private _http: HttpClient,
    private bpo: BreakpointObserver,
    @Inject(Constant.appConfig) private appConfig: AppConfig) { }
    selectedCategory: any = null;
    selectedCategoryes: any = null;
    readonly DELIMITER = '/';
    categories: any[] = [
      {name: 'Yes', key: 'Y'},
      {name: 'No', key: 'N'}
    ];

    categoriess: any[] = [
      {name: 'Yes', key: 'Y'},
      {name: 'No', key: 'N'}
    ];

    

  get rc() { return this.registrationGroup.controls; }
  get f() { return this.group.controls; }

  ngOnInit(): void {
    this.bpo.observe(['(max-width: 1024px)'])
      .subscribe(result => {
          if (result.matches) {
            this.toolTipEventType = 'focus';
          } else {
            this.toolTipEventType = 'hover';
          }
      }); 
    window.sessionStorage.clear();
    sessionStorage.clear();    
 

    // we want to keep session data so we can track user activity
    const sessionInfo = localStorage.getItem(Constant.StorageSession);
    localStorage.clear();
    localStorage.setItem(Constant.StorageSession, sessionInfo);

    this.deleteAllCookies();

    this.group = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$')]],
      canText: [this.categories[1], [Validators.required]],
      mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9\-\]*$"),Validators.minLength(10)]],
      phoneType: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      firstName: ['', [Validators.required, Validators.pattern("^[a-zA-Z'\\s_-]*$"),Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.pattern("^[a-zA-Z'\\s_-]*$"),Validators.maxLength(100)]],
      gender: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required,Validators.pattern("^[a-zA-Z\ \]*$")]],
      state: ['', [Validators.required]],
      zipCode: ['', [Validators.required,Validators.pattern("^[0-9\-\]*$")]],
      addCaregiver: [this.categories[1], [Validators.required]],
      caregiverFirstName: [''],
      caregiverLastName: [''],
      caregiverEmail: [''],
      caregiverMobileNo: ['']
    }
    , {
        validator: ConfirmedValidator('password', 'confirmPassword')
    });

    this.items = [
      { label: '' },
      { label: '' }
    ];
    this.registrationGroup = this.formBuilder.group({
      registrationCode: ['', [Validators.required,Validators.pattern("^[0-9a-zA-Z\-\]*$"),Validators.maxLength(10)]],
      dob: ['', [Validators.required]]
      // dob: ['', [Validators.required,Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]]
    });
    this.selectedCategory = this.categories[0];
    this.selectedCategoryes = this.categoriess[0];
    this.group.get('addCaregiver')
      .valueChanges
      .subscribe(value => {
        if (value.name === 'Yes') {
          this.group.get('caregiverFirstName').setValidators([Validators.required,Validators.pattern("^[a-zA-Z'\\s_-]*$"),Validators.maxLength(100)]);
          this.group.get('caregiverLastName').setValidators([Validators.required,Validators.pattern("^[a-zA-Z'\\s_-]*$"),Validators.maxLength(100)]);
          this.group.get('caregiverEmail').setValidators([Validators.required,Validators.email,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$')]);
          this.group.get('caregiverMobileNo').setValidators([Validators.required,Validators.pattern("^[0-9\-\]*$"),Validators.minLength(10)]);
        } else {
          this.group.get('caregiverFirstName').clearValidators();
          this.group.get('caregiverLastName').clearValidators();
          this.group.get('caregiverEmail').clearValidators();
          this.group.get('caregiverMobileNo').clearValidators();
        }
        this.group.get('caregiverFirstName').updateValueAndValidity();
        this.group.get('caregiverLastName').updateValueAndValidity();
        this.group.get('caregiverEmail').updateValueAndValidity();
        this.group.get('caregiverMobileNo').updateValueAndValidity();
      });
    let currentYear = new Date().getFullYear();
    this.yearRange = `${currentYear - 120}:${currentYear}`;
    
    this.getstates();

  }

  getstates(){
    var stateurl=`${this.appConfig.apiBaseUrl}${Constants.id4.getStatesList}`;
    this.httpUtility.get(stateurl).subscribe((result: any) => {
      this.states = result;
    })
  }


  ngOnDestroy() {
   
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  prevPage() {
    this.form1 = true;
    this.form2 = false;
    this.activeIndex = 0;
    window.location.reload();
  }
  RcodeError:boolean;
  Rcode:string;
  RcodeValidate(event:any){
    if(this.registrationGroup.value.registrationCode.length==10)
    {
      if(this.registrationGroup.value.registrationCode.includes('-'))
      {
        this.Rcode=this.registrationGroup.value.registrationCode;
        this.RcodeError=false;
      }
      else{
          this.RcodeError=true;
      }
    }
    else if(this.registrationGroup.value.registrationCode.length==9)
    {
      let RC1 =this.registrationGroup.value.registrationCode.substring(0,6);
      let RC2 =this.registrationGroup.value.registrationCode.substring(6,9);
      this.Rcode=RC1 + '-' + RC2;
      //this.registrationGroup.value.registrationCode;
      this.RcodeError=false;
    }
    else{
      this.RcodeError=true;
    }
  }
  zcode: string;
  zerror: boolean;
  ZipValidate(event:any){
    if(this.group.value.zipCode.length== 5 || this.group.value.zipCode.length== 9)
    {
      var zipFormat = /^[0-9]*$/
      if(zipFormat.test(this.group.value.zipCode))
      {
        this.zerror=false;
      }
      else{
        this.zerror=true;
      }
    }
    else{
      this.zerror=true;
    }  

     if(this.group.value.zipCode.length==9  && this.zerror==false)
    {
      let zip1=this.group.value.zipCode.substring(0,5);
      let zip2=this.group.value.zipCode.substring(5,9);
      this.zcode=zip1 + '-' + zip2;
    }
    else if(this.zerror==true)
    {
      this.zcode =  this.zcode.replace('-', '');
    }
  }

  perror: boolean;
  PhoneValidate(event:any){
    if(this.group.value.mobileNumber.length== 10)
    {
      var phoneFormat = /^[0-9]{10}$/
      if(phoneFormat.test(this.group.value.mobileNumber))
      {
        this.perror=false;
      }
      else{
        this.perror=true;
      }
    }
    else{
      this.perror=true;
    }  
  } 
  PhoneValidator(){
    if(this.group.value.mobileNumber.length== 10)
    {
      var phoneFormat = /^[0-9]{10}$/
      if(phoneFormat.test(this.group.value.mobileNumber))
      {
        this.perror=false;
      }
      else{
        this.perror=true;
      }
    }
    else{
      this.perror=true;
    }  
  } 

  MobilePhoneValidate(event:any){
    if(this.group.value.caregiverMobileNo.length== 10)
    {
      var phoneFormat = /^[0-9]{10}$/
      if(phoneFormat.test(this.group.value.caregiverMobileNo))
      {
        this.perror=false;
      }
      else{
        this.perror=true;
      }
    }
    else{
      this.perror=true;
    }  
  } 
  MobilePhoneValidator(){
    if(this.group.value.caregiverMobileNo.length== 10)
    {
      var phoneFormat = /^[0-9]{10}$/
      if(phoneFormat.test(this.group.value.caregiverMobileNo))
      {
        this.perror=false;
      }
      else{
        this.perror=true;
      }
    }
    else{
      this.perror=true;
    }  
  } 
  errorMessageForMemberReg = "";
  nextPage() {
     this.activeIndex = 1;
              this.form1 = false;
              this.form2 = true;
  }
  nextPage1() {
    if (this.registrationGroup && this.registrationGroup.valid && !this.RcodeError) {
      this.showLoader();
      let codeVal = this.registrationGroup.value.registrationCode;//"3Y81V2A1" or "3Y81V2-A1";
      codeVal = codeVal.substring(0, 6);
      var dob = this.datepipe.transform(this.values, 'MM/dd/yyyy');
      var url = `${this.appConfig.apiBaseUrl}${Constants.id4.getMemberCodeValidateData}?RegCode=${codeVal}`;
      const headers = new HttpHeaders()
        .set('userName', 'MemberReg');
      this.httpUtility.getByOptions(url, { responseType: 'text' })
        .subscribe((res: any) => {
          if (res) {
            if (res == "Registration code verified Successfully") {
              this.activeIndex = 1;
              this.form1 = false;
              this.form2 = true;
              localStorage.setItem('MemberDOB', dob);
            }
            else {
              this.errorMessageForMemberReg = res
              //this.CGError=true;
              this.showPopup = true;
            }
            
          } else {
            this.error = true;
          }
          this.hideLoader();
        })
    }
    this.rcSubmitted = true;

  }

  submitForm() {
    this.showLoader();
      var userId:string;
      userId = null;

      this.group.patchValue(
        {
          mobileNumber: this.group.value.mobileNumber.replace(/-/g, '')
        })
      this.group.patchValue(
        {
          caregiverMobileNo: this.group.value.caregiverMobileNo.replace(/-/g, '')
        })
        if(this.group.value.zipCode!==undefined)
        {
         
          this.group.patchValue(
            {
              zipCode: this.group.value.zipCode.replace(/-/g, '')
            })
        }
        
    if (this.group && this.group.valid && !this.zerror) {
           
      if(this.group.value.email!==undefined && this.group.value.caregiverEmail!=undefined)
      {
        if(this.group.value.email==this.group.value.caregiverEmail)
        {
          this.errorMessageForMemberReg = "Unique email address is required, cannot use Policy Holder email address"
          this.showPopup = true;
          this.hideLoader();
          this.error=true;
          return;
        }
      }       

      var url_VerifyEMail = `${this.appConfig.apiBaseUrl}${Constants.id4.getVerifyEmailAddress}?Email=${this.group.value.email}`;
      this.httpUtility.getByOptions(url_VerifyEMail, { responseType: 'text' })
        .subscribe((res: any) => {          
          if (res == "Email Verified Success") {            

            let postData_ID4 = {
              "email": this.group.value.email,
              "password": this.group.value.password,
              "loginAfterRegister": true,
              "LoginIfUserExists": true,
              "roleType": "Member"
            };
            
          
            var url_ID4 = `${this.appConfig.authConfig.id4Config.authority}${Constants.id4.registerApi}${this.appConfig.Source}`;
           this.httpUtility.post(url_ID4, postData_ID4)
              .subscribe(identityServerResponse => {
                userId = identityServerResponse.id;
                var userIdtoken = identityServerResponse.userIdtoken;
                
                //Begin Member sign Up logic
            
                  let codeVal = this.registrationGroup.value.registrationCode;

                  var url = `${this.appConfig.apiBaseUrl}${Constants.id4.postMemberManagerData}${codeVal}`;
                  let membersignUpData  = new MemberSignUpModel(codeVal,codeVal, this.appConfig.Source, userId, this.group.value.firstName,
                  
                  this.group.value.lastName, localStorage.getItem('MemberDOB'), this.group.value.gender);
             
                  membersignUpData.address = new MemberAddress("1",this.group.value.address,this.group.value.city,this.group.value.state,this.group.value.zipCode,true, true, Constant.AIProwSource);
                  membersignUpData.phone = new MemberPhone(this.group.value.phoneType,this.group.value.mobileNumber, true, true, Constant.AIProwSource);
                  membersignUpData.emailAddress = new MemberEmail("1", this.group.value.email, true, true, Constant.AIProwSource);

                  let boolCaregiverType = this.group.value.addCaregiver.name === "No" ? false : true;

                  if(boolCaregiverType) 
                  {
                    let emailAddresses : MemberEmail[] = [new MemberEmail("1",this.group.value.caregiverEmail,true, true, Constant.AIProwSource)];
                    let phoneNumbers: MemberPhone[] = [new MemberPhone("Home",this.group.value.caregiverMobileNo,true, true, Constant.AIProwSource)];

                    membersignUpData.contact = new MemberContact("CareGiver",[Constant.memberPortalConsentType, Constant.talkToCaregiverConsentType],"None",
                    this.group.value.caregiverFirstName, this.group.value.caregiverLastName, true, true, emailAddresses,phoneNumbers, Constant.AIProwSource);              
                  }

                  this.httpUtility.postByOptions(url, membersignUpData,{responseType:'json'})
                    .subscribe((aphConnectorResponse: any) => {
                      if(aphConnectorResponse.Errors ==null)
                      {
                        localStorage.setItem('userIdtoken', userIdtoken);
                        this.router.navigate(['login']);
                      }
                      else{
                        this.errorMessageForMemberReg = aphConnectorResponse
                        this.showPopup = true;
                        this.hideLoader();
                      }
                    }, (error: any)=>{
                     var url_deleteUser_ID4 = `${this.appConfig.authConfig.id4Config.authority}${Constants.id4.deleteUserApi}${userIdtoken}${'/' + this.appConfig.Source}`;
                this.httpUtility.delete(url_deleteUser_ID4)
                  .subscribe(identityServerResponse => {
            }, errorResponse=>{
                    if(errorResponse && errorResponse.status == 400 && errorResponse.error){
                    this.errorMessageForMemberReg = errorResponse.error;                
                    }else{
                      this.errorMessageForMemberReg = Constants.GenericServerError;
                    }                
                    this.showPopup = true;
                    this.hideLoader();
                  });

                      this.hideLoader();
                    });

                //End Member sign Up logic
                    
              }, errorResponse=>{
                if(errorResponse && errorResponse.status == 400 && errorResponse.error){
                this.errorMessageForMemberReg = errorResponse.error;                
                }else{
                  this.errorMessageForMemberReg = Constants.GenericServerError;
                }                
                this.showPopup = true;
                this.hideLoader();
              });
          }
          else {
            this.errorMessageForMemberReg = res
            this.showPopup = true;
            this.hideLoader();
          }
         
        });

    } else {
      this.group.patchValue(
        {
          mobileNumber: this.phonepipe.transform(this.group.value.mobileNumber)
        })
      this.group.patchValue(
        {
          caregiverMobileNo: this.phonepipe.transform(this.group.value.caregiverMobileNo)
        })
      this.group.patchValue(
        {
          zipCode: this.phonepipe.transform(this.group.value.zipCode)
        })
        // this.errorMessageForMemberReg = 'All Fields are mandatory'
        // this.showPopup = true;
       this.hideLoader();
    }
    
    this.formSubmitted = true;
  }


  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  fieldTextTypeCF: boolean;
  toggleFieldTextTypeCF() {
    this.fieldTextTypeCF = !this.fieldTextTypeCF;
  }

  dateVal:any = ""  


  checkValue(str:any, max:any) {
    if(str == '00' || str == '0000'){
      return str;
    }
    if (str.charAt(0) !== '0' || str == '00') {
      var num:any = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '' + num : num.toString();
    };
    return str;
  };
  

  
  onDateInput(e:any) {
    
    let element = e.target;
    var input:any = element.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values:any = input.split('/').map(function(v:any) {
      return v.replace(/\D/g, '')
    });
    if (values[0]) values[0] = this.checkValue(values[0], 12);
    if (values[1]) values[1] = this.checkValue(values[1], 31);
       var output = values.map(function(v:any, i:any) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      }); 
     
    
   element.value = output.join('').substr(0, 14);
   this.isDate(element.value);
   this.dobValidation(element.value);
  }
  onDateSelect(e:any){
    var date = e
      ? e.month.toString().padStart(2,'0') + this.DELIMITER + e.day.toString().padStart(2,'0') + this.DELIMITER + e.year
      : '';
   this.isDate(date);
   this.dobValidation(date);
  }
  onDateBlur(e:any) {
    
    let element = e.target;
    var input = element.value;
    var values = input.split('/').map(function(v:any, i:any) {
      return v.replace(/\D/g, '')
    });
    var output = '';
  
    if (values.length == 3) {
      var year = parseInt(values[2]);
      var month = parseInt(values[0]) - 1;
      var day = parseInt(values[1]);
      if(year == 0 && month == -1 && day == 0)
      {
        output = "00/00/0000";
      }
      else{
        var d:any = new Date(year, month, day);
        if (!isNaN(d)) {
          var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
          output = dates.map(function(v) {
            v = v.toString();
            return v.length == 1 ? '0' + v : v;
          }).join('/');
        };
      };
      }    
     
    element.value = output;
    this.isDate(element.value);
    this.dobValidation(element.value)

  } 

  OnkeyUp(event){
  
     if( event.key === 'Backspace'){
    let element = event.target;
    var input:any = element.value;
    
      element.value = input;
      this.isDate(element.value);
      this.dobValidation(element.value);   
      }
      else{
        this.onDateInput(event);
      }
  }

  showLoader() {
    this.showSpinner = true;
    this.spinner.show(this.spinnerName);
  }

  hideLoader() {
    this.showSpinner = false;
    this.spinner.hide(this.spinnerName);
  }

 }


