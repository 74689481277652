import { Component, OnInit, Input } from '@angular/core';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent implements OnInit {
  public show = true;
  public names: any;
  public resetsFilter: any;
  public checkedAll = false;
  @Input() childProps: any;
  constructor() {
    if (!this.childProps) {
      this.childProps = {};
      this.childProps.filterList = [];
    }
  }

  ngOnInit() {
  }

  toggle(index) {
    if (this.childProps && this.childProps.filterList) {
      this.childProps.filterList[index].show = !this.childProps.filterList[index].show;
    }
  };
  checkAll(index, e) {
    if (e) {
      for (let c = 0; c < this.childProps.filterList[index].filter.length; c++) {
        this.childProps.filterList[index].filter[c].checked = true;
      };
    } else {
      for (let c = 0; c < this.childProps.filterList[index].filter.length; c++) {
        this.childProps.filterList[index].filter[c].checked = false;
      };
    }
  }
  clearFilter() {
    for (let c = 0; c < this.childProps.filterList.length; c++) {
      for (let f = 0; f < this.childProps.filterList[c].filter.length; f++) {
        this.childProps.filterList[c].filter[f].checked = false;
      };
    }
  }
}