import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';




export abstract class BaseApiClient {
  constructor() { }

  protected createErrorResponseHandler(

    errorCallback?: (error: HttpErrorResponse) => void
  ): (HttpErrorResponse) => Observable<never> {
    return (response: HttpErrorResponse) => {
      try {
        if (response.error instanceof ErrorEvent) {
          console.log('Client side error: ' + response.error.message);
        }
        else {
           console.log('Server side error: ' + response.url + ' ' + response.message);
        }

        if (errorCallback) errorCallback(response);
      }
      catch (innerError) {
        try {
          // Swallow exceptions in the error handler
          console.log(innerError, { 'outerException': response.toString() });
        }
        catch{ }
      }
      finally {
        // Rethrow for the observable pipeline
        return throwError(response);
      }
    };
  };

}
