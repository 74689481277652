//#region angular imports
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
//#endregion angular imports

//#region functional/model imports
import { LoaderService } from '@app/core/services/loader.service';
import { Observable } from 'rxjs';
//#endregion functional/model imports

@Injectable({ providedIn: 'root' })
export class HttpUtility {

  constructor(
    private http: HttpClient,
    private loader: LoaderService
  ) { }

  /**
   * performs http get api call based on http params
   * @param url
   * @param httpParams
   * @param showLoader
   */
  public get(url: string, httpParams?: HttpParams, showLoader: boolean = true): Observable<any> {
    if (url) {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      const options = { params: httpParams };
      return this.getByOptions(url, options, showLoader);
    }
  }

  /**
  * performs http get api call based on options
  * @param url
  * @param options
  * @param showLoader
  */
  public getByOptions(url: string, options: any, showLoader: boolean = true) {
    if (url) {
      if (showLoader) {
        this.loader.start();
      }
      return this.http.get(url, options)
        .pipe(
          catchError((e) => { throw e; }),
          finalize(() => {
            if (showLoader) {
              this.loader.complete();
            }
          })
        );
    }
  }

  /**
   * performs http put api call
   * @param url
   * @param body
   * @param showLoader
   */
  public put(url: string, body: any, httpParams?: HttpParams, showLoader: boolean = true) {
    if (showLoader) {
      this.loader.start();
    }
    if (!httpParams) {
      httpParams = new HttpParams();
    }
    const params = { params: httpParams };
    return this.putByOptions(url, body, params, showLoader);
  }

  /**
   * performs http post api call
   * @param url
   * @param body
   * @param httpParams
   * @param showLoader
   */
  public post(url: string, body: any, httpParams?: HttpParams, showLoader: boolean = true): Observable<any> {
    if (url) {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      const params = { params: httpParams };
      return this.postByOptions(url, body, params, showLoader);
    }
  }

  /**
 * performs http post api call
 * @param url
 * @param body
 * @param httpOptions
 * @param showLoader
 */
  public postByOptions(url: string, body: any, options: any, showLoader: boolean = true) {
    if (showLoader) {
      this.loader.start();
    }
    return this.http.post(url, body, options)
      .pipe(
        catchError((e) => { throw e; }),
        finalize(() => {
          if (showLoader) {
            this.loader.complete();
          }
        })
      );
  }

  /**
   * performs http put api call
   * @param url
   * @param body
   * @param httpOptions
   * @param showLoader
   */
  public putByOptions(url: string, body: any, options: any, showLoader: boolean = true) {
    if (showLoader) {
      this.loader.start();
    }
    return this.http.put(url, body, options)
      .pipe(
        catchError((e) => { throw e; }),
        finalize(() => {
          if (showLoader) {
            this.loader.complete();
          }
        })
      );
  }

  /**
   * performs http delete api call
   * @param url
   * @param httpParams
   * @param showLoader
   */
  public deleteByOptions(url: string, options: any, showLoader: boolean = true) {
    if (url) {
     
      
      if (showLoader) {
        this.loader.start();
      }
      return this.http.delete(url, options)
        .pipe(
          catchError((e) => { throw e; }),
          finalize(() => {
            if (showLoader) {
              this.loader.complete();
            }
          })
        );
    }
  }
/**
   * performs http delete api call
   * @param url
   * @param httpParams
   * @param showLoader
   */
public delete(url: string, httpParams?: HttpParams, showLoader: boolean = true) {
  if (url) {
    if (httpParams === undefined) {
      httpParams = new HttpParams();
    }
    const params = { params: httpParams };
    if (showLoader) {
      this.loader.start();
    }
    return this.http.delete(url, params)
      .pipe(
        catchError((e) => { throw e; }),
        finalize(() => {
          if (showLoader) {
            this.loader.complete();
          }
        })
      );
  }
}

}
