import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { Ng5SliderModule } from 'ng5-slider';

import { LayoutRoutingModule } from '@app/layout/layout-routing.module';
import { HeaderComponent } from '@app/layout/header/header.component';
import { FooterComponent } from '@app/layout/footer/footer.component';
import { BreadcrumbComponent } from '@app/layout/shared/breadcrumb/breadcrumb.component';
import { DefaultComponent } from '@app/layout/default/default.component';
import { GroupFilterComponent } from '@app/layout/shared/group-filter/group-filter.component';
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthDirectivesModule } from '@colibrium/auth-lib';
import { ControlsModule } from '@shared/controls/controls.module';
import { AppendEnvPipe } from '@shared/pipes/append-env.pipe';
import { QuickLinkComponent } from './quick-link/quick-link.component';
import { MenuRenderComponent } from './shared/menu-render/menu-render.component';
import { LeftPanelComponent } from './left-panel/left-panel.component';
import { MenuComponent } from './left-panel/menu/menu.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule,
    AngularSvgIconModule,
    BreadcrumbModule,
    NgbModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    TextMaskModule,
    Ng5SliderModule,
    FormsModule,
    ReactiveFormsModule,
    AuthDirectivesModule,
    ControlsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LeftPanelComponent,
    BreadcrumbComponent,
    DefaultComponent,
    GroupFilterComponent,
    QuickLinkComponent
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LeftPanelComponent,
    BreadcrumbComponent,
    DefaultComponent,
    GroupFilterComponent,
    AppendEnvPipe,
    QuickLinkComponent,
    MenuRenderComponent,
    MenuComponent
  ]
})
export class LayoutModule { }
