import { Component, OnInit, Input, ContentChild, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Constant } from '@shared/utilities/constant';
import { ListCardConfiguration } from '@shared/controls/models/list-card-configuration';
import { PaginatorModel } from '@shared/controls/models/paginator.model';
import { ListCardFieldFilter } from '@shared/controls/models/list-card-field-filter';
import { LazyLoadOptions } from '@shared/controls/models/lazy-load-options.model';
import { ListCardSortColumn } from '@shared/controls/models/list-card-sort-column.model';
import { ControlConstant } from '@shared/controls/control.constant';
import { PaginationModel } from '@shared/models/pagination.model';
import { SortColumn } from '@shared/controls/models/sort-column.model';
import { PaginationFilter } from '@shared/models/pagination-filter.model';
import { PaginationOperator } from '@shared/enums/pagination-operator';

@Component({
  selector: 'app-list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.scss']
})
export class ListCardsComponent implements OnInit, OnDestroy {

  @Input() configurations: ListCardConfiguration;
  @Input() totalCount: number = null;

  @Input() set data(rows: any[]) {
    this.orignalRecords = rows;
    this.initializeDatarows(rows);
  }

  @Output() onChange = new EventEmitter<PaginationModel>();
  @Output() onAddNew = new EventEmitter<null>();

  @ContentChild(TemplateRef) template: TemplateRef<any>;

  public displayRecords: any[] = [];
  public filteredRecordsCount: number;
  public individualFilterRecordsCount: number[];

  public paginator: PaginatorModel;
  public lazyLoadOptions: LazyLoadOptions;
  public filteredRowsCounts: number[] = [];
  public listCardConstants = ControlConstant.ListCardConstants;

  public get isFiltersExist(): boolean {
    return _.get(this.configurations, 'fieldFilters.length', 0) > 0;
  }

  public get isGlobalSearchEnabled(): boolean {
    return _.get(this.configurations, 'globalSearchColumns.length', 0) > 0;
  }

  public get isSortByColumnsExist(): boolean {
    return _.get(this.configurations, 'sortByColumns.length', 0) > 0;
  }

  public get isLazyLoadEnabled() {
    return _.get(this.configurations, 'lazyLoadEnabled', false);
  }

  public get isPaginationEnabled() {
    return _.get(this.configurations, 'paginationEnabled', false);
  }

  public get isLoading() {
    return this.totalCount == undefined || null;
  }
  public sortConfigurations: ListCardSortColumn[] = [];
  public fieldFilterConfigurations: ListCardFieldFilter[] = [];

  private orignalRecords: any[];
  private sortedFilteredRecords: any[] = [];
  private searchChangeSubject: Subject<string> = new Subject<string>();
  private get paginationModel() {
    return {
      pageNumber: this.paginator.pageNumber,
      pageSize: this.paginator.pageNumber,
      sortFields: this.toSortColumn(this.sortConfigurations),
      filters: this.toFilterColumn(this.fieldFilterConfigurations)
    };
  }
  constructor() {
    this.lazyLoadOptions = this.listCardConstants.defaultLazyLoadOptions;
    this.paginator = this.listCardConstants.defaultPaginator;
  }

  ngOnInit() {
    this.setDefaultSortConfigurations();
    this.setDefaultFieldFilterConfigurations();

    this.searchChangeSubject
      .pipe(
        debounceTime(Constant.autoSearch.debounceTime),
      )
      .subscribe((searchText: string) => {

        const globalSearchRecords = this.globalSearchRecords(this.orignalRecords, this.configurations.globalSearchColumns, searchText);

        this.paginator = this.listCardConstants.defaultPaginator;

        this.initializeDatarows(globalSearchRecords);
      });
  }

  ngOnDestroy(): void {
    this.searchChangeSubject.unsubscribe();
  }

  /**
   * on global search change
   * @param value -
   */
  public searchChange(value: string) {
    this.searchChangeSubject.next(value);
  }


  /**
   * pagination change event.
   * @param pagination -
   */
  public onPaginatorChange(pagination: PaginatorModel) {
    this.paginator = pagination;
    if (this.isLazyLoadEnabled) {
      this.onChange.emit(this.paginationModel);
    } else {
      this.paginateDataRows();
    }
  }

  /**
   * on sort item click
   * @param index -
   */
  public onSortItemClick(index: number) {
    for (let i = 0; i < this.sortConfigurations.length; i++) {
      if (i === index) {
        this.sortConfigurations[i].isActive = true;
      } else {
        this.sortConfigurations[i].isActive = false;
      }
    }
  }

  /**
   * 
   * @param index toggle sort type.
   */
  public toggleSortType(index: number) {
    if (this.sortConfigurations[index].sortType === this.listCardConstants.asc) {
      this.sortConfigurations[index].sortType = this.listCardConstants.desc;
    } else {
      this.sortConfigurations[index].sortType = this.listCardConstants.asc;
    }
  }

  /**
   * on sort reset
   */
  public onSortReset() {
    this.setDefaultSortConfigurations();
    this.initializeDatarows(this.orignalRecords);
  }

  /**
   * apply Sort
   */
  public applySort() {
    if (this.isLazyLoadEnabled) {
      this.onChange.emit(this.paginationModel);
    } else {
      this.paginator = this.listCardConstants.defaultPaginator;
      this.initializeDatarows(this.orignalRecords);
    }
  }


  /**
   * on Add new
   */
  public addNew() {
    this.onAddNew.emit();
  }

  /**
   * Change rows visibility based on hide by field
   * @param visibility -
   */
  public filterByField(index: number) {
    this.setFieldFilterActiveStatus(index);
    if (this.isLazyLoadEnabled) {
      this.onChange.emit(this.paginationModel);
    } else {
      this.paginator = this.listCardConstants.defaultPaginator;
      this.initializeDatarows(this.orignalRecords);
    }
  }

  /**
   * ListCardFieldFilter array to PaginationFilter array
   * @param listCardFieldFilters -
   */
  private toFilterColumn(listCardFieldFilters: ListCardFieldFilter[]): PaginationFilter[] {
    const fieldFilters: PaginationFilter[] = [];
    if (listCardFieldFilters) {
      const listCardFieldFiltersToMap = listCardFieldFilters.filter(f => f.isActive);

      listCardFieldFiltersToMap.forEach(listCardfilter => {
        const fieldFilter: PaginationFilter = {
          property: listCardfilter.property,
          value: listCardfilter.value.toString(),
          operator: PaginationOperator.Equals
        };
        fieldFilters.push(fieldFilter);
      });
    }
    return fieldFilters;
  }

  /**
   * ListCardSortColumn array to SortColumn array
   * @param listCardSortColumns -
   */
  private toSortColumn(listCardSortColumns: ListCardSortColumn[]): SortColumn[] {
    const sortColumns: SortColumn[] = [];
    if (listCardSortColumns) {
      const sortColumnsToMap = listCardSortColumns.filter(f => f.isActive);

      sortColumnsToMap.forEach(listCardSortCol => {
        sortColumns.push({ property: listCardSortCol.property, sortType: listCardSortCol.sortType });
      });
    }
    return sortColumns;
  }

  /**
   * set default field filter configurations
   */
  private setDefaultFieldFilterConfigurations() {
    this.fieldFilterConfigurations = _.cloneDeep(_.get(this.configurations, 'fieldFilters', []));
  }

  /**
   * set default sort configurations
   */
  private setDefaultSortConfigurations() {
    this.sortConfigurations = _.cloneDeep(_.get(this.configurations, 'sortByColumns', []));
  }

  /**
   * apply global search.
   * @param rows 
   * @param globalSearchColumns 
   * @param searchText 
   */
  private globalSearchRecords(rows: any[], globalSearchColumns: string[], searchText: string): any[] {
    let globalFilterData = rows;
    if (globalSearchColumns && _.get(searchText, 'length', 0)) {
      globalFilterData = globalFilterData.filter(d => this.evaluateGlobalSearchPredicate(d, globalSearchColumns, searchText));
    }
    return globalFilterData;
  }

  /**
   * evaluate global search predicate.S
   * @param data 
   * @param globalSearchColumns 
   * @param searchText 
   */
  private evaluateGlobalSearchPredicate(data: any, globalSearchColumns: string[], searchText: string): boolean {
    for (let i = 0; i < globalSearchColumns.length; i++) {
      if (_.get(data, globalSearchColumns[i], '').toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * paginate eager loaded data.
   */
  private paginateDataRows() {

    const start = (this.paginator.pageNumber - 1) * this.paginator.pageSize;
    let end = (this.paginator.pageNumber) * this.paginator.pageSize;
    this.filteredRecordsCount = this.sortedFilteredRecords.length;

    if (end > this.filteredRecordsCount) {
      end = this.filteredRecordsCount;
    }

    this.displayRecords = this.sortedFilteredRecords.slice(start, end);
  }

  private setFieldFilterActiveStatus(index: number) {
    for (let i = 0; i < this.fieldFilterConfigurations.length; i++) {
      if (i === index) {
        this.fieldFilterConfigurations[i].isActive = true;
      }
      else {
        this.fieldFilterConfigurations[i].isActive = false;
      }
    }
  }

  /**
   * initialize data rows
   * @param rows 
   */
  private initializeDatarows(dataRows: any[]) {
    if (!this.isLazyLoadEnabled && dataRows) {

      const sortedRecords = this.sortRecords(dataRows, this.sortConfigurations);

      const filteredOutput = this.filterRecords(sortedRecords, this.fieldFilterConfigurations);

      this.sortedFilteredRecords = filteredOutput.filteredRecords;
      this.individualFilterRecordsCount = filteredOutput.filteredRowsCounts;

      this.paginateDataRows();

    } else {
      this.displayRecords = dataRows;
    }
  }

  /**
   * filter records counts
   * @param rows 
   * @param fieldFilters 
   */
  private filterRecordsCounts(rows: any[], fieldFilters: ListCardFieldFilter[]): number[] {
    let filteredRowsCounts: number[] = [];
    if (fieldFilters) {
      for (let i = 0; i < fieldFilters.length; i++) {
        const length = rows.filter(r => _.get(r, fieldFilters[i].property, false) === fieldFilters[i].value).length;
        filteredRowsCounts.push(length);
      }
    }

    return filteredRowsCounts;
  }

  /**
   * filter records for given data
   * @param rows 
   * @param fieldFilters - field filters
   */
  private filterRecords(data: any[], fieldFilters: ListCardFieldFilter[]) {
    let filteredRecords: any[] = data;
    let filteredRowsCounts: number[] = [];

    if (data && fieldFilters) {
      for (let i = 0; i < fieldFilters.length; i++) {
        const length = data.filter(r => _.get(r, fieldFilters[i].property, false) === fieldFilters[i].value).length;
        filteredRowsCounts.push(length);

        if (_.get(fieldFilters[i], 'isActive', false)) {
          filteredRecords = filteredRecords.filter(x => _.get(x, fieldFilters[i].property, false) === fieldFilters[i].value);
        }
      }
    }
    return { filteredRecords, filteredRowsCounts };
  }


  /**
   * sort records for given filters
   * @param data 
   * @param sortFilters 
   */
  private sortRecords(data: any[], sortFilters: ListCardSortColumn[]) {
    let sortRecords: any[] = data;
    const activeSortFilters = sortFilters.filter(f => _.get(f, 'isActive', false));
    if (data && activeSortFilters) {
      sortRecords = _.orderBy(sortRecords, activeSortFilters.map(column => {
        return sortRecords => {
          let value = sortRecords[column.property]
          return value != null ? value.toString().toLowerCase() : ''
        }
      }), activeSortFilters.map(s => s.sortType) as _.Many<boolean | "asc" | "desc">);
    }
    return sortRecords;
  }
}
