<ng-template [ngIf]="!isExpanded">
    <ng-container *ngTemplateOutlet="widgetModel"></ng-container>
</ng-template>

<ng-template #widgetModel>
    <div class="widget"
        [ngClass]="{'bg-white shadow-sm border-radius-5': !isFromDashboard || isExpanded, 'my-5': !isFromDashboard && !isExpanded}">
        <app-widget-title [titleConfig]="titleConfig" [isExpanded]="isExpanded" (onExpand)="expand($event)"
            (onRefresh)="onRefresh()">
        </app-widget-title>
        <div class="container content">
            <ng-container *ngIf="errorMessage; then error; else noError">
            </ng-container>

            <ng-template #error>
                <div class="api-error">
                    <h2>Oops!</h2>
                    {{errorMessage}}
                </div>
            </ng-template>

            <ng-template #noError>
                <ngx-spinner [name]="spinnerName" [fullScreen]="false" type="square-jelly-box" size="small">
                </ngx-spinner>
                <ng-container *ngIf="model">
                    <div class="{{description.descriptionContainerCss}}">
                        <span class="{{description.descriptionCss}}">{{description.text}}</span>
                    </div>
                    <form *ngIf="showSection" class="dynamic-form" [formGroup]="group">
                        <ng-container *ngFor="let sectionMap of model.sections; let i = index" class = "p-content">
                            <h4 class="TitleFont" [ngClass]= "sectionMap.section.cssClass">{{sectionMap.section.displayText}}</h4>
                            <abp-dynamic-field-widget [rowList]="sectionMap.rowFields"
                                [formSubmitted]="sectionMap.isFormSubmitted"
                                [pageForm]="group.controls[sectionMap.section.code]">
                            </abp-dynamic-field-widget>
                            <hr>
                        </ng-container>
                    </form>
                </ng-container>
            </ng-template>
        </div>
    </div>
</ng-template>

<abp-message-popup [(showPopup)]="showPopup" [model]="popupModel">
</abp-message-popup>

<abp-content-popup *ngIf="showContentPopup" [(showPopup)]="showContentPopup">
    <div header>
        <h4 class="TitleFont">{{popupHeader}}</h4>
    </div>
    <div body>
        <div *ngIf="showWarning" class="text-danger">
            <span>There is unsaved information, do you wish to save the changes?</span>
        </div>
        <form class="dynamic-form" [formGroup]="popupFormGroup" (change)="formTouched = true">
            <abp-dynamic-field-widget body [rowList]="popupSection.rowFields" [formSubmitted]="popupFormSubmitted"
                [pageForm]="popupFormGroup">
            </abp-dynamic-field-widget>
        </form>
    </div>
    <div footer class="row mb-4">
        <div class="col bottom-button">
            <div class="p-3 text-center d-md-flex align-items-center justify-content-end">
                <div class="blue-btn mr-md-2 mb-3 mb-md-0">
                    <a href="javascript:;" [ngClass]="isbtndisbaled ? 'disable-rounded-btn':'rounded-btn'" (click)="saveData()">Save</a>
                </div>
                <div class="blue-btn mr-md-2 mb-3 mb-md-0">
                    <a href="javascript:;" class="rounded-btn" (click)="addNewPopup(popUpSectionCode)">Clear</a>
                </div>
                <div class="blue-btn mr-md-2 mb-3 mb-md-0">
                    <a href="javascript:;" class="rounded-btn" (click)="onCloseBtnClick()">Close</a>
                </div>
            </div>
        </div>
    </div>
</abp-content-popup>
