//#region angular imports

//#endregion angular imports

//#region core imports


//#endregion core imports

//#region functional/model imports

import {
  FieldType,
  InputFieldModel,
  AbpLabelFieldModel,
  AbpDatepickerFieldModel,
  AbpTextFieldModel,
  AbpDropDownFieldModel,
  AbpRadioButtonFieldModel,
  AbpSwitchFieldModel,
  AbpCheckListFieldModel,
  SelectListItem,
  FieldModel,
  StringHelper,
  AbpCheckFieldModel
} from '@colibrium/abp-form-control-base';

import { Constant } from '@shared/utilities/constant';
import { AbpButtonFieldModel, AbpIconButtonFieldModel } from '@colibrium/abp-button';
import { FormFieldModel } from '@app/@dynamic-widget/models';
import { ControlFieldType } from '@app/@dynamic-widget/enums';
import { ConversionHelper } from '@shared/helper/conversion/conversion.helper';
import { AppConfigModel } from '../models/app-config.model';
import { AbpDynamicFieldDirective } from '@colibrium/abp-suite';

//#endregion functional/model imports

export class ControlHelper {

  //#region model properties
  //#endregion model properties

  //#region constructor
  //#endregion constructor

  //#region public functions

  /**
   * determines if given field is input type
   * @param field
   */
  public static isInputControl(field: FormFieldModel): boolean {
    if (field) {
      switch (field.fieldType) {
        case ControlFieldType.Checkbox:
        case ControlFieldType.CheckboxList:
        case ControlFieldType.Datepicker:
        case ControlFieldType.Dropdown:
        case ControlFieldType.RadioInLine:
        case ControlFieldType.RadioList:
        case ControlFieldType.Switch:
        case ControlFieldType.Textbox:
          return true;
        case ControlFieldType.Custom:
          return field.isInputField;
        default:
          return false;
      }
    }
    return false;
  }

  /**
   * gets abp field
   * @param colField
   * @param css
   * @param appConfigData
   */
  public static getAbpField(field: FormFieldModel, css: string = '', appConfigData: AppConfigModel = null): AbpButtonFieldModel | InputFieldModel | AbpLabelFieldModel {
    switch (field.fieldType) {
      case ControlFieldType.Textbox:
        return this.getTextFieldModel(field, css);
      case ControlFieldType.Dropdown:
        return this.getDropdownFieldModel(field, css, appConfigData);
      case ControlFieldType.RadioList:
        return this.getRadioListFieldModel(field, css);
      case ControlFieldType.Datepicker:
        return this.getDateFieldModel(field, css);
      case ControlFieldType.Label:
        return this.getLabelFieldModel(field, css);
      case ControlFieldType.RadioInLine:
        return this.getRadioListInlineFieldModel(field, css);
      case ControlFieldType.Switch:
        return this.getSwitchFieldModel(field, css);
      case ControlFieldType.CheckboxList:
        return this.getCheckListFieldModel(field, css);
      case ControlFieldType.Checkbox:
        return this.getCheckBoxFieldModel(field, css);
      case ControlFieldType.Button:
        return this.getButtonFieldModel(field, FieldType.Button, css);
      case ControlFieldType.ResetBtn:
        return this.getButtonFieldModel(field, FieldType.ResetButton, css);
      case ControlFieldType.SubmitBtn:
        return this.getButtonFieldModel(field, FieldType.SubmitButton, css);
      case ControlFieldType.LinkBtn:
        return this.getButtonFieldModel(field, FieldType.LinkButton, css);
      case ControlFieldType.Custom:
        return this.getCustomField(field);
      case ControlFieldType.IconButton:
        return this.getIconButtonFieldModel(field, FieldType.IconButton, css);
    }
    return null;
  }

  /**
   * get text field Model
   * @param field
   * @param css
   * @param blurHandler
   * @param changeHandler
   */
  public static getDateFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpDatepickerFieldModel {

    let abpField: AbpDatepickerFieldModel = new AbpDatepickerFieldModel();
    abpField.fieldControlType = FieldType.Date;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    //TODO:
    abpField.dateFormat = 'mm/dd/yyyy';
    return abpField;
  }

  /**
   * get text field Model
   * @param field
   * @param css
   * @param blurHandler
   * @param changeHandler
   */
  public static getTextFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpTextFieldModel {
    let abpField: AbpTextFieldModel = new AbpTextFieldModel();
    abpField.fieldControlType = FieldType.Textbox;

    abpField.placeHolder = field.placeHolder;

    abpField.blurEventHandler = blurHandler;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    return abpField;
  }
  /**
  * get text field Model
  * @param field
  * @param css
  * @param blurHandler
  * @param changeHandler
  */
  public static getCustomField(field: FormFieldModel) {
    let abpField: AbpTextFieldModel = new AbpTextFieldModel();

    abpField.fieldControlType = 20;

    abpField.displayLabelText = field.displayText;
    abpField.displayLabelCss = field.displayLabelCss;
    abpField.fieldCode = field.code;
    abpField.isVisible.next(!field.isDeleted);
    //abpField.cssClass = css;
    abpField.fieldValue = field.fieldValue;

    this.mapValidations(field, abpField);
    return abpField;
  }

  /**
   * get text field Model
   * @param field
   * @param appConfigData
   * @param css
   * @param blurHandler
   * @param changeHandler
   */
  public static getDropdownFieldModel(field: FormFieldModel,
    css: string,
    appConfigData: AppConfigModel,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpDropDownFieldModel {

    let abpField: AbpDropDownFieldModel = new AbpDropDownFieldModel();
    abpField.fieldControlType = FieldType.Dropdown;

    abpField.placeHolder = field.placeHolder;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    abpField.options = [];
    if (field.fieldValue) {
      abpField.options.push(this.getDefautSelectOption(appConfigData, false));
      if (field.options && field.options.length > 0) {
        field.options.forEach((item) => {
          abpField.options.push({ value: item.code, displayText: item.displayText, isSelected: item.code == field.fieldValue });
        });
      }
    } else {
      abpField.options.push(this.getDefautSelectOption(appConfigData));
      if (field.options && field.options.length > 0) {
        field.options.forEach((item) => {
          abpField.options.push({ value: item.code, displayText: item.displayText });
        });
      }
    }

    return abpField;
  }

  /**
  * get text field Model
  * @param field
  * @param css
  * @param blurHandler
  * @param changeHandler
  */
  public static getRadioListFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpRadioButtonFieldModel {

    let abpField: AbpRadioButtonFieldModel = new AbpRadioButtonFieldModel();
    abpField.fieldControlType = FieldType.RadioList;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    abpField.options = [];
    if (field.options && field.options.length > 0) {
      field.options.forEach((item) => {
        abpField.options.push({ value: item.code, displayText: item.displayText });
      });
    }
    return abpField;
  }

  /**
  * get text field Model
  * @param field
  * @param css
  * @param blurHandler
  * @param changeHandler
  */
  public static getRadioListInlineFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpRadioButtonFieldModel {

    let abpField: AbpRadioButtonFieldModel = new AbpRadioButtonFieldModel();
    abpField.fieldControlType = FieldType.RadioInlineList;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    abpField.options = [];
    if (field.options && field.options.length > 0) {
      field.options.forEach((item) => {
        abpField.options.push({ value: item.code, displayText: item.displayText });
      });
    }
    return abpField;
  }

  /**
 * get switch field Model
 * @param field
 * @param css
 * @param isDisabled
 * @param blurHandler
 * @param changeHandler
 */
  public static getSwitchFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpSwitchFieldModel {

    let abpField: AbpSwitchFieldModel = new AbpSwitchFieldModel();
    abpField.fieldControlType = FieldType.Switch;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    if (field.options && field.options.length == 2) {
      field.options.forEach((item) => {
        let value: boolean = ConversionHelper.toBoolean(item.code);
        if (value) {
          abpField.onSwitchDisplayText = item.displayText;
        }
        else {
          abpField.offSwitchDisplayText = item.displayText;
        }
      });
    }
    return abpField;
  }

  /**
  * get check list field Model
  * @param field
  * @param css
  * @param blurHandler
  * @param changeHandler
  */
  public static getCheckListFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpCheckListFieldModel {

    let abpField: AbpCheckListFieldModel = new AbpCheckListFieldModel();
    abpField.fieldControlType = FieldType.CheckboxList;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
    abpField.options = [];
    if (field.options && field.options.length > 0) {
      field.options.forEach((item) => {
        abpField.options.push({ value: item.code, displayText: item.displayText });
      });
    }
    abpField.noneOptionDisplayText = abpField.noneOptionDisplayText;
    abpField.allOptionDisplayText = abpField.allOptionDisplayText;

    return abpField;
  }

  /**
  * get checkbox field Model
  * @param field
  * @param css
  * @param blurHandler
  * @param changeHandler
  */
   public static getCheckBoxFieldModel(field: FormFieldModel,
    css: string,
    blurHandler: Function = null,
    changeHandler: Function = null): AbpCheckFieldModel {
    let abpField: AbpCheckFieldModel = new AbpCheckFieldModel();
    abpField.fieldControlType = FieldType.Checkbox;
    abpField.isLableOnLeft = field.isLableOnLeft;
    abpField.checked = field.fieldValue;
    abpField.fieldValue = field.fieldValue;

    this.mapInputFieldValue(field, css, abpField, blurHandler, changeHandler);
   
    return abpField;
  }

  /**
   * gets label abp field
   * @param field
   * @param css
   * @param clickHandler
   */
  public static getLabelFieldModel(field: FormFieldModel,
    css: string): AbpLabelFieldModel {
    let abpField: AbpLabelFieldModel = new AbpLabelFieldModel();
    abpField.displayLabelText = field.displayText;
    abpField.displayLabelCss = field.displayLabelCss;
    abpField.fieldCode = field.code;
    abpField.fieldControlType = FieldType.Label;
    abpField.isVisible.next(!field.isDeleted);
    abpField.cssClass = css;
    abpField.fieldValue = field.fieldValue;
    return abpField;
  }

  /**
   * gets button field model
   * @param field
   * @param fType
   * @param css
   * @param clickHandler
   */
  public static getButtonFieldModel(field: FormFieldModel,
    fType: FieldType,
    css: string = '',
    clickHandler: Function = null): AbpButtonFieldModel {
    let abpField: AbpButtonFieldModel = new AbpButtonFieldModel();
    abpField.displayLabelText = field.displayText;
    abpField.fieldCode = field.code;
    abpField.fieldControlType = fType;
    abpField.isVisible.next(!field.isDeleted);
    abpField.cssClass = field.fieldCss;
    abpField.clickEventHandler = clickHandler;
    return abpField;
  }

   /**
   * gets Icon button field model
   * @param field
   * @param fType
   * @param css
   * @param clickHandler
   */
    public static getIconButtonFieldModel(field: FormFieldModel,
      fType: FieldType,
      css: string = '',
      clickHandler: Function = null): AbpIconButtonFieldModel {
      let abpField: AbpIconButtonFieldModel = new AbpIconButtonFieldModel();
      abpField.displayLabelText = field.displayText;
      abpField.fieldCode = field.code;
      abpField.fieldControlType = fType;
      abpField.isVisible.next(!field.isDeleted);
      abpField.cssClass = field.fieldCss;
      abpField.iconClass = field.iconClass;
      abpField.toolTipText = field.tooltipText;
      abpField.clickEventHandler = clickHandler;
      return abpField;
    }

  /**
   * gets default select option
   * @param appConfigData
   * @param isSelected
   * @param defaultValue
   */
  public static getDefautSelectOption(appConfigData: AppConfigModel, isSelected: boolean = true, defaultValue: any = '') {
    let defaultOpt: SelectListItem = new SelectListItem();
    defaultOpt.isSelected = isSelected;
    defaultOpt.value = defaultValue;

    if (appConfigData) {
      defaultOpt.displayText = appConfigData.defaultSelectText;
    }

    return defaultOpt;
  }

  //#endregion public functions

  //#region private functions

  /**
   * maps input field values
   * @param edeField
   * @param css
   * @param abpField
   * @param value
   * @param blurHandler
   * @param changeHandler
   */
  private static mapInputFieldValue(edeField: FormFieldModel,
    css: string,
    abpField: InputFieldModel,
    blurHandler: Function = null,
    changeHandler: Function = null) {

    abpField.blurEventHandler = blurHandler;
    abpField.changeEventHandler = changeHandler;

    abpField.cssClass = edeField.fieldCss;
    abpField.displayLabelText = edeField.displayText;
    abpField.displayLabelCss = edeField.displayLabelCss;
    abpField.fieldCode = edeField.code;

    abpField.isDisabled = edeField.isDisabled;
    abpField.isVisible.next(!edeField.isDeleted);

    abpField.fieldValue = edeField.fieldValue;

    this.mapValidations(edeField, abpField);
  }

  /**
   * sets validation maps
   * @param edeField
   * @param abpField
   */
  private static mapValidations(edeField: FormFieldModel, abpField: InputFieldModel): void {
    abpField.validationMap = new Map<string, string>();
    if (edeField && edeField.validationMaps) {
      edeField.validationMaps.forEach((map) => {
        switch (map.validatorCode) {
          case Constant.validatorCode.required:
            abpField.isRequired = ConversionHelper.toBoolean(map.configValue);
            break;
          case Constant.validatorCode.maxlength:
            if (abpField instanceof AbpTextFieldModel) {
              abpField.maxLength = ConversionHelper.toNumber(map.configValue);
            }
            break;
          case Constant.validatorCode.minlength:
            if (abpField instanceof AbpTextFieldModel) {
              abpField.minLength = ConversionHelper.toNumber(map.configValue);
            }
            break;
          case Constant.validatorCode.pattern:
            if (abpField instanceof AbpTextFieldModel) {
              abpField.pattern = new RegExp(map.configValue);
            }
            break;
        }
        abpField.validationMap.set(map.validatorCode, StringHelper.format(map.validationMessage, map.configValue));
      });
    }
  }

  //#endregion private functions

}


