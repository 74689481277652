import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from '@shared/components/error/error.component';
import { AuthGuard } from '@colibrium/auth-lib';
import { SignInComponent } from './login/id4/sign-in/sign-in.component';
import { SignUpComponent } from './login/id4/sign-up/sign-up.component';
import { CaregiverSignUpComponent } from './login/id4/caregiver-sign-up/caregiver-sign-up.component';
import { LandingComponent } from './login/landing/landing.component';
import { truncate } from 'fs';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: 'landing',
    data:{header:true},
    pathMatch: 'full'
  },
  {
    path: 'user/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    data:{header:true}
  },
  {
    path: 'dash-board/:name',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data:{header:true}
  },
  {
    path: 'login',
    //canActivate: [AuthGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data:{header:true}
  },   
  { path: 'landing', component: LandingComponent, data:{header:false} },
  { path: 'register', component: SignUpComponent, data:{header:false} },
  { path: 'caregiverregister', component: CaregiverSignUpComponent, data:{header:true} },
  { path: 'caregiverregister/:memberContactId', component: CaregiverSignUpComponent, data:{header:true} },
  { path: 'login-redirect', component: SignInComponent, data:{header:true} },
  { path: 'error', component: ErrorComponent },
  // this should be at the bottom only
  { path: '**', redirectTo: '/error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
