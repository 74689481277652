export class CoreConstant {

  public static readonly authServiceConstants = class {
    public static readonly token: string = 'token';
    public static readonly isLoggedIn: string = 'isLoggedIn';
  }

  //#saml constants

  public static readonly saml = class {
    public static readonly authToken: string = 'auth-token';
    public static readonly samlJwtToken: string = 'jwtToken';
  }

  //#saml constants

  //#region urls
  public static readonly urls = class {
    public static readonly authenticationDefaultPath: string = 'api/Authentication/';
    public static readonly externalLogin: string = 'ExternalLogin';
    public static readonly apimAuthenticationSuffix: string = 'AuthenticationManager/';
  }
  //#endregion urls

  //#region tokens

  public static readonly token = class {
    public static readonly questionMark: string = '?';
    public static readonly dot: string = '.';
    public static readonly hyphen: string = '-';
    public static readonly slash: string = '/';
    public static readonly space: string = ' ';
    public static readonly plusEncodedValue: string = '%2B';
  };

  //#endregion tokens

  //#region storage keys

  public static readonly storageKey = class {
    public static readonly authToken: string = '';
  };

  //#endregion storage keys

  //#region auth keys

  public static readonly header = class {
    public static readonly authTokenName: string = 'Authorization';
    public static readonly authTokenValue: string = 'Bearer  {0}';
    public static readonly contentTypeName: string = 'Content-Type';
    public static readonly jsonContentType: string = 'application/json';
  };

  //#endregion auth keys

  //#region http status codes

  public static readonly statusCode = class {
    public static readonly unAuthorized: number = 401;
    public static readonly forbidden: number = 403;
    public static readonly serverError: number = 500;
    public static readonly fileNotFound: number = 404;
    public static readonly badRequest: number = 400;
  };

  //#endregion http status codes

  //#region log messages

  public static readonly logMessage = class {
    public static readonly clientApiError = (message: string): string => { return `Error: ${message}`; };
    public static readonly serverApiError = (errorCode: string, message: string): string => { return `Error Code: ${errorCode} \nMessage: ${message}`; };
  };

  //#endregion log messages

  //#region search patterns

  public static readonly searchPattern = class {
    public static readonly queryParam: string = '=([^&]*)';
  };

  //#endregion search patterns

  //#region  User Service Constants

  public static readonly userServiceConstants = class {
    public static readonly userDetailsKey = 'UserDetails';
    public static readonly featuresKey = 'Features';
    public static readonly apimAuthenticationSuffix = 'AuthenticationManager/';
    public static readonly apimAuthorizationSuffix = 'AuthorizationManager/';
    public static readonly authenticationBase = 'api/Authentication/';
    public static readonly authorizationBase = 'api/Authorize/';
    public static readonly userRoleBase = 'api/UserRole/';
    public static readonly validateUser = 'ValidateUser';
    public static readonly getUserRoles = 'GetUserRoles';
    public static readonly getAllFeaturePermission = 'GetAllFeaturePermission';
  };

  //#endregion User Service Constants

  //#region Toast constants

  public static readonly toastConstants = class {
    public static readonly fixedToastKey = 'fixedToast';
    public static readonly severityError = 'error';
    public static readonly severityInfo = 'Info';
    public static readonly severityWarn = 'warn';
    public static readonly badRequest = 'Bad Request';
  };

  //#endregion

    //#region values

    public static readonly trueVal: string = 'true';
    public static readonly falseVal: string = 'false';
    public static readonly bitTrueVal: string = '1';
    public static readonly bitFalseVal: string = '0';
    public static readonly yesTrueVal: string = 'yes';
    public static readonly noTrueVal: string = 'no';
  
    //#endregion values
    
}
