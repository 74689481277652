import { Pipe } from "@angular/core";
import * as moment from 'moment';
import { Constant } from '@shared/utilities/constant';

@Pipe({
  name: 'customDate'
})
export class DatePipe {
  transform(date) {
    if (date) {
        return moment(date).format(Constant.customDateFormat);
    }
  }
}
