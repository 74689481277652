//#region angular imports

import { Component, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { DynamicFormBase } from '@app/@dynamic-widget/dynamic-form.base';
import { ColumnModel, FormFieldModel } from '@app/@dynamic-widget/models';

//#endregion functional/model imports

@Component({
  selector: 'abp-field-row',
  templateUrl: './field-row-renderer.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldRowRendererComponent extends DynamicFormBase {

  //#region ViewChild

  @ViewChild('childDiv', { static: false }) private childFieldsDiv: ElementRef;

  //#endregion ViewChild

  //#region input properties

  @Input() parentColumn: ColumnModel<FormFieldModel>;

  //#endregion input properties

  //#region model properties

  public isAnyChildVisible: boolean;
  
  //#endregion model properties

  //#region constructor

  constructor(private changeDetection: ChangeDetectorRef) {
    super();
  }

  //#endregion constructor

  //#region get properties
  //#endregion get properties

  //#region component events

  ngOnInit() {
    this.setFieldDefaults();
    this.show = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rowField && changes.rowField.previousValue != changes.rowField.currentValue) {
      this.setFieldDefaults();
    }
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  //#endregion component events

  //#region service calls
  //#endregion service calls

  //#region public functions/events assoaciated with UI elements

  /**
   * determines if child question can be shown
   * @param child
   */
  public showChild(child: ColumnModel<FormFieldModel>): boolean {
    child.isRendered = this.canShowChild(this.parentColumn.fieldInfo, child);
    this.setChildRenderedStatus();
    return child.isRendered;
  }
  
  //#endregion public functions/events assoaciated with UI elements

  //#region private functions

  /**
   * sets field defaults
   */
  private setFieldDefaults() {
    this.hasChild = this.hasChildData(this.parentColumn);
    this.show = true;
  }

  /**
   * sets status of child rendered to true if any one of the child fields is rendered else false
   */
  private setChildRenderedStatus() {
    if (this.hasChild) {
      let status = false;
      this.parentColumn.childList.forEach((row) => {
        if (row.columns.findIndex(x => x.isRendered) > -1) {
          status = true;
        }
      });
      this.isAnyChildVisible = status;
      if (this.childFieldsDiv && this.childFieldsDiv.nativeElement && this.childFieldsDiv.nativeElement.classList) {
        if (this.isAnyChildVisible) {
          if (!this.childFieldsDiv.nativeElement.classList.contains(this.parentColumn.childDivCss)) {
            this.childFieldsDiv.nativeElement.classList.add(this.parentColumn.childDivCss);
          }
        }
        else {
          if (this.childFieldsDiv.nativeElement.classList.contains(this.parentColumn.childDivCss)) {
            this.childFieldsDiv.nativeElement.classList.remove(this.parentColumn.childDivCss);
          }
        }
        
      }
    }
  }

  //#endregion private functions

}
