export class Constants{
    public static readonly googlePlus: string = 'googlePlus';
    public static readonly applicationTitle: string = 'applicationTitle';
    public static readonly twitter: string = 'twitter';
    public static readonly facebook: string = 'facebook';
    public static readonly privacyPolicy: string = 'privacyPolicy';
    public static readonly copyRights: string = 'copyRights';
    public static readonly termsAndConditions: string = 'termsAndConditions';
    public static readonly contactUs: string = 'contactUs';
    public static readonly blank: string = '_blank';
    public static readonly appSettings = 'api/ApplicationSettings/';
    public static readonly imageUpload = 'api/ImageUpload/';
    public static readonly type = 'Type/';
    
    public static readonly url = class 
    {
      public static readonly MemberPortal: string = 'MemberManager/';
      public static readonly getMemberBasicDetails: string = 'MemberManager/api/Member/MemberBasicDetails?MemberId=##memberId##&source=client';
      public static getMemberIDByEmail = 'MemberManager/api/MemberRegistration/GetMemberIDByEmail';
      public static getUserMemberDetailsByEmail = 'MemberManager/api/Member/##email##/DetailsByEmail';
      public static getUserDetailsURL = 'MemberManager/api/User/';
    };
}
