import { SafeHtml } from "@angular/platform-browser";

export class CreateOrUpdateSecureMessageDto {
    conversationId:string;
    memberId: string;
    subject: string;  
    text: string;
    from: string;
    to: string;
    agentReadDate?:string;
    memberReadDate?:string;
    rowSource: string;
    
}

export class SecureMessageListingDto {
    messageId:string;
    conversationId:string;
    memberId: string;
    subject: string;  
    text: string | SafeHtml;
    from: string;
    to: string;
    agentReadDate?:string;
    memberReadDate?:string;
    attachmentCount:number = 0;
    fulfillmentCount: number = 0;
    attachmentFulfillmentDocumentCount: number = 0;
    documentCount:number=0;
    isAgentUnRead: boolean;
    isMemberUnRead: boolean;
    rowSource: string;
    createdDate: string;
    deletedDate: string;
}

export class AttachmentDto  {
    messageId:string;
    attachmentId:string;
    fileLocation:string;
    fileName:string;    
    rowSource: string;
    createdDate: string;
    deletedDate: string;
}

export class SecureMessageListDto {
    data: CreateOrUpdateSecureMessageDto[] = [];
    count: number;
}

export class SecureMessageAttachmentDto {
    messageSubject: string;
    fileLocation: string;
    fileName: string;
    messageTo: string;
    messageFrom: string;
    memberID: string;
    attachmentID:string;
}

export class MessageRequestDto {
    pageSize: number;
    filters: FilterDto[];
    pageNumber: number
}

export class FilterDto {
    property: string;
    value: string;
    operator: string;
}

export class MessageConversionListingDto {
    messageId:string;
    conversationId:string;
    memberId: string;
    subject: string;  
    templateId:string;
    text: string | SafeHtml;
    from: string;
    to: string;
    agentReadDate?:string;
    memberReadDate?:string;
    attachmentCount:number = 0;
    rowSource: string;
    createdDate: string;
    deletedDate: string
    attachments: AttachmentDto[] = [];
    fullfillments: ViewConversionFullfillmentDto[] = [];
    documents: DocumentDto[]=[];

}


export class DocumentDto {
    createdBy: string;
    createdDate: string;
    documentName: string;
    documentPath: string;
    id: string;
    memberId: string;
    rowSource: string;
    isSelected?: boolean = false;
}

export class  FullfillmentDto {
    id: string;
    documentName: string;
    documentDescription: string;
    isSelected?: boolean = false;
    documentCode: string;
    documentTypeId: string;
    FileTypeId: string;
    DocumentVersion: string;
    Url: string;
}
 
export class  UpdateMemberReadDateDto {
    conversationId: string;
    agentReadDate?: string;
    memberReadDate:string;
}

export class MemberMessageResponseDto{
memberMessageId: string
  isNotFound: boolean
  errors: any[]
}

export class ViewConversionFullfillmentDto {
    id: string;
    documentName: string;
    documentDescription: string;
    rowSource: string;
    Url: string;

}
