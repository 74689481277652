import { Component, OnInit, Input } from '@angular/core';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { ImageModel } from '@shared/controls/models/image.model';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirectionType } from '@shared/controls/components/enums/redirection-type.enum';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit {
  @Input() src: string;
  @Input() fallbackSrc: string;
  @Input() class: string;
  public imageDetails: ImageModel;
  public imageObjectUrl: any;
  public isLoaded: boolean = false;
  public isWithRedirection: boolean;
  constructor(
    private router: Router,
    private httpUtility: HttpUtility,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.src) {
      this.httpUtility.get(this.src, null, false)
        .pipe(finalize(() => {
          this.isLoaded = true;
        }))
        .subscribe((res: ImageModel) => {
          if (res && res.content) {
            this.imageDetails = res;
            this.imageObjectUrl = this.getImageObjectUrl(res);
            if (res.redirectUrl && res.redirectionType) {
              this.isWithRedirection = true;
            }
          }
        },
          (error) => {
            if (this.fallbackSrc) {
              this.imageObjectUrl = this.fallbackSrc;
            }
          }
        );
    }
  }

  /**
   * on image click event
   */
  public onImageClick() {
    if (this.imageDetails.redirectionType === RedirectionType.internal) {
      this.router.navigateByUrl(this.imageDetails.redirectUrl);
    } else {
      window.open(this.imageDetails.redirectUrl, '_blank');
    }
  }

  /**
   * get image object url
   * @param imageModel -
   */
  private getImageObjectUrl(imageModel: ImageModel): any {
    return this.sanitizer.bypassSecurityTrustUrl(`data:image/${imageModel.format};base64, ${imageModel.content}`);
  }

}
