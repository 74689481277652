//#region angular imports

import { Component, Injectable, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { AbpLabelFieldModel, InputFieldModel, SelectListFieldModel } from '@colibrium/abp-suite';
import { ColumnModel, FormFieldModel } from '@app/@dynamic-widget/models';

//#endregion functional/model imports

@Component({
  template:''
})
export class DynamicFormBase {

  //#region input properties

  @Input() id: string;
  @Input() applicationNumber: string;
  @Input() formSubmitted: boolean;
  @Input() pageForm: FormGroup;

  //#endregion input properties

  //#region model properties

  public hasChild: boolean;
  public show: boolean = false;

  //#endregion model properties

  //#region constructor

  constructor() {
  }

  //#endregion constructor

  //#region get properties
  //#endregion get properties

  //#region component events
  //#endregion component events

  //#region service calls
  //#endregion service calls

  //#region public functions/events assoaciated with UI elements

  /**
   * determines if child question can be shown
   * @param parent
   * @param child
   */
  public canShowChild(parent: FormFieldModel, child: ColumnModel<FormFieldModel>): boolean {
    //If field is input field, check if answered. Else return true
    if (parent.abpField instanceof InputFieldModel ||
      parent.abpField instanceof SelectListFieldModel ||
      parent.abpField instanceof AbpLabelFieldModel) {
      if (this.isFieldAttempted(parent)) {
        //If trigger value is defined, check if parent answer matches trigger answer. Else return true
        if (child.fieldInfo.triggerParentFieldValue) {
          if (parent.abpField.fieldValue.toString() == child.fieldInfo.triggerParentFieldValue.code) {
            return true;
          }
        }
        else {
          return true;
        }
      }
    }
    else {
      return true;
    }
    return false;
  }

  /**
   * determines if parent has child
   * @param parentColumn
   */
  public hasChildData(parentColumn: ColumnModel<FormFieldModel>): boolean {
    if (parentColumn && parentColumn.childList && parentColumn.childList.length > 0) {
      return true;
    }
    return false;
  }

  //#endregion public functions/events assoaciated with UI elements

  //#region private functions

  /**
   * checks if field is answered
   * @param parent
   */
  private isFieldAttempted(field: FormFieldModel) {
    if (field.abpField instanceof InputFieldModel || field.abpField instanceof AbpLabelFieldModel) {
      if (field.abpField.fieldValue != undefined && field.abpField.fieldValue != null) {
        if (typeof field.abpField.fieldValue === 'string') {
          if (field.abpField.fieldValue.trim() != '') {
            return true;
          }
        }
        else {
          return true;
        }
      }
    }
    return false;
  }

  //#endregion private functions

}
