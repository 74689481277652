<div class="row text-right align-items-center">
  <div class="col-8 d-flex align-items-center">
    <span class="col-6">Items per page</span>
    <select class="col-2 custom-select form-control" (change)="onPageSizeChange($event.target.value)"
      [value]="paginationInfo.pageSize">
      <option *ngFor="let option of itemsPerPage" [value]="option">{{option}}</option>
    </select>
    <span class="col-3">{{paginationReport}}</span>
  </div>
  <div class="col-4">
    <ngb-pagination class="d-flex justify-content-end custom-pagination mb-0-ul" [collectionSize]="totalRecords"
      [(pageSize)]="paginationInfo.pageSize" [(page)]="paginationInfo.pageNumber"
      (pageChange)="onPageNumberChange($event)">
    </ngb-pagination>
  </div>
</div>
