//#region angular imports

import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

//#endregion angular imports

//#region core imports


//#endregion core imports

//#region functional/model imports

import { Constant } from '@shared/utilities/constant';
import { ControlHelper } from '@app/@dynamic-widget/form-helpers/control.helper';
import { FormFieldModel } from '@app/@dynamic-widget/models';
import { ConversionHelper } from '@shared/helper/conversion/conversion.helper';

//#endregion functional/model imports

export class ReactiveFormHelper {

  //#region model properties
  //#endregion model properties

  //#region constructor
  //#endregion constructor

  //#region public functions

  /**
   * gets validators for the field
   * @param inputField
   */
  public static getValidators(field: FormFieldModel): ValidatorFn[] {
    let validators: ValidatorFn[] = [];
    if (!field.isDeleted) {
      if (field.validationMaps) {
        field.validationMaps.forEach((map) => {
          switch (map.validatorCode) {
            case Constant.validatorCode.required:
              if (ConversionHelper.toBoolean(map.configValue)) {
                validators.push(Validators.required);
              }
              break;
            case Constant.validatorCode.minlength:
              let minLen = ConversionHelper.toNumber(map.configValue, 0);
              if (minLen > 0) {
                validators.push(Validators.minLength(minLen));
              }
              break;
            case Constant.validatorCode.maxlength:
              let maxLen = ConversionHelper.toNumber(map.configValue, 0);
              if (maxLen > 0) {
                validators.push(Validators.maxLength(maxLen));
              }
              break;
            case Constant.validatorCode.pattern:
              if (map.configValue) {
                validators.push(Validators.pattern(map.configValue));
              }
              break;
            default:
              break;
          }
        });
      }
    }
    return validators;
  }

  /**
   * gets form control
   * @param field
   */
  public static getFormControl(field: FormFieldModel): FormControl {
    if (ControlHelper.isInputControl(field)) {
      let validator: ValidatorFn[] = this.getValidators(field);
      //let formState: any = { value: field.fieldValue, disabled: field.isDisabled };
      let formState: any = field.fieldValue;
      return new FormControl(formState, { validators: validator, updateOn: 'change' });
    }
    else {
      return new FormControl('');
    }
  }

  /**
   * add form control to group
   * @param group
   * @param field
   */
  public static addFormControl(group: FormGroup, field: FormFieldModel): void {
    group.setControl(field.code, this.getFormControl(field));
  }

  //#endregion public functions

  //#region private functions

  //#endregion private functions

}


