import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuLink } from '@shared/models/menu-link.model';
import { MenuType } from '@shared/enums/menu-type.enum';
import { LayoutConstant } from '@layout/layout.constant';

@Component({
  selector: 'app-menu-render',
  templateUrl: './menu-render.component.html',
  styleUrls: ['./menu-render.component.scss']
})
export class MenuRenderComponent implements OnInit {

  @Input() menu: MenuLink[];
  @Input() isExpand: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
  }

/**
 * navigates to menu action
 * @param menuItem 
 */
  public navigate(menuItem: MenuLink) {
    if (menuItem.isInternalUrl) {
      if(menuItem.isNewTab)
      {
        window.open(menuItem.url);
      }
      else
      {
        this.router.navigateByUrl(menuItem.url);
      }
      
    }
    else {
      if (menuItem.isNewTab)
      {
        window.open(menuItem.url, LayoutConstant.blank);
      }
      else
      {
        window.open(menuItem.url, LayoutConstant.self);
      }
    }
  }

  /**
   * return image absolute path by name
   * @param name
   */
  public imageSrc(name: string) {
    return LayoutConstant.assets + name;
  }

  /**
   * Check whether it menu type or not
   * @param type 
   */
    public isMenuType(type: MenuType)
    {
        return type == MenuType.MenuLink;
    }
}
