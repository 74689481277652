export enum ControlFieldType {
  Unknown = 'Unknown',
  Label = 'Label',
  Textbox = 'Textbox',
  Datepicker = 'Datepicker',
  RadioInLine = 'RadioInLine',
  Switch = 'Switch',
  Checkbox = 'Checkbox',
  CheckboxList = 'CheckboxList',
  Dropdown = 'Dropdown',
  RadioList = 'RadioList',
  SubmitBtn = 'SubmitBtn',
  Button = 'Button',
  ResetBtn = 'ResetBtn',
  LinkBtn = 'LinkBtn',
  Custom = 'Custom',
  IconButton = 'IconButton'
}
