export class Constants {

    public static readonly urls = class {
        public static readonly zipCodeManagerUrl: string = 'CountyZipManager/';
        public static readonly brokerApiUrl: string = 'AgencyManager/';
        // County Manager URLs
        public static readonly getCountyandStateUrl: string = 'api/location/ZipCode';
        public static readonly isZipCodeExists: string = 'api/Location/IsZipCodeExists';

        // Broker Agency URLs
        public static readonly agentsGetURL: string = 'api/Broker/BrokerSearch';
        public static readonly getBrokerAgencyDetailsURL = 'api/Broker/BrokerAgencyDetails/';

        public static readonly menuLinkUrl: string = 'api/Menu';
        public static readonly MemberPortalUrl: string = 'UnifiedPortal/';
        public static readonly menuManagerUrl: string = 'MemberManager/';
        public static readonly MemberUser = 'MemberManager/api/Member/{MemberId}/User/{UserId}';
        public static readonly MemberContactUser = 'MemberManager/api/Member/{MemberContactId}/Contact/Register/{UserId}';
    };

    public static readonly memberSiteVisit = class {
        public static readonly url: string = 'api/MemberSiteVisit/SaveByUserId';
    };

    public static readonly id4 = class {
        public static authorizeCallbackUrl = '/connect/authorize/callback?';
        public static loginApi = '/api/accounts/login';
        //public static registerApi = '/api/accounts/register';
        public static isUserExistsApi = '/api/accounts/IsUserExists';
        public static getMemberValidateData = '/MemberRegistration/api/Member/GetMemberValidateData';
        public static clientIdParam = 'client_id';
        public static redirectUriParam = 'redirect_uri';
        public static responseTypeParam = 'response_type';
        public static scopeParam = 'scope';
        public static stateParam = 'state';
        public static codeChallengeParam = 'code_challenge';
        public static codeChallengeMethodParam = 'code_challenge_method';
        public static responseModeParam = 'response_mode';
        public static registerApi = '/api/Accounts/Register?source=';
        public static getMemberCodeValidateData = 'MemberManager/api/MemberRegistration/GetMemberCodeValidateData';
        public static postMemberManagerData = 'MemberManager/api/Member/';
        public static getVerifyEmailAddress = 'MemberManager/api/MemberRegistration/VerifyEmailAddress';
        public static getStatesList = 'MemberManager/api/MemberRegistration/GetStates';
        public static getAddressTypesList = 'MemberManager/api/GenericMasterData/GenericMasterData?genericMasterData=AddressType';
        public static getPhoneTypesList = 'MemberManager/api/GenericMasterData/GenericMasterData?genericMasterData=PhoneType';
        public static getMemberIDByEmail = 'MemberManager/api/MemberRegistration/GetMemberIDByEmail';
        public static getUserMemberDetailsByEmail = 'MemberManager/api/Member/##email##/DetailsByEmail';
        public static getMemberContactValidation = 'MemberManager/api/MemberRegistration/MemberContactValidation';
        public static getMemberContactDetails = 'MemberManager/api/Member/MemberContactDetails/';
        public static deleteUserApi = '/api/Accounts/User/';
        public static getMemberAddressDetails = 'MemberManager/api/MemberAddress/MemberAddressDetails?MemberId=##memberId##';
        public static getMemberPhoneDetails='MemberManager/api/MemberPhoneNumber/GetMemberPhoneDetails?MemberId=##memberId##&sourceType=##source##';
        public static loginByToken = '/Account/LoginByToken?';
    };

    public static readonly benefits = class {
        public static getPlansListByZip = 'MemberManager/api/Plans/##zipcode##';
        public static getBenefitGroupList = 'MemberManager/api/BenefitGroups?Ordered=ASC';
        public static getPlanBenefits = 'MemberManager/api/PlanBenefits/##zipcode##?planids=##plans##&benefitgroupids=##benefitgroupids##&keyword=##keyword##&order=ASC&pagenumber=1&pagesize=50';
        public static getLocalResoucesByZip = 'MemberManager/api/LocalResource/##zipcode##'
    };

    public static readonly messages = class{
        public static postMessageUrl: string = 'MemberManager/api/Message';
        public static getmessageMemberUrl: string = 'MemberManager/api/member/##memberId##/Message';
        public static uploadAttachmentUrl: string = 'MemberManager/api/MemberMessageAttachment/UploadAttachment/';
        public static downloadAttachmentUrl: string = 'MemberManager/api/MemberMessageAttachment/DownloadAttachment'; 
        public static viewMessageUrl: string = 'MemberManager/api/member/##conversationId##/ViewMessage';
        public static memberMessageCreateOrUpdateMessageUrl: string = 'MemberManager/api/member/CreateOrUpdateMessage';
        public static updateMemberReadUrl: string = 'MemberManager/api/member/UpdateReadDate'; 
        public static DownloadFulfillmentDocumentUrl: string='MemberManager/api/member/DownloadFulfillment';
        public static DownloadDocumentUrl: string ='MemberManager/api/MemberDocument/DownloadMemberDocument';
       
         
    }

    public static readonly GenericServerError = 'Unexpected error occurred.';
    public static postMemberBasicDetails: string = 'MemberManager/api/member/##memberId##/demographic?MemberSource=##source##';
    public static getCaregiverInfo: string = 'MemberManager/api/member/##memberId##/Contact/##contactcode##/Details?ContactSource=##source##&MemberSource=##source##';
    public static deleteCgPhone: string = 'MemberManager/api/member/##memberId##/Contact/##contactcode##/Phone/##phoneId##?MemberSource=##source##';
    public static deleteCgAddress: string = 'MemberManager/api/member/##memberId##/Contact/##contactcode##/Address/##addressId##?MemberSource=##source##';
    public static postCaregiverDemographicInfo: string = 'MemberManager/api/MemberContact';
    public static postCaregiverCommunicationPreference:string = 'MemberManager/api/member/##memberId##/Contact/##contactcode##/CommunicationPreference?ContactSource=##source##&MemberSource=##source##';
    public static postCaregiverAddress:string = 'MemberManager/api/member/##memberId##/Contact/##contactcode##/Address?ContactSource=##source##&MemberSource=##source##';
    public static postCaregiverPhone:string = 'MemberManager/api/member/##memberId##/Contact/##contactcode##/Phone?ContactSource=##source##&MemberSource=##source##';
    public static getMemberMessagesUrl:string = '';

}
