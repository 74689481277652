//#region angular imports

import { Component, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

//#endregion angular imports

//#region core imports

//#endregion core imports

//#region functional/model imports

import { RowModel, FormFieldModel } from '@app/@dynamic-widget/models';
import { DynamicFormBase } from '@app/@dynamic-widget/dynamic-form.base';

//#endregion functional/model imports

@Component({
  selector: 'abp-dynamic-field-widget',
  templateUrl: './dynamic-field-widget.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFieldWidgetComponent extends DynamicFormBase {

  //#region input properties

  @Input() rowList: RowModel<FormFieldModel>[] = [];

  //#endregion input properties

  //#region model properties
  
  //#endregion model properties

  //#region constructor

  constructor() {
    super();
  }

  //#endregion constructor

  //#region component events

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  //#endregion component events

  //#region service calls
  //#endregion service calls

  //#region public functions/events assoaciated with UI elements
  
  //#endregion public functions/events assoaciated with UI elements

  //#region private functions
  
  //#endregion private functions

}
