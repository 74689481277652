<header>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 text-center d-block d-lg-none">
        <p class="mb-4 header-font">Today is {{today | date:'fullDate'}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
        <!-- <img title="Icon" src="assets/ABC_Health_Logo.jpg" class="logo"> -->
        <!-- <div> -->
       
        <img src="../../../assets/Smart_step_Logo_Final_2_JPEG.png" class="branding_sagility">
      <!-- </div> -->
      </div>
     <div class="col-sm-12 col-md-6 text-right">
        <div class="right-links">
          <ul>
            <!-- <li>
              <a href="javascript:;" class="fev" (click)="showFevToggle()">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </a>
              <div *ngIf="showFev" class="drop-down-menu shadow-sm">
                <ul>
                  <li>
                    <a href="javascript:;">Settings</a>
                  </li>
                  <li>
                    <a (click)="logout()">Log out</a>
                  </li>
                </ul>
              </div>
            </li> -->
            <li class="d-none d-lg-block ">
              <p class="mb-0">Today is {{today | date:'fullDate'}}</p>
            </li>
            <!-- <li (click)="showAlertBar()">
              <a href="javascript:;" class="bell">
                <i class="fa fa-bell-o" aria-hidden="true"></i>
                <span class="badges">8</span>
              </a>
            </li> -->
            <li>
              <a href="javascript:;" class="admin" title="Policy Holder">
                <select  (change)="changeStatus($event)" class="text-wrap" >
                  <option
                  [value]="usersDetails?.memberId" [selected]="usersDetails?.memberId == currentMember">{{usersDetails?.firstName}} {{usersDetails?.lastName}}</option>
                  <option *ngFor="let u of usersDetails?.users"
                  [value]="u.memberId" [selected]="u.memberId == currentMember">{{u.firstName}} {{u.lastName}}</option>
                  <option >Logout</option>
                </select>
              </a>
              <div *ngIf="showAdmin" class="drop-down-menu shadow-sm"
                (click)="$event.stopPropagation()">
                <ul>
                  <li>
                    <a (click)="logout()">Logout</a>
                    <!-- <a href="javascript:;" routerLink="/login">Log out</a> -->
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>