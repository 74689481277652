<div class="group-filter-container">
    <div *ngFor="let groupFilters of childProps.filterList; let index = index">
        <h3 *ngIf="groupFilters.toggleDiv">
            <a href="javascript:;" (click)="toggle(index)">{{groupFilters.name}} <span [ngClass]="{'': groupFilters.show, 'active': !groupFilters.show}"></span></a>
        </h3>
        <h3 *ngIf="!groupFilters.toggleDiv" [class]="!groupFilters.accordion ? 'font-weight-600 mb-2 pb-2 border-bottom' : 'font-weight-600 mb-2'">{{groupFilters.name}}</h3>
        <div [class]="groupFilters.accordion ? 'grey-bg p-3' : 'p-3'" *ngIf="groupFilters.show">
            <div *ngIf="groupFilters.type == 'rangeSlider'">
                <div *ngFor="let groupFilter of groupFilters.filter">
                    <h4>{{groupFilter.name}}</h4>
                    <ng5-slider value="{{groupFilter.minValue}}" highValue="{{groupFilter.maxValue}}" [options]="groupFilter.options"></ng5-slider>
                </div>
            </div>
            <div class="group-filter-content" *ngIf="groupFilters.type == '' || groupFilters.type == undefined">
                <ul>
                    <li *ngIf="groupFilters.selectAll">
                        <label class="custom-chekbox">
                            <input type="checkbox" [(ngModel)]="checkedAll" (change)="checkAll(index, checkedAll? true:false)">
                            <span class="checkmark"></span>
                            <strong>Sellect All</strong>
                        </label>
                    </li>
                    <li *ngFor="let groupFilter of groupFilters.filter">
                        <label class="custom-chekbox">
                            <input type="checkbox" [(ngModel)]="groupFilter.checked">
                            <span class="checkmark"></span>
                            {{groupFilter.name}}
                        </label>
                    </li>
                </ul>
                <a href="javascript:;" *ngIf="groupFilters.addLink"><span></span>{{groupFilters.addLinkTitle}}</a>
            </div>
        </div>
    </div>

    <div class="col-12" *ngIf="childProps.bottomData != undefined">
        <div class="reset-filter-container" *ngIf="childProps.bottomData.reset">
            <a href="javascript:;" (click)="clearFilter()">
                <i class="fa fa-repeat" aria-hidden="true"></i> RESET FILTERS
            </a>
        </div>
    </div>
    <!-- <div *ngIf="childProps.bottomData != undefined">
        <h3>{{childProps.bottomData.name}}</h3>
        <div class="grey-bg p-3">
            <div class="group-filter-content">
                <ul class="group-filter-list">
                    <li *ngFor="let bottomDatas of childProps.bottomData.data">{{bottomDatas.name}}</li>
                </ul>
                <p>{{childProps.bottomData.subTitle}}</p>
                <button class="btn btn-blue border-radius100 paddingLR34 paddingTB10">{{childProps.bottomData.btnEsti}}</button>
            </div>
        </div>
        <div class="grey-bg p-3 mt-3">
            <div class="group-filter-content">
                <p><strong>Add dosage for your medications to see better estimates</strong></p>
                <a href="javascript:;"><span></span>Add Your Dosage</a>
            </div>
        </div>
    </div> -->
</div>