import { HttpUtility } from '@app/core/utilities/http.utility';
import { Constant } from '@shared/utilities/constant';
import { Constants as UrlConstants } from './constants';
import { AppConfig } from '@shared/models/app-config.model';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '@colibrium/auth-lib';
import { Subscription } from 'rxjs';
import { SessionModel } from '@shared/models/session.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
export class MemberSiteVisitService implements OnDestroy {
    private baseUrl: string;
    private renderer: Renderer2;

    tabChangeSubscription: Subscription;

    constructor(
        private httpUtility: HttpUtility,
        private userService: UserService,
        // private userComponent: UserComponent,
        rendererFactory: RendererFactory2,
        @Inject(Constant.appConfig) private appConfig: AppConfig) {
            this.renderer = rendererFactory.createRenderer(null, null);

            if (environment.production) {
                this.baseUrl = `${appConfig.apiBaseUrl}${UrlConstants.urls.menuManagerUrl}`;
            }
            else {
                this.baseUrl = `${appConfig.apiBaseUrl}`;
            }
        }

        public saveMemberSiteVisit(url: string, memberId: string ){
            let sessionId: string;

            try {
                // try to get session data from browser and pull Id
                const sessionInfo: SessionModel = JSON.parse(localStorage.getItem(Constant.StorageSession));
                sessionId = sessionInfo.Id;
            }
            catch {
                sessionId = null;
            }

            // if (this.userService.userInformation != null){
            const siteVisitRecord = {
                userId: this.userService.userInformation?.userId ?? this.appConfig.anonymousUserId,
                memberId: memberId ?? this.appConfig.anonymousMemberId,
                SessionID: sessionId,
                TimeStamp: new Date(),
                VisitedURL: url,
                Data: null,
                ClientIPAddress: null
            };

            const postUrl = `${this.baseUrl}${UrlConstants.memberSiteVisit.url}`;

            // if we're not logged in we need to provide a Username in header to post
            let options = null;
            if (this.userService.userInformation == null){
                options = {
                    headers: new HttpHeaders({
                        Username: 'AnonUser'
                    })
                };
            }

            const postObservable = options != null
                ? this.httpUtility.postByOptions(postUrl, siteVisitRecord, options)
                : this.httpUtility.post(postUrl, siteVisitRecord);

            postObservable
                .subscribe((res: any) => {
                    const resFin = res;
                });
            // }
        }

        ngOnDestroy(): void {
            // this.tabChangeSubscription.unsubscribe();
        }
}
