import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtility } from '@app/core/utilities/http.utility';
@Injectable({
  providedIn: 'root'
})
export class FormWidgetService {

  constructor(private httpUtility: HttpUtility) { }

  delete(url){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpUtility.deleteByOptions(url,{ headers, responseType: 'text'});
  }
  get(url){
  return this.httpUtility.get(url);
  }
}
