import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '@core/models/error.model';
import { LoggingService } from '@core/services/logging.service';

@Injectable()

export class GlobalErrorService {

  private errorInfo: ErrorModel;
  private isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private loggerService: LoggingService) {
    this.errorInfo = new ErrorModel();
  }

  get isGlobalError() {
    return this.isError.asObservable();
  }
  /**
   * gets global error info
   */
  public getErrorInfo(): ErrorModel {
    return this.errorInfo;
  }

  /**
   * gets global error state
   */
  public getGlobalErrorState(): boolean {
    return this.isError.getValue();
  }

  /**
   * handles error functionality
   * @param error
   */
  public onErrorHandler(error: ErrorModel) {
    //Log error
    this.errorInfo.errorLogResponse = this.loggerService.logError(error.errorMessage);

    //set the error info
    this.setErrorInfo(error);
  }

  /**
   * clears error state
   */
  public clearErrorState() {
    //reset error info
    this.resetErrorInfo();
  }

  /**
   * notifies of a global error occurence
   * @param value
   */
  public notifyGlobalError(value: boolean): void {
    this.isError.next(value);
  }

  /**
   * sets user info
   * @param user
   */
  private setErrorInfo(error: ErrorModel): void {
    if (error) {
      this.errorInfo.errorMessage = error.errorMessage;
      this.errorInfo.statusCode = error.statusCode;
      this.errorInfo.canNotify = error.canNotify;
      this.notifyGlobalError(error.canNotify);
    }
  }

  /**
   * resets user info
   */
  private resetErrorInfo(): void {
    this.notifyGlobalError(false);
    this.errorInfo = new ErrorModel();
  }

}
