  <div *ngIf="totalCount==0;else loadingTemplate">
    <div class="row justify-content-center pt-5">
      <h2>No Records Found</h2>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <ng-container *ngIf="isLoading;else listCardTemplate">
      <div class="row justify-content-center">
        <h2>Loading...</h2>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #listCardTemplate>
    <div class="row mt-20">
      <div class="col-12">
        <div class="censusdata-list-container">
          <ul>
            <li>
              <div class="filter-container">
                <div class="row d-flex align-items-center justify-content-between">
                  <div class="col-6">
                    <div *ngIf="isGlobalSearchEnabled" class="row d-flex align-items-center">
                      <div class="col-8">
                        <div class="filters-container custom-form">
                          <div class="row mb-0">
                            <div class="filter col-6 pr-0">
                              <input class="form-control pl-2" placeholder="Global Search" type="text"
                                (keyup)="searchChange($event.target.value)">
                            </div>
                            <div class="col-6 pl-0">
                              <div class="filter-dropdown-container">
                                <span></span>
                              </div>
                              <!-- <a href="javascript:;">
                              <svg-icon [applyCss]="true" src="assets/images/icons/refresh-icon.svg">
                              </svg-icon>
                            </a> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="isSortByColumnsExist" class="census-list-function text-right">
                      <div ngbDropdown class="d-inline-block">
                        <button class="btn text-primary" ngbDropdownToggle>
                          <i class="fa fa-sort" aria-hidden="true"></i>
                          <!-- <svg-icon [applyCss]="true" src="assets/images/icons/sort-icon.svg">
                          </svg-icon> -->
                          Sort
                        </button>
                        <div ngbDropdownMenu class="container-min-width">
                          <div class="container pl-2 pr-2">
                            <div class="col-12">
                              <div class="row justify-content-between"
                                *ngFor="let sortOption of sortConfigurations; let i = index">
                                <div class="col-8">
                                  <label>
                                    <input type="radio" class="" [checked]="sortOption.isActive" name="sortControls"
                                      (change)="onSortItemClick(i)" /> {{sortOption.displayText}}
                                  </label>
                                </div>
                                <div class="col-4">
                                  <button class="btn ml-2" (click)="toggleSortType(i)">
                                    <i *ngIf="sortOption.sortType === listCardConstants.asc"
                                      class="fa fa-sort-alpha-asc" aria-hidden="true"></i>
                                    <i *ngIf="sortOption.sortType === listCardConstants.desc"
                                      class="fa fa-sort-alpha-desc" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </div>
                              <hr />

                              <div class="row justify-content-center">
                                <button class="btn btn-sm btn-primary" (click)="applySort()">
                                  Filter
                                </button>
                                <button class="btn btn-sm" (click)="onSortReset()">
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a *ngIf="configurations && configurations.addNewEnabled" href="javascript:;" (click)="addNew()">
                        <svg-icon [applyCss]="true" src="assets/images/icons/compare-add-icon.svg"></svg-icon>
                        Add New
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-5">
        <div *ngIf="isFiltersExist" class="common-btn">
          <a class="blue-border-transparent-icon-btn pl-3 pr-3 pt-1 pb-1
            text-capitalize" *ngFor="let fieldFilter of fieldFilterConfigurations; let i = index" href="javascript:;"
            [ngClass]="{'active':fieldFilter.isActive}" (click)="filterByField(i)">
            <svg-icon [src]="fieldFilter.icon" [applyCss]="true"></svg-icon>
            {{fieldFilter.dispalyText}} ({{individualFilterRecordsCount[i]}})
          </a>
        </div>
      </div>
      <div class="col-12" *ngIf="filteredRecordsCount===0;else list_pagination">
        <div class="row justify-content-center pt-5">
          <h2>No Records Found</h2>
        </div>
      </div>
      <ng-template #list_pagination>
        <div class="col-sm-7">
          <app-paginator *ngIf="isPaginationEnabled" [displayRecordsCount]="totalCount"
            [totalRecords]="filteredRecordsCount" [paginationInfo]="paginator"
            (paginationChange)="onPaginatorChange($event)"></app-paginator>
        </div>

        <div class="col-12">
          <!-- <ng-scrollbar track="vertical" visibility="hover" compact="off"
            class="scroll-event global-search-container scroll-container-max-height border-top-bottom"> -->
          <div class="censusdata-list-container">
            <ul>
              <li *ngFor="let item of displayRecords; let i = index">
                <ng-container *ngTemplateOutlet="template;context:{ $implicit:item,index:i}">
                </ng-container>
              </li>
            </ul>
          </div>
          <!-- </ng-scrollbar> -->
        </div>

        <div class=" col-sm-6 offset-sm-6">
          <app-paginator *ngIf="isPaginationEnabled" [displayRecordsCount]="totalCount"
            [totalRecords]="filteredRecordsCount" [paginationInfo]="paginator"
            (paginationChange)="onPaginatorChange($event)">
          </app-paginator>
        </div>
      </ng-template>
    </div>
  </ng-template>
