import { FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

import { Constant } from '@shared/utilities/constant';

// custom validator to check that two fields match
export function DateCompareValidator(fromCtrl: string, toCtrl: string): ValidatorFn {
    return (formGroup: FormGroup) => {
        const fromControl = formGroup.controls[fromCtrl];
        const toControl = formGroup.controls[toCtrl];
        const fromDate = moment(fromControl.value, Constant.futureDateFarmat).toDate();
        const toDate = moment(toControl.value, Constant.futureDateFarmat).toDate();
        // set error on toControl if validation fails
        if (toControl.value && fromDate > toDate) {
            toControl.setErrors({ DateCompareValidator: true });
        } else if (toControl.hasError(Constant.dateCompareValidator)) {
            delete toControl.errors[Constant.dateCompareValidator];
        }
        return null;
    };
}
