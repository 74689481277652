import { Injectable } from '@angular/core';
import { IdentityModel } from '@shared/models/member.identities.model';
import { UserMemberDetailsModel } from '@shared/models/usermemberdetails';


@Injectable({
  providedIn: 'root'
})
export class UserMemberDetailsService {
usermemberdetails:UserMemberDetailsModel;
identity : IdentityModel;
  constructor() { }

  public saveMemberDetails(value: any) {
    sessionStorage.setItem('UserMemberDetails', JSON.stringify(value));
  }

  public getMemberDetails() {
    return sessionStorage.getItem('UserMemberDetails')
  }
  public removeMemberDetails() {
    sessionStorage.removeItem('UserMemberDetails');
  }

  public GetMemberIdentityBySource(source:string) {
  this.usermemberdetails = JSON.parse(this.getMemberDetails()) as UserMemberDetailsModel;
  
  if(this.usermemberdetails != null && this.usermemberdetails.activeUser != null && this.usermemberdetails.activeUser.identities != null){
      this.identity = this.usermemberdetails.activeUser.identities.filter(x=>x.source.toLowerCase()==source.toLowerCase()).find(x=>x.code);

      return this.identity.code;
    }
  }
}