import { HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpUtility } from '@app/core/utilities/http.utility';
import { AppConfig } from '@shared/models/app-config.model';
import { Constants } from '@shared/services/constants';
import { Constant } from '@shared/utilities/constant';
import { MenuItem, MessageService } from 'primeng/api';
import { PhonePipe } from '@shared/pipes/phone.pipe';
import { DynamicWidgetsService } from '@app/dynamic-widgets/dynamic-widgets.service';
import { HttpClient } from '@angular/common/http';
import { ConfirmedValidator } from '../sign-up/form-validators';
import {NgbDateParserFormatter,  NgbDateStruct,} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from '@shared/utilities/guid';
import { AuthenticationService } from '@colibrium/auth-lib';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {

    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month.toString().padStart(2,'0') + this.DELIMITER + date.day.toString().padStart(2,'0') + this.DELIMITER + date.year
      : '';
  }  
}

@Component({
  selector: 'app-caregiver-sign-up',
  templateUrl: './caregiver-sign-up.component.html',
  styleUrls: ['./caregiver-sign-up.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})

export class CaregiverSignUpComponent implements OnInit {
  group: FormGroup;
  items: MenuItem[];
  activeIndex = 0;
  form1 = true;
  form2 = false;
  displayform2 = false;
  disablecntrl = false;
  registrationGroup: FormGroup;
  public rcSubmitted = false;
  public formSubmitted = false;
  public showPopup = false;
  public maxDate = new Date();
  public yearRange: string;
  public error: boolean;
  public states: any[];
  public memberContactId: string;
  public regCode: string;
  comparePswdError = false;
  public serverError: string;
  public showServerError: boolean;
  public memberContactresult;
  public spinnerName: string = Guid.randomId;
  showSpinner: boolean = false;
  toolTipEventType: string = 'hover';
  tooltipPosition: string = 'top';

  today = new Date() ;
  minYear: number = this.today.getFullYear() - 120;
  maxYear: number = this.today.getFullYear();
  maxMonth: number = this.today.getMonth() +1;
  Date: number=this.today.getDate();
  values: any = this.today.getFullYear();
  valueDate:number=this.today.getFullYear();
  validYear = true; 


  date = this.today.getDate() > 9 ? this.today.getDate() : 
   `0${this.today.getDate()}`;
  month = this.today.getMonth() > 9 ? this.today.getMonth() + 1 : 
   `${this.today.getMonth() + 1}`;
   year = this.today.getFullYear();

  currentDate=(this.year+"-"+this.month+"-"+this.date)
  
isDate(inputDate){
  var date = moment(inputDate,"MM/DD/YYYY",true);
  if(!date.isValid()){
    this.validYear = false;
    return;
  }
  else{
    this.validYear = true;
  }
  
}
  dobValidation(date: any) {
    
    let datevali=new Date(this.currentDate)    
    this.values = new Date(date);
  
    
    if (datevali < this.values || date.substr(-4) < this.minYear || date.length!= 10){
      this.validYear = false;
    }
    else
    {
      this.validYear = true;  
    }
  
}
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private widgetService: DynamicWidgetsService,
    private httpUtility: HttpUtility,
    private router: Router,
    private messageService: MessageService,
    public datepipe: DatePipe,
    public phonepipe: PhonePipe,
    private route: ActivatedRoute,
    private _http: HttpClient,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private bpo: BreakpointObserver,
    @Inject(Constant.appConfig) private appConfig: AppConfig) { }
    selectedCategory: any = null;
    selectedCategoryes: any = null;
    readonly DELIMITER = '/';
    categories: any[] = [
      {name: 'Yes', key: 'Y'},
      {name: 'No', key: 'N'}
    ];

    categoriess: any[] = [
      {name: 'Yes', key: 'Y'},
      {name: 'No', key: 'N'}
    ];

    

  get rc() { return this.registrationGroup.controls; }
  get f() { return this.group.controls; }

  ngOnInit(): void {
    this.bpo.observe(['(max-width: 1024px)'])
      .subscribe(result => {
          if (result.matches) {
            this.toolTipEventType = 'focus';
            this.tooltipPosition = 'left'
          } else {
            this.toolTipEventType = 'hover';
            this.tooltipPosition = 'top';
          }
      });
    
    if(this.authService.isUserLoggedIn){
    window.sessionStorage.clear();
    sessionStorage.clear();    
 
    localStorage.clear();

    this.deleteAllCookies();
    window.location.reload();
    }

    this.route.paramMap.subscribe(params => {
      this.memberContactId = params.get('memberContactId');
  });
  
  this.route.queryParamMap.subscribe((queryParams) => {
     this.regCode = queryParams.get('Code');
  });
  
    this.group = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$')]],
      canText: [this.categories[1], [Validators.required]],
      mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9\-\]*$"),Validators.minLength(10)]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      addCaregiver: [this.categories[1], [Validators.required]]
    }
    , {
        validator: ConfirmedValidator('password', 'confirmPassword')
    });

    this.items = [
      { label: '' },
      { label: '' }
    ];
    this.registrationGroup = this.formBuilder.group({
      registrationCode: [this.regCode, [Validators.required]],
      dob: ['', [Validators.required]]
       // dob: ['', [Validators.required,Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]]
    });
    this.selectedCategory = this.categories[0];
    this.selectedCategoryes = this.categoriess[0];
    this.group.get('addCaregiver')
      .valueChanges
      .subscribe(value => {
        if (value.name === 'Yes') {
       
        }
       
      });
    let currentYear = new Date().getFullYear();
    this.yearRange = `${currentYear - 120}:${currentYear}`;
    
    this.getstates();

  }


  public logout() {
    // when we log out we want to remove session info so any new activity is regarded as a new session
    localStorage.removeItem(Constant.StorageSession);
    localStorage.clear();
    this.authService.logout();
  }

  getstates(){
    var stateurl=`${this.appConfig.apiBaseUrl}${Constants.id4.getStatesList}`;
    this.httpUtility.get(stateurl).subscribe((result: any) => {
      this.states = result;
    })
  }


  ngOnDestroy() {
   
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  prevPage() {
    this.showServerError = false;
    this.form1 = true;
    this.form2 = false;
    this.activeIndex = 0;
    window.location.reload();
  }
  RcodeError:boolean;
  Rcode:string;
  RcodeValidate(event:any){
    if(this.registrationGroup.value.registrationCode.length>6)
    {
      this.Rcode=this.registrationGroup.value.registrationCode;
      this.RcodeError=false;
    }
    else 
      this.RcodeError=true;
    
  }
  zcode: string;
  zerror: boolean;
  ZipValidate(event:any){
    if(this.group.value.zipCode.length== 5 || this.group.value.zipCode.length== 9)
    {
      var zipFormat = /^[0-9]*$/
      if(zipFormat.test(this.group.value.zipCode))
      {
        this.zerror=false;
      }
      else{
        this.zerror=true;
      }
    }
    else{
      this.zerror=true;
    }

     if(this.group.value.zipCode.length==9  && this.zerror==false)
    {
      let zip1=this.group.value.zipCode.substring(0,5);
      let zip2=this.group.value.zipCode.substring(5,9);
      this.zcode=zip1 + '-' + zip2;
    }
    else if(this.zerror==true)
    {
      this.zcode =  this.zcode.replace('-', '');
    }
  }
  
  errorMessageForMemberReg = "";
  nextPage() {
    this.showServerError = false;
     this.activeIndex = 1;
              this.form1 = false;
              this.form2 = true;
  }
  nextPage1() {
    this.showServerError = false;
    if (this.registrationGroup && this.registrationGroup.valid && !this.RcodeError) {
      this.showLoader();
      let codeVal = this.registrationGroup.value.registrationCode;
      var dob = this.datepipe.transform(this.values, 'MM/dd/yyyy');
      var url = `${this.appConfig.apiBaseUrl}${Constants.id4.getMemberContactValidation}?MemberContactRegCode=${codeVal}&MemberDOB=${dob}`;
      const headers = new HttpHeaders()
        .set('userName', 'MemberReg');
      this.httpUtility.get(url)
        .subscribe((memberContactres: any) => {
          if (memberContactres) {
              this.memberContactresult = memberContactres;
              this.memberContactId = memberContactres.memberContactId;
              this.group = this.formBuilder.group({
                       email: [memberContactres.emailAddress, [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$')]],
                       canText: [this.categories[1], [Validators.required]],
                       mobileNumber: [memberContactres.phoneNumber,Validators.required ],
                       password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
                       confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
                       addCaregiver: [this.categories[1], [Validators.required]]
                     }
                     , {
                         validator: ConfirmedValidator('password', 'confirmPassword')
                     });
                     this.displayform2 = true;
                     this.activeIndex = 1;
                      this.form1 = false;
                      this.form2 = true;
                      localStorage.setItem('MemberDOB', dob);
          }
          else
          {
            this.error = true;
          }
          this.hideLoader();
        }, (errorResponse: any) => {
          if(errorResponse && errorResponse.error){
            this.errorMessageForMemberReg = errorResponse.error[0];
          }else{
            this.errorMessageForMemberReg = "Unknown error occurred.";
          }
          this.hideLoader();
          this.showPopup = true;
        });
    }
    this.rcSubmitted = true;
  }
    

  submitForm() {
    this.showLoader();
    this.showServerError = false;
      this.group.patchValue(
        {
          mobileNumber: this.group.value.mobileNumber.replace(/-/g, '')
        })
    if (this.group && this.group.valid ) {
      var url_VerifyEMail = `${this.appConfig.apiBaseUrl}${Constants.id4.getVerifyEmailAddress}?Email=${this.group.value.email}`;
      this.httpUtility.getByOptions(url_VerifyEMail, { responseType: 'text' })
        .subscribe((res: any) => {
          if (res == "Email Verified Success") {
              let postData_ID4 = {
                "email": this.group.value.email,
                "password": this.group.value.password,
                "roleType": "Contact",
                "loginAfterRegister": true,
                "LoginIfUserExists": true
              };

            var url_ID4 = `${this.appConfig.authConfig.id4Config.authority}${Constants.id4.registerApi}`;
            this.httpUtility.post(url_ID4, postData_ID4)
              .subscribe(identityServerResponse => {
                if (identityServerResponse) {      
                  /* Send Member & User Ids */
                  var MemberUrlUrl = Constants.urls.MemberContactUser.replace("{MemberContactId}",this.memberContactId).replace("{UserId}",identityServerResponse.id);
                  var userMemberUrl = `${this.appConfig.apiBaseUrl}${MemberUrlUrl}`;  
                  this.httpUtility.post(userMemberUrl, null)
                  .subscribe(userMemberResponse => {
                    if (userMemberResponse) {
                      var message = Constant.caregiverSignupSuccessMessage;
                      this.messageService.add({
                        severity: Constant.severity.success,
                        summary: message,
                        detail: message
                      });
                      this.hideLoader();
                      this.router.navigate(['login']);
                    } else {
                      this.hideLoader();
                    }
                  });
                } else {
                    this.hideLoader();
                }
              },errorresponse =>{
                if(errorresponse && errorresponse.error){
                  this.serverError = errorresponse.error;
                }else{
                  this.serverError = "Unexpected error occurred. Please try again later.";
                }
                this.showServerError = true;
                this.hideLoader();
              });
            
          }
          else {
            this.errorMessageForMemberReg = res
            this.showPopup = true;
            this.hideLoader();
          }
          
        });

    } else {
      this.group.patchValue(
        {
          mobileNumber: this.phonepipe.transform(this.group.value.mobileNumber)
        })
        // this.errorMessageForMemberReg = 'All Fields are mandatory'
        // this.showPopup = true;
        this.hideLoader();
    }
    this.formSubmitted = true;
  }


  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  fieldTextTypeCF: boolean;
  toggleFieldTextTypeCF() {
    this.fieldTextTypeCF = !this.fieldTextTypeCF;
  }

  dateVal:any = ""  


  checkValue(str:any, max:any) {
    if(str == '00' || str == '0000'){
      return str;
    }
    if (str.charAt(0) !== '0' || str == '00') {
      var num:any = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '' + num : num.toString();
    };
    return str;
  };
  

  
  onDateInput(e:any) {
    
    let element = e.target;
    var input:any = element.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values:any = input.split('/').map(function(v:any) {
      return v.replace(/\D/g, '')
    });
    if (values[0]) values[0] = this.checkValue(values[0], 12);
    if (values[1]) values[1] = this.checkValue(values[1], 31);
       var output = values.map(function(v:any, i:any) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      }); 
     
    
   element.value = output.join('').substr(0, 14);
   this.isDate(element.value);
   this.dobValidation(element.value);
  }
  onDateSelect(e:any){
    var date = e
      ? e.month.toString().padStart(2,'0') + this.DELIMITER + e.day.toString().padStart(2,'0') + this.DELIMITER + e.year
      : '';
   this.isDate(date);
   this.dobValidation(date);
  }
  onDateBlur(e:any) {
    
    let element = e.target;
    var input = element.value;
    var values = input.split('/').map(function(v:any, i:any) {
      return v.replace(/\D/g, '')
    });
    var output = '';
  
    if (values.length == 3) {
      var year = parseInt(values[2]);
      var month = parseInt(values[0]) - 1;
      var day = parseInt(values[1]);
      if(year == 0 && month == -1 && day == 0)
      {
        output = "00/00/0000";
      }
      else{
        var d:any = new Date(year, month, day);
        if (!isNaN(d)) {
          var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
          output = dates.map(function(v) {
            v = v.toString();
            return v.length == 1 ? '0' + v : v;
          }).join('/');
        };
      };
      }    
     
    element.value = output;
    this.isDate(element.value);
    this.dobValidation(element.value)

  } 

  OnkeyUp(event){
  
     if( event.key === 'Backspace'){
    let element = event.target;
    var input:any = element.value;
    
      element.value = input;
      this.isDate(element.value);
      this.dobValidation(element.value);   
      }
      else{
        this.onDateInput(event);
      }
  }

  showLoader() {
    this.showSpinner = true;
    this.spinner.show(this.spinnerName);
  }

  hideLoader() {
    this.showSpinner = false;
    this.spinner.hide(this.spinnerName);
  }
}



