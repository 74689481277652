//#region angular imports
//#endregion angular imports

//#region core imports
//#endregion core imports

//#region functional/model imports

import { MasterEntityModel } from '@app/@dynamic-widget/models/master-entity.model';

//#endregion functional/model imports

export class SectionModel extends MasterEntityModel {

  //#region model properties
  public count: number;
  public cardHeaderCss: string;
  public cardBodyCss: string;
  //#endregion model properties

  //#region constructor

  constructor() {
    super();
  }

  //#endregion constructor

}
