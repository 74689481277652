<form [formGroup]="dateRangeForm" (ngSubmit)="apply()">
    <div class="card card-form">
        <div class="card-body">
            <div class="form-row align-items-center form-default">
                <div class="form-group col-md-5 col-lg-5">
                    <label for="fromDate">From Date</label>
                    <p>
                    <app-calendar formControlName="startDate" [config]="config" placeholder="Select start date" showIcon="true" id="fromDate">
                    </app-calendar>
                    </p>
                </div>
                <div class="form-group col-md-5 col-lg-5">
                    <label for="todate">To Date</label>
                    <p>
                    <app-calendar formControlName="endDate" [config]="config" placeholder="Select end date" showIcon="true" id ="todate">
                    </app-calendar>
                    </p>
                </div>
                <div class="form-group col-md-2 col-lg-2">
                    <button type="submit" [disabled]="!dateRangeForm.valid" class="btn btn-primary">Apply</button>
                </div>
            </div>
            <div *ngIf="dateRangeForm.get('endDate').errors?.DateCompareValidator"
                class="text-danger">
                <small>To Date should be greater than From Date</small>
            </div>
        </div>
    </div>
</form>