<div class="bg-image">
    <div class="container-flex ">
        <div class="d-flex5">
          <img src="../../../assets/pru-tab&mobile.png" alt="" width="100%">
        </div>
    
        <div class="d-flex3 ">
    
          <a href="/login" class="btn  btn-md"> Already Enrolled? Login Here. </a>
    
    
         
    
        </div>
        <!-- for moblie view login and enroll buttons -->
        <div class="row">
          <div class="col-sm-6">
            <a href="/login" class="btn  btn-md"> Already Enrolled? Login Here.</a>
    
          </div>
          
    
        </div>
       
        <div>
            <div class="d-flex heading-margin">
                <h3 class="header1 " style="color: #00cba2; font-weight: bolder;">Welcome to </h3>
             </div>
            <img src="../../../assets/Smart_step_Logo_Final_2_JPEG.png" alt="" class="branding_sagility" width="100%" >
          </div>

          <div class="d-flex landing_page_div_1" >
              <p class="Landing_page_content1"><b style="font-size: 1.3em; font-weight: 600;">Sagility Smart Step<sup>TM</sup></b> is a falls prevention program offered at no cost for Prudential policy holders. It is a safe and effective program focused on whole-person health that can support your goals for healthy living! By answering a few questions you will learn about your risk level and how to prevent a fall.</p>
          </div>
          <div class="d-flex">
              <p class="font-landing" ><b style="font-size: 1.3em; font-weight: 600;">Get Started Today!</b></p>
          </div>
    
  
              <div class="d-flex Landing_page_content2 landing_page_div_2" >
                  <ol>
                      <li>Simply click the Enroll Now button below to create your account and gain access to the Sagility Smart Step<sup>TM</sup> Program, online resources, and education that is personalized just for you.</li>
                      <li>To complete this process, make sure to have your unique code found on the introductory letter received by mail from Prudential handy.</li>
                  </ol>
              </div>
  
              <div class="d-flex landing_page_div_3" >
                  <p class="Landing_page_content3">If you prefer to enroll by phone or have questions, please call 1-888-413-2099. If you  use a TTY/TDD, call 1-888-413-2099. We are available for assistance  Monday through Friday, 8AM to 8PM EST. We look forward to introducing you to this exciting program soon!</p>
              </div>
              <br>
              <div class="d-flex">
                  <a href="/register" class="btn btn-lg ts-scroll ">Enroll Now !</a> 
              </div>
      </div>
  </div>